import React from "react";
import styled from "@emotion/styled";

// assets
import BgCircleBSVG from "assets/images/pc/bg_circle_b.svg";

const Circle01 = () => {
  return (
    <FloatRoot>
      <img
        src={BgCircleBSVG}
        className="bg_circle_b"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="300"
      />
    </FloatRoot>
  );
};

export default Circle01;

const FloatRoot = styled.div`
  & .bg_circle_b {
    position: absolute;
    margin: -400px 0 0 -500px;
  }
`;
