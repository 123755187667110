import * as yup from "yup";

/**
 * @file 아이디 찾기 유효성 검사 및 입력 체크
 * @author TW.Lee
 */
export default yup.object().shape({
  userPhone2: yup.string().required("핸드폰 번호를 입력하세요."),
  verifiCode: yup.string().required("인증번호를 입력하세요."),
});
