/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect, useCallback } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// components
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import Button from "components/Button/Button";
import Checkbox from "components/Checkbox/Checkbox";
import Policybox from "components/Policybox/Policybox";
import Row from "containers/LabelWithInput/LabelWithInput";
import Postcode from "components/Postcode/DaumPostcode";

// hooks
import useAlert from "hooks/useAlert";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// api
import authApis from "api/auth";

// utils
import {
  PREFIX_NUMBER_OPTIONS,
  CARD_COMPANY_OPTIONS,
  CARD_COMPANY_OPTIONS_EN,
} from "utils/constants";
import vaildationSchema from "utils/validation/partnerValidationSchema";
import vaildationSchemaEn from "utils/validation/partnerValidationSchemaEn";

const defaultValues = {
  PartnerEmail: "",
  PartnerPassword: "",
  PartnerPasswordCheck: "",
  PartnerName: "",
  PartnerTeam: "",
  PartnerLevel: "",
  userPhone1: "",
  userPhone2: "",
  verifiCode: "",
  Address1: "",
  Address2: "",
  Address3: "",
  companyName: "",
  OwnerPhone1: "",
  OwnerPhone2: "",
  companyFaxNumber01: "",
  companyFaxNumber02: "",
  companyFaxNumber03: "",
  businessNumber: "",
  OwnerName: "",
  TransferBank01: "",
  TransferBank02: "",
  PolicyCheck: "",
};

/**
 * @file 협력사 회원가입 페이지
 * @author TW.Lee
 */
const PartnerForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { noti } = useAlert();
  const btnRef = useRef();
  const codeRef = useRef();
  const [popup, setPopup] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [isSmsSand, setIsSmsSand] = useState(false);
  const [submitState, setSubmitState] = useState("schemako");
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const [enrollCompany, setEnrollCompany] = useState({
    address: "",
    zipcode: "",
  });

  const root = PartnerFormStyled(t("lang"));

  const UI = {
    schemako: vaildationSchema,
    schemaen: vaildationSchemaEn,
  };

  const {
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(UI[submitState]),
    mode: "onChange",
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (t("lang") === "EN") {
      setSubmitState("schemako");
    } else {
      setSubmitState("schemaen");
    }
  }, [t("lang")]);

  useEffect(() => {
    setValue("Address1", enrollCompany.zipcode);
    setValue("Address2", enrollCompany.address);
  }, [enrollCompany.zipcode, enrollCompany.address]);

  const signup = useMutation(
    () => {
      return authApis.signUpPartner({
        PartnerEmail: values.PartnerEmail,
        PartnerPassword: values.PartnerPassword,
        PartnerName: values.PartnerName,
        PartnerTeam: values.PartnerTeam,
        PartnerLevel: values.PartnerLevel,
        userPhone: values.userPhone1.value + values.userPhone2,
        companyName: values.companyName,
        OwnerPhone:
          values.OwnerPhone1.value + values.OwnerPhone2 !== undefined
            ? values.OwnerPhone1.value + values.OwnerPhone2
            : null,
        zipcode: enrollCompany.zipcode,
        addressItem: enrollCompany.address,
        detailAddress: values.Address3,
        faxNum:
          values.companyFaxNumber01 +
          values.companyFaxNumber02 +
          values.companyFaxNumber03,
        OwnerName: values.OwnerName,
        businessNum: values.businessNumber,
        bankNum: values.TransferBank01.value,
        accountNumber: values.TransferBank02,
      });
    },
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          navigate("/signup/joined", {
            replace: true,
            state: {
              day: dayjs().format("YYYY-MM-DD A hh:mm:ss"),
              role: t("partners"),
              email: values.PartnerEmail,
              name: values.PartnerName,
            },
          });
        }
      },
      onError: async (err) => {
        if (err.response.data.message === "duplicated email") {
          if (t("lang") === "EN") {
            noti("이미 가입된 회원입니다.");
          } else {
            noti("This user already exists.", "Confirm");
          }
        }

        if (err.response.data.message === "Invalid email") {
          if (t("lang") === "EN") {
            noti("이메일 형식이 올바르지 않습니다.");
          } else {
            noti("E-mail format is incorrect.", "Confirm");
          }
        }

        if (err.response.data.message === "business_num is duplicate") {
          if (t("lang") === "EN") {
            noti("이미 등록되어 있는 사업자등록번호입니다.");
          } else {
            noti("business number has already been registered.", "Confirm");
          }
        }

        if (err.response.data.message === "duplicated phone") {
          if (t("lang") === "EN") {
            noti("이미 가입된 회원의 휴대폰 번호입니다.");
          } else {
            noti("Already a registered phone number.", "Confirm");
          }
          setIsVerify(false);
          setIsSmsSand(false);
        }

        if (err.response.status === 500) {
          if (t("lang") === "EN") {
            await noti(
              "사용자 정보발급 오류가 발생했습니다.\n관리자에게 문의 해주세요."
            );
          } else {
            await noti(
              "An error occurred in issuing user information.\nPlease contact the manager.",
              "Confirm"
            );
          }
        }
      },
    }
  );

  // 문자전송 요청
  const smsSend = useMutation(
    () => {
      return authApis.smsSend({
        phoneNum: values.userPhone1.value + values.userPhone2,
      });
    },
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          if (t("lang") === "EN") {
            noti("인증코드가 전송되었습니다.");
          } else {
            noti("Verification code has been sent.", "Confirm");
          }
          setIsSmsSand(true);
        }
      },
      onError: async (err) => {},
    }
  );

  // 인증번호 검증 요청
  const verify = useMutation(
    () => {
      return authApis.smsVerify({
        phoneNum: values.userPhone1.value + values.userPhone2,
        verifyCodeNum: values.verifiCode,
      });
    },
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          if (t("lang") === "EN") {
            noti("인증되었습니다.");
          } else {
            noti("Certified.", "Confirm");
          }
          setIsVerify(true);
        }
      },
      onError: async (err) => {
        if (err.response.data.message === "not matched") {
          if (t("lang") === "EN") {
            noti("인증코드가 올바르지 않습니다. 인증번호를 재전송해주세요.");
          } else {
            noti(
              "Incorrect Verification Code.\nPlease try it again.",
              "Confirm"
            );
          }
          setIsVerify(false);
          setIsSmsSand(false);
          codeRef.current.focus();
        }
      },
    }
  );

  // 사업자등록번호 검증
  const validationBusiness = useCallback(() => {
    const bNum = values.businessNumber;
    const regsplitNum = bNum
      .replace(/-/gi, "")
      .split("")
      .map((item) => {
        return parseInt(item, 10);
      });

    if (regsplitNum.length === 10) {
      const regkey = [1, 3, 7, 1, 3, 7, 1, 3, 5];
      let regNumSum = 0;

      for (let i = 0; i < regkey.length; i++) {
        regNumSum += regkey[i] * regsplitNum[i];
      }

      regNumSum += parseInt((regkey[8] * regsplitNum[8]) / 10, 10);
      const regCheck =
        Math.floor(regsplitNum[9]) === (10 - (regNumSum % 10)) % 10;

      return regCheck;
    }
  }, [values.businessNumber]);
  const isBusi = validationBusiness();

  return (
    <div css={root}>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          handleSubmit(
            () => {
              if (isVerify && isBusi) {
                signup.mutate();
              }
              if (!isBusi) {
                if (t("lang") === "EN") {
                  noti("유효하지 않은 사업자등록번호입니다.");
                } else {
                  noti("Invalid business registration number.", "Confirm");
                }
              }
              if (!isVerify) {
                if (t("lang") === "EN") {
                  noti("본인인증을 완료해주세요.");
                } else {
                  noti("Please complete your Verify your identity.", "Confirm");
                }
              }
            },
            (err) => {
              const keys = ["PolicyCheck"];

              for (const key of keys) {
                if (err[key]) {
                  if (t("lang") === "EN") {
                    noti(err[key].message, "확인");
                  } else {
                    noti(err[key].message, "Confirm");
                  }
                  setFocus(key);
                  break;
                }
              }
            }
          )();
        }}
      >
        <Row label={t("emaillabel")} isHightlight>
          <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
            <Controller
              control={control}
              name="PartnerEmail"
              render={({ field }) => (
                <Input
                  {...field}
                  className="inp-item"
                  type="text"
                  placeholder={t("enteridemail")}
                  errors={errors}
                  fullWidth
                />
              )}
            />
          </div>
        </Row>
        <Row label={t("pwlabel")} isHightlight>
          <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
            <Controller
              control={control}
              name="PartnerPassword"
              render={({ field }) => (
                <Input
                  {...field}
                  className="inp-item"
                  type="password"
                  placeholder={t("enterpassword")}
                  errors={errors}
                  fullWidth
                />
              )}
            />
          </div>
        </Row>
        <Row label={t("checkpwlabel")} isHightlight>
          <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
            <Controller
              control={control}
              name="PartnerPasswordCheck"
              render={({ field }) => (
                <Input
                  {...field}
                  className="inp-item"
                  type="password"
                  placeholder={t("entercheckpassword")}
                  errors={errors}
                  fullWidth
                />
              )}
            />
          </div>
        </Row>
        <Row label={t("namelabel")} isHightlight>
          <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
            <Controller
              control={control}
              name="PartnerName"
              render={({ field }) => (
                <Input
                  {...field}
                  className="inp-item"
                  type="text"
                  placeholder={t("entername")}
                  errors={errors}
                  fullWidth
                />
              )}
            />
          </div>
        </Row>
        <Row label={t("department")}>
          <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
            <Controller
              control={control}
              name="PartnerTeam"
              render={({ field }) => (
                <Input
                  {...field}
                  className="inp-item"
                  type="text"
                  placeholder={t("enterdepartment")}
                  fullWidth
                />
              )}
            />
          </div>
        </Row>
        <Row label={t("position")}>
          <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
            <Controller
              control={control}
              name="PartnerLevel"
              render={({ field }) => (
                <Input
                  {...field}
                  className="inp-item"
                  type="text"
                  placeholder={t("enterposition")}
                  fullWidth
                />
              )}
            />
          </div>
        </Row>
        <Row label={t("phonelabel")} isHightlight>
          <div
            className={
              isMobileWidth
                ? "mo_input-box phonenumber"
                : "input-box phonenumber"
            }
          >
            <Controller
              control={control}
              name="userPhone1"
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder={t("select")}
                  variant="secondary"
                  w={isMobileWidth ? "110" : ""}
                  options={PREFIX_NUMBER_OPTIONS}
                />
              )}
            />
            <span></span>
            <Controller
              className="inp-item"
              control={control}
              name="userPhone2"
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  placeholder={t("enternumber")}
                  fullWidth
                  disabled={isVerify}
                  maxLength="8"
                  errors={errors}
                />
              )}
            />
            {!isMobileWidth && (
              <div>
                {isSmsSand ? (
                  <button
                    type="button"
                    className="send-message"
                    ref={btnRef}
                    onClick={() => verify.mutate()}
                  >
                    {t("verifyself")}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="send-message"
                    ref={btnRef}
                    onClick={() => {
                      if (
                        values.userPhone1 === "" ||
                        values.userPhone2 === ""
                      ) {
                        if (t("lang") === "EN") {
                          noti("휴대전화번호를 입력해 주세요.");
                        } else {
                          noti("Please enter your phone number.", "Confirm");
                        }
                      } else {
                        smsSend.mutate();
                      }
                    }}
                  >
                    {t("sendmessage")}
                  </button>
                )}
              </div>
            )}
          </div>
          {isMobileWidth && (
            <div>
              {isSmsSand ? (
                <button
                  type="button"
                  className="mo-send-message"
                  ref={btnRef}
                  onClick={() => verify.mutate()}
                >
                  {t("verifyself")}
                </button>
              ) : (
                <button
                  type="button"
                  className="mo-send-message"
                  ref={btnRef}
                  onClick={() => {
                    if (values.userPhone1 === "" || values.userPhone2 === "") {
                      if (t("lang") === "EN") {
                        noti("휴대전화번호를 입력해 주세요.");
                      } else {
                        noti("Please enter your phone number.", "Confirm");
                      }
                    } else {
                      smsSend.mutate();
                    }
                  }}
                >
                  {t("sendmessage")}
                </button>
              )}
            </div>
          )}
        </Row>
        <Row label={t("idverifylabel")} isHightlight>
          <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
            <Controller
              className="inp-item"
              control={control}
              name="verifiCode"
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  placeholder={t("enterverification")}
                  fullWidth
                  errors={errors}
                  maxLength="6"
                />
              )}
            />
          </div>
        </Row>
        <div className={isMobileWidth ? "mo-compony-info" : "compony-info"}>
          <h3>&#183; {t("coinfo")} &#183;</h3>
          <Row label={t("Coname")} isHightlight>
            <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
              <Controller
                control={control}
                name="companyName"
                render={({ field }) => (
                  <Input
                    {...field}
                    className="inp-item"
                    type="text"
                    placeholder={t("enterconame")}
                    errors={errors}
                    fullWidth
                  />
                )}
              />
            </div>
          </Row>
          <Row label={t("reprenum")}>
            <div
              className={
                isMobileWidth
                  ? "mo_input-box phonenumber"
                  : "input-box phonenumber"
              }
            >
              <Controller
                control={control}
                name="OwnerPhone1"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder={t("select")}
                    variant="secondary"
                    w={isMobileWidth ? "110" : ""}
                    options={PREFIX_NUMBER_OPTIONS}
                  />
                )}
              />
              <span></span>
              <Controller
                className="inp-item"
                control={control}
                name="OwnerPhone2"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    placeholder={t("enternumber")}
                    fullWidth
                    maxLength="8"
                  />
                )}
              />
            </div>
          </Row>
          <Row label={t("addresslabel")}>
            <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
              <div className="zipcode">
                <Controller
                  control={control}
                  name="Address1"
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="inp-item"
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      placeholder={t("enterzipcode")}
                      value={enrollCompany.zipcode}
                      fullWidth
                      errors={errors}
                    />
                  )}
                />
                {isMobileWidth ? (
                  <button
                    type="button"
                    className="mo-address-search"
                    onClick={() => {
                      setPopup(true);
                    }}
                  >
                    {t("searchaddrlabel")}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="address-search"
                    onClick={() => {
                      setPopup(true);
                    }}
                  >
                    {t("searchaddrlabel")}
                  </button>
                )}
                {popup && (
                  <Postcode
                    popup={popup}
                    setPopup={setPopup}
                    company={enrollCompany}
                    setcompany={setEnrollCompany}
                  />
                )}
              </div>
              <div className="void-sec2"></div>
              <Controller
                control={control}
                name="Address2"
                render={({ field }) => (
                  <Input
                    {...field}
                    className="inp-item"
                    type="text"
                    placeholder={t("addresslabel")}
                    fullWidth
                    value={enrollCompany.address}
                    errors={errors}
                  />
                )}
              />
              <div className="void-sec2"></div>
              <Controller
                control={control}
                name="Address3"
                render={({ field }) => (
                  <Input
                    {...field}
                    className="inp-item"
                    type="text"
                    placeholder={t("enterdetailaddr")}
                    fullWidth
                    errors={errors}
                  />
                )}
              />
            </div>
          </Row>
          <Row label={t("fax")} isHightlight>
            <div
              className={
                isMobileWidth
                  ? "mo_input-box phonenumber faxnumber"
                  : "input-box phonenumber faxnumber"
              }
            >
              <Controller
                control={control}
                name="companyFaxNumber01"
                render={({ field }) => (
                  <Input
                    {...field}
                    className="inp-item"
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength="4"
                    errors={errors}
                  />
                )}
              />
              <span>-</span>
              <Controller
                control={control}
                name="companyFaxNumber02"
                render={({ field }) => (
                  <Input
                    {...field}
                    className="inp-item"
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength="4"
                    errors={errors}
                  />
                )}
              />
              <span>-</span>
              <Controller
                control={control}
                name="companyFaxNumber03"
                render={({ field }) => (
                  <Input
                    {...field}
                    className="inp-item"
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength="4"
                    errors={errors}
                  />
                )}
              />
            </div>
          </Row>
          <Row label={t("businessnum")} isHightlight>
            <div
              className={
                isMobileWidth
                  ? "mo_input-box business-number"
                  : "input-box business-number"
              }
            >
              <Controller
                control={control}
                name="businessNumber"
                render={({ field }) => (
                  <Input
                    {...field}
                    className="inp-item"
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    placeholder={t("enternumber")}
                    maxLength="10"
                    fullWidth
                    errors={errors}
                  />
                )}
              />
            </div>
          </Row>
          <Row label={t("reprename")}>
            <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
              <Controller
                control={control}
                name="OwnerName"
                render={({ field }) => (
                  <Input
                    {...field}
                    className="inp-item"
                    type="text"
                    placeholder={t("enterownername")}
                    fullWidth
                  />
                )}
              />
            </div>
          </Row>
          <Row label={t("coaccountnum")} isHightlight>
            <div
              className={
                isMobileWidth
                  ? "mo_input-box bank-select"
                  : "input-box bank-select"
              }
            >
              <Controller
                control={control}
                name="TransferBank01"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder={t("enterbank")}
                    variant="secondary"
                    w={isMobileWidth ? "110" : ""}
                    options={
                      t("lang") === "EN"
                        ? CARD_COMPANY_OPTIONS
                        : CARD_COMPANY_OPTIONS_EN
                    }
                  />
                )}
              />
              <span className="void-sec"></span>
              <Controller
                control={control}
                name="TransferBank02"
                render={({ field }) => (
                  <Input
                    {...field}
                    className="inp-item"
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    placeholder={t("enternumber")}
                    errors={errors}
                  />
                )}
              />
            </div>
          </Row>
        </div>
        <div className={isMobileWidth ? "mo-policy-agree" : "policy-agree"}>
          <h3>&#183; {t("termslabel")} &#183;</h3>
          {t("lang") === "EN" ? (
            <Policybox label="회원가입 이용약관" type="service">
              회원가입 약관 주식회사 누리글로벌서비스 홈페이지 회원에 가입하시면
              더욱 더 다양한 서비스를 이용하실 수 있습니다. 우리 사이트는
              정보통신망 이용촉진에 관한 법률 등 관련 법령에 따라 아래와 같이
              이용자의 동의를 받은 후 회원가입을 받고 있습니다. 제1조 (목적) 이
              약관은 주식회사 누리글로벌서비스 (이하 “누리”)가 운영하는
              누리글로벌서비스 웹사이트(이하 “웹”)에서 제공하는 용역 및 인터넷
              관련 서비스(이하 “서비스”)를 이용함에 있어 이용자와 “누리”의 권리
              · 의무 및 책임사항을 규정함을 목적으로 합니다. 제2조 (정의) 1. 본
              약관에서 사용하는 용어의 정의는 다음과 같습니다. ① 이용계약:
              서비스 이용과 관련하여 “누리”와 이용자 간에 체결하는 계약 ② 가입:
              “웹”이 제공하는 신청서 양식에 해당 정보를 기입하고, 본 약관에
              동의하여 서비스 이용계약을 완료하는 행위 ③ 이용자: “누리”에서
              제공하는 “웹”의 모든 형태의 서비스를 이용하는 회원 및 비회원 ④
              회원: “웹”에 회원가입에 필요한 개인 정보를 제공하여 회원 등록을 한
              자로서, “웹”의 정보 및 서비스를 이용할 수 있는 자 ⑤ 이용자
              번호(ID): 이용 고객의 식별과 이용자의 원활한 회원가입을 위한
              본인의 이메일 주소, 또는 관리자 및 이용자가 정한 문자와 숫자의
              조합 ⑥ 비밀번호(PW): 이용자가 등록 회원과 동일인인지 신원을
              확인하고 통신상의 자신의 개인정보보호를 위하여 이용자 자신이 정한
              문자와 숫자의 조합 ⑦ 게시물: “누리”가 회원에게 원활한 서비스
              제공을 위해 “웹”에 게시하는 글, 이미지, 동영상 등 각종 파일과 링크
              등, 또는, 회원이 “웹”에 게시하는 글, 이미지, 동영상 등 각종 파일과
              링크 등 ⑧ 탈퇴(해지): 서비스 또는 회원이 이용계약을 종료하는 행위
              ⑨ 파트너: “누리”와 업무적으로 계약을 맺거나 업무 협력관계에 있는
              모든 사업체, 단체, 또는 개인 제3조 (약관의 명시와 개정) 1.
              “누리”는 이 약관의 내용과 주소지, 관리자의 성명, 개인정보보호
              담당자의 성명, 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가
              알 수 있도록 “웹”의 초기 서비스 화면(전면)에 게시합니다.
              제4조(이용 계약의 성립) 1. 이용계약은 이용자가 본 이용약관 내용에
              대한 동의와 이용신청에 대하여 “누리”의 “웹” 이용 승낙으로
              성립합니다. 제5조(회원가입 및 탈퇴) 1. 회원가입은 신청자(이용자)가
              온라인으로 “누리”에서 제공하는 소정의 가입신청 양식에서 요구하는
              사항을 기록하여 가입을 완료하는 것으로 성립됩니다.
              제6조(이용자번호 부여 및 변경 등) 1. “누리”는 이용고객에 대하여
              약관에 정하는 바에 따라 이용자 ID를 부여하거나, 이용자 본인이
              설정한 이메일 계정을 ID로 설정합니다. 제7조(회원정보 사용에 대한
              동의) 1. “누리”의 “웹” 회원정보는 “개인정보보호법” 및
              “개인정보처리방침” 의해 보호됩니다. 제8조(이용자의 정보 보안) 1.
              회원은 “웹”의 서비스 가입 절차를 완료하는 순간부터 입력한 정보의
              비밀을 유지할 책임이 있으며, ID와 비밀번호를 사용하여 발생하는
              모든 결과에 대한 책임은 본인에게 있습니다. 제9조(서비스 이용시간)
              1. 서비스 이용시간은 “누리”의 업무상 또는 기술상 특별한 지장이
              없는 한 연중무휴, 1일 24시간을 원칙으로 합니다. 제10조(서비스의
              중지 및 중지에 대한 공지) 1. 이용자는 “웹”의 서비스에 보관되거나
              전송된 메시지 및 기타 통신 메시지 등의 내용이 국가의 비상사태,
              정전, “웹”의 관리 범위 외의 서비스 설비 장애 및 기타 불가항력에
              의하여 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타
              통신 데이터의 손실이 있을 경우에 “누리”는 관련 책임을 부담하지
              아니합니다. 제11조(정보 제공 및 홍보물 게재) 1. “누리”는 서비스를
              운영함에 있어서 각종 정보를 “웹”에 게재하는 방법, 전자우편,
              서신우편, 휴대폰 메시지 발송 등으로 회원에게 제공할 수 있습니다.
              제12조 (“웹” 게시물의 관리 및 운영) 1. “누리”가 게시한 게시물의
              내용에 대한 권리는 “누리”에게 있으며, 회원이 게시한 게시물의
              내용에 대한 권리는 회원에게 있습니다. 제13조(서비스 이용제한) 1.
              회원이 제공하는 정보의 내용이 허위인 것으로 판명되거나, 허위가
              있다고 의심할 만한 합리적인 사유가 발생할 경우 “누리”는 회원의 본
              서비스 사용을 일부 또는 전부 중지할 수 있으며, 이로 인해 발생하는
              불이익에 대해 책임을 부담하지 아니합니다. 제14조(“누리”의 의무) 1.
              “누리”는 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지
              않으며, 지속적이고 안정적으로 서비스를 제공하기 위해 노력할 의무가
              있습니다. 제15조(회원의 의무) 1. 회원가입 시에 요구되는 정보는
              정확하게 기입하여야 합니다. 또한 이미 제공된 회원에 대한 정보가
              정확한 정보가 되도록 유지, 갱신하여야 하며, 회원은 자신의 ID 및
              비밀번호를 제3자가 이용하게 해서는 안 됩니다. 제16조(“누리”의
              소유권) 1. “누리”가 “웹”에서 제공하는 용역, 서비스, 그에 필요한
              소프트웨어, 이미지, 마크, 로고, 디자인, 서비스 명칭, 정보 및 상표
              등과 관련된 지적재산권 및 기타 권리는 “누리”에게 소유권이 있습니다
              제17조(양도금지) 1. 회원이 서비스의 이용권한, 기타 이용계약 상
              지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수
              없습니다. 제18조(손해배상) 1. “누리”는 무료로 제공되는 서비스와
              관련하여 회원에게 어떠한 손해가 발생하더라도 “누리”가 고의로 행한
              범죄행위를 제외하고는 이에 대하여 책임을 부담하지 아니합니다.
              제19조(면책조항) 1. 누리”는 천재지변, 전쟁 및 기타 이에 준하는
              불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에
              대한 책임이 면제됩니다. 제20조(재판권 및 준거법) 1. 이 약관에
              명시되지 않은 사항은 전기통신사업법 등 대한민국의 관계법령과
              상관습에 따릅니다. &lt;부칙&gt; 1. 본 약관은 2022년 11월 01일부터
              적용됩니다. 위 약관을 모두 숙지하였으며 회원가입에 동의합니다.
            </Policybox>
          ) : (
            <Policybox
              label="Terms and Conditions of Registration"
              type="service"
            >
              Terms and Conditions of Registration The purpose of these Terms
              and Conditions is to prescribe the rights, obligations, and
              responsibilities of the users and NURI Global Service Co., Ltd.
              (hereinafter referred to as the &quot;Nuri&quot;) when using
              services provided by its websites (hereinafter referred to as
              &quot;Web&quot;) and Internet-related services (hereinafter
              referred to as the &quot;Service&quot;). Article #2 (Definition)
              1. The phrases used in these Terms and Conditions are defined as
              following: The Contract of Usage (The Usage Contract): An
              agreement between the Nuri and the users when using the Web and
              Service provided by NURI Global Service Co., Ltd. Registration:
              The process of the users agreeing with the Nuri’s Terms &
              Conditions and entering the information in an online registration
              form provided by the Web. Visitors: Members and non-members who
              use all forms of the web services provided by the Nuri. Members
              (Users): The person who has registered to the Web as a member by
              providing his or her personal information necessary for a complete
              registration process. The members can use information and services
              provided the Web and Nuri. User (Member) ID: An e-mail address for
              identifying the members of the Web. The User ID could be a
              combination of Alphabets and numbers the assigned by the Web
              administrators or chosen by the member him or herself. User
              (Member) Password: A combination of letters and numbers that is
              necessary for the members to identify their Web membership and
              protect their personal information. The combination can be
              determined by the user him/herself. Posts: Various files and
              links, such as articles, images, videos, etc. posted by the Nuri
              on the Web for flexible provision of services to its members. It
              could also be various files and links, such as articles, images,
              videos, etc. posted on the Web by the users. Termination: An act
              of terminating the Service or a member &#39;s Contract of Usage.
              Partners: Any business, organization, or individual who signed any
              form of contract with the Nuri. 2. Any term or phrase that are not
              specified in the Article #2 are separately defined in other
              articles. Article #3 (Clarification and Amendment of the Terms and
              Conditions) The Nuri will post the copy of these Terms and
              Conditions on the Web. The Nuri will also post the administrator’s
              contact information, such as address, name, telephone number, fax
              number, e-mail address, etc., on the main screen of the Web. The
              Nuri may amend the Terms and Conditions to the extent that it does
              not violate the relevant laws, such as the Act on the Regulation
              of Terms and Service, the Framework Act on Electronic
              Transactions, the Electronic Signature Act, the Promotion of
              Information and Communication Network Use, etc. If the Nuri
              revises the Terms and Conditions, it shall be notified on the main
              screen of the Web with the revised Terms and Service. The
              notification will be posted for 7 days from the day of
              notification to the day before the actual renewal date. Acceptance
              of these Terms and Conditions means that the users agree to visit
              the Web on a regular basis to confirm any changes made to the
              Terms and Conditions. The Nuri is not responsible for any damage
              caused by not recognizing any change made in the Terms and
              Conditions. If a member disagrees with the renewed Terms and
              Conditions, the Nuri may request withdrawal of his or her
              membership (cancellation of an account), and if the member
              continues to use the Service without expressing a rejection after
              7 days from the renewal date, the Nuri will consider that the
              member has agreed to its Terms and Conditions. Any other detail
              that are not specified in the Terms and Conditions shall be
              governed by the Framework Act on Telecommunications, the
              Telecommunications Business Act, the Information and Communication
              Network Promotion Act, the Information Protection Act, and other
              relevant laws and regulations. Article #4 (Establishment of the
              Usage Contract) The Usage Contract is established when the user
              agrees to the Terms and Conditions, AND the Nuri gives the user
              permission to use the Web. The user agrees to the Terms and
              Conditions by pressing the ‘Agree’ button on the Web during the
              online registration process. 1. These Terms and Service will be
              effective from November 01, 2022. I have understood all the Terms
              and Conditions above and agree to complete the Web membership
              registration process.
            </Policybox>
          )}
          <span className="void-sec"></span>
          {t("lang") === "EN" ? (
            <Policybox label="개인정보 처리방침" type="policy">
              개인정보 처리방침 &lt;주식회사 누리글로벌서비스&gt;
              (&quot;www.nuri-gs.com&quot; 및 &quot;www.nurida.com&quot;, 이하
              &quot;누리&quot;)가 취급하는 모든 개인정보는 개인정보보호법 등
              관련 법령상의 개인정보보호 규정을 준수하여 이용자의 개인정보 보호
              및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게
              처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다. 1.
              개인정보의 처리 목적 ① &lt;주식회사 누리글로벌서비스&gt;는 다음의
              목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의
              용도로는 이용하지 않습니다. 2. 개인정보의 처리 및 보유 기간 작성 ①
              &lt;주식회사 누리글로벌서비스&gt;는 정보주체로부터 개인정보를
              수집할 때 동의 받은 개인정보 보유·이용기간 또는 법령에 따른
              개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다. 3.
              정보주체와 법정대리인의 권리·의무 및 그 행사방법 ① 정보주체는
              &lt;주식회사 누리글로벌서비스&gt;에 대해 언제든지 다음 각 호의
              개인정보 보호 관련 권리를 행사할 수 있습니다. 4. 처리하는 개인정보
              항목 ① 개인정보 처리업무: 홈페이지 회원가입 및 관리, 사업 제휴,
              사업 협력, 인력 등록 및 급여 제공, 출퇴근 기록, 재화 또는 서비스
              제공, 마케팅 및 광고에의 활용 5. 개인정보의 파기 6. 개인정보 자동
              수집 장치의 설치·운영 및 그 거부에 관한 사항 ① &lt;주식회사
              누리글로벌서비스&gt;는 개별적인 맞춤서비스를 제공하기 위해
              이용정보를 저장하고 수시로 불러오는 &quot;쿠키(cookie)&quot;를
              사용합니다. 7. 개인정보 보호 책임자 8. 개인정보의 안전성 확보 조치
              9. 개인정보처리방침의 변경 ① 이 개인정보처리방침은 시행일로부터
              적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는
              경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할
              것입니다.
            </Policybox>
          ) : (
            <Policybox label="Privacy Policy" type="policy">
              Privacy Policy All personal information handled by &lt;Nuri Global
              Service Co., Ltd.&gt; (“www.nuri-gs.com” and “www.nurida.com”;
              hereinafter referred to as the “Nuri”) shall be protected by
              personal information protection regulations under the Personal
              Information Protection Act. The Nuri shall adopt privacy policy to
              protect its users&#39; personal information & rights and resolve
              any difficulty that could arise when using the Nuri&#39;s digital
              platforms and related service. The following is the Nuri’s Privacy
              Policy: The Purpose of the Privacy Policy &lt;Nuri Global Service
              Co., Ltd.&gt; processes personal information for the following
              purposes and does not use it for any other intent: Identifying and
              confirming the users&#39; intention to sign up Maintaining and
              managing the users’ membership Paying wages and compensation to
              the users Paying for any material necessary for maintaining the
              Nuri’s web service Supplying and delivering goods Marketing and
              advertising Personal Information Processing & Retention Period
              &lt;Nuri Global Service Co., Ltd.&gt; processes and preserves the
              user’s personal information within the retention period. The
              retention period is effective from the moment when the user agrees
              to provide his or her personal information to the Nuri, AND from
              the date the laws and regulations specify. The detail about the
              period of personal information processing and retention is
              described below: Membership Registration and Management: The Nuri
              will preserve the member’s personal information up to 2 years
              after his or her withdrawal from the Nuri’s websites and other
              service. If the Nuri and the user are involved in debt
              relationship, the Nuri will preserve the member’s personal
              information until the remaining payment is made, or bond
              relationship between the two parties is settled. Member
              Transaction Record: Up to 5 years Rights and Obligations of
              Information Provider and Legal Representatives · the Method of
              Exercising these Rights and Obligations The information provider
              may request the followings against &lt;NURI Global Service Co.,
              Ltd.&gt; to protect his or her personal information and privacy:
              1. Request to access his or her personal information 2. Request to
              correct his or her personal information 3. Request to delete his
              or her personal information 4. Request to stop the processing of
              his or her personal information The Types of Personal Information
              Collected by NURI Global Service Personal information is collected
              and preserved for website registration, membership management,
              business cooperation, manpower dispatch, salary disbursement,
              attendance checks, provision of marketing goods, and on & offline
              advertisement. Required Personal Information: Personal e-mail
              address NURI Global Service Website account information: ID and
              Password Phone number and fax number Home address Business
              Registration Number Identification Photo Bank Account Number
              Career details Medical records Disability records Military service
              records Service usage records Access logs and cookies IP address
              Online transaction records GPS records Disposal of Personal
              Information Disposal Process: The information provided by the user
              is transferred to a separate DB (separate documents if the
              provided information is in paper) and stored for a certain period
              in accordance with the company’s internal policies and other
              relevant laws & regulations. Then, the information is destroyed
              immediately sooner of after. The personal information transferred
              to the DB shall not be used for any other purpose unless it is
              requested by a judicial institution. Expiration Date: If the
              retention period has already passed, the user’s personal
              information shall be disposed within 5 days from the end of the
              retention period. If the Nuri does not need the user’s personal
              information any longer or terminates its service and business, the
              user’s personal information shall be disposed within 5 days from
              the date which the Nuri considers his or her personal information
              is unnecessary. Automatic Collection of Personal Information ·
              Device Installation and Management · Rejection &lt;NURI Global
              Service Co., Ltd.&gt; uses the ‘cookies’, which save and load
              information on the server, to provide personalized web-service to
              the users. The information entered by the user is transferred to a
              separate DB after achieving the purpose (separate documents in the
              case of paper) and stored for a certain period in accordance with
              internal policies and other relevant laws or regulations, and then
              destroyed immediately. The ‘Cookies’ are small fragments of
              information that the server (http) sends to the user&#39;s
              browser. The Cookies are sometimes stored on the user&#39;s PC
              hard disk. The purpose of using the Cookies: To provide optimized
              information to the users by identifying their web history, usage
              patterns, top searched terms, security access records, etc.
              Installation, management, and rejection of the Cookies: The
              visitors and users can turn off the Cookies on by accessing Tools
              &gt; Internet Options &gt; Privacy at the top of their web
              browsers. There may be difficulties receiving personalized service
              and related benefits if the user turns off the Cookies.
              Information Security Manager Name: Sung Hoo (Danny) Lim Company:
              NURI Global Service Planning Department Mobile: +82-10-2331-4132
              E-mail: admin@nuri-gs.com Measures to Secure the Safety of
              Personal Information Encryption of the user’s personal information
              The user&#39;s password is encrypted and safely preserved so that
              only the member can use it to log-in to the Nuri&#39;s websites.
              The Nuri and its websites use other separate security functions,
              such as encrypting data and locking files, to protect confidential
              or private files. Amending the Privacy Policy The Privacy Policy
              will be effective on the date of its enforcement date. If an
              additional change, deletion, or correction is made to the Privacy
              Policy in accordance with domestic / international laws,
              regulations, and policies, the Nuri will notify the users 7 days
              before the change takes effect. Announcement Date: November 1,
              2022 Enforcement Date: January 1, 2023
            </Policybox>
          )}
          <span className="void-sec"></span>
          <Controller
            control={control}
            name="PolicyCheck"
            render={({ field }) => (
              <Checkbox {...field} fontWegiht errors={errors}>
                {t("termsagree")}
              </Checkbox>
            )}
          />
        </div>
        <div className={isMobileWidth ? "mo-submit-button" : "submit-button"}>
          <Button type="submit" variant="primaryHover">
            {t("partnerssignup")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PartnerForm;

const PartnerFormStyled = (isLang) => {
  return css`
    .send-message {
      font-size: ${isLang === "EN" ? "18px" : "15px"};
    }
    .faxnumber {
      & span {
        margin-top: 13px;
      }
    }
  `;
};
