import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// assets
import BtnNextCircleSVG from "assets/images/pc/btn_next_circle.svg";

import Partnership from "../components/Partnership";

const ProposalSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  return (
    <ProposalRoot>
      <div>
        <div className={isMobileWidth ? "mo_proposal_wrap" : "proposal_wrap"}>
          <p
            className={
              isMobileWidth ? "mo_main_section_text" : "main_section_text"
            }
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            PROPOSAL
          </p>
          <h3
            className={isMobileWidth ? "mo_main_section_h3" : "main_section_h3"}
            data-aos="fade-up"
            data-aos-duration="1600"
          >
            <span className="pc_brack">
              {t("lang") === "EN" ? `주식회사 ` : "NURI "} <br />
              {t("mainProposaltext1")}
            </span>
            <span className="big_txt">
              {t("mainProposaltext2")} <br />
              {t("mainProposaltext3")}
            </span>
            <span className="big_txt">{t("mainProposaltext4")}</span>
          </h3>
          {t("lang") === "EN" ? (
            <p
              className={
                isMobileWidth
                  ? "mo_main_section_sentence"
                  : "main_section_sentence"
              }
              data-aos="fade-up"
              data-aos-duration="1650"
            >
              사업 제휴 양식에 맞춰 제안을 보내주시면,
              <br /> 검토 후 연락 드리겠습니다.
            </p>
          ) : (
            <p
              className={
                isMobileWidth
                  ? "mo_main_section_sentence"
                  : "main_section_sentence"
              }
              data-aos="fade-up"
              data-aos-duration="1650"
            >
              Please send us a proposal according to our format.
              <br />
              <span className="span-sec">
                We will contact you after reviewing it. Thank you very much.
              </span>
            </p>
          )}

          <div
            className={isMobileWidth ? "mo_more_btn" : "more_btn"}
            onClick={() => navigate("/business")}
          >
            <p>{t("partner")}</p>
            <img src={BtnNextCircleSVG} className="btn_next_circle" />
          </div>
          <p className="arita_copyright">{t("fontcopyright")}</p>
        </div>
      </div>
      <Partnership />
    </ProposalRoot>
  );
};

export default ProposalSection;

const ProposalRoot = styled.div`
  & .proposal_wrap {
    position: relative;
    z-index: 500;
    width: 1120px;
    margin: 0 auto;
    padding-top: 120px;
    padding-bottom: 150px;
  }

  & .mo_proposal_wrap {
    padding-top: 100px;
    padding-bottom: 145px;
    margin: 0 30px;
  }

  & .arita_copyright {
    position: absolute;
    right: 20%;
    margin-top: 135px;
    font-size: 6px;
    opacity: 0.2;
  }

  .span-sec {
    display: block;
  }
`;
