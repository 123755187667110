import { useEffect } from "react";

/**
 * @file 드래그 & 스크롤 커스텀 훅
 */
const useDragScroll = (listName, scrollDotRef) => {
  const slider = document.querySelector(listName);
  let isMouseDown = false;
  let startPoint;
  let scrollLeft;

  slider.addEventListener("mousedown", (e) => {
    isMouseDown = true;
    startPoint = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;

    //for (let i = 0; i < scrollDotRef.current.length; i++) {
    //  if (slider.scrollLeft < 370) {
    //    scrollDotRef.current[i].style.backgroundColor = "#707070";
    //    scrollDotRef.current[0].style.backgroundColor = "#c09260";
    //  } else if (slider.scrollLeft > 371 || slider.scrollLeft < 730) {
    //    scrollDotRef.current[i].style.backgroundColor = "#707070";
    //    scrollDotRef.current[1].style.backgroundColor = "#c09260";
    //  } else if (slider.scrollLeft > 731 || slider.scrollLeft < 1090) {
    //    scrollDotRef.current[i].style.backgroundColor = "#707070";
    //    scrollDotRef.current[2].style.backgroundColor = "#c09260";
    //  } else if (slider.scrollLeft > 1090) {
    //    scrollDotRef.current[i].style.backgroundColor = "#707070";
    //    scrollDotRef.current[3].style.backgroundColor = "#c09260";
    //  }
    //}
  });
  slider.addEventListener("mousemove", (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startPoint) * 1;
    slider.scrollLeft = scrollLeft - walk;
  });
  slider.addEventListener("mouseup", () => {
    isMouseDown = false;
  });
};

export default useDragScroll;
