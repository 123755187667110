import * as yup from "yup";

export default yup.object().shape({
  TransferName: yup.string().required("Please enter your Name."),
  TransferGender: yup.string().required("Please select your Gender."),
  TransferBirth01: yup.string().required("Please enter your Date of Year."),
  TransferBirth02: yup.string().required("Please enter your Date of Year."),
  TransferBirth03: yup.string().required("Please enter your Date of Year."),
  TransferPhone2: yup.string().required("Please enter your Phone Number."),
  TransferPhone3: yup.string().required("Please enter your Phone Number."),
  TransferEmail: yup.string().required("Please enter your Email Address."),
  Address1: yup.string().required("Please "),
  Address2: yup.string().required("Please enter your Address."),
  Address3: yup.string().required("Please enter your detailed address."),
  TransferFirst01: yup
    .object()
    .nullable()
    .required("Please select First preference."),
  TransferFirst02: yup
    .object()
    .nullable()
    .required("Please select First Part."),
  TransferSecond01: yup
    .object()
    .nullable()
    .required("Select Second preference."),
  TransferSecond02: yup
    .object()
    .nullable()
    .required("Please select Second Part."),
  TransferBank01: yup.object().nullable().required("Please select Bank."),
  TransferBank02: yup.string().required("Please enter acount number."),
  TransferEdu: yup
    .object()
    .nullable()
    .required("Please select Highest Level of Education."),
  TransferSchoolName: yup.string().required("Please enter the School name."),
  TransferMajor: yup.string().required("Please enter your major."),
  TransferGraduateGubun: yup
    .object()
    .nullable()
    .required("Please select graduation status."),
  TransferArmy: yup
    .object()
    .nullable()
    .required("Please select Military service."),
  TransferDisabled: yup
    .object()
    .nullable()
    .required("Please select Disability Rating."),
  TransferSelf: yup.string().required("Please enter the content."),
  BankPolicyCheck: yup.boolean(),
});
