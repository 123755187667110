import React, { useState } from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import Panel from "components/Panel/Panel";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner03 from "assets/images/pc/img_banner03.png";
import IconSev01SVG from "assets/images/pc/icon_sev01.png";
import IconSev02SVG from "assets/images/pc/icon_sev02.png";
import IconSev03SVG from "assets/images/pc/icon_sev03.png";
import IconSev04SVG from "assets/images/pc/icon_sev04.png";
import IconSev05SVG from "assets/images/pc/icon_sev05.png";
import IconSev06SVG from "assets/images/pc/icon_sev06.png";
import BgCircleFSVG from "assets/images/pc/bg_circle_f.svg";
import BgCircleCSVG from "assets/images/pc/bg_circle_c.svg";

/**
 * @file 서비스 페이지
 * @brief 회사 서비스 상세 소개
 * @author TW.Lee
 */
const ServiceInfo = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState(null);
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <Layout subFixed="true">
      <SubLayout
        banner={Banner03}
        pageText={
          t("lang") === "EN" ? PAGE_LAYOUT.service : PAGE_LAYOUT_EN.service
        }
      >
        {isMobileWidth ? (
          <div className="service_inner">
            <GradientMobileYellow01>
              <img src={BgCircleCSVG} />
            </GradientMobileYellow01>
            <GradientMobileYellow02>
              <img src={BgCircleFSVG} />
            </GradientMobileYellow02>
            <ServiceMobileInner01>
              {t("lang") === "EN" ? (
                <ul>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("guestroom")}
                      text1={`호텔 고객의 투숙 예약 절차와\n객실 안내를 진행하며, 고객이 필요로 하는`}
                      text2="정보 제공의 업무를 주로 수행합니다."
                      image={IconSev01SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="1"
                    >
                      객실 서비스는 크게{" "}
                      <span>
                        도어맨, 발렛파킹, 예약, 프론트, 안내,
                        <br />
                        컨시어지, 휘트니스
                      </span>
                      로 구성되어 있으며,
                      <br />
                      각 부서의 전문 인력은 고객이 호텔 입구에 들어선
                      <br />
                      순간부터 어떠한 번거로움 없이 신속 · 정확하게 객실로
                      <br />
                      안내 받을 수 있도록 준비합니다.
                      <br />
                      <br />
                      객실인원은 고객과 직접적으로 접촉하는 만큼
                      <br />
                      단정한 외모, 고객에 대한 매너, 기본적인 외국어 구사력,
                      <br />
                      분명한 의사소통 능력, 순발력 등의 다양한 역량을 갖춘
                      <br />
                      인재로만 엄선합니다.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("guestroommanage")}
                      text1={`호텔의 영업이 원활히\n진행될 수 있도록 효율적인 객실관리`}
                      text2="서비스를 제공합니다."
                      image={IconSev02SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="2"
                    >
                      객실관리팀은 호텔의 자산, 즉 모든 시설을 관리하는
                      <br />
                      부서인 만큼 호텔의 전반적인 업무를 총괄하는
                      <br />
                      중추적 역할을 맡고 있습니다.
                      <br />
                      연중무휴 24시간 운영되며, 호텔 고객에게 깨끗하고
                      <br />
                      안락한 환경을 제공하기 위해 객실에 비치된
                      <br />
                      <span>
                        린넨 및 소모품 관리, 고객 의류 세탁 등과 같은
                        <br />
                        제반 서비스 업무
                      </span>
                      를 주로 처리합니다.
                      <br />
                      <br />
                      비품과 린넨류의 효율적인 재고관리를 통해 원가절감에
                      <br />
                      중대한 역할을 하며, 호텔 내 가장 수익성이 있는
                      <br />
                      객실 상품의 재생산 업무 또한 책임지고 있습니다.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("fandb")}
                      text1={`식음팀은 영업장 관리와 연회 부서로\n나뉘어져 연회 행사의 전반적인 운영과`}
                      text2="서비스를 제공합니다."
                      image={IconSev03SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="3"
                    >
                      영업장 관리 부서는 연회가 열릴 장소의
                      <br />
                      <span>
                        테마 구상, 세팅, 청소, 예약과 같이 원활한 영업장 운영에
                        <br />
                        관련된 업무
                      </span>
                      를 담당합니다.
                      <br />
                      또한, 업장의 고객관리, 집기·비품 관리를 담당하고 있으며
                      <br />
                      가장 중요한 식음료 상품의 판매를 책임지고 있습니다.
                      <br />
                      연회부서는 연회에 참여하는 청소, 책임 구역의 준비 및
                      <br />
                      정돈 등의 영업 준비를 하며, 안내, 서빙,
                      <br />
                      계산과 같이 원활한 연회가 진행될 수 있도록 고객 접대
                      <br />
                      서비스 또한 맡고 있습니다.
                      <br />
                      <br />두 부서 모두 고객을 직접적으로 상대하는 직무인 만큼,
                      <br />
                      밝은 미소와 깨끗한 용모를 갖춘 인재로 엄선하며
                      <br />
                      호텔 내 영업장의 긍정적 이미지 형성에 최선을 다하고
                      <br />
                      있습니다.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("safety")}
                      text1={`호텔 고객과 직원의 안전 및 재산 보호가\n주 임무인 안전관리팀을`}
                      text2="자체적으로 보유하고 있습니다."
                      image={IconSev04SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="4"
                    >
                      안전관리팀은 혹시 모를{" "}
                      <span>
                        재난상황에 대비하여 호텔 내<br />
                        소화장치, 건축 구조물 등을 상시적으로 보수관리
                      </span>{" "}
                      하며,
                      <br />
                      호텔 내{" "}
                      <span>
                        범죄를 예방하기 위하여 숙련된 경비 인력을
                        <br />
                        활용하여 호텔 내·외부 순찰 등의 방범 업무
                      </span>
                      에 투입합니다.
                      <br />
                      <br />
                      또한, 소방 훈련, 대피 훈련, 호텔 직원 안전관리 교육
                      <br />
                      등을 통해 재난 상황이 발생하더라도, 고객과 직원의 피해를
                      <br />
                      최소화 시킬 수 있는 역량을 갖추었습니다.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("facility")}
                      text1={`시설관리팀은 호텔 내 시설물의 설계,\n유지·보수, 공사진행 및`}
                      text2="관리감독을 담당하고 있습니다."
                      image={IconSev05SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="5"
                    >
                      호텔 고객에게 최상의 서비스를 제공하기 위해{" "}
                      <span>
                        객실, 영업장 및 시설물의 상시적인 유지·보수 업무는 물론,
                        <br />
                        노후화 방지를 위한 개조, 보수공사,
                        <br />
                        장비 점검 및 교체 업무
                      </span>
                      를 집중적으로 맡고 있습니다.
                      <br />
                      <br />
                      특히, 자사는 여러 분야의 시설관리 직원을 통솔할 수 있는
                      <br />
                      리더십 있는 인재와, 위기상황 또는 고객 컴플레인 발생 시
                      <br />
                      신속한 대처를 할 수 있는 민첩한 인재,
                      <br />
                      그리고 높은 전문성을 갖춘 건설·전기·기계·소방 관련 기사
                      자격증을 소지하거나 건축공학·전기공학·기계공학과·
                      <br />
                      소방행정학과 졸업자를 집중적으로 채용하고 있습니다.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("customerservice")}
                      text1={`고객응대(CS)팀은 자사에서 제공하는\n호텔 서비스 개선을`}
                      text2="위한 응대 서비스를 담당합니다."
                      image={IconSev06SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="6"
                    >
                      고객과의 긴밀한 커뮤니케이션을 통해 설문조사를
                      <br />
                      진행하고 문의사항, 건의사항 또는 불만사항을 접수 받고{" "}
                      <span>
                        자사 서비스의 취약점을 인지하여 관련 부서에 전달하는
                        <br />
                        조직인 만큼 친절하고 커뮤니케이션 역량이 뛰어난 인재
                      </span>
                      로<br />
                      구성되어 있습니다.
                      <br />
                      <br />
                      실시간 카카오톡 채널과 챗봇을 활용하여 젊은 층의 고객과
                      <br />
                      원활한 소통을 할 수 있는 환경을 제공하며,
                      <br />
                      동시에 유선상담을 병행하여 50·60세대의 고객도 불편함
                      <br />
                      없이 고객응대 서비스를 제공할 수 있도록 노력합니다.
                      <br />
                      CS매뉴얼 또한 마련되어 있으므로, 빠른 시간 내에
                      <br />
                      고객이 원하는 정보 제공과 애로사항 해결이 가능합니다.
                    </Panel>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("guestroom")}
                      text1={`Room Management Team ("Room-part") is responsible for assisting customers with`}
                      text2={`reservation procedures and room information guidance.`}
                      image={IconSev01SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="1"
                    >
                      The Room Management Team consists of{" "}
                      <span>
                        door
                        <br />
                        attendants, valet parking staffs, reservation
                        assistants,
                        <br />
                        front desk assistants, concierge staffs, and fitness
                        <br />
                        center staffs.
                        <br />
                      </span>
                      <br />
                      From the moment when guests arrive at a hotel,
                      <br />
                      our professionals in
                      <br />
                      each department prepare for quick and accurate
                      <br />
                      room guidance without any hesitance.
                      <br />
                      <br />
                      We prefer Room Management Team staffs who
                      <br />
                      have neat appearance, manners, good adaptability,
                      <br />
                      foreign language proficiency, and strong sociability,
                      <br />
                      since they serve guests face-to-face.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("guestroommanage")}
                      text1="Housekeeping Team is responsible for efficient room management, which helps"
                      text2="our partner hotel to operate smoothly."
                      image={IconSev02SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="2"
                    >
                      The Housekeeping Team takes the key role when
                      <br />
                      it comes to a hotel operation; it manages all the
                      <br />
                      assets in hotel facilities for 24/7 and provide
                      <br />
                      necessary services for hotel customers.
                      <br />
                      <br />
                      <span>
                        The team is responsible for cleaning rooms, refilling
                        <br />
                        amenities, replacing dirty linens, and providing high
                        <br />
                        quality laundry service for guests.{" "}
                      </span>
                      Also, the members
                      <br />
                      of the Housekeeping Team always try their best to
                      <br />
                      reduce cost and increase the re-sell rate of hotel
                      <br />
                      rooms through efficient hotel inventory management.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("fandb")}
                      text1={`Room Service Team has two parts: Restaurant Management and Banquet Management. They are both responsible for managing events and`}
                      text2={` providing necessary F&B services to hotel customers.`}
                      image={IconSev03SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="3"
                    >
                      The hotel restaurant staffs are responsible for
                      <br />
                      <span>
                        making a theme scheme, decorating, cleaning,
                        <br />
                        and assisting reservations for participants.
                      </span>
                      <br />
                      They are also in charge of customer management,
                      <br />
                      dishware management, and F&B product sales.
                      <br />
                      <br />
                      The banquet management staffs have their own
                      <br />
                      assigned areas in a banquet hall and must constantly
                      <br />
                      organize these designated areas. At the same time,
                      <br />
                      the staffs must take care of customers’ hospitality
                      <br />
                      by providing necessary information, serving food &
                      <br />
                      beverage, and assisting payment procedures.
                      <br />
                      <br />
                      Since we wish our partner hotel to make good
                      <br />
                      impression on their customers, we try our best on
                      <br />
                      hiring the finest staffs with benevolent smiles and
                      <br />
                      kind attitude.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("safety")}
                      text1="Our Safety Management Team&#39;s primary duty is to protect hotel guests, staffs, and"
                      text2="properties so that any disasters could be prevented beforehand."
                      image={IconSev04SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="4"
                    >
                      The Safety Management Team has well trained
                      <br />
                      personnel who are always ready to manage
                      <br />
                      emergency incidents.{" "}
                      <span>
                        our security and safety staffs patrol the hotel for 24/7
                        and regularly check
                        <br />
                        surveillance cameras, fire alarms, fire extinguishers,
                        <br />
                        fire walls, and emergency exits.
                      </span>
                      <br />
                      <br />
                      In addition, they hold mandatory evacuation drills
                      <br />
                      and staff safety training to minimize damage that
                      <br />
                      hotel staffs and guests could receive from unexpected
                      <br />
                      disaster.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("facility")}
                      text1={`Facility Management Team is responsible for `}
                      text2="designing, maintaining, and constructing partner hotel&#39;s facilities."
                      image={IconSev05SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="5"
                    >
                      The Facility Team is responsible for{" "}
                      <span>
                        maintaining and
                        <br />
                        repairing hotel rooms, restaurants, and other facilities
                        <br />& utilities. The team is also responsible for
                        renovating
                        <br />
                        rooms, inspecting equipment, and preventing
                        <br />
                        corrosion in hotel buildings.
                      </span>
                      <br />
                      <br />
                      Especially, our Facility Team hires members who have
                      <br />
                      strong leadership, outstanding risk management skills,
                      <br />
                      and knowledge in structures · electricity · machineries ·
                      <br />
                      firefighting. The team also prefers employees with
                      <br />
                      degrees in structural · electrical · mechanical
                      <br />
                      engineering, or certificates in emergency ·<br />
                      firefighting drills.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("customerservice")}
                      text1="The members of Customer Service Team are always eager to improve our partner hotel&#39;s"
                      text2="service quality. Their adequate and fast response satisfies the hotel guests."
                      image={IconSev06SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="6"
                    >
                      The Customer Service (CS) Team frequently conducts
                      <br />a survey and collect various inquiries, suggestions,
                      <br />
                      and complaints from hotel guests.{" "}
                      <span>
                        The team is
                        <br />
                        composed of staffs with excellent communication
                        <br />
                        skills since they play the role of a ‘bridge’ that
                        <br />
                        connects customers and partner hotels.
                      </span>
                      <br />
                      <br />
                      Our CS Team is willing to create an environment
                      <br />
                      where customers can freely communicate with the
                      <br />
                      hotel despite of their age. The team utilizes real-time
                      <br />
                      Kakao Talk channels and AI ‘chatbot’ to communicate
                      <br />
                      with younger customers while providing ‘standard’
                      <br />
                      telephone counseling to older customers. The CS
                      <br />
                      Team also has its own response manuals, which help
                      <br />
                      the CS staffs to proactively assist the hotel guests.
                    </Panel>
                  </li>
                </ul>
              )}
            </ServiceMobileInner01>
          </div>
        ) : (
          <div className="service_inner">
            <GradientYellow01>
              <img src={BgCircleCSVG} />
            </GradientYellow01>
            <GradientYellow02>
              <img src={BgCircleFSVG} />
            </GradientYellow02>
            <ServiceInner01>
              {t("lang") === "EN" ? (
                <ul>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("guestroom")}
                      text1="호텔 고객의 투숙 예약 절차와 객실 안내를 진행하며,"
                      text2="고객이 필요로 하는 정보 제공의 업무를 주로 수행합니다."
                      image={IconSev01SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="1"
                    >
                      객실 서비스는 크게{" "}
                      <span>
                        도어맨, 발렛파킹, 예약, 프론트, 안내,
                        <br />
                        컨시어지, 휘트니스
                      </span>
                      로 구성되어 있으며,
                      <br />
                      각 부서의 전문 인력은 고객이 호텔 입구에 들어선 순간부터
                      <br />
                      어떠한 번거로움 없이 신속 · 정확하게 객실로 안내 받을 수
                      있도록
                      <br />
                      준비합니다.
                      <br />
                      <br />
                      객실인원은 고객과 직접적으로 접촉하는 만큼 단정한 외모,
                      <br />
                      고객에 대한 매너, 기본적인 외국어 구사력, 분명한 의사소통
                      능력,
                      <br />
                      순발력 등의 다양한 역량을 갖춘 인재로만 엄선합니다.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("guestroommanage")}
                      text1="호텔의 영업이 원활히 진행될 수 있도록 효율적인"
                      text2="객실관리 서비스를 제공합니다."
                      image={IconSev02SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="2"
                    >
                      객실관리팀은 호텔의 자산, 즉 모든 시설을 관리하는 부서인
                      만큼
                      <br />
                      호텔의 전반적인 업무를 총괄하는 중추적 역할을 맡고
                      있습니다.
                      <br />
                      연중무휴 24시간 운영되며, 호텔 고객에게 깨끗하고 안락한
                      환경을
                      <br />
                      제공하기 위해 객실에 비치된{" "}
                      <span>
                        린넨 및 소모품 관리,
                        <br />
                        고객 의류 세탁 등과 같은 제반 서비스 업무
                      </span>
                      를 주로 처리합니다.
                      <br />
                      <br />
                      비품과 린넨류의 효율적인 재고관리를 통해 원가절감에 중대한
                      역할을 하며,
                      <br />
                      호텔 내 가장 수익성이 있는 객실 상품의 재생산 업무 또한
                      책임지고 있습니다.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("fandb")}
                      text1="식음팀은 영업장 관리와 연회 부서로 나뉘어져"
                      text2="연회 행사의 전반적인 운영과 서비스를 제공합니다."
                      image={IconSev03SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="3"
                    >
                      영업장 관리 부서는 연회가 열릴 장소의{" "}
                      <span>
                        테마 구상, 세팅, 청소,
                        <br />
                        예약과 같이 원활한 영업장 운영에 관련된 업무
                      </span>
                      를 담당합니다.
                      <br />
                      또한, 업장의 고객관리, 집기·비품 관리를 담당하고 있으며
                      가장 중요한
                      <br />
                      식음료 상품의 판매를 책임지고 있습니다.
                      <br />
                      연회부서는 연회에 참여하는 청소, 책임 구역의 준비 및 정돈
                      등의
                      <br />
                      영업 준비를 하며, 안내, 서빙, 계산과 같이 원활한 연회가
                      진행될 수 있도록
                      <br />
                      고객 접대 서비스 또한 맡고 있습니다.
                      <br />
                      <br />두 부서 모두 고객을 직접적으로 상대하는 직무인 만큼,
                      <br />
                      밝은 미소와 깨끗한 용모를 갖춘 인재로 엄선하며 호텔 내
                      영업장의
                      <br />
                      긍정적 이미지 형성에 최선을 다하고 있습니다.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("safety")}
                      text1="호텔 고객과 직원의 안전 및 재산 보호가 주 임무인"
                      text2="안전관리팀을 자체적으로 보유하고 있습니다."
                      image={IconSev04SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="4"
                    >
                      안전관리팀은 혹시 모를{" "}
                      <span>
                        재난상황에 대비하여 호텔 내 소화장치,
                        <br />
                        건축 구조물 등을 상시적으로 보수관리
                      </span>{" "}
                      하며, 호텔 내{" "}
                      <span>
                        범죄를 예방하기 위하여
                        <br />
                        숙련된 경비 인력을 활용하여 호텔 내·외부 순찰 등의 방범
                        업무
                      </span>
                      에 투입합니다.
                      <br />
                      <br />
                      또한, 소방 훈련, 대피 훈련, 호텔 직원 안전관리 교육 등을
                      통해
                      <br />
                      재난 상황이 발생하더라도, 고객과 직원의 피해를 최소화 시킬
                      수 있는
                      <br />
                      역량을 갖추었습니다.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("facility")}
                      text1="시설관리팀은 호텔 내 시설물의 설계, 유지·보수,"
                      text2="공사진행 및 관리감독을 담당하고 있습니다."
                      image={IconSev05SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="5"
                    >
                      호텔 고객에게 최상의 서비스를 제공하기 위해{" "}
                      <span>
                        객실, 영업장 및 시설물의
                        <br />
                        상시적인 유지·보수 업무는 물론, 노후화 방지를 위한 개조,
                        보수공사,
                        <br />
                        장비 점검 및 교체 업무
                      </span>
                      를 집중적으로 맡고 있습니다.
                      <br />
                      <br />
                      특히, 자사는 여러 분야의 시설관리 직원을 통솔할 수 있는
                      리더십 있는 인재와,
                      <br />
                      위기상황 또는 고객 컴플레인 발생 시 신속한 대처를 할 수
                      있는 민첩한 인재,
                      <br />
                      그리고 높은 전문성을 갖춘 건설·전기·기계·소방 관련 기사
                      자격증을 소지하거나
                      <br />
                      건축공학·전기공학·기계공학과·소방행정학과 졸업자를
                      집중적으로 채용하고 있습니다.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("customerservice")}
                      text1="고객응대(CS)팀은 자사에서 제공하는 호텔 서비스 개선을"
                      text2="위한 응대 서비스를 담당합니다."
                      image={IconSev06SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="6"
                    >
                      고객과의 긴밀한 커뮤니케이션을 통해 설문조사를 진행하고
                      문의사항, 건의사항 또는
                      <br />
                      불만사항을 접수 받고{" "}
                      <span>
                        자사 서비스의 취약점을 인지하여 관련 부서에 전달하는
                        <br />
                        조직인 만큼 친절하고 커뮤니케이션 역량이 뛰어난 인재
                      </span>
                      로 구성되어 있습니다.
                      <br />
                      <br />
                      실시간 카카오톡 채널과 챗봇을 활용하여 젊은 층의 고객과
                      원활한 소통을
                      <br />할 수 있는 환경을 제공하며, 동시에 유선상담을
                      병행하여 50·60세대의 고객도
                      <br />
                      불편함 없이 고객응대 서비스를 제공할 수 있도록 노력합니다.
                      <br />
                      CS매뉴얼 또한 마련되어 있으므로, 빠른 시간 내에 고객이
                      원하는 정보 제공과
                      <br />
                      애로사항 해결이 가능합니다.
                    </Panel>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("guestroom")}
                      text1={`Room Management Team ("Room-part") is responsible for assisting`}
                      text2="customers with reservation procedures and room information guidance."
                      image={IconSev01SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="1"
                    >
                      The Room Management Team consists of{" "}
                      <span>
                        door attendants, valet parking staffs,
                        <br />
                        reservation assistants, front desk assistants, concierge
                        staffs, and fitness center staffs.{" "}
                      </span>
                      From the moment when guests arrive at a hotel, our
                      professionals in each
                      <br />
                      department prepare for quick and accurate room guidance
                      without any hesitance.
                      <br />
                      <br />
                      We prefer Room Management Team staffs who have neat
                      appearance, manners,
                      <br />
                      good adaptability, foreign language proficiency, and
                      strong sociability, since they
                      <br />
                      serve guests face-to-face.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("guestroommanage")}
                      text1="Housekeeping Team is responsible for efficient room management, "
                      text2="which helps our partner hotel to operate smoothly."
                      image={IconSev02SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="2"
                    >
                      The Housekeeping Team takes the key role when it comes to
                      a hotel operation;
                      <br />
                      it manages all the assets in hotel facilities for 24/7 and
                      provide necessary
                      <br />
                      services for hotel customers.
                      <br />
                      <br />
                      <span>
                        The team is responsible for cleaning rooms, refilling
                        amenities, replacing dirty linens,
                        <br />
                        and providing high quality laundry service for guests.{" "}
                      </span>
                      Also, the members of the <br />
                      Housekeeping Team always try their best to reduce cost and
                      increase the re-sell
                      <br />
                      rate of hotel rooms through efficient hotel inventory
                      management.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("fandb")}
                      text1="Room Service Team has two parts: Restaurant Management and Banquet Management. They are both responsible for managing"
                      text2="events and providing necessary F&B services to hotel customers."
                      image={IconSev03SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="3"
                    >
                      The hotel restaurant staffs are responsible for
                      <br />
                      <span>
                        making a theme scheme, decorating, cleaning, and
                        assisting reservations for participants.{" "}
                      </span>
                      They are also in charge of customer management, dishware
                      <br />
                      management, and F&B product sales.
                      <br />
                      <br />
                      The banquet management staffs have their own assigned
                      areas in a banquet hall
                      <br />
                      and must constantly organize these designated areas. At
                      the same time, the staffs
                      <br />
                      must take care of customers’ hospitality by providing
                      necessary information,
                      <br />
                      serving food & beverage, and assisting payment procedures.
                      <br />
                      <br />
                      Since we wish our partner hotel to make good impression on
                      their customers,
                      <br />
                      we try our best on hiring the finest staffs with
                      benevolent smiles and kind attitude.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("safety")}
                      text1="Our Safety Management Team&#39;s primary duty is to protect "
                      text2="hotel guests, staffs, and properties so that any disasters could be prevented beforehand."
                      image={IconSev04SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="4"
                    >
                      The Safety Management Team has well trained personnel who
                      are always ready
                      <br />
                      to manage emergency incidents.{" "}
                      <span>
                        our security and safety staffs patrol the hotel
                        <br />
                        for 24/7 and regularly check surveillance cameras, fire
                        alarms, fire extinguishers,
                        <br />
                        fire walls, and emergency exits.
                      </span>
                      <br />
                      <br />
                      In addition, they hold mandatory evacuation drills and
                      staff safety training to minimize
                      <br />
                      damage that hotel staffs and guests could receive from
                      unexpected disaster.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("facility")}
                      text1="Facility Management Team is responsible for designing, "
                      text2="maintaining, and constructing partner hotel&#39;s facilities."
                      image={IconSev05SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="5"
                    >
                      The Facility Team is responsible for{" "}
                      <span>
                        maintaining and repairing hotel rooms,
                        <br />
                        restaurants, and other facilities & utilities. The team
                        is also responsible for
                        <br />
                        renovating rooms, inspecting equipment, and preventing
                        corrosion in hotel buildings.
                      </span>
                      <br />
                      <br />
                      Especially, our Facility Team hires members who have
                      strong leadership, outstanding
                      <br />
                      risk management skills, and knowledge in structures ·
                      electricity · machineries ·<br />
                      firefighting. The team also prefers employees with degrees
                      in structural · electrical ·<br />
                      mechanical engineering, or certificates in emergency ·
                      firefighting drills.
                    </Panel>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <Panel
                      title={t("customerservice")}
                      text1="The members of Customer Service Team are always eager to improve"
                      text2="our partner hotel&#39;s service quality. Their adequate and fast response satisfies the hotel guests."
                      image={IconSev06SVG}
                      active={active}
                      handleToggle={handleToggle}
                      idx="6"
                    >
                      The Customer Service (CS) Team frequently conducts a
                      survey and collect various
                      <br />
                      inquiries, suggestions, and complaints from hotel guests.{" "}
                      <span>
                        The team is composed of
                        <br />
                        staffs with excellent communication skills since they
                        play the role of a ‘bridge’ that
                        <br />
                        connects customers and partner hotels.
                      </span>
                      <br />
                      <br />
                      Our CS Team is willing to create an environment where
                      customers can freely
                      <br />
                      communicate with the hotel despite of their age. The team
                      utilizes real-time
                      <br />
                      Kakao Talk channels and AI ‘chatbot’ to communicate with
                      younger customers
                      <br />
                      while providing ‘standard’ telephone counseling to older
                      customers. The CS Team
                      <br />
                      also has its own response manuals, which help the CS
                      staffs to proactively assist
                      <br />
                      the hotel guests.
                    </Panel>
                  </li>
                </ul>
              )}
            </ServiceInner01>
          </div>
        )}
      </SubLayout>
    </Layout>
  );
};

export default ServiceInfo;

const GradientYellow01 = styled.div`
  position: absolute;
  z-index: 500;
  width: 200%;
  height: 100%;
  top: 150px;
  left: -80%;
  background: radial-gradient(
    circle at 10% 90%,
    rgba(177, 136, 86, 0.3),
    rgba(128, 102, 70, 0) 44%
  );

  & img {
    position: relative;
    top: 38%;
    left: 25%;
  }
`;

const GradientYellow02 = styled.div`
  overflow: hidden;
  position: absolute;
  z-index: 500;
  width: 200%;
  height: 100%;
  top: 150px;
  right: -60%;
  background: radial-gradient(
    circle at 90% 90%,
    rgba(177, 136, 86, 0.3),
    rgba(128, 102, 70, 0) 44%
  );

  & img {
    position: relative;
    top: 80%;
    left: 58%;
  }
`;

const ServiceInner01 = styled.div`
  position: relative;
  z-index: 600;
  & ul > li {
    width: 100%;
    display: flex;
    padding: 50px;
    border-bottom: 1px solid #707070;
  }

  & ul > li:first-of-type {
    margin-top: 60px;
    border-top: 1px solid #707070;
  }

  & h3 {
    width: 460px;
    font-size: 36px;
    line-height: 71px;
    color: #ffc17d;
    font-weight: bold;
  }

  & .service_con_wrap_text_1 {
    width: 720px;
    font-size: 22px;
    line-height: 40px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 30px;
  }

  & .service_con_wrap_text_2 {
    width: 710px;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    padding-top: 30px;
    border-top: 2px dashed #707070;
    letter-spacing: normal;

    & span {
      color: #ffdab2;
    }
  }

  & .service_con_wrap_image {
    position: absolute;
    left: 3%;
    width: 150px;

    & img {
      width: 100%;
    }
  }

  & .service_con_wrap_detail {
    width: 110px;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    opacity: 0.7;
    margin-bottom: 30px;
    cursor: pointer;

    & img {
      margin-left: 8px;
    }
  }
`;

const GradientMobileYellow01 = styled.div`
  position: absolute;
  z-index: 500;
  width: 200%;
  height: 100%;
  top: 150px;
  left: -80%;
  background: radial-gradient(
    circle at 10% 30%,
    rgba(177, 136, 86, 0.3),
    rgba(128, 102, 70, 0) 44%
  );

  & img {
    position: relative;
    width: 300px;
    top: 38%;
    left: 30%;
  }
`;

const GradientMobileYellow02 = styled.div`
  overflow: hidden;
  position: absolute;
  z-index: 500;
  width: 200%;
  height: 100%;
  top: 150px;
  right: -60%;
  background: radial-gradient(
    circle at 90% 50%,
    rgba(177, 136, 86, 0.3),
    rgba(128, 102, 70, 0) 44%
  );

  & img {
    position: relative;
    width: 300px;
    top: 80%;
    left: 48%;
  }
`;

const ServiceMobileInner01 = styled.div`
  position: relative;
  z-index: 600;
  & ul > li {
    width: 100%;
    padding-top: 20px;
    border-bottom: 1px solid #707070;
  }

  & ul > li:first-of-type {
    margin-top: 60px;
    border-top: 1px solid #707070;
  }

  & h3 {
    width: 470px;
    font-size: 20px;
    color: #ffc17d;
    font-weight: bold;
    margin-bottom: 16px;
  }

  & .service_con_wrap_text_1 {
    font-size: 16px;
    line-height: 1.63;
    font-weight: bold;
    color: #fff;
    margin-bottom: 20px;
  }

  & .service_con_wrap_text_2 {
    font-size: 14px;
    line-height: 1.86;
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 2px dashed #707070;
    letter-spacing: normal;

    & span {
      color: #ffdab2;
    }
  }

  & .service_con_wrap_image {
    width: 150px;
    height: 100px;
    margin: 0 0 15px -10px;

    & img {
      width: 100%;
    }
  }

  & .service_con_wrap_detail {
    width: 110px;
    font-size: 16px;
    line-height: 2;
    color: #fff;
    opacity: 0.7;
    padding-bottom: 20px;
    cursor: pointer;

    & img {
      margin-left: 8px;
    }
  }
`;
