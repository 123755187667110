import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner06 from "assets/images/pc/img_banner06.png";

/**
 * @file Contact Us - 담당자 연락처 페이지
 * @brief 대표번호, 팩스번호, 사업담당자, 인사담당자 연락처
 * @author TW.Lee
 */
const ContactInfo = () => {
  const { t } = useTranslation();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  return (
    <Layout subFixed="true">
      <SubLayout
        banner={Banner06}
        pageText={
          t("lang") === "EN"
            ? PAGE_LAYOUT.contactinfo
            : PAGE_LAYOUT_EN.contactinfo
        }
      >
        {isMobileWidth ? (
          <ContactInfoMobileInner01>
            <div>
              <div className="content_section1">
                <div>
                  {t("lang") === "EN" ? (
                    <p
                      className="section1_text1"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      항상 초심을 잃지
                      <br /> 않는 누리글로벌서비스가
                      <br /> 되겠습니다.
                    </p>
                  ) : (
                    <p
                      className="section1_text1"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      NURI Global Service
                      <br /> will never rest
                      <br /> on its laurels.
                    </p>
                  )}
                  {t("lang") === "EN" ? (
                    <p
                      className="section1_text2"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      전화로 문의하시면 자세히
                      <br />
                      답변드리겠습니다.
                    </p>
                  ) : (
                    <p
                      className="section1_text2"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      We will inform you in more detail
                      <br />
                      through telephone consultation.
                    </p>
                  )}
                </div>
                <div>
                  <div
                    className="section1_wrap"
                    data-aos="fade-up"
                    data-aos-duration="1650"
                  >
                    <div className={t("lang") === "EN" ? "void" : "en_section"}>
                      <p
                        className={
                          t("lang") === "EN"
                            ? "section1_wrap_text1"
                            : "section1_wrap_text1_en"
                        }
                      >
                        {t("lang") === "EN"
                          ? "대표번호"
                          : "Representative Phone Number"}
                      </p>
                      <p className="section1_wrap_text2">02-3210-0930</p>
                    </div>
                    <div>
                      <p
                        className={
                          t("lang") === "EN"
                            ? "section1_wrap_text1"
                            : "section1_wrap_text1_en"
                        }
                      >
                        {t("lang") === "EN" ? "팩스번호" : "Fax Number"}
                      </p>
                      <p className="section1_wrap_text2">02-3210-0931</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content_section2">
                <div
                  className="section2_wrap"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <p className="section2_wrap_text1">
                    {t("lang") === "EN" ? "사업 담당자" : "Business Inquiry"}
                  </p>
                  <p className="section2_wrap_text2">
                    {t("lang") === "EN"
                      ? "상무 임성후"
                      : "Vice President, Danny Lim"}
                  </p>
                  <p className="section2_wrap_text3">sunghoo.lim@nuri-gs.com</p>
                </div>
                <div
                  className="section2_wrap"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <p className="section2_wrap_text1">
                    {t("lang") === "EN"
                      ? "인사 담당자"
                      : "Human Resource Inquiry"}
                  </p>
                  <p className="section2_wrap_text2">
                    {t("lang") === "EN"
                      ? "경영지원 과장 구지애"
                      : "Business Support Manager, Zoey Ku"}
                  </p>
                  <p className="section2_wrap_text3">withnuri@nuri-gs.com</p>
                </div>
                <div
                  className="section2_wrap section2_wrap_span"
                  data-aos="fade-up"
                  data-aos-duration="1750"
                >
                  <div>
                    <p className="section2_wrap_text1">
                      {t("lang") === "EN"
                        ? "침구류 구매 문의 [He:on]"
                        : "[He:on] Premium Bedding Shop"}
                    </p>
                  </div>
                  <div>
                    <div>
                      <p className="section2_wrap_text2">
                        {t("lang") === "EN" ? "전화번호" : "Mobile"}
                      </p>
                      <p className="section2_wrap_text3">010-2644-7933</p>
                    </div>
                    <div>
                      <p className="section2_wrap_text2">
                        {t("lang") === "EN" ? "이메일 주소" : "Email Address"}
                      </p>
                      <p className="section2_wrap_text3">heon@nuri-gs.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ContactInfoMobileInner01>
        ) : (
          <ContactInfoInner01>
            <div>
              <div className="content_section1">
                <div>
                  {t("lang") === "EN" ? (
                    <p
                      className="section1_text1"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      항상 초심을
                      <br /> 잃지 않는
                      <br />
                      누리글로벌서비스가
                      <br /> 되겠습니다.
                    </p>
                  ) : (
                    <p
                      className="section1_text1"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      NURI Global Service
                      <br />
                      will never rest
                      <br /> on its laurels.
                    </p>
                  )}
                  {t("lang") === "EN" ? (
                    <p
                      className="section1_text2"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      전화로 문의하시면 자세히 답변드리겠습니다.
                    </p>
                  ) : (
                    <p
                      className="section1_text2_en"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      We will inform you in more detail
                      <br /> through telephone consultation.
                    </p>
                  )}
                </div>
                <div>
                  <div
                    className="section1_wrap"
                    data-aos="fade-up"
                    data-aos-duration="1650"
                  >
                    <div>
                      <p className="section1_wrap_text1">
                        {t("lang") === "EN"
                          ? "대표번호"
                          : "Representative Phone Number"}
                      </p>
                      <p className="section1_wrap_text2">02-3210-0930</p>
                    </div>
                    <div>
                      <p className="section1_wrap_text1">
                        {t("lang") === "EN" ? "팩스번호" : "Fax Number"}
                      </p>
                      <p className="section1_wrap_text2">02-3210-0931</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content_section2">
                <div
                  className="section2_wrap"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <p className="section2_wrap_text1">
                    {t("lang") === "EN" ? "사업 담당자" : "Business Inquiry"}
                  </p>
                  <p className="section2_wrap_text2">
                    {t("lang") === "EN"
                      ? "상무 임성후"
                      : "Vice President, Danny Lim"}
                  </p>
                  <p className="section2_wrap_text3">sunghoo.lim@nuri-gs.com</p>
                </div>
                <div
                  className="section2_wrap"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <p className="section2_wrap_text1">
                    {t("lang") === "EN"
                      ? "인사 담당자"
                      : "Human Resource Inquiry"}
                  </p>
                  <p className="section2_wrap_text2">
                    {t("lang") === "EN"
                      ? "경영지원 과장 구지애"
                      : "Business Support Manager, Zoey Ku"}
                  </p>
                  <p className="section2_wrap_text3">withnuri@nuri-gs.com</p>
                </div>
                <div
                  className="section2_wrap section2_wrap_span"
                  data-aos="fade-up"
                  data-aos-duration="1750"
                >
                  <div>
                    <p className="section2_wrap_text1">
                      {t("lang") === "EN"
                        ? "침구류 구매 문의 [He:on]"
                        : "[He:on] Premium Bedding Shop"}
                    </p>
                  </div>
                  <div>
                    <div>
                      <p className="section2_wrap_text2">
                        {t("lang") === "EN" ? "전화번호" : "Mobile"}
                      </p>
                      <p className="section2_wrap_text3">010-2644-7933</p>
                    </div>
                    <div>
                      <p className="section2_wrap_text2">
                        {t("lang") === "EN" ? "이메일 주소" : "Email Address"}
                      </p>
                      <p className="section2_wrap_text3">heon@nuri-gs.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ContactInfoInner01>
        )}
      </SubLayout>
    </Layout>
  );
};

export default ContactInfo;

const ContactInfoInner01 = styled.div`
  margin-top: 60px;
  color: #fff;

  & > div {
    display: flex;
    justify-content: space-between;
    background-color: #1d1d1d;
    padding: 100px 168px 100px 100px;
  }
  & .content_section1 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & .section1_text1 {
    width: 360px;
    font-family: "Arita-buri-SemiBold";
    font-size: 36px;
    font-weight: 600;
    line-height: 1.61;

    color: var(--primary-color1);
  }

  & .section1_text2 {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.15;
    color: var(--bg-color1);
    margin-top: 90px;
  }
  & .section1_text2_en {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.15;
    color: var(--bg-color1);
    margin-top: 60px;
    margin-bottom: 10px;
  }

  & .section1_wrap {
    margin-top: 22px;
    position: relative;
    display: flex;
  }

  & .section1_wrap > div:first-of-type {
    margin-right: 50px;
  }

  & .section1_wrap > div > .section1_wrap_text1 {
    font-size: 18px;
    line-height: 1.67;
    color: var(--secondary-color1);
  }

  & .section1_wrap > div > .section1_wrap_text2 {
    font-size: 22px;
    line-height: 1.36;
    color: var(--bg-color1);
  }

  & .content_section2 > .section2_wrap > .section2_wrap_text1,
  & .content_section2 > .section2_wrap_span > div > .section2_wrap_text1 {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    color: #a89a8b;
    padding-bottom: 8px;
    border-bottom: solid 1px #444;
  }

  & .content_section2 > .section2_wrap_span > div > .section2_wrap_text1,
  &
    .content_section2
    > .section2_wrap:not(:first-of-type)
    > .section2_wrap_text1 {
    margin-top: 50px;
  }

  & .content_section2 > .section2_wrap > .section2_wrap_text2 {
    margin-top: 20px;
    font-family: Pretendard;
    font-size: 18px;
    line-height: 1.67;
    color: #ffdab2;
  }

  & .content_section2 > .section2_wrap > .section2_wrap_text3 {
    font-family: Pretendard;
    font-size: 18px;
    line-height: 1.67;
    color: #fff;
  }

  & .content_section2 > .section2_wrap_span > div:last-child {
    position: relative;
    display: flex;
    margin-top: 20px;
  }

  & .content_section2 > .section2_wrap_span > div > div > .section2_wrap_text2 {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #ffdab2;
  }
  & .content_section2 > .section2_wrap_span > div > div > .section2_wrap_text3 {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  & .content_section2 > .section2_wrap_span > div > div:last-of-type {
    margin-left: 30px;
  }
`;

const ContactInfoMobileInner01 = styled.div`
  margin-top: 40px;
  color: #fff;

  & > div {
    background-color: #1d1d1d;
    padding: 30px 35px 30px 30px;
  }
  & .content_section1 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & .section1_text1 {
    font-family: "Arita-buri-SemiBold";
    font-size: 24px;
    font-weight: 600;
    line-height: 1.61;
    color: var(--primary-color1);
  }

  & .section1_text2 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    color: var(--bg-color1);
    margin-top: 30px;
  }

  & .section1_wrap {
    margin: 20px 0 40px 0;

    display: flex;
  }

  & .section1_wrap > .void {
    margin-right: 50px;
  }

  & .section1_wrap > .en_section {
    width: 50%;
    margin-right: 20px;
  }

  & .section1_wrap > div > .section1_wrap_text1 {
    font-size: 14px;
    line-height: 1.67;
    color: var(--secondary-color1);
  }

  & .section1_wrap > div > .section1_wrap_text1_en {
    font-size: 14px;
    line-height: 1.67;
    color: var(--secondary-color1);
  }

  & .section1_wrap > div > .section1_wrap_text2 {
    font-size: 14px;
    line-height: 1.36;
    color: var(--bg-color1);
  }

  & .content_section2 > .section2_wrap > .section2_wrap_text1,
  & .content_section2 > .section2_wrap_span > div > .section2_wrap_text1 {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    color: #a89a8b;
    padding-bottom: 8px;
    border-bottom: solid 1px #444;
  }

  & .content_section2 > .section2_wrap_span > div > .section2_wrap_text1,
  &
    .content_section2
    > .section2_wrap:not(:first-of-type)
    > .section2_wrap_text1 {
    margin-top: 20px;
  }

  & .content_section2 > .section2_wrap > .section2_wrap_text2 {
    margin-top: 12px;
    font-family: Pretendard;
    font-size: 14px;
    line-height: 1.67;
    color: #ffdab2;
  }

  & .content_section2 > .section2_wrap > .section2_wrap_text3 {
    font-family: Pretendard;
    font-size: 14px;
    line-height: 1.67;
    color: #fff;
  }

  & .content_section2 > .section2_wrap_span > div:last-child {
    position: relative;
    display: flex;
    margin-top: 20px;
  }

  & .content_section2 > .section2_wrap_span > div > div > .section2_wrap_text2 {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #ffdab2;
  }
  & .content_section2 > .section2_wrap_span > div > div > .section2_wrap_text3 {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  & .content_section2 > .section2_wrap_span > div > div:last-of-type {
    margin-left: 30px;
  }
`;
