import React, { useEffect, useRef, useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner01 from "assets/images/pc/img_banner01.png";
import BtnNextSVG from "assets/images/pc/btn_next_circle.svg";

/**
 * @file 회원가입 페이지
 * @brief 일반/파트타이머, 협력사 별 회원가입 분기 페이지
 * @author TW.Lee
 */
const SignupMain = () => {
  const { t } = useTranslation();
  const normalRef = useRef();
  const partnerRef = useRef([]);
  const [isMove, setIsMove] = useState(false);
  const [isRotate, setIsRotate] = useState(false);
  const navigate = useNavigate();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const root = SignupStyled(t("lang"));
  const moroot = SignupMobileStyled(t("lang"));

  useEffect(() => {
    if (isRotate) {
      normalRef.current.style.animation =
        "rotation 2s ease-in-out 0s 1 alternate";
    } else {
      normalRef.current.style.animation = "";
    }
    if (isMove) {
      for (let i = 0; i < partnerRef.current.length; i++) {
        partnerRef.current[i].style.animation = `moves${
          i + 1
        } 2s ease-out 0s 1 alternate`;
      }
    } else {
      for (let i = 0; i < partnerRef.current.length; i++) {
        partnerRef.current[i].style.animation = "";
      }
    }
  }, [isRotate, isMove]);

  return (
    <Layout subFixed="true">
      <SubLayout
        banner={Banner01}
        pageText={
          t("lang") === "EN" ? PAGE_LAYOUT.signup : PAGE_LAYOUT_EN.signup
        }
      >
        {isMobileWidth ? (
          <SignUpMobileInner01 css={moroot}>
            <div className="join_choice">
              <ul>
                <li>
                  <div>
                    <h3>{t("regular")}</h3>
                    <p className="join_choice_text">
                      {t("regulartext1")}
                      <br />
                      {t("regulartext2")}
                    </p>
                    <p
                      className="join_choice_move"
                      onClick={() => {
                        setIsRotate(true);
                        setTimeout(() => {
                          navigate("/signup/createAccount", {
                            state: {
                              id: 1,
                            },
                          });
                        }, 300);
                      }}
                    >
                      {t("joinus")}
                      <img src={BtnNextSVG} />
                    </p>
                    <NormalMobileRoot>
                      <ul ref={normalRef}>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </NormalMobileRoot>
                  </div>
                </li>
                <li>
                  <div>
                    <h3>{t("partners")}</h3>
                    <p className="join_choice_text">
                      {t("partnerstext1")}
                      <br />
                      {t("partnerstext2")}
                    </p>
                    <p
                      className="join_choice_move"
                      onClick={() => {
                        setIsMove(true);
                        setTimeout(() => {
                          navigate("/signup/createAccount", {
                            state: {
                              id: 2,
                            },
                          });
                        }, 300);
                      }}
                    >
                      {t("receive")}
                      <img src={BtnNextSVG} />
                    </p>
                    <PartnerMobileRoot>
                      <ul>
                        <li ref={(el) => (partnerRef.current[0] = el)}></li>
                        <li ref={(el) => (partnerRef.current[1] = el)}></li>
                        <li ref={(el) => (partnerRef.current[2] = el)}></li>
                        <li ref={(el) => (partnerRef.current[3] = el)}></li>
                        <li ref={(el) => (partnerRef.current[4] = el)}></li>
                        <li ref={(el) => (partnerRef.current[5] = el)}></li>
                        <li ref={(el) => (partnerRef.current[6] = el)}></li>
                        <li ref={(el) => (partnerRef.current[7] = el)}></li>
                      </ul>
                    </PartnerMobileRoot>
                  </div>
                </li>
              </ul>
            </div>
          </SignUpMobileInner01>
        ) : (
          <SignUpInner01 css={root}>
            <div className="join_choice">
              <ul>
                <li
                  onMouseEnter={() => setIsRotate(true)}
                  onMouseLeave={() => setIsRotate(false)}
                >
                  <div>
                    <h3>{t("regular")}</h3>
                    <p className="join_choice_text">
                      {t("regulartext1")}
                      <br />
                      {t("regulartext2")}
                    </p>
                    <p
                      className="join_choice_move"
                      onClick={() =>
                        navigate("/signup/createAccount", {
                          state: {
                            id: 1,
                            subText:
                              t("lang") === "EN"
                                ? "일반 · 파트타이머"
                                : "Regular · Part-timer",
                          },
                        })
                      }
                    >
                      {t("joinus")}
                      <img src={BtnNextSVG} />
                    </p>
                    <NormalRoot>
                      <ul ref={normalRef}>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </NormalRoot>
                  </div>
                </li>
                <li
                  onMouseEnter={() => setIsMove(true)}
                  onMouseLeave={() => setIsMove(false)}
                >
                  <div>
                    <h3>{t("partners")}</h3>
                    <p className="join_choice_text">
                      {t("partnerstext1")}
                      <br />
                      {t("partnerstext2")}
                    </p>
                    <p
                      className="join_choice_move"
                      onClick={() =>
                        navigate("/signup/createAccount", {
                          state: {
                            id: 2,
                            subText: t("lang") === "EN" ? "협력사" : "Partners",
                          },
                        })
                      }
                    >
                      {t("receive")}
                      <img src={BtnNextSVG} />
                    </p>
                    <PartnerRoot>
                      <ul>
                        <li ref={(el) => (partnerRef.current[0] = el)}></li>
                        <li ref={(el) => (partnerRef.current[1] = el)}></li>
                        <li ref={(el) => (partnerRef.current[2] = el)}></li>
                        <li ref={(el) => (partnerRef.current[3] = el)}></li>
                        <li ref={(el) => (partnerRef.current[4] = el)}></li>
                        <li ref={(el) => (partnerRef.current[5] = el)}></li>
                        <li ref={(el) => (partnerRef.current[6] = el)}></li>
                        <li ref={(el) => (partnerRef.current[7] = el)}></li>
                      </ul>
                    </PartnerRoot>
                  </div>
                </li>
              </ul>
            </div>
          </SignUpInner01>
        )}
      </SubLayout>
    </Layout>
  );
};

export default SignupMain;

const SignupStyled = (lang) => {
  return css`
    & .join_choice .join_choice_move {
      width: ${lang === "EN" ? "180px" : "320px"};
      font-size: 18px;
      color: #fff;
      opacity: 0.7;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  `;
};

const SignupMobileStyled = (lang) => {
  return css`
    & .join_choice .join_choice_move {
      width: 190px;
      font-size: 14px;
      color: #fff;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  `;
};

const SignUpInner01 = styled.div`
  & .join_choice > ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  & .join_choice > ul > li {
    width: 590px;
    height: 614px;
    margin: 60px 20px 100px 0;
    padding: 80px 80px 80px 80px;
    border-radius: 90px;
    background-color: #000;

    &:hover {
      background-image: linear-gradient(142deg, #000 63%, #c09260 111%);
    }
  }

  & .join_choice h3 {
    font-size: 36px;
    line-height: 44px;
    font-weight: bold;
    color: #fff;
  }

  & .join_choice .join_choice_text {
    font-size: 20px;
    color: #fff;
    margin: 30px 0 40px 0;
  }

  & .join_choice .join_choice_move:hover {
    opacity: 1;
  }

  & .join_choice .join_choice_move img {
    margin-left: 12px;
  }
`;

const SignUpMobileInner01 = styled.div`
  & .join_choice > ul {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 50px;
  }
  & .join_choice > ul > li {
    width: 330px;
    height: 340px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
    padding: 40px;
    border-radius: 60px;
    background-color: #000;

    &:hover {
      background-image: linear-gradient(142deg, #000 63%, #c09260 111%);
    }
  }

  & .join_choice h3 {
    font-size: 22px;
    line-height: 0.95;
    font-weight: bold;
    color: #fff;
  }

  & .join_choice .join_choice_text {
    font-size: 14px;
    color: #fff;
    margin: 30px 0 24px 0;
  }

  & .join_choice .join_choice_move img {
    width: 26px;
    margin-left: 12px;
  }
`;

const NormalRoot = styled.div`
  position: absolute;
  margin-left: 150px;
  margin-top: 90px;
  display: flex;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  & li {
    width: 93px;
    height: 93px;
    border-radius: 50%;
    opacity: 0.9;
    float: left;
    position: relative;
  }

  & li:nth-of-type(1) {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    z-index: 30;
    margin-left: 55px;
  }
  & li:nth-of-type(2) {
    background-image: linear-gradient(to bottom, #dba366, #dba366);
    left: -27px;
    z-index: 20;
  }

  & li:nth-of-type(3) {
    background-color: #444;
    left: -54px;
    z-index: 10;
  }
`;

const NormalMobileRoot = styled.div`
  position: absolute;
  margin-left: 120px;
  margin-top: 50px;
  display: flex;
  animation: rotation 2s ease-in-out 0s 1 alternate;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  & li {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    opacity: 0.9;
    float: left;
    position: relative;
  }

  & li:nth-of-type(1) {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    z-index: 30;
    margin-left: 21px;
  }
  & li:nth-of-type(2) {
    background-image: linear-gradient(to bottom, #dba366, #dba366);
    left: -10px;
    z-index: 20;
  }

  & li:nth-of-type(3) {
    background-color: #444;
    left: -20px;
    z-index: 10;
  }
`;

const PartnerRoot = styled.div`
  position: absolute;
  margin-left: 200px;
  margin-top: 97px;

  & li {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    position: absolute;
  }

  & li:nth-of-type(1) {
    opacity: 0.8;
    border: solid 2.5px #fff;
    z-index: 30;
  }
  & li:nth-of-type(2) {
    background-image: linear-gradient(
      134deg,
      #dba366 15%,
      rgba(219, 163, 102, 0) 86%
    );
    margin-left: 22px;
    margin-top: -47px;
    z-index: 20;
  }
  & li:nth-of-type(3) {
    background-color: #444;
    margin-left: 69px;
    margin-top: -72px;
    z-index: 10;
  }
  & li:nth-of-type(4) {
    opacity: 0.8;
    border: solid 2.5px #ffc17d;
    margin-left: 116px;
    margin-top: -47px;
    z-index: 20;
  }
  & li:nth-of-type(5) {
    border: solid 2.5px #707070;
    margin-left: 137px;
    z-index: 10;
  }
  & li:nth-of-type(6) {
    background-color: #c09260;
    margin-left: 116px;
    margin-top: 47px;
    z-index: 20;
  }
  & li:nth-of-type(7) {
    opacity: 0.9;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    margin-left: 69px;
    margin-top: 72px;
    z-index: 20;
  }
  & li:nth-of-type(8) {
    opacity: 0.8;
    border: solid 2.5px #ffc17d;
    margin-left: 22px;
    margin-top: 47px;
    z-index: 30;
  }

  @keyframes moves1 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-30px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves2 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-30px, -30px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves3 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, -30px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves4 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(30px, -30px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves5 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(30px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves6 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(30px, 30px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves7 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 30px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves8 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-30px, 30px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
`;

const PartnerMobileRoot = styled.div`
  position: absolute;
  margin-left: 140px;
  margin-top: 50px;

  & li {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    position: absolute;
  }

  & li:nth-of-type(1) {
    opacity: 0.8;
    border: solid 2.5px #fff;
    z-index: 30;
    animation: moves1 2s ease-out 0s 1 alternate;
  }
  & li:nth-of-type(2) {
    background-image: linear-gradient(
      134deg,
      #dba366 15%,
      rgba(219, 163, 102, 0) 86%
    );
    margin-left: 8px;
    margin-top: -24px;
    z-index: 20;
    animation: moves2 2s ease-out 0s 1 alternate;
  }
  & li:nth-of-type(3) {
    background-color: #444;
    margin-left: 35px;
    margin-top: -35px;
    z-index: 10;
    animation: moves3 2s ease-out 0s 1 alternate;
  }
  & li:nth-of-type(4) {
    opacity: 0.8;
    border: solid 2.5px #ffc17d;
    margin-left: 60px;
    margin-top: -24px;
    z-index: 20;
    animation: moves4 2s ease-out 0s 1 alternate;
  }
  & li:nth-of-type(5) {
    border: solid 2.5px #707070;
    margin-left: 70px;
    z-index: 10;
    animation: moves5 2s ease-out 0s 1 alternate;
  }
  & li:nth-of-type(6) {
    background-color: #c09260;
    margin-left: 60px;
    margin-top: 24px;
    z-index: 20;
    animation: moves6 2s ease-out 0s 1 alternate;
  }
  & li:nth-of-type(7) {
    opacity: 0.9;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    margin-left: 35px;
    margin-top: 35px;
    z-index: 20;
    animation: moves7 2s ease-out 0s 1 alternate;
  }
  & li:nth-of-type(8) {
    opacity: 0.8;
    border: solid 2.5px #ffc17d;
    margin-left: 8px;
    margin-top: 24px;
    z-index: 30;
    animation: moves8 2s ease-out 0s 1 alternate;
  }

  @keyframes moves1 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-15px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves2 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-15px, -15px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves3 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, -15px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves4 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(15px, -15px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves5 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(15px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves6 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(15px, 15px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves7 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 15px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes moves8 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-15px, 15px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
`;
