import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";

// layout
import Layout from "layout/Layout";
import GradientLayout from "layout/GradientLayout";

// assets
import ImgBgMainPNG from "assets/images/pc/img_bg_main@3x.png";
import ImgMain01PNG from "assets/images/pc/img_main01.png";

// store
import { isMobileWidthAtom } from "store/ui/atom";

import GlobalService from "./components/GlobalService";
import SloganSection from "./section/SloganSection";
import ValuesSection from "./section/ValuesSection";
import ProposalSection from "./section/ProposalSection";

/**
 * @file 메인 페이지
 * @brief 슬로건, 가치관, 제휴문의 등 소개
 * @author TW.Lee
 * @todo 해야할 일
 * - SloganSection 스크롤 인터렉션 적용하기
 */
const Main = () => {
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  if (isMobileWidth) {
    return (
      <Layout>
        <MainMobileRoot>
          <GradientLayout>
            <div className="img_mo_bg_main">
              <SloganSection />
              <div className="moMainIngSection">
                <div>
                  <p>scroll down</p>
                  <img src={ImgMain01PNG} />
                </div>
              </div>
              <GlobalService />
              <ValuesSection />
              <ProposalSection />
            </div>
          </GradientLayout>
        </MainMobileRoot>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <MainRoot>
          <GradientLayout>
            <div className="img_bg_main">
              <SloganSection />
              <div className="mainIngSection">
                <div>
                  <p>scroll down</p>
                  <img src={ImgMain01PNG} />
                </div>
              </div>
              <GlobalService />
              <ValuesSection />
              <ProposalSection />
            </div>
          </GradientLayout>
        </MainRoot>
      </Layout>
    );
  }
};

export default Main;

const MainRoot = styled.div`
  width: 100vw;
  background-image: linear-gradient(to bottom, #111, #060606);

  & .img_bg_main {
    background: url(${ImgBgMainPNG}) no-repeat right bottom -80px / auto 834px;
  }

  & .mainIngSection {
    position: relative;
    z-index: 10;
    padding-bottom: 140px;
  }

  & .mainIngSection > div {
    width: 920px;
    margin: 0 auto;
    color: var(--Affdab2);
    text-decoration: underline;
    font-size: 14px;
    & p {
      opacity: 0.6;
      margin-bottom: 30px;
    }
    & img {
      height: 460px;
    }
  }

  & .main_section_h3 {
    font-family: "Arita-buri-SemiBold";
    color: var(--primary-color1);
    line-height: 56px;
    font-size: 40px;

    & br {
      display: none;
    }

    & .big_txt {
      display: block;
      font-size: 55px;
      line-height: 76px;
      margin-left: 157px;
    }
  }

  & .main_section_text {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: bold;
    color: var(--primary-color2);
  }

  & .main_section_sentence {
    font-size: 20px;
    line-height: 34px;
    margin: 20px 0 40px 157px;
    color: var(--Aaca298);

    & br {
      display: none;
    }
  }

  & .more_btn {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    margin-left: 157px;

    &:hover {
      & p {
        opacity: 1;
      }
    }

    & p {
      cursor: pointer;
      opacity: 0.7;
      color: var(--bg-color1);
    }
  }

  & .pc_word_break {
    display: block;
    line-height: 34px;
  }

  & .btn_next_circle {
    width: 34px;
    height: 34px;
    margin-left: 12px;
    cursor: pointer;
  }
`;

const MainMobileRoot = styled.div`
  background-image: linear-gradient(to bottom, #111, #060606);

  & .img_mo_bg_main {
    background: url(${ImgBgMainPNG}) no-repeat right bottom -30px / auto 20%;
    @media screen and (max-width: 500px) {
      background: url(${ImgBgMainPNG}) no-repeat right -30px bottom -30px / auto
        300px;
    }
  }

  & .moMainIngSection {
    position: relative;
    z-index: 10;

    padding-bottom: 140px;
  }

  & .moMainIngSection > div {
    margin: 0 30px;
    color: var(--Affdab2);
    text-decoration: underline;
    font-size: 14px;
    & p {
      opacity: 0.6;
      margin-bottom: 30px;
    }
    & img {
      width: 100%;
    }
  }

  & .mo_main_section_h3 {
    font-family: "Arita-buri-SemiBold";
    color: var(--primary-color1);
    font-size: 24px;

    & br {
      display: block;
    }

    & .big_txt {
      display: block;
      font-size: 28px;
      line-height: 1.57;
      margin-left: 28px;
    }
  }

  & .mo_main_section_text {
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: bold;
    color: var(--primary-color2);
  }

  & .mo_main_section_sentence {
    font-size: 14px;
    line-height: 1.71;
    margin: 16px 0 20px 28px;
    color: var(--Aaca298);

    & br {
      display: block;
    }
  }

  & .more_btn {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    margin-left: 157px;

    &:hover {
      & p {
        opacity: 1;
      }
    }

    & p {
      cursor: pointer;
      opacity: 0.7;
      color: var(--bg-color1);
    }
  }

  & .mo_more_btn {
    position: relative;
    z-index: 100;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: 28px;

    & img {
      width: 26px;
    }
    &:hover {
      & p {
        opacity: 1;
      }
    }

    & p {
      cursor: pointer;
      opacity: 0.7;
      color: var(--bg-color1);
    }
  }

  & .pc_word_break {
    display: block;
    line-height: 34px;
  }

  & .btn_next_circle {
    width: 34px;
    height: 34px;
    margin-left: 12px;
    cursor: pointer;
  }
`;
