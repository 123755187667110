import { forwardRef, useState } from "react";
import ReactSelect, { components } from "react-select";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import WarningMessage from "components/WarningMessage/WarningMessage";

// asstes
import DropdownSVG from "assets/images/pc/icon_dropdewn.svg";

const primaryStyles = {
  control: (provided, state) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "335px";
    const borderColor = state.hasValue ? "#707070" : "#dddddd";
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      width: state.selectProps.fullWidth ? "100%" : width,
      height: isMobileWidth ? "48px" : "65px",
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      border: `solid 1px ${
        state.selectProps.menuIsOpen ? "#707070" : borderColor
      } !important`,
      borderRadius: 0,
      boxShadow: "none",
    };
  },
  dropdownIndicator: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      marginRight: 20,
      padding: 0,
      width: 20,
      height: 8,
      background: `url(${DropdownSVG}) no-repeat center right/${
        isMobileWidth ? "13px" : "15px"
      }`,
      transform: `rotate(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
    };
  },
  menu: (provided, state) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "335px";
    return {
      ...provided,
      width: `${state.selectProps.fullWidth ? "100%" : width}`,
      backgroundColor: "#fff",
      marginTop: 4,
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid #707070",
    };
  },
  option: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      paddingLeft: 20,
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: isMobileWidth ? "14px" : "16px",
      textAlign: "left",
      color: state.isSelected ? "#000" : "#b2b2b2",
      background: state.isFocused ? "#fafafa" : "#fff",
      cursor: "pointer",
    };
  },
  placeholder: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      margin: 0,
      fontSize: isMobileWidth ? "14px" : "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 20,
  }),
  singleValue: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      marginLeft: 0,
      fontSize: isMobileWidth ? "14px" : "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  indicatorSeparator: (provided) => ({ ...provided, display: "none" }),
};

const secondaryStyles = {
  control: (provided, state) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "155px";
    const borderColor = state.hasValue ? "#707070" : "#dddddd";
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      width: state.selectProps.fullWidth ? "100%" : width,
      height: isMobileWidth ? "48px" : "65px",
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      border: `solid 1px ${
        state.selectProps.menuIsOpen ? "#707070" : borderColor
      } !important`,
      borderRadius: 0,
      boxShadow: "none",
    };
  },
  dropdownIndicator: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      marginRight: 20,
      padding: 0,
      width: 20,
      height: 8,
      background: `url(${DropdownSVG}) no-repeat center right/${
        isMobileWidth ? "13px" : "15px"
      }`,
      transform: `rotate(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
    };
  },
  menu: (provided, state) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "155px";
    return {
      ...provided,
      width: `${state.selectProps.fullWidth ? "100%" : width}`,
      backgroundColor: "#fff",
      marginTop: 4,
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid #707070",
    };
  },
  option: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      paddingLeft: 20,
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: isMobileWidth ? "14px" : "16px",
      textAlign: "left",
      color: state.isSelected ? "#000" : "#b2b2b2",
      background: state.isFocused ? "#fafafa" : "#fff",
      cursor: "pointer",
    };
  },
  placeholder: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      margin: 0,
      fontSize: isMobileWidth ? "13px" : "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  valueContainer: (provided) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      paddingLeft: isMobileWidth ? 10 : 20,
    };
  },
  singleValue: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      marginLeft: 0,
      fontSize: isMobileWidth ? "14px" : "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  indicatorSeparator: (provided) => ({ ...provided, display: "none" }),
};

const tertiaryStyles = {
  control: (provided, state) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "210px";
    const borderColor = state.hasValue ? "#707070" : "#dddddd";
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      width: state.selectProps.fullWidth ? "100%" : width,
      height: isMobileWidth ? "48px" : "65px",
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      border: `solid 1px ${
        state.selectProps.menuIsOpen ? "#707070" : borderColor
      } !important`,
      borderRadius: 0,
      boxShadow: "none",
    };
  },
  dropdownIndicator: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      marginRight: 20,
      padding: 0,
      width: 20,
      height: 8,
      background: `url(${DropdownSVG}) no-repeat center right/${
        isMobileWidth ? "13px" : "15px"
      }`,
      transform: `rotate(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
    };
  },
  menu: (provided, state) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "210px";
    return {
      ...provided,
      width: `${state.selectProps.fullWidth ? "100%" : width}`,
      backgroundColor: "#fff",
      marginTop: 4,
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid #707070",
    };
  },
  option: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      paddingLeft: 20,
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: isMobileWidth ? "14px" : "16px",
      textAlign: "left",
      color: state.isSelected ? "#000" : "#b2b2b2",
      background: state.isFocused ? "#fafafa" : "#fff",
      cursor: "pointer",
    };
  },
  placeholder: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      margin: 0,
      fontSize: isMobileWidth ? "14px" : "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 15,
  }),
  singleValue: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      marginLeft: 0,
      fontSize: isMobileWidth ? "14px" : "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  indicatorSeparator: (provided) => ({ ...provided, display: "none" }),
};

const quaternaryStyles = {
  control: (provided, state) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "87px";

    return {
      ...provided,
      width: state.selectProps.fullWidth ? "100%" : width,
      height: state.selectProps.h ? `${state.selectProps.h}px` : "30px",
      background: state.selectProps.bgColor
        ? state.selectProps.bgColor
        : "#fff",
      border: 0,
      borderRadius: 0,
      boxShadow: "none",
    };
  },
  dropdownIndicator: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      padding: 0,
      width: 20,
      height: 8,
      background: `url(${DropdownSVG}) no-repeat center/${
        isMobileWidth ? "13px" : "15px"
      }`,
      transform: `rotate(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
    };
  },
  menu: (provided, state) => {
    const width = state.selectProps.w ? `${state.selectProps.w}px` : "87px";
    return {
      ...provided,
      width: `${state.selectProps.fullWidth ? "100%" : width}`,
      backgroundColor: "#fff",
      marginTop: 4,
      boxShadow: "none",
      borderRadius: 0,
      border: "1px solid #707070",
    };
  },
  option: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      textAlign: "center",
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: isMobileWidth ? "14px" : "16px",
      color: state.isSelected ? "#000" : "#b2b2b2",
      background: state.isFocused ? "#fafafa" : "#fff",
      cursor: "pointer",
    };
  },
  placeholder: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      margin: 0,
      fontSize: isMobileWidth ? "14px" : "16px",
      textAlign: "left",
      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided, state) => {
    const isMobileWidth = useRecoilValue(isMobileWidthAtom);
    return {
      ...provided,
      marginLeft: 0,
      fontSize: isMobileWidth ? "14px" : "16px",

      color: state.selectProps.color ? state.selectProps.color : "#000",
    };
  },
  indicatorSeparator: (provided) => ({ ...provided, display: "none" }),
};

const Arrow = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div />
    </components.DropdownIndicator>
  );
};

/**
 * @file Select Components
 */
const Select = forwardRef((props, ref) => {
  const {
    options,
    defaultValue,
    isSearchable = false,
    variant,
    errors,
    ...rest
  } = props;

  const defaultOption =
    defaultValue && options
      ? [...options].map((x) => x.value).indexOf(defaultValue)
      : null;

  return (
    <>
      <div>
        <ReactSelect
          {...rest}
          ref={ref}
          className="component-select"
          defaultValue={options[defaultOption]}
          components={{
            DropdownIndicator: Arrow,
          }}
          options={options}
          styles={(() => {
            switch (variant) {
              case "primary":
                return primaryStyles;
              case "secondary":
                return secondaryStyles;
              case "tertiary":
                return tertiaryStyles;
              case "quaternary":
                return quaternaryStyles;
              default:
                return primaryStyles;
            }
          })()}
          isSearchable={isSearchable}
          errors={errors?.[props.name]}
        />
        {errors && <WarningMessage errors={errors} name={props.name} />}
      </div>
    </>
  );
});

export default Select;
