import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

const CoreValue = () => {
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  if (isMobileWidth) {
    return (
      <FloatMobileRoot>
        <img
          src={require("assets/images/pc/CoreValue.png")}
          className="corevalue"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        />
      </FloatMobileRoot>
    );
  } else {
    return (
      <FloatRoot>
        <img
          src={require("assets/images/pc/CoreValue.png")}
          className="corevalue"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        />
      </FloatRoot>
    );
  }
};

export default CoreValue;

const FloatRoot = styled.div`
  & .corevalue {
    position: absolute;
    margin: -35px 0 0 950px;
    z-index: 1;
  }
`;

const FloatMobileRoot = styled.div`
  & .corevalue {
    width: 300px;
    position: absolute;
    margin: -15px 0 0 -50px;
    z-index: 1;
  }
`;
