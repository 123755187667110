// 서브페이지 공통 layout props
export const PAGE_LAYOUT = {
  gretting: {
    mainTextEn: "Company Introduction",
    mainTextKo: "회사소개",
    subText: "대표인사",
    bgBlack: true,
  },
  companyinfo: {
    mainTextEn: "Company Introduction",
    mainTextKo: "회사소개",
    subText: "기업정보",
    bgBlack: true,
  },
  nurida: {
    mainTextEn: "Company Introduction",
    mainTextKo: "회사소개",
    subText: "NURIDA 소개영상",
    bgBlack: true,
  },
  service: {
    mainTextEn: "Provided Service",
    mainTextKo: "서비스",
    subText: "서비스",
    bgBlack: true,
  },
  business: {
    mainTextEn: "Business Inquiry",
    mainTextKo: "사업문의",
    subText: "제휴문의",
    bgBlack: false,
  },
  hiring: {
    mainTextEn: "Career",
    mainTextKo: "커리어",
    subText: "채용공고",
    bgBlack: false,
  },
  hiringregist: {
    mainTextEn: "Career",
    mainTextKo: "커리어",
    subText: "채용공고 등록",
    bgBlack: false,
  },
  hiringedit: {
    mainTextEn: "Career",
    mainTextKo: "커리어",
    subText: "채용공고 수정",
    bgBlack: false,
  },
  promotion: {
    mainTextEn: "Career",
    mainTextKo: "커리어",
    subText: "행사공지",
    bgBlack: false,
  },
  promotionregist: {
    mainTextEn: "Career",
    mainTextKo: "커리어",
    subText: "행사공지 등록",
    bgBlack: false,
  },
  promotionedit: {
    mainTextEn: "Career",
    mainTextKo: "커리어",
    subText: "행사공지 수정",
    bgBlack: false,
  },
  transfer: {
    mainTextEn: "Career",
    mainTextKo: "커리어",
    subText: "인력등록",
    bgBlack: false,
  },
  transferdetail: {
    mainTextEn: "Career",
    mainTextKo: "커리어",
    subText: "인력등록 상세",
    bgBlack: false,
  },
  contactinfo: {
    mainTextEn: "Contact Us",
    mainTextKo: "문의하기",
    subText: "담당자 연락처",
    bgBlack: true,
  },
  contactmap: {
    mainTextEn: "Contact Us",
    mainTextKo: "문의하기",
    subText: "오시는 길",
    bgBlack: true,
  },
  mypage: {
    mainTextEn: "My Page",
    mainTextKo: "마이페이지",
    subText: "마이페이지",
    bgBlack: false,
  },
  login: {
    mainTextEn: "Sign Up",
    mainTextKo: "회원가입",
    subText: "로그인",
    bgBlack: false,
  },
  signup: {
    mainTextEn: "Sign Up",
    mainTextKo: "회원가입",
    subText: "회원가입",
    bgBlack: false,
  },
  policy: {
    mainTextEn: "Terms of Service",
    mainTextKo: "약관안내",
    subText: "개인정보 처리방침",
    bgBlack: false,
  },
};

export const PAGE_LAYOUT_EN = {
  gretting: {
    mainTextEn: "Company Introduction",
    mainTextKo: "Company Profile",
    subText: "CEO Greetings",
    bgBlack: true,
  },
  companyinfo: {
    mainTextEn: "Company Introduction",
    mainTextKo: "Company Profile",
    subText: "About Us",
    bgBlack: true,
  },
  nurida: {
    mainTextEn: "Company Introduction",
    mainTextKo: "Company Profile",
    subText: "NURIDA 소개영상",
    bgBlack: true,
  },
  service: {
    mainTextEn: "Provided Service",
    mainTextKo: "Service",
    subText: "Our Main Service",
    bgBlack: true,
  },
  business: {
    mainTextEn: "Business Inquiry",
    mainTextKo: "Business Inquiry",
    subText: "B2B Inquiry",
    bgBlack: false,
  },
  hiring: {
    mainTextEn: "Career",
    mainTextKo: "Careers",
    subText: "Recruitment",
    bgBlack: false,
  },
  hiringregist: {
    mainTextEn: "Career",
    mainTextKo: "Careers",
    subText: "Register Recruitment",
    bgBlack: false,
  },
  hiringedit: {
    mainTextEn: "Career",
    mainTextKo: "Careers",
    subText: "Modify Recruitment",
    bgBlack: false,
  },
  promotion: {
    mainTextEn: "Career",
    mainTextKo: "Careers",
    subText: "Event",
    bgBlack: false,
  },
  promotionregist: {
    mainTextEn: "Career",
    mainTextKo: "Careers",
    subText: "Register Event",
    bgBlack: false,
  },
  promotionedit: {
    mainTextEn: "Career",
    mainTextKo: "Careers",
    subText: "Modify Event",
    bgBlack: false,
  },
  transfer: {
    mainTextEn: "Career",
    mainTextKo: "Careers",
    subText: "Part-timer Application",
    bgBlack: false,
  },
  transferdetail: {
    mainTextEn: "Career",
    mainTextKo: "Careers",
    subText: "Part-timer Application Details",
    bgBlack: false,
  },
  contactinfo: {
    mainTextEn: "Contact Us",
    mainTextKo: "Contact Us",
    subText: "Contact Info",
    bgBlack: true,
  },
  contactmap: {
    mainTextEn: "Contact Us",
    mainTextKo: "Contact Us",
    subText: "Location",
    bgBlack: true,
  },
  mypage: {
    mainTextEn: "My Page",
    mainTextKo: "My Page",
    subText: "My Page",
    bgBlack: false,
  },
  login: {
    mainTextEn: "Sign Up",
    mainTextKo: "Sign-up",
    subText: "Login",
    bgBlack: false,
  },
  signup: {
    mainTextEn: "Sign Up",
    mainTextKo: "Sign-up",
    subText: "Sign-up",
    bgBlack: false,
  },
  policy: {
    mainTextEn: "Terms of Service",
    mainTextKo: "Terms of Service",
    subText: "Privacy policy",
    bgBlack: false,
  },
};
