import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import Input from "components/Input/Input";
import Button from "components/Button/Button";

// hooks
import useAlert from "hooks/useAlert";

// api
import userApis from "api/user";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner01 from "assets/images/pc/img_banner01.png";

const defaultValues = {
  userEmail: "",
};

/**
 * @file 비밀번호 찾기 컴포넌트
 * @author TW.Lee
 */
const SearchPw = () => {
  const { t } = useTranslation();
  const { noti } = useAlert();
  const navigate = useNavigate();
  const [successPw, setSuccessPw] = useState(false);
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const { handleSubmit, setFocus, getValues, control, watch } = useForm({
    defaultValues,
    mode: "onSubmit",
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  const findpw = useMutation(
    () => {
      return userApis.findPw({ userEmail: values.userEmail });
    },
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          setSuccessPw(true);
        }
      },
      onError: async (err) => {
        if (err.response.data.message === "user not found") {
          if (t("lang") === "EN") {
            noti("가입 정보를 정확하게 입력해주세요.");
          } else {
            noti(
              "Please enter your registration information correctly.",
              "Confirm"
            );
          }
        }
      },
    }
  );

  if (isMobileWidth) {
    return (
      <Layout subFixed="true">
        <SubLayout
          banner={Banner01}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.login : PAGE_LAYOUT_EN.login
          }
        >
          <SearchInfoMobileRoot>
            <ul>
              <li
                className="switch-style-off"
                onClick={() => navigate("/login/searchId")}
              >
                {t("findid")}
              </li>
              <li
                className="switch-style-on"
                onClick={() => navigate("/login/searchPw")}
              >
                {t("findpw")}
              </li>
            </ul>
            <SearchPwRoot>
              {successPw ? (
                <SearchPwMobileInner02>
                  <div className="pw-search-success">
                    {t("lang") === "EN"
                      ? "이메일 주소로 임시 비밀번호가 발송되었습니다."
                      : "A temporary password has been sent to your E-mail."}
                  </div>
                  <Button
                    variant="primaryHover"
                    fullWidth
                    onClick={() => navigate("/login")}
                  >
                    {t("login")}
                  </Button>
                </SearchPwMobileInner02>
              ) : (
                <SearchPwMobileInner01>
                  <h4>
                    {t("lang") !== "EN" && "Please Verify Your "}
                    <span>{t("lang") === "EN" ? "이메일 주소" : "E-mail"}</span>
                    {t("lang") === "EN" ? "가 필요합니다." : " Address."}
                  </h4>
                  <div className="pw-search-form">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();

                        handleSubmit(
                          () => {
                            findpw.mutate();
                          },
                          (err) => {
                            const keys = ["userEmail"];

                            for (const key of keys) {
                              if (err[key]) {
                                if (t("lang") === "EN") {
                                  noti(err[key].message, "확인");
                                } else {
                                  noti(err[key].message, "Confirm");
                                }
                                setFocus(key);
                                break;
                              }
                            }
                          }
                        )();
                      }}
                    >
                      <div className="input-box">
                        <Controller
                          className="inp-item"
                          control={control}
                          name="userEmail"
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={
                                t("lang") === "EN"
                                  ? "이메일 주소 (아이디)"
                                  : "E-mail (ID)"
                              }
                              fullWidth
                            />
                          )}
                        />
                      </div>
                      <div className="submit-button">
                        <Button type="submit" variant="primaryHover" fullWidth>
                          {t("findpw")}
                        </Button>
                      </div>
                    </form>
                  </div>
                </SearchPwMobileInner01>
              )}
            </SearchPwRoot>
          </SearchInfoMobileRoot>
        </SubLayout>
      </Layout>
    );
  } else {
    return (
      <Layout subFixed="true">
        <SubLayout
          banner={Banner01}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.login : PAGE_LAYOUT_EN.login
          }
        >
          <SearchInfoRoot>
            <ul>
              <li
                className="switch-style-off"
                onClick={() => navigate("/login/searchId")}
              >
                {t("findid")}
              </li>
              <li
                className="switch-style-on"
                onClick={() => navigate("/login/searchPw")}
              >
                {t("findpw")}
              </li>
            </ul>
            <SearchPwRoot>
              {successPw ? (
                <SearchPwInner02>
                  <div className="pw-search-success">
                    {t("lang") === "EN"
                      ? "이메일 주소로 임시 비밀번호가 발송되었습니다."
                      : "A temporary password has been sent to your E-mail."}
                  </div>
                  <Button
                    variant="primaryHover"
                    fullWidth
                    onClick={() => navigate("/login")}
                  >
                    {t("login")}
                  </Button>
                </SearchPwInner02>
              ) : (
                <SearchPwInner01>
                  <h4>
                    {t("lang") !== "EN" && "Please Verify Your "}
                    <span>{t("lang") === "EN" ? "이메일 주소" : "E-mail"}</span>
                    {t("lang") === "EN" ? "가 필요합니다." : " Address."}
                  </h4>
                  <div className="pw-search-form">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();

                        handleSubmit(
                          () => {
                            findpw.mutate();
                          },
                          (err) => {}
                        )();
                      }}
                    >
                      <div className="input-box">
                        <Controller
                          className="inp-item"
                          control={control}
                          name="userEmail"
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={
                                t("lang") === "EN"
                                  ? "이메일 주소 (아이디)"
                                  : "E-mail (ID)"
                              }
                              fullWidth
                            />
                          )}
                        />
                      </div>
                      <div className="submit-button">
                        <Button type="submit" variant="primaryHover" fullWidth>
                          {t("findpw")}
                        </Button>
                      </div>
                    </form>
                  </div>
                </SearchPwInner01>
              )}
            </SearchPwRoot>
          </SearchInfoRoot>
        </SubLayout>
      </Layout>
    );
  }
};

export default SearchPw;

const SearchInfoRoot = styled.div`
  width: 500px;
  margin: 0 auto;
  & > ul {
    padding-top: 100px;
    display: flex;
    justify-content: space-between;

    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 250px;
      height: 64px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    }

    & .switch-style-on {
      color: #c09260;
      border-bottom: solid 1px #c09260;
    }
    & .switch-style-off {
      color: #999;
      border-bottom: solid 1px #ddd;
    }
  }
`;

const SearchPwRoot = styled.div`
  text-align: center;
  padding-bottom: 100px;
`;

const SearchPwInner01 = styled.div`
  & h4 {
    margin: 40px 0;
    font-size: 18px;
    font-weight: normal;
    & span {
      font-weight: bold;
    }
  }
  & .input-box {
    margin-bottom: 40px;
  }
`;

const SearchPwInner02 = styled.div`
  & .pw-search-success {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 126px;
    background-color: #f9f9f9;
    margin: 40px 0;
  }
`;

const SearchInfoMobileRoot = styled.div`
  & > ul {
    position: relative;
    width: 100vw;
    left: -30px;
    display: flex;
    justify-content: space-between;

    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 51px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }

    & .switch-style-on {
      color: #c09260;
      border-bottom: solid 1px #c09260;
    }
    & .switch-style-off {
      color: #999;
      border-bottom: solid 1px #ddd;
    }
  }
`;

const SearchPwMobileInner01 = styled.div`
  & h4 {
    margin: 30px 0;
    font-size: 18px;
    font-weight: normal;
    & span {
      font-weight: bold;
    }
  }
  & .input-box {
    margin-bottom: 30px;
  }
`;

const SearchPwMobileInner02 = styled.div`
  & .pw-search-success {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 126px;
    background-color: #f9f9f9;
    margin: 40px 0;
  }
`;
