import React, { useRef } from "react";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import KakaoMap from "components/Map/KakaoMap";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner06 from "assets/images/pc/img_banner06.png";
import IconCopySVG from "assets/images/pc/icon_copy.svg";

/**
 * @file Contact Us - 오시는 길 페이지
 * @brief 지도 안내 및 차량/지하철 이용 방법 안내
 * @author TW.Lee
 * @param handleClipboardCopy 주소 복사 function
 */
const ContactMap = () => {
  const { t } = useTranslation();
  const clipRef = useRef();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const handleClipboardCopy = async () => {
    try {
      await navigator.clipboard.writeText(clipRef.current.innerText);
      if (t("lang") === "EN") {
        toast("주소가 복사되었습니다.");
      } else {
        toast("Address Copied.");
      }
    } catch (error) {
      if (t("lang") === "EN") {
        toast("주소가 복사되지 않았습니다.");
      } else {
        toast("Address not Copied.");
      }
    }
  };

  return (
    <Layout subFixed="true">
      <SubLayout
        banner={Banner06}
        pageText={
          t("lang") === "EN"
            ? PAGE_LAYOUT.contactmap
            : PAGE_LAYOUT_EN.contactmap
        }
      >
        <ContactMapInner01>
          <KakaoMap />
        </ContactMapInner01>
        {isMobileWidth ? (
          <ContactMapMobileInner02>
            {t("lang") === "EN" ? (
              <h3 ref={clipRef} data-aos="fade-up" data-aos-duration="1500">
                서울 중구 을지로 30
                <br />
                롯데호텔 서울 본관 6층{" "}
                <img
                  src={IconCopySVG}
                  alt="주소 복사 버튼"
                  onClick={() => handleClipboardCopy()}
                />
              </h3>
            ) : (
              <h3 ref={clipRef} data-aos="fade-up" data-aos-duration="1500">
                6F, Lotte Hotel Seoul Main Tower,
                <br />
                30 Eulji-ro, Jung-gu, Seoul{" "}
                <img
                  src={IconCopySVG}
                  alt="주소 복사 버튼"
                  onClick={() => handleClipboardCopy()}
                />
              </h3>
            )}

            <div
              className="car_use"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <p className="use_txt">{t("contactinfo1")}</p>
              <p className="use_txt2">{t("contactinfo2")}</p>
            </div>
            <div
              className="subway_use"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <p className="use_txt">{t("contactinfo3")}</p>
              <p className="use_txt2">{t("contactinfo4")}</p>
              <ul>
                {t("lang") === "EN" ? (
                  <li data-aos="fade-up" data-aos-duration="1500">
                    ① <span>{t("contactinfo5")}</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{t("contactinfo6")}
                  </li>
                ) : (
                  <li data-aos="fade-up" data-aos-duration="1500">
                    ① {t("contactinfo6")}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span>{t("contactinfo5")}</span>
                  </li>
                )}
                {t("lang") === "EN" ? (
                  <li data-aos="fade-up" data-aos-duration="1500">
                    ② <span>{t("contactinfo7")}</span>
                    {t("contactinfo8")}
                  </li>
                ) : (
                  <li data-aos="fade-up" data-aos-duration="1500">
                    ② {t("contactinfo8")} <span>{t("contactinfo7")}</span>
                  </li>
                )}
                {t("lang") === "EN" ? (
                  <li data-aos="fade-up" data-aos-duration="1500">
                    ③ {t("contactinfo9")}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span>{t("contactinfo10")}</span>
                    {t("contactinfo11")}
                  </li>
                ) : (
                  <li data-aos="fade-up" data-aos-duration="1500">
                    ③ {t("contactinfo11")}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span>{t("contactinfo10")}</span>
                    {t("contactinfo9")}
                  </li>
                )}
              </ul>
              <p className="ps_txt" data-aos="fade-up" data-aos-duration="1500">
                {t("contactinfo12")}
              </p>
              <p className="ps_txt" data-aos="fade-up" data-aos-duration="1500">
                {t("contactinfo14")}
                <br />
                &nbsp;&nbsp;&nbsp;{t("contactinfo15")}
              </p>
            </div>
          </ContactMapMobileInner02>
        ) : (
          <ContactMapInner02>
            {t("lang") === "EN" ? (
              <h3 ref={clipRef} data-aos="fade-up" data-aos-duration="1500">
                서울 중구 을지로 30 롯데호텔 서울 본관 6층{" "}
                <img
                  src={IconCopySVG}
                  alt="주소 복사 버튼"
                  onClick={() => handleClipboardCopy()}
                />
              </h3>
            ) : (
              <h3 ref={clipRef} data-aos="fade-up" data-aos-duration="1500">
                6F, Lotte Hotel Seoul Main Tower, 30 Eulji-ro, Jung-gu, Seoul{" "}
                <img
                  src={IconCopySVG}
                  alt="주소 복사 버튼"
                  onClick={() => handleClipboardCopy()}
                />
              </h3>
            )}

            <div
              className="car_use"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <p className="use_txt">{t("contactinfo1")}</p>
              <p className="use_txt2">{t("contactinfo2")}</p>
            </div>
            <div
              className="subway_use"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <p className="use_txt">{t("contactinfo3")}</p>
              <p className="use_txt2">{t("contactinfo4")}</p>
              <ul>
                {t("lang") === "EN" ? (
                  <li data-aos="fade-up" data-aos-duration="1500">
                    ① <span>{t("contactinfo5")}</span>
                    {t("contactinfo6")}
                  </li>
                ) : (
                  <li data-aos="fade-up" data-aos-duration="1500">
                    ① {t("contactinfo6")} <span>{t("contactinfo5")}</span>
                  </li>
                )}
                {t("lang") === "EN" ? (
                  <li data-aos="fade-up" data-aos-duration="1500">
                    ② <span>{t("contactinfo7")}</span>
                    {t("contactinfo8")}
                  </li>
                ) : (
                  <li data-aos="fade-up" data-aos-duration="1500">
                    ② {t("contactinfo8")} <span>{t("contactinfo7")}</span>
                  </li>
                )}
                {t("lang") === "EN" ? (
                  <li data-aos="fade-up" data-aos-duration="1500">
                    ③ {t("contactinfo9")}
                    <span>{t("contactinfo10")}</span>
                    {t("contactinfo11")}
                  </li>
                ) : (
                  <li data-aos="fade-up" data-aos-duration="1500">
                    ③ {t("contactinfo11")}
                    <span>{t("contactinfo10")}</span>
                    {t("contactinfo9")}
                  </li>
                )}
              </ul>
              <p className="ps_txt" data-aos="fade-up" data-aos-duration="1500">
                {t("contactinfo12")}
              </p>
              <p className="ps_txt" data-aos="fade-up" data-aos-duration="1500">
                {t("contactinfo13")}
              </p>
            </div>
          </ContactMapInner02>
        )}
      </SubLayout>
    </Layout>
  );
};

export default ContactMap;

const ContactMapInner01 = styled.div`
  & .map_img {
    margin: 60px 0;
  }
  & .map_img img {
    width: 100%;
  }
  & .mo_map_img {
    margin: 40px 0;
  }
  & .mo_map_img img {
    width: 100%;
  }
`;

const ContactMapInner02 = styled.div`
  & h3 {
    font-size: 36px;
    line-height: 30px;
    font-weight: bold;
    color: #ffc17d;
    display: flex;
    align-items: center;
  }

  & h3 img {
    cursor: pointer;
    margin-left: 8px;
  }

  & .car_use {
    margin: 60px 0 50px 0;
  }

  & .use_txt {
    font-size: 26px;
    line-height: 30px;
    font-weight: bold;
    color: #aca298;
  }

  & .use_txt2 {
    font-size: 22px;
    line-height: 30px;
    color: #fff;
    margin-top: 12px;
  }

  & .subway_use ul {
    width: 1200px;
    height: 190px;
    margin: 20px 0;
    padding: 30px 0px 40px 40px;
    background-color: #1d1d1d;
    font-size: 22px;
    color: #fff;
    line-height: 42px;
  }

  & .subway_use ul li {
    margin: 10px 0;
  }

  & .subway_use ul span {
    color: #ffdab2;
  }

  & .ps_txt {
    font-size: 20px;
    line-height: 36px;
    color: #fff;
  }
`;

const ContactMapMobileInner02 = styled.div`
  & h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    color: #ffc17d;
    display: flex;
    align-items: flex-end;
  }

  & h3 img {
    width: 20px;
    cursor: pointer;
    margin-left: 8px;
    margin-bottom: 5px;
  }

  & .car_use {
    margin: 20px 0;
  }

  & .use_txt {
    font-size: 16px;
    font-weight: bold;
    color: #aca298;
  }

  & .use_txt2 {
    font-size: 16px;

    color: #fff;
    margin-top: 8px;
  }

  & .subway_use ul {
    height: 190px;
    margin: 20px 0;
    padding: 20px 20px 20px 20px;
    background-color: #1d1d1d;
    font-size: 14px;
    color: #fff;
    line-height: 42px;
  }

  & .subway_use ul li {
    margin: 10px 0;
  }

  & .subway_use ul span {
    color: #ffdab2;
  }

  & .ps_txt {
    font-size: 14px;
    line-height: 1.57;
    color: #fff;
  }
`;
