import axios from "axios";
import queryString from "query-string";

export default () => {
  const { REACT_APP_API } = process.env;
  axios.defaults.baseURL = REACT_APP_API;
  // params를 직렬화하는 선택적인 함수
  axios.defaults.paramsSerializer = (params) => {
    const queryParams = queryString.stringify(params, {
      skipEmptyString: true,
    });
    return queryParams;
  };

  // 요청 인터셉터 추가하기
  axios.interceptors.request.use(
    async (req) => {
      const targetStorage = localStorage.getItem("accessToken")
        ? localStorage
        : sessionStorage;
      const accessToken = targetStorage.getItem("accessToken");

      if (accessToken) {
        req.headers.authorization = `Bearer ${accessToken}`;
      }

      return req;
    },
    async (err) => {
      return Promise.reject(err);
    }
  );

  // 응답 인터셉터 추가하기
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      if (err.response.status === 401) {
        const originalRequest = err.config;
      }
      return Promise.reject(err);
    }
  );
};
