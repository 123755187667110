import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// assets
import HomeImgWSVG from "assets/images/pc/icon_home_w.svg";
import HomeImgSVG from "assets/images/pc/icon_home.svg";

/**
 * @file SubLayout Layout (서브페이지 공통 부분)
 */
const SubLayout = ({ children, banner, pageText }) => {
  const { t } = useTranslation();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const root = SubLayoutStyled(t("lang"));

  if (isMobileWidth) {
    return (
      <SubLayoutMobileRoot
        css={css({
          background: `url(${banner}) no-repeat center top/100% 300px`,
        })}
      >
        <div>
          <div className="visual">
            <h2>{pageText.mainTextEn}</h2>
            <p>{pageText.mainTextKo}</p>
          </div>
          <div
            className={
              pageText.bgBlack
                ? "float_wraps float_wrap_black"
                : "float_wraps float_wrap_white"
            }
          >
            {pageText.subText === "로그인" || pageText.subText === "Login" ? (
              <div className={pageText.bgBlack ? "black_bg" : "white_bg"}>
                {children}
              </div>
            ) : (
              <div className={pageText.bgBlack ? "black_bg" : "white_bg"}>
                <div className="sub_title">
                  <h3 className={pageText.bgBlack ? "white_txt" : "black_txt"}>
                    {pageText.subText}
                  </h3>
                  <p className={pageText.bgBlack ? "white_txt2" : "black_txt"}>
                    {pageText.bgBlack ? (
                      <img src={HomeImgWSVG} alt="home icon" />
                    ) : (
                      <img src={HomeImgSVG} alt="home icon" />
                    )}
                    HOME · {pageText.mainTextKo} ··{" "}
                    <span>{pageText.subText}</span>
                  </p>
                </div>
                {children}
              </div>
            )}
          </div>
        </div>
      </SubLayoutMobileRoot>
    );
  } else {
    return (
      <SubLayoutRoot
        css={css({
          background: `url(${banner}) no-repeat center top/100% 700px`,
        })}
      >
        <div>
          <div className="visual">
            <h2>{pageText.mainTextEn}</h2>
            <p>{pageText.mainTextKo}</p>
          </div>
          <div
            className={
              pageText.bgBlack
                ? "float_wrap float_wrap_black"
                : "float_wrap float_wrap_white"
            }
          >
            {pageText.subText === "로그인" || pageText.subText === "Login" ? (
              <div className={pageText.bgBlack ? "black_bg" : "white_bg"}>
                {children}
              </div>
            ) : (
              <div className={pageText.bgBlack ? "black_bg" : "white_bg"}>
                <div className="sub_title" css={root}>
                  <h3 className={pageText.bgBlack ? "white_txt" : "black_txt"}>
                    {pageText.subText}
                  </h3>
                  <p className={pageText.bgBlack ? "white_txt2" : "black_txt"}>
                    {pageText.bgBlack ? (
                      <img src={HomeImgWSVG} alt="home icon" />
                    ) : (
                      <img src={HomeImgSVG} alt="home icon" />
                    )}
                    HOME · {pageText.mainTextKo} ··{" "}
                    <span>{pageText.subText}</span>
                  </p>
                </div>
                {children}
              </div>
            )}
          </div>
        </div>
      </SubLayoutRoot>
    );
  }
};

export default SubLayout;

const SubLayoutStyled = (lang) => {
  return css`
    h3 {
      font-family: Arita-buri-SemiBold;
      font-size: ${lang === "EN" ? "45px" : "35px"};
      font-weight: 600;
    }
  `;
};

const SubLayoutRoot = styled.div`
  & > div {
    background: linear-gradient(to bottom, rgba(17, 17, 17, 0.2) 21%, #060606);
    background-size: 100% 700px;
  }

  & .visual {
    height: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--primary-color1);
  }

  & .visual h2 {
    font-family: Arita-buri-SemiBold;
    font-size: 50px;
    font-weight: 600;
  }

  & .visual p {
    font-size: 20px;
  }

  & > div > .float_wrap {
    margin-top: 150px;
  }

  & > div > .float_wrap > div {
    width: 1500px;
    position: relative;
    top: -150px;
    margin: 0 auto;
    padding: 0 150px;
  }

  & .float_wrap_black {
    background-image: linear-gradient(to bottom, #111, #060606);
  }

  & .float_wrap_white {
    background-color: #f8f8f8;
  }

  & .black_bg {
    background-color: var(--bg-color3);
    background-image: linear-gradient(
      to bottom,
      #111 31%,
      rgba(17, 17, 17, 0.6)
    );
  }

  & .white_bg {
    background-color: var(--bg-color1);
  }

  & .black_txt {
    color: var(--secondary-color2);
  }

  & .white_txt {
    color: var(--secondary-color3);
  }

  & .white_txt2 {
    color: var(--Acccccc);
  }

  & .sub_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 100px;
  }

  & .sub_title p {
    font-size: 14px;
  }

  & .sub_title p img {
    margin-right: 4px;
  }

  & .sub_title p span {
    color: var(--secondary-color1);
  }
`;

const SubLayoutMobileRoot = styled.div`
  & > div {
    background: linear-gradient(to bottom, rgba(17, 17, 17, 0.2) 21%, #060606);
    background-size: 100% 320px;
  }

  & .visual {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--primary-color1);
  }

  & .visual h2 {
    font-family: Arita-buri-SemiBold;
    font-size: 26px;
    font-weight: 600;
  }

  & .visual p {
    font-size: 14px;
  }

  & > div > .float_wraps {
    margin-top: 150px;
  }

  & > div > .float_wraps > div {
    position: relative;
    top: -150px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
  }

  & .float_wrap_black {
    background-image: linear-gradient(to bottom, #111, #060606);
  }

  & .float_wrap_white {
    background-color: #f8f8f8;
  }

  & .black_bg {
    background-color: var(--bg-color3);
    background-image: linear-gradient(
      to bottom,
      #111 31%,
      rgba(17, 17, 17, 0.6)
    );
  }

  & .white_bg {
    background-color: var(--bg-color1);
    margin-bottom: -150px;
  }

  & .black_txt {
    color: var(--secondary-color2);
  }

  & .white_txt {
    color: var(--secondary-color3);
  }

  & .white_txt2 {
    color: var(--Acccccc);
  }

  & .sub_title {
    padding-top: 50px;
  }

  & .sub_title h3 {
    font-family: Arita-buri-SemiBold;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  & .sub_title p {
    font-size: 13px;
  }

  & .sub_title p img {
    margin-right: 4px;
  }

  & .sub_title p span {
    color: var(--secondary-color1);
  }
`;
