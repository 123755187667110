/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Label from "components/Label/Label";

/**
 * @file LabelWithInput
 * @brief Input 라벨 붙이기
 */
const LabelWithInput = (props) => {
  const { label, children, isHightlight } = props;

  return (
    <div>
      <div>
        <Label isHightlight={isHightlight}>{label}</Label>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default LabelWithInput;
