import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "react-dayjs-picker";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import FileInput from "components/FileInput/FileInput";
import Row from "containers/LabelWithInput/LabelWithInput";

// hooks
import useAlert from "hooks/useAlert";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";
import hiringValidationSchema from "utils/validation/hiringValidationSchema";
import hiringValidationSchemaEn from "utils/validation/hiringValidationSchemaEn";

// assets
import Banner05 from "assets/images/pc/img_banner05.png";
import IconCalSVG from "assets/images/pc/icon_cal.svg";

import "react-dayjs-picker/dist/index.css";

const defaultValues = {
  HiringTitle: "",
  HiringDeadline: "",
  HiringImage: "",
  HiringContent: "",
};

/**
 * @file 커리어 - 채용공고 등록 페이지
 * @brief 채용공고 등록 Form Validation
 * @author TW.Lee
 */
const HiringRegist = () => {
  const { noti } = useAlert();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [submitState, setSubmitState] = useState("schemako");
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const UI = {
    schemako: hiringValidationSchema,
    schemaen: hiringValidationSchemaEn,
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    shouldFocusError: true,
    resolver: yupResolver(UI[submitState]),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (t("lang") === "EN") {
      setSubmitState("schemako");
    } else {
      setSubmitState("schemaen");
    }
  }, [t("lang")]);

  useEffect(() => {
    if (values.HiringImage[0]?.size > 1024 * 1024 * 5) {
      // 용량 초과시 경고후 해당 파일의 용량도 보여줌
      if (t("lang") === "EN") {
        noti("업로드 할 수 있는 파일 형식과 최대 용량을 확인해주세요.");
      } else {
        noti(
          "Please check the file format and maximum capacity that can be uploaded.",
          "Confirm"
        );
      }
      setValue("HiringImage", "");
    }

    const reg = /jpg|jpeg|png/i;
    if (values.HiringImage[0] !== undefined) {
      if (
        reg.test(
          values.HiringImage[0]?.name.substring(
            values.HiringImage[0]?.name.lastIndexOf(".") + 1
          )
        ) === false
      ) {
        if (t("lang") === "EN") {
          noti("업로드 할 수 있는 파일 형식과 최대 용량을 확인해주세요.");
        } else {
          noti(
            "Please check the file format and maximum capacity that can be uploaded.",
            "Confirm"
          );
        }
        setValue("HiringImage", "");
      }
    }
  }, [values.HiringImage]);

  const imageFormData = new FormData();
  imageFormData.append("file", values.HiringImage[0]);

  const addPost = async (imageId) => {
    await axios({
      method: "post",
      url: "/job_posts",
      data: {
        state: "RECRUITING",
        title: values.HiringTitle,
        content: values.HiringContent,
        image: imageId,
        end_date: values.HiringDeadline,
      },
    }).then((res) => {
      if (res.status === 200) {
        if (t("lang") === "EN") {
          noti("등록되었습니다.");
        } else {
          noti("Registered.", "Confirm");
        }
        navigate("/hiring", { replace: true });
      }
    });
  };

  const addFile = async () => {
    await axios({
      method: "post",
      url: "/files",
      data: imageFormData,
    }).then((res) => {
      if (res.status === 200) {
        addPost(res.data.result.id);
      }
    });
  };

  return (
    <Layout subFixed="true">
      <WhiteRoot>
        <SubLayout
          banner={Banner05}
          pageText={
            t("lang") === "EN"
              ? PAGE_LAYOUT.hiringregist
              : PAGE_LAYOUT_EN.hiringregist
          }
        >
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();

              handleSubmit(
                () => {
                  if (values.HiringImage[0]) {
                    addFile();
                  } else {
                    addPost();
                  }
                },
                (err) => {
                  const keys = [
                    "HiringTitle",
                    "HiringDeadline",
                    "HiringContent",
                  ];

                  for (const key of keys) {
                    if (err[key]) {
                      if (t("lang") === "EN") {
                        noti(err[key].message, "확인");
                      } else {
                        noti(err[key].message, "Confirm");
                      }
                      break;
                    }
                  }
                }
              )();
            }}
          >
            <HiringRegistInner>
              <div className={isMobileWidth ? "mo_hiring-box" : "hiring-box"}>
                <Row label={t("title")}>
                  <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
                    <Controller
                      control={control}
                      name="HiringTitle"
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="inp-item"
                          type="text"
                          placeholder={t("hiringregisttext1")}
                          fullWidth
                          maxLength="50"
                        />
                      )}
                    />
                  </div>
                </Row>
                <Row label={t("deadline")}>
                  <div
                    className={isMobileWidth ? "mo-date-image" : "date-image"}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <img src={IconCalSVG} alt="캘린더" />
                  </div>
                  <div
                    className={
                      isMobileWidth
                        ? "mo_input-box mo-date-input"
                        : "input-box date-input"
                    }
                  >
                    <Controller
                      control={control}
                      name="HiringDeadline"
                      render={({ field }) => (
                        <DatePicker
                          register={register("HiringDeadline")}
                          isOpen={open}
                          setIsOpen={setOpen}
                          popoverPositions={["bottom"]}
                          onSelect={(date) => {
                            field.onChange(dayjs(date).format("YYYY-MM-DD"));
                          }}
                          value={field.value}
                          closeOnSelect={true}
                          placeholder={t("hiringregisttext2")}
                          onChange={() => {}}
                          errors={errors}
                        />
                      )}
                    />
                  </div>
                </Row>
                <Row label={t("image")}>
                  <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
                    <div className="attachment">
                      <Controller
                        control={control}
                        name="HiringImage"
                        render={({ field }) => (
                          <FileInput
                            {...field}
                            accept=".jpg, .png"
                            className="inp-item"
                          >
                            {t("hiringregisttext3")}
                          </FileInput>
                        )}
                      />
                    </div>
                  </div>
                </Row>
                <Row label={t("hiringcontent")}>
                  <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
                    <Controller
                      control={control}
                      name="HiringContent"
                      render={({ field }) => (
                        <Input
                          {...field}
                          multiline
                          useMaxLengthIndicator
                          maxLength={1000}
                          className="inp-item"
                          type="text"
                          placeholder={t("hiringregisttext4")}
                          doubleheight={isMobileWidth ? "" : "true"}
                          fullWidth
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                </Row>
              </div>
            </HiringRegistInner>
            <div className="submit-button">
              <Button type="submit" variant="primary" preset="1">
                {t("regist")}
              </Button>
            </div>
          </form>
        </SubLayout>
      </WhiteRoot>
    </Layout>
  );
};

export default HiringRegist;

const WhiteRoot = styled.div`
  & > div > div > .float_wrap > div {
    padding: 0 410px;
  }

  & .submit-button {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
  }
`;

const HiringRegistInner = styled.div`
  & .hiring-box {
    margin: 60px 0;
    padding: 40px 0;
    border-top: 1px solid #222;
    border-bottom: 1px solid #ddd;
  }
  & .mo_hiring-box {
    margin: 40px 0;
    padding: 20px 0;
    border-top: 1px solid #222;
    border-bottom: 1px solid #ddd;
  }

  & .input-box {
    margin: 16px 0 30px;
  }

  & .mo_input-box {
    margin: 10px 0 20px;
  }

  & .date-image {
    position: absolute;
    margin-top: 8px;
    right: 31%;
    transform: translateX(96%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 49px;
    height: 49px;
    background-color: #444;
    cursor: pointer;
  }

  & .date-input {
    input::placeholder {
      color: #b2b2b2;
    }
    input[name="rdp-date-picker"] {
      width: 100%;
      height: 65px;
      border: solid 1px #ddd;
      background-color: #fff;
      line-height: 1.29;
      font-size: 16px;
      color: #000;
      padding-left: 20px;
    }
    .react-tiny-popover-container {
      height: 100px;
      background-color: red;
    }
  }

  & .mo-date-image {
    position: absolute;
    margin-top: 4px;
    right: 63px;
    transform: translateX(96%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #444;
    cursor: pointer;
  }

  & .mo-date-input {
    input::placeholder {
      color: #b2b2b2;
      font-size: 14px;
    }
    input[name="rdp-date-picker"] {
      width: 100%;
      height: 48px;
      border: solid 1px #ddd;
      background-color: #fff;
      line-height: 1.29;
      font-size: 14px;
      color: #000;
      padding-left: 20px;
    }
    .react-tiny-popover-container {
      height: 100px;
      background-color: red;
    }
  }
`;
