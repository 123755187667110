import React, { useState, useCallback, useEffect, useRef } from "react";
import axios from "axios";
import dayjs from "dayjs";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import Button from "components/Button/Button";
import Radio from "components/Radio/Radio";
import Textbox from "components/Textbox/Textbox";
import FileInput from "components/FileInput/FileInput";
import Checkbox from "components/Checkbox/Checkbox";
import Row from "containers/LabelWithInput/LabelWithInput";
import Postcode from "components/Postcode/DaumPostcode";

// hooks
import useAlert from "hooks/useAlert";
import useSave from "hooks/useSave";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// utils
import transferValidationSchema from "utils/validation/transferValidationSchema";
import transferValidationSchemaEn from "utils/validation/transferValidationSchemaEn";
import transferSaveValidationSchema from "utils/validation/transferSaveValidationSchema";
import {
  PREFIX_NUMBER_OPTIONS,
  DEPARTMENT_OPTIONS,
  CARD_COMPANY_OPTIONS,
  EDUCATION_OPTIONS,
  GRADUATE_OPTIONS,
  MILITARY_OPTIONS,
  ELIGIBILTY_OPTIONS,
  DISABLED_OPTIONS,
  EMPLOY_OPTIONS,
  PART1_OPTIONS,
  PART2_OPTIONS,
  PART3_OPTIONS,
  PART4_OPTIONS,
  PART5_OPTIONS,
  DEPARTMENT_OPTIONS_EN,
  CARD_COMPANY_OPTIONS_EN,
  EDUCATION_OPTIONS_EN,
  GRADUATE_OPTIONS_EN,
  MILITARY_OPTIONS_EN,
  ELIGIBILTY_OPTIONS_EN,
  DISABLED_OPTIONS_EN,
  EMPLOY_OPTIONS_EN,
  PART1_OPTIONS_EN,
  PART2_OPTIONS_EN,
  PART3_OPTIONS_EN,
  PART4_OPTIONS_EN,
  PART5_OPTIONS_EN,
} from "utils/constants";
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner05 from "assets/images/pc/img_banner05.png";
import IconPlusSVG from "assets/images/pc/icon_plus.svg";
import IconCalSVG from "assets/images/pc/icon_cal.svg";

// etc
import TransferListPopup from "./components/TransferListPopup";
import TransferListMobilePopup from "./components/TransferListMobilePopup";

const defaultValues = {
  TransferName: "",
  TransferGender: "",
  TransferBirth01: "",
  TransferBirth02: "",
  TransferBirth03: "",
  TransferPhone1: "",
  TransferPhone2: "",
  TransferPhone3: "",
  TransferEmail: "",
  TransferFile: "",
  Address1: "",
  Address2: "",
  Address3: "",
  TransferFirst01: "",
  TransferFirst02: "",
  TransferSecond01: "",
  TransferSecond02: "",
  TransferBank01: "",
  TransferBank02: "",
  TransferEdu: "",
  TransferSchoolName: "",
  TransferMajor: "",
  TransferGraduateGubun: "",
  TransferGraduateYear: "",
  TransferArmy: "",
  TransferEli: "",
  TransferDisabled: "",
  TransferWorkInput: [
    {
      career_name: "",
      type: "",
      start_date: "",
      end_date: "",
      salary: "",
      job: "",
      job_summary: "",
    },
  ],
  TransferLicenseInput: [
    {
      license_name: "",
      publisher: "",
      pass_date: "",
    },
  ],
  TransferSelf: "",
  BankPolicyCheck: "",
};

registerLocale("ko", ko);

/**
 * @file 커리어 - 인력등록 수정 페이지
 * @brief 인력등록 수정
 * @author TW.Lee
 */
const TransferEdit = () => {
  const { noti } = useAlert();
  const { isSaved } = useSave();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const floatRef = useRef();
  const [tableData, setTableData] = useState([]);
  const [careerData, setCareerData] = useState([]);
  const [licenseData, setLicenseData] = useState([]);
  const [saveState, setSaveState] = useState("APPROVE_WAITING");
  const [submitState, setSubmitState] = useState("submit");
  const [firstPartOption, setFirstPartOption] = useState([]);
  const [secondPartOption, setSecondPartOption] = useState([]);
  const [popup, setPopup] = useState(false);
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const [enrollCompany, setEnrollCompany] = useState({
    address: "",
    zipcode: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [isPopup, setIsPopup] = useState(false);
  const [popupProfile, setPopupProfile] = useState(false);
  const [popupSupport, setPopupSupport] = useState(false);
  const [popupEdu, setPopupEdu] = useState(false);
  const [popupCareer, setPopupCareer] = useState(false);
  const [popupLicense, setPopupLicense] = useState(false);
  const [popupSelf, setPopupSelf] = useState(false);
  const [isGraduate, setIsGraduate] = useState(false);
  const [ScrollY, setScrollY] = useState(0);

  // 한영에 따른 유효성 체크 변경
  const UI = {
    submit:
      t("lang") === "EN"
        ? transferValidationSchema
        : transferValidationSchemaEn,
    save: transferSaveValidationSchema,
  };

  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(UI[submitState]),
    mode: "onChange",
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    axios({
      method: "get",
      url: `/resume/${location.state.resumeId}`,
    }).then((res) => {
      setTableData(res.data.result);
      if (res.data.result.career_info) {
        setCareerData(JSON.parse(res.data.result.career_info));
      }
      if (res.data.result.license_info) {
        setLicenseData(JSON.parse(res.data.result.license_info));
      }
    });
  }, []);

  // 스크롤에 따른 플롯창 스타일 변경
  const handleScroll = useCallback(() => {
    if (ScrollY > 4300) {
      setScrollY(window.pageYOffset);
      floatRef.current.style.position = "inherit";
      floatRef.current.style.padding = "0 0 100px 0";
    } else {
      setScrollY(window.pageYOffset);
      floatRef.current.style.position = "fixed";
      floatRef.current.style.bottom = "0";
      floatRef.current.style.left = "0";
      floatRef.current.style.width = "100%";
      floatRef.current.style.height = "80px";
      floatRef.current.style.backgroundColor = "#fff";
      floatRef.current.style.padding = "12px 20px 20px 20px";
      floatRef.current.style.zIndex = "800";
    }
  }, [ScrollY]);

  useEffect(() => {
    function scrollListener() {
      if (isMobileWidth) {
        window.addEventListener("scroll", handleScroll);
      }
    } //  window 에서 스크롤을 감시 시작
    scrollListener(); // window 에서 스크롤을 감시
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }; //  window 에서 스크롤을 감시를 종료
  });

  useEffect(() => {
    if (values.TransferFile[0]?.size > 1024 * 1024 * 5) {
      // 용량 초과시 경고후 해당 파일의 용량도 보여줌
      if (t("lang") === "EN") {
        noti("업로드 할 수 있는 파일 형식과 최대 용량을 확인해주세요.");
      } else {
        noti(
          "Please check the file format and maximum capacity that can be uploaded.",
          "Confirm"
        );
      }
      setValue("TransferFile", "");
    }

    const reg = /jpg|jpeg|png/i;
    if (values.TransferFile[0] !== undefined) {
      if (
        reg.test(
          values.TransferFile[0]?.name.substring(
            values.TransferFile[0]?.name.lastIndexOf(".") + 1
          )
        ) === false
      ) {
        if (t("lang") === "EN") {
          noti("업로드 할 수 있는 파일 형식과 최대 용량을 확인해주세요.");
        } else {
          noti(
            "Please check the file format and maximum capacity that can be uploaded.",
            "Confirm"
          );
        }
        setValue("TransferFile", "");
      }
    }
  }, [values.TransferFile]);

  useEffect(() => {
    setValue("Address1", enrollCompany.zipcode);
    setValue("Address2", enrollCompany.address);
  }, [enrollCompany.zipcode, enrollCompany.address]);

  useEffect(() => {
    if (values.TransferGraduateGubun.value === "Graduated") {
      setIsGraduate(true);
      setStartDate(new Date(tableData?.graduation));
    } else {
      setIsGraduate(false);
    }
  }, [values.TransferGraduateGubun]);

  useEffect(() => {
    if (
      values.TransferName !== "" &&
      values.TransferGender !== "" &&
      values.TransferBirth01 !== "" &&
      values.TransferBirth02 !== "" &&
      values.TransferBirth03 !== "" &&
      values.TransferPhone1 !== "" &&
      values.TransferPhone2 !== "" &&
      values.TransferPhone3 !== "" &&
      values.TransferEmail !== "" &&
      values.TransferFile !== "" &&
      enrollCompany.zipcode !== "" &&
      enrollCompany.address !== "" &&
      values.Address3 !== ""
    ) {
      setPopupProfile(true);
    } else {
      setPopupProfile(false);
    }

    if (
      values.TransferFirst01 !== "" &&
      values.TransferFirst02 !== "" &&
      values.TransferSecond01 !== "" &&
      values.TransferSecond02 !== "" &&
      values.TransferBank01 !== "" &&
      values.TransferBank02 !== ""
    ) {
      setPopupSupport(true);
    } else {
      setPopupSupport(false);
    }

    if (
      values.TransferEdu !== "" &&
      values.TransferSchoolName !== "" &&
      values.TransferMajor !== "" &&
      values.TransferGraduateGubun !== "" &&
      values.TransferGraduateYear.value !== "" &&
      values.TransferArmy !== "" &&
      values.TransferDisabled !== ""
    ) {
      setPopupEdu(true);
    } else {
      setPopupEdu(false);
    }

    if (
      values.TransferWorkInput[0].career_name !== "" &&
      values.TransferWorkInput[0].type !== "" &&
      values.TransferWorkInput[0].start_date !== "" &&
      values.TransferWorkInput[0].end_date !== "" &&
      values.TransferWorkInput[0].salary !== "" &&
      values.TransferWorkInput[0].job !== "" &&
      values.TransferWorkInput[0].job_summary !== ""
    ) {
      setPopupCareer(true);
    } else {
      setPopupCareer(false);
    }

    if (
      values.TransferLicenseInput[0].license_name !== "" &&
      values.TransferLicenseInput[0].publisher !== "" &&
      values.TransferLicenseInput[0].pass_date !== ""
    ) {
      setPopupLicense(true);
    } else {
      setPopupLicense(false);
    }

    if (values.TransferSelf !== "") {
      setPopupSelf(true);
    } else {
      setPopupSelf(false);
    }
  }, [
    values.TransferName,
    values.TransferGender,
    values.TransferBirth01,
    values.TransferBirth02,
    values.TransferBirth03,
    values.TransferPhone1,
    values.TransferPhone2,
    values.TransferPhone3,
    values.TransferEmail,
    values.TransferFile,
    values.Address3,
    enrollCompany,
    values.TransferFirst01,
    values.TransferFirst02,
    values.TransferSecond01,
    values.TransferSecond02,
    values.TransferBank01,
    values.TransferBank02,
    values.TransferEdu,
    values.TransferSchoolName,
    values.TransferMajor,
    values.TransferGraduateGubun,
    values.TransferGraduateYear,
    values.TransferArmy,
    values.TransferEli,
    values.TransferDisabled,
    values.TransferWorkInput[0].career_name,
    values.TransferWorkInput[0].type,
    values.TransferWorkInput[0].start_date,
    values.TransferWorkInput[0].end_date,
    values.TransferWorkInput[0].salary,
    values.TransferWorkInput[0].job,
    values.TransferWorkInput[0].job_summary,
    values.TransferLicenseInput[0].license_name,
    values.TransferLicenseInput[0].publisher,
    values.TransferLicenseInput[0].pass_date,
    values.TransferSelf,
  ]);

  // 1차 희망 선택 시 파트 옵션
  const choiceFirstPartOption = useCallback(() => {
    if (t("lang") === "EN") {
      switch (values.TransferFirst01.label) {
        case "객실팀":
          setFirstPartOption(PART1_OPTIONS);
          break;
        case "객실정비팀":
          setFirstPartOption(PART2_OPTIONS);
          break;
        case "식음팀":
          setFirstPartOption(PART3_OPTIONS);
          break;
        case "안전관리팀":
          setFirstPartOption(PART4_OPTIONS);
          break;
        case "시설관리팀":
          setFirstPartOption(PART5_OPTIONS);
          break;
        default:
          setFirstPartOption([]);
          break;
      }
    } else {
      switch (values.TransferFirst01.label) {
        case "Room-part Team":
          setFirstPartOption(PART1_OPTIONS_EN);
          break;
        case "Housekeeping Team":
          setFirstPartOption(PART2_OPTIONS_EN);
          break;
        case "Room Service Team":
          setFirstPartOption(PART3_OPTIONS_EN);
          break;
        case "Safety Management Team":
          setFirstPartOption(PART4_OPTIONS_EN);
          break;
        case "Facility Management Team":
          setFirstPartOption(PART5_OPTIONS_EN);
          break;
        default:
          setFirstPartOption([]);
          break;
      }
    }
  }, [t("lang"), values.TransferFirst01.label]);

  // 2차 희망 선택 시 파트 옵션
  const choiceSecondPartOption = useCallback(() => {
    if (t("lang") === "EN") {
      switch (values.TransferSecond01.label) {
        case "객실팀":
          setSecondPartOption(PART1_OPTIONS);
          break;
        case "객실정비팀":
          setSecondPartOption(PART2_OPTIONS);
          break;
        case "식음팀":
          setSecondPartOption(PART3_OPTIONS);
          break;
        case "안전관리팀":
          setSecondPartOption(PART4_OPTIONS);
          break;
        case "시설관리팀":
          setSecondPartOption(PART5_OPTIONS);
          break;
        default:
          setSecondPartOption([]);
          break;
      }
    } else {
      switch (values.TransferSecond01.label) {
        case "Room-part Team":
          setSecondPartOption(PART1_OPTIONS_EN);
          break;
        case "Housekeeping Team":
          setSecondPartOption(PART2_OPTIONS_EN);
          break;
        case "Room Service Team":
          setSecondPartOption(PART3_OPTIONS_EN);
          break;
        case "Safety Management Team":
          setSecondPartOption(PART4_OPTIONS_EN);
          break;
        case "Facility Management Team":
          setSecondPartOption(PART5_OPTIONS_EN);
          break;
        default:
          setSecondPartOption([]);
          break;
      }
    }
  }, [t("lang"), values.TransferSecond01.label]);

  // 최대 업데이트 깊이 초과 에러 대응
  useEffect(() => {
    choiceFirstPartOption();
    choiceSecondPartOption();
  }, [choiceFirstPartOption, choiceSecondPartOption]);

  // 경력사항 추가 필드
  const {
    fields: workFields,
    append: workAppend,
    remove: workRemove,
  } = useFieldArray({
    control,
    name: "TransferWorkInput",
  });

  // 경력사항 추가 함수
  const handleAddWork = useCallback(() => {
    workAppend({});
  }, [workAppend]);

  // 자격증 추가 필드
  const {
    fields: licenseFields,
    append: licenseAppend,
    remove: licenseRemove,
  } = useFieldArray({
    control,
    name: "TransferLicenseInput",
  });

  // 자격증 추가 함수
  const handleAddLicense = useCallback(() => {
    licenseAppend({});
  }, [licenseAppend]);

  let careerInfo;
  values.TransferWorkInput.map((e) => {
    if (
      e?.career_name === "" &&
      e?.type === "" &&
      e?.start_date === "" &&
      e?.end_date === "" &&
      e?.job === "" &&
      e?.job_summary === "" &&
      e?.salary === ""
    ) {
      careerInfo = "";
    } else {
      careerInfo = JSON.stringify(values.TransferWorkInput);
    }
  });

  let licenseInfo;
  values.TransferLicenseInput.map((e) => {
    if (e?.license_name === "" && e?.pass_date === "" && e?.publisher === "") {
      licenseInfo = "";
    } else {
      licenseInfo = JSON.stringify(values.TransferLicenseInput);
    }
  });

  const imageFormData = new FormData();
  imageFormData.append("file", values.TransferFile[0]);

  // 인력등록 수정 요청
  const submitTransfer = async () => {
    const attachment = [];

    if (
      values.TransferFile[0]?.name ===
      `${tableData?.profile_image_info?.file_name}.${tableData?.profile_image_info?.extension}`
    ) {
      attachment.push(tableData?.profile_image_info?.id);
    } else {
      try {
        const res = await axios({
          method: "post",
          url: "/files",
          data: imageFormData,
        });

        if (res.status === 200) {
          attachment.push(res.data.result.id);
        }
      } catch (err) {
        throw err;
      }
    }

    let grad;
    if (startDate) {
      grad = dayjs(startDate).format("YYYY");
    }

    let payload = {
      type: "PARTTIMER",
      job_title_id: location?.state.jobTitleId,
      job_title:
        location?.state.jobTitleId === null ? "" : location?.state.jobTitle,
      state: saveState,
      resume_name: values.TransferName,
      gender: values.TransferGender,
      birth_day:
        values.TransferBirth01 +
        values.TransferBirth02 +
        values.TransferBirth03,
      phone:
        values.TransferPhone1.value +
        values.TransferPhone2 +
        values.TransferPhone3,
      email: values.TransferEmail,
      zip_code: enrollCompany.zipcode,
      address: enrollCompany.address,
      detail_address: values.Address3,
      job1_depth1: values.TransferFirst01.value,
      job1_depth2: values.TransferFirst02.value,
      job2_depth1: values.TransferSecond01.value,
      job2_depth2: values.TransferSecond02.value,
      bank: values.TransferBank01.value,
      account_number: values.TransferBank02,
      education: values.TransferEdu.value,
      school_name: values.TransferSchoolName,
      major: values.TransferMajor,
      graduation: grad,
      graduation_category: values.TransferGraduateGubun.value,
      military_service: values.TransferArmy.value,
      veterans: values.TransferEli.value,
      disabled: values.TransferDisabled.value,
      self_introducation: values.TransferSelf,
      career_info: careerInfo,
      license_info: licenseInfo,
    };

    if (attachment) {
      payload = {
        ...payload,
        profile_image_id: attachment,
      };
    }

    await axios({
      method: "post",
      url: "/resume",
      data: payload,
    }).then((res) => {
      if (saveState === "APPROVE_WAITING") {
        const transferDone =
          t("lang") === "EN"
            ? noti("제출 완료 되었습니다.")
            : noti(
                "Your application has been submitted successfully.",
                "Confirm"
              );

        const getData = () => {
          transferDone.then((result) => {
            if (result === true) {
              navigate("/transfer/transfer-done", {
                replace: true,
                state: {
                  time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                  job1Depth1: values.TransferFirst01.value,
                  job1Depth2: values.TransferFirst02.value,
                  job2Depth1: values.TransferSecond01.value,
                  job2Depth2: values.TransferSecond02.value,
                  email: values.TransferEmail,
                  name: values.TransferName,
                  bank: values.TransferBank01.value,
                  account: values.TransferBank02,
                },
              });
            }
          });
        };

        getData();
      }
      if (saveState === "TEMPORARY_SAVE") {
        if (t("lang") === "EN") {
          noti(
            "임시 저장 되었습니다.\n(마이페이지 > 인력등록 현황에서 확인 가능)"
          );
        } else {
          noti(
            "Your application has been temporarily saved.\n(You can find your temporarily saved application at My Page > Application)",
            "Close"
          );
        }
      }
    });
  };

  // 경력사항 및 자격증 추가 필드 셋팅
  useEffect(() => {
    if (careerData?.length > 1) {
      for (let i = 1; i <= careerData.length; i++) {
        workAppend();
      }
    }
    if (licenseData?.length > 1) {
      for (let i = 1; i <= licenseData.length; i++) {
        licenseAppend();
      }
    }
  }, [careerData, licenseData]);

  const birth_year = tableData?.birth_day?.substr(0, 4);
  const birth_month = tableData?.birth_day?.substr(4, 2);
  const birth_days = tableData?.birth_day?.substr(6, 2);
  const phone1 = tableData?.phone?.substr(0, 3);
  const phone2 = tableData?.phone?.substr(3, 4);
  const phone3 = tableData?.phone?.substr(7, 4);

  useEffect(() => {
    for (let j = 0; j < firstPartOption?.length; j++) {
      if (firstPartOption[j].value === tableData.job1_depth2)
        setValue("TransferFirst02", firstPartOption[j]);
    }
    for (let k = 0; k < secondPartOption?.length; k++) {
      if (secondPartOption[k].value === tableData.job2_depth2) {
        setValue("TransferSecond02", secondPartOption[k]);
      }
    }
  }, [firstPartOption, secondPartOption]);

  // 한영
  useEffect(() => {
    if (t("lang") === "EN") {
      if (tableData?.gender === "남성" || tableData?.gender === "Male") {
        setValue("TransferGender", "남성");
      } else {
        setValue("TransferGender", "여성");
      }

      // 고용형태 셋팅
      for (let i = 0; i < EMPLOY_OPTIONS.length; i++) {
        for (let j = 0; j < careerData?.length; j++) {
          if (EMPLOY_OPTIONS[i].value === careerData[j].type?.value) {
            setValue(`TransferWorkInput[${j}].type`, EMPLOY_OPTIONS[i]);
          }
        }
      }

      // 희망 지원분야 셋팅
      for (let i = 0; i < DEPARTMENT_OPTIONS?.length; i++) {
        if (DEPARTMENT_OPTIONS[i].value === tableData.job1_depth1) {
          setValue("TransferFirst01", DEPARTMENT_OPTIONS[i]);
        }

        if (DEPARTMENT_OPTIONS[i].value === tableData.job2_depth1) {
          setValue("TransferSecond01", DEPARTMENT_OPTIONS[i]);
        }
      }

      // 은행 셋팅
      for (let i = 0; i < CARD_COMPANY_OPTIONS.length; i++) {
        if (CARD_COMPANY_OPTIONS[i].value === tableData.bank) {
          setValue("TransferBank01", CARD_COMPANY_OPTIONS[i]);
        }
      }

      // 최종학력 셋팅
      for (let i = 0; i < EDUCATION_OPTIONS.length; i++) {
        if (EDUCATION_OPTIONS[i].value === tableData.education) {
          setValue("TransferEdu", EDUCATION_OPTIONS[i]);
        }
      }

      // 졸업구분 셋팅
      for (let i = 0; i < GRADUATE_OPTIONS.length; i++) {
        if (GRADUATE_OPTIONS[i].value === tableData.graduation_category) {
          setValue("TransferGraduateGubun", GRADUATE_OPTIONS[i]);
        }
      }

      // 병역 셋팅
      for (let i = 0; i < MILITARY_OPTIONS.length; i++) {
        if (MILITARY_OPTIONS[i].value === tableData.military_service) {
          setValue("TransferArmy", MILITARY_OPTIONS[i]);
        }
      }

      // 병역 셋팅
      for (let i = 0; i < ELIGIBILTY_OPTIONS.length; i++) {
        if (ELIGIBILTY_OPTIONS[i].value === tableData.veterans) {
          setValue("TransferEli", ELIGIBILTY_OPTIONS[i]);
        }
      }

      // 장애 셋팅
      for (let i = 0; i < DISABLED_OPTIONS.length; i++) {
        if (DISABLED_OPTIONS[i].value === tableData.disabled) {
          setValue("TransferDisabled", DISABLED_OPTIONS[i]);
        }
      }

      // 경력사항 셋팅
      for (let i = 0; i < careerData?.length; i++) {
        setValue(
          `TransferWorkInput[${i}].career_name`,
          careerData[i].career_name
        );
        setValue(
          `TransferWorkInput[${i}].start_date`,
          careerData[i].start_date
        );
        setValue(`TransferWorkInput[${i}].end_date`, careerData[i].end_date);
        setValue(`TransferWorkInput[${i}].salary`, careerData[i].salary);
        setValue(`TransferWorkInput[${i}].job`, careerData[i].job);
        setValue(
          `TransferWorkInput[${i}].job_summary`,
          careerData[i].job_summary
        );

        for (let j = 0; j < EMPLOY_OPTIONS.length; j++) {
          if (EMPLOY_OPTIONS[j].value === careerData[i].type) {
            setValue(`TransferWorkInput[${i}].type`, EMPLOY_OPTIONS[j]);
          }
        }
      }
    } else {
      if (tableData.gender === "남성" || tableData.gender === "Male") {
        setValue("TransferGender", "Male");
      } else {
        setValue("TransferGender", "Female");
      }

      // 고용형태 셋팅 영어
      for (let i = 0; i < EMPLOY_OPTIONS_EN.length; i++) {
        for (let j = 0; j < careerData?.length; j++) {
          if (EMPLOY_OPTIONS_EN[i].value === careerData[j].type?.value) {
            setValue(`TransferWorkInput[${j}].type`, EMPLOY_OPTIONS_EN[i]);
          }
        }
      }

      // 희망 지원분야 셋팅 영어
      for (let i = 0; i < DEPARTMENT_OPTIONS_EN.length; i++) {
        if (DEPARTMENT_OPTIONS_EN[i].value === tableData.job1_depth1) {
          setValue("TransferFirst01", DEPARTMENT_OPTIONS_EN[i]);
        }

        if (DEPARTMENT_OPTIONS_EN[i].value === tableData.job2_depth1) {
          setValue("TransferSecond01", DEPARTMENT_OPTIONS_EN[i]);
        }
      }

      // 은행 셋팅 영어
      for (let i = 0; i < CARD_COMPANY_OPTIONS_EN.length; i++) {
        if (CARD_COMPANY_OPTIONS_EN[i].value === tableData.bank) {
          setValue("TransferBank01", CARD_COMPANY_OPTIONS_EN[i]);
        }
      }

      // 최종학력 셋팅 영어
      for (let i = 0; i < EDUCATION_OPTIONS_EN.length; i++) {
        if (EDUCATION_OPTIONS_EN[i].value === tableData.education) {
          setValue("TransferEdu", EDUCATION_OPTIONS_EN[i]);
        }
      }

      // 졸업구분 셋팅 영어
      for (let i = 0; i < GRADUATE_OPTIONS_EN.length; i++) {
        if (GRADUATE_OPTIONS_EN[i].value === tableData.graduation_category) {
          setValue("TransferGraduateGubun", GRADUATE_OPTIONS_EN[i]);
        }
      }

      // 병역 셋팅 영어
      for (let i = 0; i < MILITARY_OPTIONS_EN.length; i++) {
        if (MILITARY_OPTIONS_EN[i].value === tableData.military_service) {
          setValue("TransferArmy", MILITARY_OPTIONS_EN[i]);
        }
      }

      // 장애 셋팅 영어
      for (let i = 0; i < DISABLED_OPTIONS_EN.length; i++) {
        if (DISABLED_OPTIONS_EN[i].value === tableData.disabled) {
          setValue("TransferDisabled", DISABLED_OPTIONS_EN[i]);
        }
      }

      // 경력사항 셋팅 영어
      for (let i = 0; i < careerData?.length; i++) {
        setValue(
          `TransferWorkInput[${i}].career_name`,
          careerData[i].career_name
        );
        setValue(
          `TransferWorkInput[${i}].start_date`,
          careerData[i].start_date
        );
        setValue(`TransferWorkInput[${i}].end_date`, careerData[i].end_date);
        setValue(`TransferWorkInput[${i}].salary`, careerData[i].salary);
        setValue(`TransferWorkInput[${i}].job`, careerData[i].job);
        setValue(
          `TransferWorkInput[${i}].job_summary`,
          careerData[i].job_summary
        );

        for (let j = 0; j < EMPLOY_OPTIONS_EN.length; j++) {
          if (EMPLOY_OPTIONS_EN[j].value === careerData[i].type) {
            setValue(`TransferWorkInput[${i}].type`, EMPLOY_OPTIONS_EN[j]);
          }
        }
      }
    }
  }, [t("lang"), tableData]);

  // 저장된 데이터 뿌려주기
  useEffect(() => {
    // 전화번호 앞자리 셋팅
    for (let i = 0; i < PREFIX_NUMBER_OPTIONS?.length; i++) {
      if (PREFIX_NUMBER_OPTIONS[i].value === phone1) {
        setValue("TransferPhone1", PREFIX_NUMBER_OPTIONS[i]);
      }
    }

    // 자격증 셋팅
    for (let i = 0; i < licenseData?.length; i++) {
      setValue(
        `TransferLicenseInput[${i}].license_name`,
        licenseData[i].license_name
      );
      setValue(
        `TransferLicenseInput[${i}].pass_date`,
        licenseData[i].pass_date
      );
      setValue(
        `TransferLicenseInput[${i}].publisher`,
        licenseData[i].publisher
      );
    }

    // 이미지 셋팅
    if (tableData.profile_image_info) {
      const arrayFile = [
        {
          name: `${tableData.profile_image_info.file_name}.${tableData.profile_image_info.extension}`,
          id: tableData.profile_image_info.id,
          size: tableData.profile_image_info.size,
        },
      ];
      setValue("TransferFile", tableData.profile_image_info ? arrayFile : "");
    }

    setEnrollCompany({
      address: tableData.address,
      zipcode: tableData.zip_code,
    });

    setValue(
      "TransferName",
      tableData.resume_name ? tableData.resume_name : ""
    );

    setValue("TransferBirth01", tableData.birth_day ? birth_year : "");
    setValue("TransferBirth02", tableData.birth_day ? birth_month : "");
    setValue("TransferBirth03", tableData.birth_day ? birth_days : "");
    setValue("TransferPhone2", tableData.phone ? phone2 : "");
    setValue("TransferPhone3", tableData.phone ? phone3 : "");
    setValue("Address1", enrollCompany.zipcode);
    setValue("Address2", enrollCompany.address);
    setValue("Address3", tableData.detail_address);
    setValue("TransferEmail", tableData.email ? tableData.email : "");
    setValue(
      "TransferSelf",
      tableData.self_introducation ? tableData.self_introducation : ""
    );
    setValue(
      "TransferBank02",
      tableData.account_number ? tableData.account_number : ""
    );
    setValue(
      "TransferSchoolName",
      tableData.school_name ? tableData.school_name : ""
    );
    setValue("TransferMajor", tableData.major ? tableData.major : "");
  }, [tableData, firstPartOption, secondPartOption]);

  return (
    <Layout subFixed="true">
      <WhiteRoot>
        <SubLayout
          banner={Banner05}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.transfer : PAGE_LAYOUT_EN.transfer
          }
        >
          <div className="transfer_inner">
            {isMobileWidth ? (
              <TransferListMobilePopup
                isPopup={isPopup}
                setIsPopup={setIsPopup}
                popupProfile={popupProfile}
                popupSupport={popupSupport}
                popupEdu={popupEdu}
                popupCareer={popupCareer}
                popupLicense={popupLicense}
                popupSelf={popupSelf}
              />
            ) : (
              <TransferListPopup
                isPopup={isPopup}
                setIsPopup={setIsPopup}
                popupProfile={popupProfile}
                popupSupport={popupSupport}
                popupEdu={popupEdu}
                popupCareer={popupCareer}
                popupLicense={popupLicense}
                popupSelf={popupSelf}
              />
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();

                handleSubmit(
                  (ele) => {
                    setSubmitState("submit");
                    setSaveState("APPROVE_WAITING");

                    if (
                      values.TransferGraduateGubun.value === "졸업" &&
                      values.TransferGraduateYear === "" &&
                      saveState === "APPROVE_WAITING"
                    ) {
                      if (t("lang") === "EN") {
                        noti("졸업연도를 선택해주세요.", "확인");
                      } else {
                        noti("Please select your graduation year.", "Confirm");
                      }
                    } else if (saveState === "TEMPORARY_SAVE") {
                      submitTransfer();
                    } else {
                      const submit = isSaved(
                        t("lang") === "EN"
                          ? `제출 완료 시 수정 및 삭제가 불가능합니다. 제출하시겠습니까?`
                          : "It cannot be modified or deleted once submission is complete. Would you like to submit?",
                        "No",
                        "Yes"
                      );

                      const setData = () => {
                        submit.then((result) => {
                          if (result === true) {
                            submitTransfer();
                          }
                        });
                      };
                      setData();
                    }
                  },
                  (err) => {
                    const keys = [
                      "TransferName",
                      "TransferGender",
                      "TransferBirth01",
                      "TransferBirth02",
                      "TransferBirth03",
                      "TransferPhone2",
                      "TransferPhone3",
                      "TransferEmail",
                      "Address1",
                      "Address2",
                      "Address3",
                      "TransferFirst01",
                      "TransferFirst02",
                      "TransferSecond01",
                      "TransferSecond02",
                      "TransferBank01",
                      "TransferBank02",
                      "TransferEdu",
                      "TransferSchoolName",
                      "TransferMajor",
                      "TransferGraduateGubun",
                      "TransferArmy",
                      "TransferDisabled",
                      "TransferSelf",
                    ];

                    for (const key of keys) {
                      if (err[key]) {
                        if (t("lang") === "EN") {
                          noti(err[key].message, "확인");
                        } else {
                          noti(err[key].message, "Confirm");
                        }
                        setFocus(key);
                        break;
                      }
                    }
                  }
                )();
              }}
            >
              <TransferInner01>
                <h3
                  className={
                    isMobileWidth ? "mo-transfer-profile" : "transfer-profile"
                  }
                >
                  &#183; {t("profile")} &#183;
                </h3>
                <div className={isMobileWidth ? "mo-form-inner" : "form-inner"}>
                  <Row label={t("name")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="TransferName"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="text"
                            placeholder={t("entername")}
                            fullWidth
                            errors={errors}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("gender")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="TransferGender"
                        render={({ field }) => (
                          <div>
                            <Radio
                              {...field}
                              name="gender"
                              value={t("male")}
                              w={isMobileWidth ? "64" : "88"}
                              h={isMobileWidth ? "48" : "65"}
                              p="0"
                              {...register("TransferGender")}
                            >
                              {t("male")}
                            </Radio>
                            <span className="void-sec"></span>
                            <Radio
                              {...field}
                              name="gender"
                              value={t("female")}
                              w={isMobileWidth ? "64" : "88"}
                              h={isMobileWidth ? "48" : "65"}
                              p="0"
                              {...register("TransferGender")}
                            >
                              {t("female")}
                            </Radio>
                          </div>
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("BOD")}>
                    <div
                      className={
                        isMobileWidth
                          ? "mo_input-box birth-box"
                          : "input-box birth-box"
                      }
                    >
                      <Controller
                        control={control}
                        name="TransferBirth01"
                        render={({ field }) => (
                          <Textbox
                            {...field}
                            placeholder="YYYY"
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength="4"
                            fullWidth={isMobileWidth ? "true" : ""}
                          >
                            {t("year")}
                          </Textbox>
                        )}
                      />
                      <span className="void-sec"></span>
                      <Controller
                        control={control}
                        name="TransferBirth02"
                        render={({ field }) => (
                          <Textbox
                            {...field}
                            placeholder="MM"
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength="2"
                            fullWidth={isMobileWidth ? "true" : ""}
                          >
                            {t("month")}
                          </Textbox>
                        )}
                      />
                      <span className="void-sec"></span>
                      <Controller
                        control={control}
                        name="TransferBirth03"
                        render={({ field }) => (
                          <Textbox
                            {...field}
                            placeholder="DD"
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength="2"
                            fullWidth={isMobileWidth ? "true" : ""}
                          >
                            {t("day")}
                          </Textbox>
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("phonelabel")}>
                    <div
                      className={
                        isMobileWidth
                          ? "mo_input-box phonenumber"
                          : "input-box phonenumber"
                      }
                    >
                      <Controller
                        control={control}
                        name="TransferPhone1"
                        render={({ field }) => (
                          <Select
                            {...field}
                            ref={field.ref}
                            value={field.value}
                            placeholder={t("select")}
                            variant="tertiary"
                            w={isMobileWidth ? "92" : ""}
                            options={PREFIX_NUMBER_OPTIONS}
                          />
                        )}
                      />
                      <span>-</span>
                      <Controller
                        control={control}
                        name="TransferPhone2"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength="4"
                          />
                        )}
                      />
                      <span>-</span>
                      <Controller
                        control={control}
                        name="TransferPhone3"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength="4"
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("emailaddr")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="TransferEmail"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item dim-input"
                            type="text"
                            placeholder={t("enteremailaddr")}
                            fullWidth
                            warning={errors}
                            readOnly
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("idphoto")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <div className="attachment">
                        <Controller
                          control={control}
                          name="TransferFile"
                          render={({ field }) => (
                            <FileInput
                              {...field}
                              accept=".jpg, .png"
                              className="inp-item"
                            >
                              {t("hiringregisttext3")}
                            </FileInput>
                          )}
                        />
                      </div>
                    </div>
                  </Row>
                  <Row label={t("addresslabel")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <div className="zipcode">
                        <Controller
                          control={control}
                          name="Address1"
                          render={({ field }) => (
                            <Input
                              {...field}
                              className="inp-item"
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              placeholder={t("enterzipcode")}
                              fullWidth
                              value={enrollCompany.zipcode || ""}
                            />
                          )}
                        />
                        {isMobileWidth ? (
                          <button
                            type="button"
                            className="mo-address-search"
                            onClick={() => {
                              setPopup(true);
                            }}
                          >
                            {t("searchaddrlabel")}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="address-search"
                            onClick={() => {
                              setPopup(true);
                            }}
                          >
                            {t("searchaddrlabel")}
                          </button>
                        )}
                        {popup && (
                          <Postcode
                            popup={popup}
                            setPopup={setPopup}
                            company={enrollCompany}
                            setcompany={setEnrollCompany}
                          />
                        )}
                      </div>
                      <div className="void-sec2"></div>
                      <Controller
                        control={control}
                        name="Address2"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="text"
                            placeholder={t("addresslabel")}
                            fullWidth
                            value={enrollCompany.address || ""}
                          />
                        )}
                      />
                      <div className="void-sec2"></div>
                      <Controller
                        control={control}
                        name="Address3"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="text"
                            placeholder={t("enterdetailaddr")}
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </Row>
                </div>
              </TransferInner01>
              <TransferInner02>
                <h3
                  className={
                    isMobileWidth ? "mo-transfer-profile" : "transfer-profile"
                  }
                >
                  &#183; {t("appfield")} &#183;
                </h3>
                <div className="form-inner">
                  <Row label={t("firstpre")}>
                    <div
                      className={
                        isMobileWidth
                          ? "mo_input-box hope-select"
                          : "input-box hope-select"
                      }
                    >
                      <Controller
                        control={control}
                        name="TransferFirst01"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder={t("selectdepart")}
                            variant="primary"
                            fullWidth
                            options={
                              t("lang") === "EN"
                                ? DEPARTMENT_OPTIONS
                                : DEPARTMENT_OPTIONS_EN
                            }
                          />
                        )}
                      />
                      <span className="void-sec"></span>
                      <Controller
                        control={control}
                        name="TransferFirst02"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder={t("selectpart")}
                            variant="primary"
                            fullWidth
                            options={firstPartOption}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("Secondpre")}>
                    <div
                      className={
                        isMobileWidth
                          ? "mo_input-box hope-select"
                          : "input-box hope-select"
                      }
                    >
                      <Controller
                        control={control}
                        name="TransferSecond01"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder={t("selectdepart")}
                            variant="primary"
                            fullWidth
                            options={
                              t("lang") === "EN"
                                ? DEPARTMENT_OPTIONS
                                : DEPARTMENT_OPTIONS_EN
                            }
                          />
                        )}
                      />
                      <span className="void-sec"></span>
                      <Controller
                        control={control}
                        name="TransferSecond02"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder={t("selectpart")}
                            variant="primary"
                            fullWidth
                            options={secondPartOption}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("accountnum")}>
                    <div
                      className={
                        isMobileWidth
                          ? "mo_input-box bank-select"
                          : "input-box bank-select"
                      }
                    >
                      <Controller
                        control={control}
                        name="TransferBank01"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder={t("enterbank")}
                            variant="secondary"
                            w={isMobileWidth ? "110" : ""}
                            options={
                              t("lang") === "EN"
                                ? CARD_COMPANY_OPTIONS
                                : CARD_COMPANY_OPTIONS_EN
                            }
                          />
                        )}
                      />
                      <span className="void-sec"></span>
                      <Controller
                        control={control}
                        name="TransferBank02"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            placeholder={t("enternumber")}
                            warning={errors}
                          />
                        )}
                      />
                    </div>
                    <span
                      className={
                        isMobileWidth ? "mo-bank-detail" : "bank-detail"
                      }
                    >
                      * {t("enterpayroll1")}
                      {t("enterpayroll2")}
                    </span>
                  </Row>
                </div>
              </TransferInner02>
              <TransferInner03>
                <h3
                  className={
                    isMobileWidth ? "mo-transfer-profile" : "transfer-profile"
                  }
                >
                  &#183; {t("EduMilImp2")} &#183;
                </h3>
                <div className={isMobileWidth ? "mo-form-inner" : "form-inner"}>
                  <Row label={t("edulevel")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="TransferEdu"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder={t("select")}
                            variant="tertiary"
                            options={
                              t("lang") === "EN"
                                ? EDUCATION_OPTIONS
                                : EDUCATION_OPTIONS_EN
                            }
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("schoolname")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="TransferSchoolName"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="text"
                            placeholder={t("enterschoolname")}
                            fullWidth
                            warning={errors}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("major")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="TransferMajor"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="text"
                            placeholder={t("entermajor")}
                            fullWidth
                            warning={errors}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("graddivision")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="TransferGraduateGubun"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder={t("select")}
                            variant="tertiary"
                            options={
                              t("lang") === "EN"
                                ? GRADUATE_OPTIONS
                                : GRADUATE_OPTIONS_EN
                            }
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("yearofgrad")}>
                    <div
                      className={
                        isMobileWidth
                          ? "mo_input-box mo-date-input"
                          : "input-box date-input"
                      }
                    >
                      <Controller
                        control={control}
                        name="TransferGraduateYear"
                        render={({ field }) => (
                          <DatePicker
                            locale="ko"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showYearPicker
                            showPopperArrow={false}
                            dateFormat="yyyy"
                            popperPlacement="bottom"
                            placeholderText={t("selectyearofgrad")}
                            startDate={startDate}
                            endDate={null}
                            disabled={!isGraduate}
                          />
                        )}
                      />
                      <div
                        className={isMobileWidth ? "mo-date-img" : "date-img"}
                      >
                        <img src={IconCalSVG} />
                      </div>
                    </div>
                  </Row>
                  <Row label={t("militaryservice")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="TransferArmy"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder={t("select")}
                            variant="tertiary"
                            options={
                              t("lang") === "EN"
                                ? MILITARY_OPTIONS
                                : MILITARY_OPTIONS_EN
                            }
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("eligibility")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="TransferEli"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder={t("select")}
                            variant="tertiary"
                            options={
                              t("lang") === "EN"
                                ? ELIGIBILTY_OPTIONS
                                : ELIGIBILTY_OPTIONS_EN
                            }
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("impairment")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="TransferDisabled"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder={t("Disrating")}
                            variant="tertiary"
                            options={
                              t("lang") === "EN"
                                ? DISABLED_OPTIONS
                                : DISABLED_OPTIONS_EN
                            }
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </Row>
                </div>
              </TransferInner03>
              <TransferInner04>
                <h3
                  className={
                    isMobileWidth ? "mo-transfer-profile" : "transfer-profile"
                  }
                >
                  &#183; {t("career2")} &#183;
                </h3>
                <div
                  className={isMobileWidth ? "mo-form-inner2" : "form-inner2"}
                >
                  {workFields.map((license, index) => {
                    return (
                      <div className="add-section-inner" key={index}>
                        <Row label={t("Coname")}>
                          <div
                            className={
                              isMobileWidth ? "mo_input-box" : "input-box"
                            }
                          >
                            <Controller
                              control={control}
                              name={`TransferWorkInput[${index}].career_name`}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  className="inp-item"
                                  type="text"
                                  placeholder={t("enterconame2")}
                                  fullWidth
                                  warning={errors}
                                />
                              )}
                            />
                          </div>
                        </Row>
                        <Row label={t("emplotype")}>
                          <div
                            className={
                              isMobileWidth ? "mo_input-box" : "input-box"
                            }
                          >
                            <Controller
                              control={control}
                              name={`TransferWorkInput[${index}].type`}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  placeholder={t("select")}
                                  variant="tertiary"
                                  options={
                                    t("lang") === "EN"
                                      ? EMPLOY_OPTIONS
                                      : EMPLOY_OPTIONS_EN
                                  }
                                  fullWidth
                                />
                              )}
                            />
                          </div>
                        </Row>
                        <Row label={t("emploperiod")}>
                          <div
                            className={
                              isMobileWidth
                                ? "mo_input-box workday-box"
                                : "input-box workday-box"
                            }
                          >
                            <Controller
                              control={control}
                              name={`TransferWorkInput[${index}].start_date`}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  className="inp-item"
                                  type="text"
                                  placeholder="YYYY.MM.DD"
                                  warning={errors}
                                />
                              )}
                            />
                            <span className="void-sec">-</span>
                            <Controller
                              control={control}
                              name={`TransferWorkInput[${index}].end_date`}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  className="inp-item"
                                  type="text"
                                  placeholder="YYYY.MM.DD"
                                  warning={errors}
                                />
                              )}
                            />
                          </div>
                        </Row>
                        <Row label={t("salary")}>
                          <div
                            className={
                              isMobileWidth ? "mo_input-box" : "input-box"
                            }
                          >
                            <Controller
                              control={control}
                              name={`TransferWorkInput[${index}].salary`}
                              render={({ field }) => (
                                <Textbox
                                  {...field}
                                  placeholder={t("entersalary")}
                                  type="number"
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                  fullWidth
                                >
                                  {t("won")}
                                </Textbox>
                              )}
                            />
                          </div>
                        </Row>
                        <Row label={t("duty")}>
                          <div
                            className={
                              isMobileWidth ? "mo_input-box" : "input-box"
                            }
                          >
                            <Controller
                              control={control}
                              name={`TransferWorkInput[${index}].job`}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  className="inp-item"
                                  type="text"
                                  placeholder={t("enterduty")}
                                  fullWidth
                                  warning={errors}
                                />
                              )}
                            />
                          </div>
                        </Row>
                        <Row label={t("summarize")}>
                          <div
                            className={
                              isMobileWidth ? "mo_input-box" : "input-box"
                            }
                          >
                            <Controller
                              control={control}
                              name={`TransferWorkInput[${index}].job_summary`}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  multiline
                                  useMaxLengthIndicator
                                  maxLength={1000}
                                  className="inp-item"
                                  type="text"
                                  placeholder={t("entermessage")}
                                  fullWidth
                                  warning={errors}
                                />
                              )}
                            />
                          </div>
                        </Row>
                        <div
                          className={
                            isMobileWidth ? "mo-section-add" : "section-add"
                          }
                        >
                          {workFields.length > 1 && (
                            <button
                              className="delete-sec"
                              onClick={() => workRemove(1)} // 배열에서 1번만 삭제하도록 처리. -> 0번은 삭제 안됨.
                            >
                              {t("delete")}
                            </button>
                          )}
                          <button
                            className="add-sec"
                            type="button"
                            onClick={() => handleAddWork()}
                          >
                            {t("addcareer")}{" "}
                            <img src={IconPlusSVG} alt="자격증 추가 버튼" />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TransferInner04>
              <TransferInner05>
                <h3
                  className={
                    isMobileWidth ? "mo-transfer-profile" : "transfer-profile"
                  }
                >
                  &#183; {t("certificate")} &#183;
                </h3>
                <div
                  className={isMobileWidth ? "mo-form-inner2" : "form-inner2"}
                >
                  {licenseFields.map((license, index) => {
                    return (
                      <div className="add-section-inner" key={index}>
                        <Row label={t("credentials")}>
                          <div
                            className={
                              isMobileWidth ? "mo_input-box" : "input-box"
                            }
                          >
                            <Controller
                              control={control}
                              name={`TransferLicenseInput[${index}].license_name`}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  className="inp-item"
                                  type="text"
                                  placeholder={t("entercredentials")}
                                  fullWidth
                                  warning={errors}
                                />
                              )}
                            />
                          </div>
                        </Row>
                        <Row label={t("placeissue")}>
                          <div
                            className={
                              isMobileWidth ? "mo_input-box" : "input-box"
                            }
                          >
                            <Controller
                              control={control}
                              name={`TransferLicenseInput[${index}].publisher`}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  className="inp-item"
                                  type="text"
                                  placeholder={t("enterplaceissue")}
                                  fullWidth
                                  warning={errors}
                                />
                              )}
                            />
                          </div>
                        </Row>
                        <Row label={t("acqulsition")}>
                          <div
                            className={
                              isMobileWidth ? "mo_input-box" : "input-box"
                            }
                          >
                            <Controller
                              control={control}
                              name={`TransferLicenseInput[${index}].pass_date`}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  className="inp-item"
                                  type="text"
                                  placeholder="YYYY.MM.DD"
                                  fullWidth
                                  warning={errors}
                                />
                              )}
                            />
                          </div>
                        </Row>
                        <div
                          className={
                            isMobileWidth ? "mo-section-add" : "section-add"
                          }
                        >
                          {licenseFields.length > 1 && (
                            <button
                              className="delete-sec"
                              onClick={() => licenseRemove(1)}
                            >
                              {t("delete")}
                            </button>
                          )}
                          <button
                            className="add-sec"
                            type="button"
                            onClick={() => handleAddLicense()}
                          >
                            {t("addceriflcate")}{" "}
                            <img src={IconPlusSVG} alt="자격증 추가 버튼" />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TransferInner05>
              <TransferInner06>
                <h3
                  className={
                    isMobileWidth ? "mo-transfer-profile" : "transfer-profile"
                  }
                >
                  &#183; {t("yourself")} &#183;
                </h3>
                <div className={isMobileWidth ? "mo-form-inner" : "form-inner"}>
                  <div className="input-box self-box">
                    <Controller
                      control={control}
                      name="TransferSelf"
                      render={({ field }) => (
                        <Input
                          {...field}
                          multiline
                          useMaxLengthIndicator
                          maxLength={3000}
                          className="inp-item"
                          type="text"
                          placeholder={t("entermessage")}
                          warning={errors}
                          fullWidth
                          doubleheight={isMobileWidth ? "" : "true"}
                        />
                      )}
                    />
                  </div>
                </div>
              </TransferInner06>
              <div
                className={
                  isMobileWidth ? "mo-bank-policy-check" : "bank-policy-check"
                }
              >
                <Controller
                  control={control}
                  name="BankPolicyCheck"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      {...register("BankPolicyCheck")}
                      fontWegiht
                    >
                      {t("bankpolicy")}
                    </Checkbox>
                  )}
                />
                <p>
                  *{" "}
                  <span className="red-font">
                    {t("lang") === "EN"
                      ? "잘못된 은행명과 계좌번호 입력으로 받는 모든 불이익은 본인의 책임"
                      : "I hereby agree that any disadvantages occurring from entering incorrect bank information will be at my own responsibility,"}
                  </span>
                  {t("lang") === "EN" && `이며,`}
                  {t("lang") === "EN" && <br />}
                  {t("lang") === "EN"
                    ? "주식회사 누리글로벌서비스는 이에 대해 어떠한 손해배상을 할 의무가 없습니다."
                    : " and NURI Global Service Co., Ltd. is not obligated to compensate for any forms of damage I could receive from it."}
                  <br />{" "}
                  <span className="black-font">
                    {t("lang") === "EN" ? "이에 동의 합니다." : ""}
                  </span>
                </p>
              </div>
              {values.BankPolicyCheck ? (
                <div
                  ref={isMobileWidth ? floatRef : null}
                  className={
                    isMobileWidth ? "mo_transfer_submit" : "transfer_submit"
                  }
                >
                  <Button
                    type="submit"
                    variant="secondary"
                    preset="1"
                    onClick={() => {
                      setSubmitState("save");
                      setSaveState("TEMPORARY_SAVE");
                    }}
                  >
                    {t("temporarysaveBtn")}
                  </Button>
                  <span className="void-sec"></span>
                  <Button type="submit" variant="primary" preset="2">
                    {t("submit")}
                  </Button>
                </div>
              ) : (
                <div
                  ref={isMobileWidth ? floatRef : null}
                  className={
                    isMobileWidth ? "mo_transfer_submit" : "transfer_submit"
                  }
                >
                  <Button
                    type="button"
                    variant="secondary"
                    preset="2"
                    onClick={() => {
                      setSubmitState("save");
                      setSaveState("TEMPORARY_SAVE");
                    }}
                  >
                    {t("temporarysaveBtn")}
                  </Button>
                  <span className="void-sec"></span>
                  <Button type="button" variant="primary" preset="1">
                    {t("submit")}
                  </Button>
                </div>
              )}
            </form>
          </div>
        </SubLayout>
      </WhiteRoot>
    </Layout>
  );
};

export default TransferEdit;

const WhiteRoot = styled.div`
  & > div > div > .float_wrap > div {
    padding: 0 410px;
  }

  & .transfer-profile {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
  }

  & .mo-transfer-profile {
    clear: both;
    font-size: 18px;
    line-height: 38px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
  }

  & .form-inner {
    margin: 30px 0 60px;
    padding-top: 40px;
    border-top: 1px solid #222;
    border-bottom: 1px solid #ddd;
  }

  & .mo-form-inner {
    margin: 12px 0 60px;
    padding-top: 20px;
    border-top: 1px solid #222;
    border-bottom: 1px solid #ddd;
  }

  & .form-inner2 {
    margin: 30px 0 60px;
    border-top: 1px solid #222;
    border-bottom: 1px solid #ddd;
  }

  & .mo-form-inner2 {
    margin: 12px 0 60px;
    border-top: 1px solid #222;
    border-bottom: 1px solid #ddd;
  }

  & .bank-policy-check {
    background-color: #f9f9f9;
    padding: 30px;
    margin-bottom: 60px;
    & p {
      line-height: 30px;
      border-top: 1px solid #222;
      padding-top: 18px;
      padding-left: 12px;
      margin-top: 12px;
      text-indent: -12px;
    }
    & p .red-font {
      color: var(--Aff4b4b);
      font-weight: bold;
    }
    & p .black-font {
      font-weight: bold;
    }
  }

  & .mo-bank-policy-check {
    background-color: #f9f9f9;
    padding: 30px;
    margin-bottom: 30px;
    & p {
      line-height: 1.62;
      border-top: 1px solid #222;
      padding-top: 18px;
      padding-left: 12px;
      margin-top: 12px;
      text-indent: -12px;
      font-size: 13px;
    }
    & p .red-font {
      color: var(--Aff4b4b);
      font-weight: bold;
    }
    & p .black-font {
      font-weight: bold;
    }
    br {
      display: none;
    }
  }
  & .dim-input {
    background-color: #f9f9f9;
    color: #707070;
  }

  & .date-input {
    width: 100%;
    height: 65px;
    border: solid 1px #ddd;
    cursor: pointer;
    input::placeholder {
      color: #b2b2b2;
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
    input {
      width: 100%;
      height: 63px;
      background-color: #fff;
      line-height: 1.29;
      font-size: 16px;
      color: #000;
      padding-left: 20px;
    }

    & .date-img {
      position: absolute;
      margin-top: -57px;
      right: 31%;
      transform: translateX(96%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 49px;
      height: 49px;
      background-color: #444;
      cursor: pointer;
    }
  }

  & .mo-date-input {
    cursor: pointer;
    input::placeholder {
      color: #b2b2b2;
    }
    input {
      width: 100%;
      height: 48px;
      border: solid 1px #ddd;
      background-color: #fff;
      line-height: 1.29;
      font-size: 14px;
      color: #000;
      padding-left: 20px;
    }

    & .mo-date-img {
      position: absolute;
      margin-top: -43px;
      right: 72px;
      transform: translateX(96%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      background-color: #444;
      cursor: pointer;
    }
  }

  .react-datepicker {
    width: 220px;
  }
  .react-datepicker__year-wrapper {
    max-width: 300px;
  }
  .react-datepicker__navigation--previous {
    top: 8px;
  }
  .react-datepicker__navigation--next {
    top: 8px;
  }

  & .transfer_submit {
    width: 408px;
    margin: 0 auto;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }

  & .mo_transfer_submit {
    margin: 0 auto;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }

  & .input-box {
    margin: 16px 0 30px;
  }

  & .mo_input-box {
    margin: 10px 0 20px;
  }

  & .section-add {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;

    & .delete-sec {
      width: 60px;
      height: 35px;
      padding: 8px 12px;
      border: solid 1px #444;
      margin-right: 6px;
      color: #444;
    }

    & .add-sec {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 117px;
      height: 35px;
      padding: 8px 12px;
      background-color: #444;
      color: #fff;

      & img {
        margin-left: 4px;
      }
    }
  }

  & .mo-section-add {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    & .delete-sec {
      width: 30%;
      height: 48px;
      padding: 8px 12px;
      border: solid 1px #444;
      margin-right: 6px;
      color: #444;
    }

    & .add-sec {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70%;
      height: 48px;
      padding: 8px 12px;
      background-color: #444;
      color: #fff;

      & img {
        margin-left: 4px;
      }
    }
  }

  & .add-section-inner {
    border-bottom: 2px dashed #ddd;
    margin-top: 40px;

    &:last-of-type {
      border-bottom: 0;
    }
  }
`;

const TransferInner01 = styled.div`
  margin-top: 60px;

  & .void-sec {
    margin: 0 5px;
  }

  & .phonenumber {
    display: flex;
    align-items: center;

    & span {
      margin: 0 10px;
    }
  }

  & .zipcode {
    display: flex;
  }

  & .void-sec2 {
    height: 10px;
  }

  & .address-search {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
  }

  & .mo-address-search {
    width: 88px;
    height: 48px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 14px;
    margin-left: 8px;
  }

  & .attachment {
    display: flex;
    margin-bottom: 10px;
  }

  & .birth-box {
    display: flex;
  }
`;

const TransferInner02 = styled.div`
  & .hope-select,
  & .bank-select,
  & .time-select {
    display: flex;
    align-items: center;
  }

  & .hope-select > div,
  & .time-select > div {
    flex-grow: 1;
  }

  & .void-sec {
    margin: 0 5px;
  }

  & .mo-void-sec {
    margin: 0 2px;
  }

  & .bank-detail {
    position: relative;
    top: -20px;
    font-size: 16px;
    font-weight: normal;
    color: #707070;
  }

  & .mo-bank-detail {
    position: relative;
    top: -20px;
    font-size: 13px;
    font-weight: normal;
    color: #707070;
  }
`;

const TransferInner03 = styled.div``;
const TransferInner04 = styled.div`
  & .workday-box {
    display: flex;
    align-items: center;
  }

  & .void-sec {
    margin: 0 9px;
  }
`;
const TransferInner05 = styled.div``;
const TransferInner06 = styled.div`
  & .self-box {
    margin-top: -1px;
  }
`;
