import React, { useState, useEffect, useRef, createContext } from "react";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

const { kakao } = window;

export const MapContext = createContext();

/**
 * @file 카카오 지도 api
 * @param map 지도 상태 변수
 * @param isMount 페이지 마운트 상태 변수
 * @param isMapRendered 지도 렌더링 상태 변수
 */
const KakaoMap = ({ children }) => {
  const mapRef = useRef();
  const [map, setMap] = useState();
  const [isMount, setIsMount] = useState(false);
  const [isMapRendered, setIsMapRendered] = useState(false);
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  useEffect(() => {
    if (isMount) {
      // 지도를 표시할 div
      const container = mapRef.current;
      // 지도 옵션
      const options = {
        center: new kakao.maps.LatLng(37.565375, 126.980825), // 지도의 중심좌표
        level: 3, // 지도의 확대 레벨
      };

      const tempMap = new kakao.maps.Map(container, options); // 지도 생성

      // 마커가 표시될 위치
      const markerPosition = new kakao.maps.LatLng(37.565375, 126.980825);

      // 마커 생성
      const marker = new kakao.maps.Marker({
        position: markerPosition,
      });

      // 마커가 지도 위에 표시되도록 설정
      marker.setMap(tempMap);

      // 상태 저장
      setMap(tempMap);

      setIsMapRendered(true);
    }
  }, [isMount]);

  useEffect(() => {
    setIsMount(true);
  }, []);

  return (
    <MapContext.Provider value={map}>
      {isMapRendered ? children : null}
      <div
        className={isMobileWidth ? "mo_map_img" : "map_img"}
        id="map"
        ref={mapRef}
        style={
          isMobileWidth
            ? { width: "100%", height: "300px" }
            : { width: "100%", height: "550px" }
        }
      ></div>
    </MapContext.Provider>
  );
};

export default KakaoMap;
