/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ErrorMessage } from "@hookform/error-message";
import { ERROR_COLOR } from "utils/constants";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

const errorMessage = (isMobileWidth) => {
  return css`
    color: ${ERROR_COLOR};
    font-weight: 400;
    font-size: ${isMobileWidth ? "14px" : "16px"};
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
  `;
};

/**
 * @file Error Message Components
 */
function ErrorMessageComponent(props) {
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const { errors, name } = props;

  const root = errorMessage(isMobileWidth);
  return (
    <>
      {errors?.[name] && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={() => <p css={root}>{`${errors[name].message}`}</p>}
        />
      )}
    </>
  );
}

export default ErrorMessageComponent;
