import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import dayjs from "dayjs";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import Select from "components/Select/Select";

// containers
import Pagination from "containers/Pagination/Pagination";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// utils
import { LIST_OPTIONS, LIST_OPTIONS_EN } from "utils/constants";
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner05 from "assets/images/pc/img_banner05.png";

/**
 * @file 커리어 - 채용공고 페이지
 * @brief 채용공고 Form Validation
 * @author TW.Lee
 */
const Hiring = () => {
  const { t } = useTranslation();
  const btnRef = useRef([]); // 탭메뉴 버튼 DOM
  const { control } = useForm();
  const navigate = useNavigate();
  const [isStatus, setIsStatus] = useState("ALL"); // 탭메뉴 상태
  const [listOption, setListOption] = useState("created_at"); // select value 상태
  const [tableData, setTableData] = useState([]); // 게시판 리스트 데이터
  const [totalPages, setTotalPages] = useState(0); // 게시판 총 페이지 수
  const [page, setPage] = useState(1); // 게시판 페이지 번호
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const hiring01 = Hiring01Styled(t("lang"));
  const hiring03 = Hiring03Styled(t("lang"));

  const targetStorage = localStorage.getItem("accessToken")
    ? localStorage
    : sessionStorage;
  const userRole = targetStorage.getItem("role");
  const employeeRole = targetStorage.getItem("Erole");

  // 채용공고 리스트 요청
  useEffect(() => {
    if (isStatus === "ALL") {
      btnRef.current[0].className = "action";
      btnRef.current[1].className = "void";
      btnRef.current[2].className = "void";
    } else if (isStatus === "RECRUITING") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "action";
      btnRef.current[2].className = "void";
    } else if (isStatus === "DEADLINE") {
      btnRef.current[0].className = "void";
      btnRef.current[1].className = "void";
      btnRef.current[2].className = "action";
    }

    axios({
      method: "get",
      url: "/job_posts",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        state: isStatus,
        orderCol: listOption,
      },
    }).then((res) => {
      setTableData(res.data.result.data);
      setTotalCount(res.data.result.total_elements);
      setTotalPages(Math.ceil(res.data.result.total_elements / 10));
    });
  }, [page, limit, listOption, isStatus]);

  const pageArr = [];
  const pagination = () => {
    for (let i = 0; i < tableData.length; i += 10) {
      pageArr.push(tableData.slice(i, i + 10));
    }
    return pageArr;
  };
  pagination();

  if (isMobileWidth) {
    return (
      <Layout subFixed="true">
        <SubLayout
          banner={Banner05}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.hiring : PAGE_LAYOUT_EN.hiring
          }
        >
          <Container>
            <HiringMobileInner01>
              <div></div>
              <h3>
                <button
                  ref={(el) => (btnRef.current[0] = el)}
                  onClick={() => {
                    setIsStatus("ALL");
                    setPage(1);
                  }}
                >
                  {t("all")}
                </button>
                <span>&#183;</span>
                <button
                  ref={(el) => (btnRef.current[1] = el)}
                  onClick={() => {
                    setIsStatus("RECRUITING");
                    setPage(1);
                  }}
                >
                  {t("progress")}
                </button>
                <span>&#183;</span>
                <button
                  ref={(el) => (btnRef.current[2] = el)}
                  onClick={() => {
                    setIsStatus("DEADLINE");
                    setPage(1);
                  }}
                >
                  {t("closed")}
                </button>
              </h3>
            </HiringMobileInner01>
            <HiringMobileInner02>
              <Controller
                control={control}
                name="OwnerName"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder={t("byregistration")}
                    variant="quaternary"
                    value={
                      t("lang") === "EN"
                        ? LIST_OPTIONS.find((item) => item.value === listOption)
                        : LIST_OPTIONS_EN.find(
                            (item) => item.value === listOption
                          )
                    }
                    onChange={(obj) => setListOption(obj.value)}
                    options={
                      t("lang") === "EN" ? LIST_OPTIONS : LIST_OPTIONS_EN
                    }
                    w={t("lang") === "EN" ? "75" : "180"}
                    bgColor="#f9f9f9"
                  />
                )}
              />
            </HiringMobileInner02>
            <HiringMobileInner03>
              {tableData?.length > 0 ? (
                pageArr[page - 1].map((item, index) => {
                  const end = item.end_date.substr(0, 10);
                  const today = dayjs().format("YYYY-MM-DD");

                  const end2 = dayjs(item.end_date);
                  const today2 = dayjs(); // created_at으로 변경
                  const result = end2.diff(today2, "day", true);
                  const dDay = Math.floor(result);

                  return (
                    <HiringMobileContent01
                      key={index}
                      onClick={() =>
                        navigate(`/hiring/hiring-detail/${item.id}`, {
                          state: {
                            postId: item.id,
                            imageId: item.image,
                          },
                        })
                      }
                    >
                      <ul>
                        {item.state === "RECRUITING" ? (
                          <ContentMobileStatus01>
                            {t("progress")}
                          </ContentMobileStatus01>
                        ) : (
                          <ContentMobileStatus02>
                            {t("closed")}
                          </ContentMobileStatus02>
                        )}
                        <ContentMobileTitle className="title">
                          {item.title}
                        </ContentMobileTitle>
                        <ContentMobileRegDtm>
                          <span>{t("registrationdate")}</span>{" "}
                          {dayjs(item.created_at).format("YYYY-MM-DD")}
                        </ContentMobileRegDtm>
                        <ContentMobileDeadline>
                          <span>{t("deadline")}</span>{" "}
                          {(end < today && "-") ||
                            (end === today && "D-day") ||
                            (end > today && `D-${dDay}`)}
                        </ContentMobileDeadline>
                      </ul>
                    </HiringMobileContent01>
                  );
                })
              ) : (
                <HiringContent02>{t("noongoing")}</HiringContent02>
              )}
              {userRole === "EMPLOYEE" && (
                <div className="new-post">
                  <div onClick={() => navigate("/hiring/hiring-regist")}>
                    {t("newapplication")}
                  </div>
                </div>
              )}
            </HiringMobileInner03>
            {totalPages > 1 ? (
              <Pagination
                page={page}
                limit={limit}
                count={totalPages}
                list={tableData.length}
                onChangePage={setPage}
              />
            ) : (
              ""
            )}
          </Container>
        </SubLayout>
      </Layout>
    );
  } else {
    return (
      <Layout subFixed="true">
        <SubLayout
          banner={Banner05}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.hiring : PAGE_LAYOUT_EN.hiring
          }
        >
          <Container>
            <HiringInner01 css={hiring01}>
              <div></div>
              <h3>
                <button
                  ref={(el) => (btnRef.current[0] = el)}
                  onClick={() => {
                    setIsStatus("ALL");
                    setPage(1);
                  }}
                >
                  {t("all")}
                </button>
                <span>&#183;</span>
                <button
                  ref={(el) => (btnRef.current[1] = el)}
                  onClick={() => {
                    setIsStatus("RECRUITING");
                    setPage(1);
                  }}
                >
                  {t("progress")}
                </button>
                <span>&#183;</span>
                <button
                  ref={(el) => (btnRef.current[2] = el)}
                  onClick={() => {
                    setIsStatus("DEADLINE");
                    setPage(1);
                  }}
                >
                  {t("closed")}
                </button>
              </h3>
              <Controller
                control={control}
                name="OwnerName"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder={t("byregistration")}
                    variant="quaternary"
                    w={t("lang") === "EN" ? "" : "220"}
                    value={
                      t("lang") === "EN"
                        ? LIST_OPTIONS.find((item) => item.value === listOption)
                        : LIST_OPTIONS_EN.find(
                            (item) => item.value === listOption
                          )
                    }
                    onChange={(obj) => setListOption(obj.value)}
                    options={
                      t("lang") === "EN" ? LIST_OPTIONS : LIST_OPTIONS_EN
                    }
                  />
                )}
              />
            </HiringInner01>
            <HiringInner02>
              <ul>
                <GuideLineNo>NO.</GuideLineNo>
                <GuideLineStatus>{t("status")}</GuideLineStatus>
                <GuideLineTitle>{t("title")}</GuideLineTitle>
                <GuideLineRegDtm>{t("registrationdate")}</GuideLineRegDtm>
                <GuideLineDeadline>{t("deadline")}</GuideLineDeadline>
              </ul>
            </HiringInner02>
            <HiringInner03 css={hiring03}>
              {tableData?.length > 0 ? (
                pageArr[page - 1].map((item, index) => {
                  const end = item.end_date.substr(0, 10);
                  const today = dayjs().format("YYYY-MM-DD");

                  const dDay = Math.floor(
                    dayjs(item.end_date).diff(dayjs(), "day", true)
                  );

                  return (
                    <HiringContent01
                      key={index}
                      onClick={() =>
                        navigate(`/hiring/hiring-detail/${item.id}`, {
                          state: {
                            postId: item.id,
                            imageId: item.image,
                          },
                        })
                      }
                    >
                      <ul>
                        <ContentNo>
                          {(totalCount - (index + (page - 1) * 10))
                            .toString()
                            .padStart(2, "0")}
                        </ContentNo>
                        {item.state === "RECRUITING" ? (
                          <ContentStatus01>{t("progress")}</ContentStatus01>
                        ) : (
                          <ContentStatus02>{t("closed")}</ContentStatus02>
                        )}
                        <ContentTitle className="title">
                          {item.title}
                        </ContentTitle>
                        <ContentRegDtm>
                          {dayjs(item.created_at).format("YYYY-MM-DD")}
                        </ContentRegDtm>
                        <ContentDeadline>
                          {(end < today && "-") ||
                            (end === today && "D-day") ||
                            (end > today && `D-${dDay}`)}
                        </ContentDeadline>
                      </ul>
                    </HiringContent01>
                  );
                })
              ) : (
                <HiringContent02>{t("noongoing")}</HiringContent02>
              )}
              {userRole === "EMPLOYEE" && employeeRole === "ADMIN" && (
                <div className="new-post">
                  <div onClick={() => navigate("/hiring/hiring-regist")}>
                    {t("newapplication")}
                  </div>
                </div>
              )}
            </HiringInner03>
            {totalPages > 1 ? (
              <Pagination
                page={page}
                limit={limit}
                count={totalPages}
                list={tableData.length}
                onChangePage={setPage}
              />
            ) : (
              ""
            )}
          </Container>
        </SubLayout>
      </Layout>
    );
  }
};

export default Hiring;

const Hiring01Styled = (lang) => {
  return css`
    & h3 {
      margin-left: ${lang === "EN" ? "60px" : "170px"};
      & span {
        font-size: 35px;
        margin: 0 33px;
        color: #b2b2b2;
      }

      & button {
        font-size: 26px;
        font-weight: bold;
        color: #707070;
      }
    }
  `;
};

const Hiring03Styled = (lang) => {
  return css`
    & .new-post {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & div {
        width: ${lang === "EN" ? "88px" : "184px"};
        margin-top: 12px;
        padding: 12px 16px;
        background-color: #444;
        color: #fff;
        cursor: pointer;
      }
    }
  `;
};

const Container = styled.div`
  padding-bottom: 100px;
`;

const HiringInner01 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 60px;

  & .action {
    color: #c09260;
  }
`;

const HiringInner02 = styled.div`
  border-top: 1px solid #222;
  margin-top: 30px;
  padding-top: 12px;

  & ul {
    display: flex;
    background-color: #f9f9f9;
    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      font-weight: bold;
    }
  }
`;

const HiringInner03 = styled.div``;

const HiringContent01 = styled.div`
  border-bottom: 1px solid #ddd;
  cursor: pointer;

  &:hover {
    background-color: rgba(192, 146, 96, 0.1);

    & .title {
      text-decoration: underline;
      color: #c09260;
    }
  }

  & ul {
    display: flex;

    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 81px;
    }
  }
`;

const HiringContent02 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 820px;
  font-size: 20px;
  border-bottom: 1px solid #ddd;
`;

const GuideLineNo = styled.li`
  width: 82px;
`;

const GuideLineStatus = styled.li`
  width: 152px;
`;

const GuideLineTitle = styled.li`
  width: 568px;
`;

const GuideLineRegDtm = styled.li`
  width: 199px;
`;

const GuideLineDeadline = styled.li`
  width: 199px;
`;

const ContentNo = styled.li`
  width: 82px;
`;

const ContentStatus01 = styled.li`
  width: 152px;
  font-weight: bold;
  color: #c09260;
`;

const ContentStatus02 = styled.li`
  width: 152px;
  font-weight: bold;
  color: #b2b2b2;
`;

const ContentTitle = styled.li`
  width: 568px;
`;

const ContentRegDtm = styled.li`
  width: 199px;
`;

const ContentDeadline = styled.li`
  width: 199px;
`;

const HiringMobileInner01 = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  & h3 {
    display: flex;
    justify-content: center;
    & span {
      font-size: 35px;
      margin: 0 12px;
      color: #b2b2b2;
    }

    & button {
      font-size: 20px;
      font-weight: bold;
      color: #707070;
    }
  }

  & .action {
    color: #c09260;
  }
`;

const HiringMobileInner02 = styled.div`
  background-color: #f9f9f9;
  border-top: 1px solid #222;
  margin-top: 10px;
  padding: 12px 12px 12px;

  display: flex;
  justify-content: end;
`;

const HiringMobileInner03 = styled.div`
  & .new-post {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & div {
      margin-top: 12px;
      padding: 10px 8px;
      font-size: 14px;
      background-color: #444;
      color: #fff;
      cursor: pointer;
    }
  }
`;

const HiringMobileContent01 = styled.div`
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  padding-left: 10px;

  &:hover {
    background-color: rgba(192, 146, 96, 0.1);

    & .title {
      text-decoration: underline;
      color: #c09260;
    }
  }

  & ul {
    padding: 20px 0 40px 0;
  }
`;

const ContentMobileStatus01 = styled.li`
  width: 152px;
  font-size: 14px;
  font-weight: bold;
  color: #c09260;
`;

const ContentMobileStatus02 = styled.li`
  width: 152px;
  font-size: 14px;
  font-weight: bold;
  color: #b2b2b2;
`;

const ContentMobileTitle = styled.li`
  width: 568px;
  font-size: 18px;
`;

const ContentMobileRegDtm = styled.li`
  float: left;
  font-size: 14px;
  & span {
    font-weight: bold;
  }
`;

const ContentMobileDeadline = styled.li`
  float: left;
  font-size: 14px;
  margin-left: 20px;
  & span {
    font-weight: bold;
  }
`;
