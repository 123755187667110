import * as yup from "yup";

export default yup.object().shape({
  mypageNewPassword: yup
    .string()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,15}[^\s]*$/,
      "Please enter 8-15 characters with a combination of uppercase / lowercase / numbers / special characters."
    ),
  mypageNewPasswordCheck: yup
    .string()
    .oneOf(
      [yup.ref("mypageNewPassword"), null],
      "Please enter the same characters as your password."
    ),
  verifiCode: yup
    .string()
    .required(
      "Please enter the verification code you received via Text message."
    ),
});
