/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, forwardRef } from "react";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

const rootCss = (props, isMobileWidth) => {
  return css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: #fff;
    width: ${props.fullWidth ? "100%" : "115px"};
    height: ${isMobileWidth ? "48px" : "65px"};

    & span {
      position: absolute;
      font-size: ${isMobileWidth ? "14px" : "16px"};
      font-weight: normal;
      margin-right: 15px;
    }
  `;
};

const inputCss = (props, isMobileWidth) => {
  return css`
    width: 100%;
    height: ${isMobileWidth ? "48px" : "65px"};
    border: solid 1px #ddd;
    font-size: ${isMobileWidth ? "14px" : "16px"};
    padding-left: 20px;

    &::placeholder {
      font-size: ${isMobileWidth ? "14px" : "16px"};
      color: #b2b2b2;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  `;
};

/**
 * @file Textbox Components
 */
const Birth = forwardRef((props, ref) => {
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const { type, children, fullWidth, ...rest } = props;

  const root = rootCss(props, isMobileWidth);
  const input = inputCss(props, isMobileWidth);

  const [currentLength, setCurrentLength] = useState(0);

  const handleChange = (e) => {
    if (e.target.maxLength > 0) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }

    props.onChange && props.onChange(e);
    setCurrentLength(e.target.value.length);
  };

  return (
    <div>
      <label css={root} className="component-birth">
        <input
          {...rest}
          css={input}
          ref={ref}
          onChange={handleChange}
          type={type}
        />
        <span>{children}</span>
      </label>
    </div>
  );
});

export default Birth;
