import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import Checkbox from "components/Checkbox/Checkbox";

// hooks
import useAlert from "hooks/useAlert";

// store
import { isMobileWidthAtom } from "store/ui/atom";
import useAuthActions from "store/auth/action";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";
import vaildationSchema from "utils/validation/loginValidationSchema";
import vaildationSchemaEn from "utils/validation/loginValidationSchemaEn";

// assets
import Banner01 from "assets/images/pc/img_banner01.png";

const defaultValues = {
  userId: "",
  userPw: "",
  rememberId: "",
};

/**
 * @file 로그인 페이지
 * @author TW.Lee
 * @param handleSearchInfo 아이디/비밀번호 찾기 함수
 * @param setSearchState 아이디/비밀번호 상태 값
 * @param setSearchInfo 아이디/비밀번호 찾기 컴포넌트 표시/비표시
 */
const LoginMain = () => {
  const { t } = useTranslation();
  const authActions = useAuthActions();
  const navigate = useNavigate();
  const { noti } = useAlert();
  const [autoLogin, setAutoLogin] = useState(false);
  const [isSaveId, setIsSaveId] = useState(false);
  const [submitState, setSubmitState] = useState("schemako");
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const saveId = localStorage.getItem("id");

  const UI = {
    schemako: vaildationSchema,
    schemaen: vaildationSchemaEn,
  };

  const {
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(UI[submitState]),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (t("lang") === "EN") {
      setSubmitState("schemako");
    } else {
      setSubmitState("schemaen");
    }
  }, [t("lang")]);

  useEffect(() => {
    if (saveId !== null) {
      setValue("userId", saveId || "");
      setIsSaveId(true);
    }
  }, [saveId]);

  // useMutaion(mutationFn, options)
  const login = useMutation(
    // mutationFn - 생성/수정/삭제 API를 호출 함수 할당
    () => {
      return authActions.login(
        {
          userEmail: values.userId,
          userPassword: values.userPw,
          rememberId: isSaveId,
          isAutoLogin: autoLogin,
        },
        () => {
          navigate("/home", { replace: true });
        }
      );
    },
    // options - 성공, 실패 등 다양한 콜백 함수를 할당
    {
      onSuccess: () => {},
      onError: async (err) => {
        if (err.response.data.message === "signname is required") {
          if (t("lang") === "EN") {
            noti("가입된 회원정보가 없습니다.\n회원가입을 해주세요.");
          } else {
            noti(
              "No registration information has been found.\nPlease register as a member first.",
              "Confirm"
            );
          }
        }
        if (err.response.data.message === "invalid password") {
          if (t("lang") === "EN") {
            noti("비밀번호가 틀렸습니다.");
          } else {
            noti(
              "There is no registered member information.\nPlease register as a member."
            );
          }
        }
        if (err.response.data.message === "invalid signname") {
          if (t("lang") === "EN") {
            noti("가입된 회원정보가 없습니다.\n회원가입을 해주세요.");
          } else {
            noti(
              "No registration information has been found.\nPlease register as a member first.",
              "Confirm"
            );
          }
        }
      },
    }
  );

  return (
    <Layout subFixed="true">
      <SubLayout
        banner={Banner01}
        pageText={t("lang") === "EN" ? PAGE_LAYOUT.login : PAGE_LAYOUT_EN.login}
      >
        <LoginInner01>
          <h4 className={isMobileWidth ? "mo-login-header" : "login-header"}>
            {t("lang") !== "EN" && t("welcome")}
            <span>{t("lang") === "EN" ? t("nuri") : `${t("nuri")}!`}</span>
            {t("lang") === "EN" && t("welcome")}
          </h4>
          <div className={isMobileWidth ? "mo-login-form" : "login-form"}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(
                  () => {
                    // useMutaion 반환하는 객체의 메서드인 mutate로 mutationFn를 호출. mutate 인자는 mutationFn의 매개변수로 전달
                    login.mutate();
                  },
                  (err) => {
                    const keys = ["userId", "userPw"];

                    for (const key of keys) {
                      if (err[key]) {
                        if (t("lang") === "EN") {
                          noti(err[key].message, "확인");
                        } else {
                          noti(err[key].message, "Confirm");
                        }
                        setFocus(key);
                        break;
                      }
                    }
                  }
                )();
              }}
            >
              <div className="input-box">
                <Controller
                  control={control}
                  name="userId"
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="inp-item"
                      type="text"
                      placeholder={
                        t("lang") === "EN"
                          ? "이메일 주소 (아이디)"
                          : "E-mail (ID)"
                      }
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="input-box">
                <Controller
                  control={control}
                  name="userPw"
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="inp-item"
                      type="password"
                      placeholder={t("lang") === "EN" ? "비밀번호" : "Password"}
                      fullWidth
                    />
                  )}
                />
              </div>
              <div
                className={isMobileWidth ? "mo-login-option" : "login-option"}
              >
                <div
                  className={isMobileWidth ? "mo-checkbox-box" : "checkbox-box"}
                >
                  <Checkbox
                    checked={autoLogin}
                    onChange={(e) => setAutoLogin(e.target.checked)}
                  >
                    {t("autologin")}
                  </Checkbox>
                  <Checkbox
                    checked={isSaveId}
                    onChange={(e) => setIsSaveId(e.target.checked)}
                  >
                    {t("rememid")}
                  </Checkbox>
                </div>
                {!isMobileWidth && (
                  <div className="search-info">
                    <span onClick={() => navigate("/login/searchId")}>
                      {t("findid")}
                    </span>
                    <span onClick={() => navigate("/login/searchPw")}>
                      {t("findpw")}
                    </span>
                  </div>
                )}
              </div>
              <div>
                <Button type="submit" variant="primaryHover" fullWidth>
                  {t("login")}
                </Button>
                {isMobileWidth && (
                  <div className="mo-search-info">
                    <span onClick={() => navigate("/login/searchId")}>
                      {t("findid")}
                    </span>
                    <span onClick={() => navigate("/login/searchPw")}>
                      {t("findpw")}
                    </span>
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className={isMobileWidth ? "mo-signup-move" : "signup-move"}>
            <Button
              variant="secondaryHover"
              fullWidth
              onClick={() => navigate("/signup")}
            >
              {t("sign")}
            </Button>
          </div>
        </LoginInner01>
      </SubLayout>
    </Layout>
  );
};

export default LoginMain;

const LoginInner01 = styled.div`
  max-width: 500px;
  margin: 0 auto;

  & .login-header {
    text-align: center;
    font-size: 26px;
    padding: 100px 0 40px;
    font-weight: normal;
    & span {
      font-weight: bold;
    }
  }

  & .mo-login-header {
    text-align: center;
    font-size: 20px;
    padding: 40px 0 20px;
    font-weight: normal;
    & span {
      font-weight: bold;
    }
  }

  & .login-form {
    margin-bottom: 40px;
  }

  & .mo-login-form {
    margin-bottom: 20px;
  }

  & .input-box {
    margin-bottom: 10px;
  }

  & .login-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }

  & .mo-login-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  & .checkbox-box {
    display: flex;
    & label {
      margin-right: 30px;
    }
  }

  & .mo-checkbox-box {
    display: flex;
    & label {
      margin-right: 20px;
    }
  }

  & .search-info {
    font-size: 16px;
    color: #999;

    & span {
      cursor: pointer;
    }

    & span:first-of-type::after {
      content: "|";
      color: var(--border-color2);
      opacity: 50%;
      font-size: 5px;
      font-weight: bold;
      margin: 0 8px;
      vertical-align: middle;
    }
  }

  & .mo-search-info {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #999;
    margin-top: 16px;

    & span {
      cursor: pointer;
    }

    & span:first-of-type::after {
      content: "|";
      color: var(--border-color2);
      opacity: 50%;
      font-size: 5px;
      font-weight: bold;
      margin: 0 8px;
      vertical-align: middle;
    }
  }

  & .signup-move {
    border-top: 1px solid #ddd;
    padding: 40px 0 100px;
  }

  & .mo-signup-move {
    border-top: 1px solid #ddd;
    padding: 20px 0 50px;
  }
`;
