import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import Button from "components/Button/Button";

// hooks
import useSave from "hooks/useSave";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner05 from "assets/images/pc/img_banner05.png";

const HiringDetail = () => {
  const { t } = useTranslation();
  const { isSaved } = useSave();
  const location = useLocation();
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const { REACT_APP_API } = process.env;

  const targetStorage = localStorage.getItem("accessToken")
    ? localStorage
    : sessionStorage;
  const userRole = targetStorage.getItem("role");
  const employeeRole = targetStorage.getItem("Erole");

  // 게시물 불러오기
  useEffect(() => {
    axios({
      method: "get",
      url: `/job_posts/${location.state?.postId}`,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setPosts(res.data.result);
    });
  }, [location]);

  // 게시물 삭제
  const deletePost = async (args) => {
    await axios.delete(`/job_posts/${args}`).then(() => {
      navigate("/hiring", { replace: true });
    });
  };

  if (isMobileWidth) {
    return (
      <Layout subFixed="true">
        <SubLayout
          banner={Banner05}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.hiring : PAGE_LAYOUT_EN.hiring
          }
        >
          <Container>
            <HiringDetailMobileInner>
              <div className="detail-section">
                <div className="detail-title">
                  {posts?.state === "RECRUITING" && (
                    <DetailMobileStatue>{t("progress")}</DetailMobileStatue>
                  )}
                  {posts?.state === "DEADLINE" && (
                    <DetailMobileStatueClosed>
                      {t("closed")}
                    </DetailMobileStatueClosed>
                  )}
                  <DetailMobileTitle>{posts?.title}</DetailMobileTitle>
                  <div className="detail-date">
                    <DetailMobileRegDtm>
                      <span className="detail-txt">
                        {t("registrationdate")}
                      </span>
                      <span>
                        {dayjs(posts?.created_at).format("YYYY-MM-DD")}
                      </span>
                    </DetailMobileRegDtm>
                    <DetailMobileDeadline>
                      <span className="detail-txt">{t("deadline")}</span>
                      <span>{posts?.end_date?.substr(0, 10)}</span>
                    </DetailMobileDeadline>
                  </div>
                </div>
                <div className="detail-contents">
                  <div className="detail-img">
                    <img
                      src={
                        posts?.job_post_file
                          ? `${REACT_APP_API}/files/${posts?.job_post_file.id}`
                          : ""
                      }
                    />
                  </div>
                  <div>{posts?.content}</div>
                </div>
                {userRole === "EMPLOYEE" && (
                  <div className="detail-config">
                    <ConfigMobileUpdate
                      onClick={() =>
                        navigate(
                          `/hiring/hiring-edit/${location.state?.postId}`,
                          {
                            state: {
                              postId: location.state?.postId,
                              data: posts,
                            },
                          }
                        )
                      }
                    >
                      {t("modify")}
                    </ConfigMobileUpdate>
                    <ConfigMobileRemove
                      onClick={() => {
                        const isDelete = isSaved(
                          t("lang") === "EN"
                            ? "해당 게시글을 삭제하시겠습니까?"
                            : "Delete the post?"
                        );
                        const getData = () => {
                          isDelete.then((result) => {
                            if (result === true) {
                              deletePost(location.state?.postId);
                            }
                          });
                        };
                        getData();
                      }}
                    >
                      {t("delete")}
                    </ConfigMobileRemove>
                  </div>
                )}
              </div>
              <div className="detail_btn">
                <Button
                  variant="secondaryHover"
                  onClick={() => navigate("/hiring", { replace: true })}
                >
                  {t("backtolist")}
                </Button>
                <span className="void-sec"></span>
                {posts.state === "RECRUITING" && (
                  <Button
                    variant="primaryHover"
                    onClick={() =>
                      navigate("/transfer", {
                        state: {
                          jobTitleId: location.state?.postId,
                          jobTitle: posts?.title,
                        },
                      })
                    }
                  >
                    {t("apply")}
                  </Button>
                )}
              </div>
            </HiringDetailMobileInner>
          </Container>
        </SubLayout>
      </Layout>
    );
  } else {
    return (
      <Layout subFixed="true">
        <SubLayout
          banner={Banner05}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.hiring : PAGE_LAYOUT_EN.hiring
          }
        >
          <Container>
            <HiringDetailInner>
              <div className="detail-section">
                <div className="detail-title">
                  {posts?.state === "RECRUITING" && (
                    <DetailStatue>{t("progress")}</DetailStatue>
                  )}
                  {posts?.state === "DEADLINE" && (
                    <DetailStatueClosed>{t("closed")}</DetailStatueClosed>
                  )}
                  <DetailTitle>{posts?.title}</DetailTitle>
                  <div className="detail-date">
                    <DetailRegDtm>
                      <span className="detail-txt">
                        {t("registrationdate")}
                      </span>
                      <span>
                        {dayjs(posts?.created_at).format("YYYY-MM-DD")}
                      </span>
                    </DetailRegDtm>
                    <DetailDeadline>
                      <span className="detail-txt">{t("deadline")}</span>
                      <span>{posts?.end_date?.substr(0, 10)}</span>
                    </DetailDeadline>
                  </div>
                </div>
                <div className="detail-contents">
                  <div className="detail-img">
                    <img
                      src={
                        posts?.job_post_file
                          ? `${REACT_APP_API}/files/${posts?.job_post_file.id}`
                          : ""
                      }
                    />
                  </div>
                  <div>{posts?.content}</div>
                </div>
                {userRole === "EMPLOYEE" && employeeRole === "ADMIN" && (
                  <div className="detail-config">
                    <ConfigUpdate
                      onClick={() =>
                        navigate(
                          `/hiring/hiring-edit/${location.state?.postId}`,
                          {
                            state: {
                              postId: location.state?.postId,
                              data: posts,
                            },
                          }
                        )
                      }
                    >
                      {t("modify")}
                    </ConfigUpdate>
                    <ConfigRemove
                      onClick={() => {
                        const isDelete = isSaved(
                          t("lang") === "EN"
                            ? "해당 게시글을 삭제하시겠습니까?"
                            : "Delete the post?",
                          "No",
                          "Yes"
                        );
                        const getData = () => {
                          isDelete.then((result) => {
                            if (result === true) {
                              deletePost(location.state?.postId);
                            }
                          });
                        };
                        getData();
                      }}
                    >
                      {t("delete")}
                    </ConfigRemove>
                  </div>
                )}
              </div>
              <div className="detail_btn">
                <Button
                  variant="secondaryHover"
                  onClick={() => navigate("/hiring", { replace: true })}
                >
                  {t("backtolist")}
                </Button>
                <span className="void-sec"></span>
                {posts.state === "RECRUITING" && (
                  <Button
                    variant="primaryHover"
                    onClick={() =>
                      navigate("/transfer", {
                        state: {
                          jobTitleId: location.state?.postId,
                          jobTitle: posts?.title,
                        },
                      })
                    }
                  >
                    {t("apply")}
                  </Button>
                )}
              </div>
            </HiringDetailInner>
          </Container>
        </SubLayout>
      </Layout>
    );
  }
};

export default HiringDetail;

const Container = styled.div`
  padding-bottom: 100px;
`;

const HiringDetailInner = styled.div`
  & .detail-section {
    margin: 60px 0;
    border-top: 1px solid #222;
    border-bottom: 1px solid #ddd;
  }

  & .mo-detail-section {
    margin: 40px 0;
    border-top: 1px solid #222;
    border-bottom: 1px solid #ddd;
  }

  & .detail-title {
    padding: 40px 30px;
    border-bottom: 1px solid #ddd;
  }

  & .mo-detail-title {
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
  }

  & .detail-date {
    display: flex;

    & span {
      font-size: 16px;
      font-weight: normal;
    }
  }

  & .detail-txt {
    color: #b2b2b2;
    margin-right: 10px;
  }

  & .detail-contents {
    margin: 0 30px 120px 30px;
    font-size: 18px;
    font-weight: normal;
  }

  & .detail-img {
    margin: 40px 0;
    & img {
      width: 100%;
    }
  }

  & .detail-config {
    display: flex;
    justify-content: end;
    margin-bottom: 18px;
    margin-right: 30px;
  }

  & .detail_btn {
    width: 408px;
    margin: 0 auto;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;

const DetailStatue = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #c09260;
`;
const DetailStatueClosed = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #b2b2b2;
`;
const DetailTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  color: #000;
  margin: 12px 0 20px;
`;
const DetailRegDtm = styled.div`
  margin-right: 20px;
`;
const DetailDeadline = styled.div``;

const ConfigUpdate = styled.button`
  padding: 8px 12px;
  border: solid 1px #444;
  margin-right: 6px;
`;
const ConfigRemove = styled.button`
  padding: 8px 12px;
  border: solid 1px #444;
  background-color: #444;
  color: #fff;
`;

const HiringDetailMobileInner = styled.div`
  & .detail-section {
    margin: 40px 0;
    border-top: 1px solid #222;
    border-bottom: 1px solid #ddd;
  }

  & .detail-title {
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
  }

  & .detail-date {
    display: flex;

    & span {
      font-size: 14px;
      font-weight: normal;
    }
  }

  & .detail-txt {
    color: #b2b2b2;
    margin-right: 10px;
  }

  & .detail-contents {
    margin: 0 0 120px 0;
    font-size: 14px;
    font-weight: normal;
  }

  & .detail-img {
    margin: 20px 0;
    & img {
      width: 100%;
    }
  }

  & .detail-config {
    display: flex;
    justify-content: end;
    margin-bottom: 18px;
  }

  & .detail_btn {
    margin: 0 auto;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }
`;

const DetailMobileStatue = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #c09260;
`;
const DetailMobileStatueClosed = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #b2b2b2;
`;
const DetailMobileTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin: 8px 0 12px;
`;
const DetailMobileRegDtm = styled.div`
  margin-right: 20px;
`;
const DetailMobileDeadline = styled.div``;

const ConfigMobileUpdate = styled.button`
  padding: 8px 12px;
  border: solid 1px #444;
  margin-right: 6px;
  font-size: 14px;
`;
const ConfigMobileRemove = styled.button`
  padding: 8px 12px;
  border: solid 1px #444;
  background-color: #444;
  color: #fff;
  font-size: 14px;
`;
