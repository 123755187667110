import * as yup from "yup";

/**
 * @file 일반/파트타이머 유효성 검사 및 입력 체크
 * @author TW.Lee
 */
export default yup.object().shape({
  NormalEmail: yup
    .string()
    .required("Please enter your E-mail.")
    .matches(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      "Incorrect Email address. Please try it again."
    ),
  NormalPassword: yup
    .string()
    .required(
      "Please enter 8-15 characters with a combination of uppercase / lowercase / numbers / special characters."
    )
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,15}[^\s]*$/,
      "Please enter 8-15 characters with a combination of uppercase / lowercase / numbers / special characters."
    ),
  NormalPasswordCheck: yup
    .string()
    .oneOf(
      [yup.ref("NormalPassword"), null],
      "Please enter the same characters as your password."
    )
    .required("Please enter the same characters as your password."),
  userPhone2: yup.string().required("Complete your Verify your identity."),
  verifiCode: yup
    .string()
    .required(
      "Please enter the verification code you received via Text message."
    ),
  Address1: yup.string().required("Please enter your Zip Code."),
  Address2: yup.string().required("Please enter your Address."),
  Address3: yup.string().required("Please enter your detailed address."),
  PolicyCheck: yup
    .string()
    .required(
      "To sign up for membership, you must agree to the Terms of Service and Privacy Policy."
    ),
});
