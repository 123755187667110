import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./translation.en.json";
import translationKO from "./translation.ko.json";

const resources = {
  en: {
    translations: translationEN,
  },
  ko: {
    translations: translationKO,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lan: "ko",
    fallbackLng: "ko",
    //debug: true, // 로그
    defaultNS: "translations",
    ns: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });
