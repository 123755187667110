import { useSetRecoilState, useResetRecoilState } from "recoil";

// api
import authApi from "api/auth";

// store
import {
  accessTokenAtom,
  refreshTokenAtom,
  userIdAtom,
  userRoleAtom,
} from "store/auth/atom";

const useAuthActions = () => {
  // 상태값 업데이트
  const setAccessToken = useSetRecoilState(accessTokenAtom);
  const setRefreshToken = useSetRecoilState(refreshTokenAtom);
  const setUserId = useSetRecoilState(userIdAtom);
  // 상태값 초기화
  const resetAccessToken = useResetRecoilState(accessTokenAtom);
  const resetRefreshToken = useResetRecoilState(refreshTokenAtom);
  const resetUserId = useResetRecoilState(userIdAtom);
  const resetUserRole = useResetRecoilState(userRoleAtom);

  // 사이트 실행 시 자동 로그인
  const loginAuto = async () => {
    try {
      // 로컬에 있으면 로컬 리턴, 없으면 세션 리턴
      const targetStorage = localStorage.getItem("accessToken")
        ? localStorage
        : sessionStorage;
      // 토큰 저장
      const accessToken = targetStorage.getItem("accessToken");
      const refreshToken = targetStorage.getItem("refreshToken");

      // 토큰 상태값 업데이트
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
    } catch (err) {
      throw err;
    }
  };

  // 로그인 폼을 통한 로그인
  const login = async (
    { userEmail, userPassword, rememberId, isAutoLogin },
    onSuccess
  ) => {
    try {
      // 자동로그인 체크 시 로컬 저장. 기본은 세션.
      const targetStorage = isAutoLogin ? localStorage : sessionStorage;

      // 로그인 요청
      const { data, status } = await authApi.login({
        userEmail,
        userPassword,
      });

      // 로그인 정보 없을시 에러 상태 리턴
      if (status !== 200) {
        return status;
      }

      // 타겟 스토리지에 토큰값 저장
      targetStorage.setItem("accessToken", data.access_token);
      targetStorage.setItem("refreshToken", data.refresh_token);

      // 상태값 업데이트
      setAccessToken(data.access_token);
      setRefreshToken(data.refresh_token);

      // 아이디 저장 체크 시 상태값 업데이트
      if (rememberId) {
        localStorage.setItem("id", userEmail);
        setUserId(userEmail);
      } else {
        localStorage.removeItem("id");
        resetUserId();
      }

      if (onSuccess) {
        onSuccess();
      }

      return data;
    } catch (err) {
      throw err;
    }
  };

  // 로그아웃
  const logout = async () => {
    const targetStorage = localStorage.getItem("accessToken")
      ? localStorage
      : sessionStorage;
    targetStorage.removeItem("accessToken");
    targetStorage.removeItem("refreshToken");
    targetStorage.removeItem("role");
    resetAccessToken();
    resetRefreshToken();
    resetUserRole();
  };

  return {
    login,
    logout,
    loginAuto,
  };
};
export default useAuthActions;
