import React, { useEffect, useState, useRef, useCallback } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// hooks
import useAlert from "hooks/useAlert";

// store
import useAuthActions from "store/auth/action";
import { userInfoAtom } from "store/auth/atom";
import { isMobileWidthAtom } from "store/ui/atom";

// assets
import LogoKoSVG from "assets/images/pc/logo_kr.svg";
import LogoEnSVG from "assets/images/pc/logo_en.svg";
import LinkSVG from "assets/images/pc/icon_link.svg";
import MenuSVG from "assets/images/mo/icon_menu_W.svg";
import MenuBSVG from "assets/images/mo/icon_menu_B.svg";
import IconXSVG from "assets/images/mo/icon_x.svg";
import BtnUpdownOnSVG from "assets/images/mo/btn_updown_on.svg";

/**
 * @file Header Layout
 * @param isOpenMenu 메뉴 오픈 상태
 * @param isOverMenu 마우스 오버 상태
 * @param isHeaderBg 헤더 백그라운드 색상 상태
 * @param isHeaderBorder 헤더 테두리 상태
 */
const Header = ({ subFixed }) => {
  const { noti } = useAlert();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { REACT_APP_NURIDA_URL } = process.env;

  const headerRef = useRef(); // header DOM
  const logoRef = useRef(); // logo DOM
  const menuBtnRef = useRef(); // menuBtn DOM
  const menuRef = useRef([]); // menu DOM
  const subMenuRef = useRef([]); // sub menu DOM

  const [isOpenMenu, setIsOpenMenu] = useState(false); // 메뉴 오픈 상태
  const [isOverMenu, setIsOverMenu] = useState(false); // 마우스 오버 상태
  const [isMoOpenMenu, setIsMoOpenMenu] = useState(false); // 모바일 메뉴 오픈 상태
  const [isHeaderBg, setIsHeaderBg] = useState("rgba(0, 0, 0, 0)"); // 기본 헤더 색상 지정
  const [isHeaderBorder, setIsHeaderBorder] = useState("0"); // 기본 헤더 보더 지정

  const [visible, setVisible] = useState(false); // 스크롤을 위로 올리면 true, 내리면 false
  const [scrollY, setScrollY] = useState(window.pageYOffset); // 초기 수직 스크롤 값 = 0

  const [active, setActive] = useState(null); // 모바일 메뉴 상태
  const [isLang, setIsLang] = useState(t("lang")); // 모바일 메뉴 상태

  const headerCss = headerStyled(isLang);
  const DropMenusCss = DropMenus(isLang);
  const userInfo = useRecoilValue(userInfoAtom);
  // const userRole = useRecoilValue(userInfoAtom);
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const authActions = useAuthActions();

  const targetStorage = localStorage.getItem("accessToken")
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem("accessToken");
  const userRole = targetStorage.getItem("role");

  const changelanguageToKo = () => i18n.changeLanguage("ko");
  const changelanguageToEn = () => i18n.changeLanguage("en");

  // fixed 스타일
  const fixedSet = () => {
    setScrollY(window.pageYOffset);
    headerRef.current.style.position = "fixed";
    headerRef.current.style.backgroundColor = "rgba(149, 128, 106, 0.8)";
    if (isLang === "EN") {
      logoRef.current.src = LogoEnSVG;
    }
    if (isLang === "KO") {
      logoRef.current.src = LogoEnSVG;
    }
    if (!isMobileWidth) {
      if (menuRef.current)
        for (let i = 0; i < menuRef?.current?.length; i++) {
          menuRef.current[i].style.color = "#fff";
        }
      if (subMenuRef.current)
        for (let j = 0; j < subMenuRef?.current?.length; j++) {
          subMenuRef.current[j].style.color = "#fff";
        }
    } else {
      menuBtnRef.current.src = MenuBSVG;
    }
  };

  // absolute 스타일
  const absolSet = () => {
    setScrollY(window.pageYOffset);
    if (headerRef.current) headerRef.current.style.position = "absolute";
    if (headerRef.current)
      headerRef.current.style.backgroundColor = "rgba(0,0,0,0)";
    if (isLang === "EN") {
      if (logoRef.current) logoRef.current.src = LogoEnSVG;
    }
    if (isLang === "KO") {
      if (logoRef.current) logoRef.current.src = LogoEnSVG;
    }
    if (!isMobileWidth) {
      for (let i = 0; i < menuRef?.current?.length; i++) {
        if (menuRef.current[i]) menuRef.current[i].style.color = "#e4caae";
      }
      if (subMenuRef.current)
        for (let j = 0; j < subMenuRef?.current?.length; j++) {
          if (subMenuRef.current[j])
            subMenuRef.current[j].style.color = "var(--primary-color2)";
        }
    } else {
      menuBtnRef.current.src = MenuSVG;
    }
  };

  // 스크롤 함수
  const handleScroll = useCallback(() => {
    const moving = window.pageYOffset; // 스크롤 시 수직 스크롤 값 moving에 담기
    setVisible(scrollY > moving); // 초기 스크롤 값이 스크롤 후 값보다 크면 true를 담고, 작으면 false를 담는다
    setScrollY(moving); // 초기 스크롤 상태에 스크롤된 값 담기

    // 스크롤에 따른 스타일 지정
    if (visible) {
      fixedSet();
    } else {
      absolSet();
      setIsOverMenu(false);
    }

    // 스크롤 값이 0이 될 때 스타일 지정
    if (moving === 0 || scrollY === 0) {
      absolSet();
    }
  }, [scrollY, visible]);

  // 메뉴 마우스 오버 시
  useEffect(() => {
    if (isOverMenu) {
      setIsOpenMenu(true);
      setIsHeaderBg("rgba(0, 0, 0, 1)");
      setIsHeaderBorder("1px solid rgba(117, 90, 61, 0.5)");

      if (isLang === "EN") {
        logoRef.current.src = LogoEnSVG;
      }
      if (isLang === "KO") {
        logoRef.current.src = LogoEnSVG;
      }
      for (let i = 0; i < menuRef.current.length; i++)
        menuRef.current[i].style.color = "#e4caae";
      for (let j = 0; j < subMenuRef.current.length; j++)
        subMenuRef.current[j].style.color = "var(--primary-color2)";
    } else {
      setIsOpenMenu(false);
      setIsHeaderBg("rgba(0, 0, 0, 0)");
      setIsHeaderBorder("0");

      headerRef.current.style.position = "absolute";
    }
  }, [isOverMenu]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (subFixed) {
      window.addEventListener("scroll", handleScroll); // 스크롤 이벤트 등록
      return () => {
        window.removeEventListener("scroll", handleScroll); // 스크롤 이벤트 제거
      };
    }
  });

  // 모바일 메뉴 핸들러
  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const handleChangeKo = () => {
    setIsLang("EN");
    changelanguageToKo();

    if (
      location.pathname === "/home" ||
      location.pathname === "/signup/createAccount" ||
      location.pathname === "/mypage" ||
      location.pathname === "/partnermypage" ||
      location.pathname === "/transfer" ||
      location.pathname.includes("/transfer/transfer-edit")
    ) {
      window.location.reload();
    }
  };

  const handleChangeEn = () => {
    setIsLang("KO");
    changelanguageToEn();

    if (
      location.pathname === "/home" ||
      location.pathname === "/signup/createAccount" ||
      location.pathname === "/mypage" ||
      location.pathname === "/partnermypage" ||
      location.pathname === "/transfer" ||
      location.pathname.includes("/transfer/transfer-edit")
    ) {
      window.location.reload();
    }
  };

  const newWindow = () => {
    window.open(REACT_APP_NURIDA_URL);
  };

  if (isMobileWidth) {
    return (
      <>
        <HeaderMobileRoot
          ref={headerRef}
          style={{ backgroundColor: isHeaderBg, borderBottom: isHeaderBorder }}
        >
          <h1 onClick={() => navigate("/home")}>
            {isLang === "EN" ? (
              <img src={LogoEnSVG} ref={logoRef} />
            ) : (
              <img src={LogoEnSVG} ref={logoRef} />
            )}
          </h1>
          <div>
            <button onClick={() => setIsMoOpenMenu(true)}>
              <img src={MenuSVG} ref={menuBtnRef} alt="모바일 메뉴 이미지" />
            </button>
          </div>
        </HeaderMobileRoot>
        {isMoOpenMenu && (
          <HeaderMobileWrap>
            <div className="mo_login">
              <button
                onClick={() => {
                  setIsMoOpenMenu(false);
                  setActive(null);
                }}
              >
                <img src={IconXSVG} alt="모바일 메뉴 닫기 이미지" />
              </button>
              {accessToken ? (
                <div>
                  <div
                    className="mo_login_text"
                    onClick={() => {
                      if (userRole === "PARTNER") {
                        navigate("/partnermypage");
                      }
                      if (
                        userRole === "PARTTIMER" ||
                        userRole === "PARTNER_PARTTIMER" ||
                        userRole === "EMPLOYEE"
                      ) {
                        navigate("/mypage");
                      }
                    }}
                  >
                    {userInfo}
                    <span>{t("whomypage")} &gt;</span>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    onClick={() => {
                      navigate("/login");
                      window.location.reload();
                    }}
                  >
                    {t("gologin")}
                  </div>
                </div>
              )}
            </div>
            <nav>
              <ul>
                <li className="include_img">
                  <div
                    onClick={() => handleToggle(1)}
                    style={
                      active === 1
                        ? { backgroundColor: "rgba(117, 90, 61, 0.2)" }
                        : { backgroundColor: "black" }
                    }
                  >
                    {t("componay")}
                    <img
                      className="updown_btn"
                      src={BtnUpdownOnSVG}
                      style={
                        active === 1
                          ? { transform: "rotate(180deg)" }
                          : { transform: "rotate(0deg)" }
                      }
                    />
                  </div>
                  <ul
                    style={
                      active === 1 ? { display: "block" } : { display: "none" }
                    }
                  >
                    <li></li>
                    <li onClick={() => navigate("/greeting")}>{t("ceo")}</li>
                    <li onClick={() => navigate("/introduce")}>{t("info")}</li>
                    <li onClick={() => navigate("/nurida")}>NURIDA 소개영상</li>
                  </ul>
                </li>
                <li>
                  <div onClick={() => navigate("/service")}>{t("service")}</div>
                </li>
                <li className="include_img">
                  <div
                    onClick={() => handleToggle(2)}
                    style={
                      active === 2
                        ? { backgroundColor: "rgba(117, 90, 61, 0.2)" }
                        : { backgroundColor: "black" }
                    }
                  >
                    {t("business")}
                    <img
                      className="updown_btn"
                      src={BtnUpdownOnSVG}
                      style={
                        active === 2
                          ? { transform: "rotate(180deg)" }
                          : { transform: "rotate(0deg)" }
                      }
                    />
                  </div>
                  <ul
                    style={
                      active === 2 ? { display: "block" } : { display: "none" }
                    }
                  >
                    <li></li>
                    <li onClick={() => navigate("/business")}>
                      {t("partner")}
                    </li>
                  </ul>
                </li>
                <li className="include_img">
                  <div
                    onClick={() => handleToggle(3)}
                    style={
                      active === 3
                        ? { backgroundColor: "rgba(117, 90, 61, 0.2)" }
                        : { backgroundColor: "black" }
                    }
                  >
                    {t("careers")}
                    <img
                      className="updown_btn"
                      src={BtnUpdownOnSVG}
                      style={
                        active === 3
                          ? { transform: "rotate(180deg)" }
                          : { transform: "rotate(0deg)" }
                      }
                    />
                  </div>
                  <ul
                    style={
                      active === 3 ? { display: "block" } : { display: "none" }
                    }
                  >
                    <li></li>
                    <li onClick={() => navigate("/hiring")}>{t("recruit")}</li>
                    <li onClick={() => navigate("/promotion")}>{t("event")}</li>
                    <li
                      onClick={() => {
                        if (
                          userRole === "PARTTIMER" ||
                          userRole === "PARTNER_PARTTIMER"
                        ) {
                          navigate("/transfer", {
                            state: {
                              postId: 0,
                            },
                          });
                        } else {
                          noti(t("accessParttimer"));
                        }
                      }}
                    >
                      {t("manpower")}
                      <img src={LinkSVG} className="link_img" />
                    </li>
                  </ul>
                </li>
                <li className="include_img">
                  <div
                    onClick={() => handleToggle(4)}
                    style={
                      active === 4
                        ? { backgroundColor: "rgba(117, 90, 61, 0.2)" }
                        : { backgroundColor: "black" }
                    }
                  >
                    Contact Us
                    <img
                      className="updown_btn"
                      src={BtnUpdownOnSVG}
                      style={
                        active === 4
                          ? { transform: "rotate(180deg)" }
                          : { transform: "rotate(0deg)" }
                      }
                    />
                  </div>
                  <ul
                    style={
                      active === 4 ? { display: "block" } : { display: "none" }
                    }
                  >
                    <li></li>
                    <li onClick={() => navigate("/contact_info")}>
                      {t("manager")}
                    </li>
                    <li onClick={() => navigate("/contact_map")}>
                      {t("location")}
                    </li>
                  </ul>
                </li>
                <li>
                  <div onClick={() => newWindow()}>
                    NURIDA
                    <img className="link_img" src={LinkSVG} />
                  </div>
                </li>
              </ul>
              <div className="side_menu">
                {accessToken ? (
                  <div
                    onClick={async () => {
                      authActions.logout();
                      navigate("/login", { replace: true });
                    }}
                  >
                    {t("logout")}
                  </div>
                ) : (
                  <div onClick={() => navigate("/signup")}>{t("sign")}</div>
                )}
                <div
                  onClick={() => {
                    if (isLang === "KO") {
                      handleChangeKo();
                    }
                    if (isLang === "EN") {
                      handleChangeEn();
                    }
                  }}
                >
                  {t("lang")}
                </div>
              </div>
            </nav>
          </HeaderMobileWrap>
        )}
      </>
    );
  } else {
    return (
      <HeaderRoot
        ref={headerRef}
        style={{ backgroundColor: isHeaderBg, borderBottom: isHeaderBorder }}
      >
        <HeaderWrap css={headerCss}>
          <h1 onClick={() => navigate("/home")}>
            {isLang === "EN" ? (
              <img src={LogoEnSVG} ref={logoRef} />
            ) : (
              <img src={LogoEnSVG} ref={logoRef} />
            )}
          </h1>
          <nav onMouseOver={() => setIsOverMenu(true)}>
            <MainMenu>
              <li>
                <h2
                  onClick={() => navigate("/greeting")}
                  ref={(el) => (menuRef.current[0] = el)}
                >
                  {t("componay")}
                </h2>
              </li>
              <li>
                <h2
                  onClick={() => navigate("/service")}
                  ref={(el) => (menuRef.current[1] = el)}
                >
                  {t("service")}
                </h2>
              </li>
              <li>
                <h2
                  onClick={() => navigate("/business")}
                  ref={(el) => (menuRef.current[2] = el)}
                >
                  {t("business")}
                </h2>
              </li>
              <li>
                <h2
                  onClick={() => navigate("/hiring")}
                  ref={(el) => (menuRef.current[3] = el)}
                >
                  {t("careers")}
                </h2>
              </li>
              <li>
                <h2
                  onClick={() => navigate("/contact_info")}
                  ref={(el) => (menuRef.current[4] = el)}
                >
                  Contact Us
                </h2>
              </li>
              <li>
                <h2 onClick={() => newWindow()}>
                  NURIDA
                  <img src={LinkSVG} />
                </h2>
              </li>
            </MainMenu>
            {isOpenMenu && (
              <ul css={DropMenusCss} onMouseLeave={() => setIsOverMenu(false)}>
                <div>
                  {isLang === "EN" ? (
                    <div style={{ width: "242px" }}></div>
                  ) : (
                    <div style={{ width: "242px" }}></div>
                  )}
                  <div className="drop_menu_inner">
                    <ul>
                      <li onClick={() => navigate("/greeting")}>{t("ceo")}</li>
                      <li onClick={() => navigate("/introduce")}>
                        {t("info")}
                      </li>
                      <li onClick={() => navigate("/nurida")}>
                        NURIDA 소개영상
                      </li>
                    </ul>
                    <ul></ul>
                    <ul>
                      <li onClick={() => navigate("/business")}>
                        {t("partner")}
                      </li>
                    </ul>
                    <ul>
                      <li onClick={() => navigate("/hiring")}>
                        {t("recruit")}
                      </li>
                      <li onClick={() => navigate("/promotion")}>
                        {t("event")}
                      </li>
                      <li
                        onClick={() => {
                          if (
                            userRole === "PARTTIMER" ||
                            userRole === "PARTNER_PARTTIMER"
                          ) {
                            navigate("/transfer", {
                              state: {
                                postId: 0,
                              },
                            });
                          } else {
                            noti(t("accessParttimer"));
                          }
                        }}
                      >
                        {t("manpower")}
                        <img src={LinkSVG} className="icon_link" />
                      </li>
                    </ul>
                    <ul>
                      <li onClick={() => navigate("/contact_info")}>
                        {t("manager")}
                      </li>
                      <li onClick={() => navigate("/contact_map")}>
                        {t("location")}
                      </li>
                    </ul>
                    <ul></ul>
                  </div>
                  {isLang === "EN" ? (
                    <div
                      style={
                        accessToken
                          ? { width: "166.2px" }
                          : { width: "140.33px" }
                      }
                    ></div>
                  ) : (
                    <div
                      style={
                        accessToken
                          ? { width: "157.2px" }
                          : { width: "147.33px" }
                      }
                    ></div>
                  )}
                </div>
              </ul>
            )}
          </nav>
          {accessToken ? (
            <UnitMenu>
              <li
                onClick={async () => {
                  authActions.logout();
                  navigate("/login", { replace: true });
                }}
                ref={(el) => (subMenuRef.current[0] = el)}
              >
                {t("logout")}
              </li>
              <li
                onClick={() => {
                  if (userRole === "PARTNER") {
                    navigate("/partnermypage");
                  }
                  if (
                    userRole === "PARTTIMER" ||
                    userRole === "PARTNER_PARTTIMER" ||
                    userRole === "EMPLOYEE"
                  ) {
                    navigate("/mypage");
                  }
                }}
                ref={(el) => (subMenuRef.current[1] = el)}
              >
                {t("mypage")}
              </li>
              <li
                onClick={() => {
                  if (isLang === "KO") {
                    handleChangeKo();
                  }
                  if (isLang === "EN") {
                    handleChangeEn();
                  }
                }}
                ref={(el) => (subMenuRef.current[2] = el)}
              >
                {t("lang")}
              </li>
            </UnitMenu>
          ) : (
            <UnitMenu>
              <li
                onClick={() => {
                  navigate("/login");
                  window.location.reload();
                }}
                ref={(el) => (subMenuRef.current[0] = el)}
              >
                {t("login")}
              </li>
              <li
                onClick={() => navigate("/signup")}
                ref={(el) => (subMenuRef.current[1] = el)}
              >
                {t("sign")}
              </li>
              <li
                onClick={() => {
                  if (isLang === "KO") {
                    handleChangeKo();
                  }
                  if (isLang === "EN") {
                    handleChangeEn();
                  }
                }}
                ref={(el) => (subMenuRef.current[2] = el)}
              >
                {t("lang")}
              </li>
            </UnitMenu>
          )}
        </HeaderWrap>
      </HeaderRoot>
    );
  }
};

export default Header;

const headerStyled = (isLang) => {
  return css`
    max-width: ${isLang === "EN" ? "1300px" : "1400px"};
  `;
};

const DropMenus = (isLang) => {
  return css`
    position: absolute;
    z-index: 800;
    width: 100vw;
    top: 92px;
    left: 0;
    background-color: var(--bg-color3);

    & li {
      font-size: 16px;
      color: var(--Ae4caae);
      cursor: pointer;

      &:nth-of-type(2) {
        margin: 20px 0;
      }

      &:hover {
        color: var(--secondary-color3);
        text-decoration: underline;

        & .icon_link {
          filter: brightness(0) invert(1);
        }
      }
    }

    & > div {
      width: ${isLang === "EN" ? "1300px" : "1400px"};
      margin: 0 auto;
      padding-left: 7px;
      display: flex;
      justify-content: space-between;
    }

    & .drop_menu_inner {
      margin: 30px 0;
      display: flex;
    }

    & ul:nth-of-type(1) {
      width: ${isLang === "EN" ? "62.23px" : "160px"};
      margin: ${isLang === "EN" ? "0 25px" : "0 15px 0 25px"};
    }
    & ul:nth-of-type(2) {
      width: ${isLang === "EN" ? "46.67px" : "95px"};
      margin: ${isLang === "EN" ? "0 25px" : "0 10px"};
    }
    & ul:nth-of-type(3) {
      width: ${isLang === "EN" ? "62.23px" : "147px"};
      margin: ${isLang === "EN" ? "0 25px" : "0 35px 0 40px"};
    }
    & ul:nth-of-type(4) {
      width: ${isLang === "EN" ? "62.67px" : "100px"};
      margin: ${isLang === "EN" ? "0 9px 0 25px" : "0 10px 0 0"};
    }
    & ul:nth-of-type(5) {
      width: ${isLang === "EN" ? "91.64px" : "135px"};
      margin: ${isLang === "EN" ? "0 25px" : "0 5px 0 10px"};
    }
    & ul:nth-of-type(6) {
      width: ${isLang === "EN" ? "81.36px" : "110px"};
      margin: ${isLang === "EN" ? "0 25px" : "0"};
    }
  `;
};

const HeaderRoot = styled.header`
  width: 100vw;
  height: 92px;
  position: absolute;
  z-index: 900;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  & h1 {
    cursor: pointer;
  }
  & h1 img {
    height: 26px;
  }
`;

const HeaderWrap = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;

  & nav img {
    position: relative;
    top: -5px;
    left: 5px;
    width: 7px;
    height: 7px;
  }
`;

const MainMenu = styled.ul`
  display: flex;

  & li {
    margin: 0 25px;
  }
  & li h2 {
    font-size: 18px;
    color: #e4caae;
    height: 92px;
    display: flex;
    align-items: center;
    cursor: pointer;

    a {
      color: #e4caae;
      cursor: pointer;
    }
  }
`;

const UnitMenu = styled.ul`
  display: flex;

  & li {
    font-size: 14px;
    font-weight: bold;
    color: var(--primary-color2);
    cursor: pointer;
  }

  & li:not(:last-child)::after {
    content: "|";
    color: var(--primary-color2);
    opacity: 50%;
    font-size: 5px;
    font-weight: bold;
    margin: 0 8px;
    vertical-align: middle;
  }
`;

const HeaderMobileRoot = styled.header`
  width: 100vw;
  height: 50px;
  position: absolute;
  z-index: 900;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  & h1 {
    margin-left: 20px;
    cursor: pointer;
    & img {
      height: 20px;
    }
  }

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
`;

const HeaderMobileWrap = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);

  & .mo_login {
    width: 100vw;
    height: 86px;
    font-size: 22px;
    font-weight: bold;
    color: #ffc17d;
    border-bottom: 1px solid rgba(117, 90, 61, 0.5);

    & button {
      float: right;
      margin: 15px 20px 0 0;
    }

    & div div {
      float: left;
      margin: 35px 0 0 20px;
      cursor: pointer;
    }

    & span {
      font-weight: 100;
    }
  }

  & nav {
    & .include_img div {
      display: flex;
      justify-content: space-between;
    }
    & > ul > li {
      color: #e4caae;
      font-size: 18px;
      font-weight: normal;
      cursor: pointer;
    }
    & > ul > li > div {
      font-size: 18px;
      padding: 20px 20px 21px;

      a {
        display: block;
        color: #e4caae;
        cursor: pointer;
      }
    }
    & .link_img {
      position: relative;
      top: -5px;
      left: 5px;
      width: 7px;
      height: 7px;
    }

    & > ul > li > ul {
      display: none;
      padding: 0 20px 10px 20px;
      background-color: rgba(117, 90, 61, 0.2);
    }

    & .active {
      display: block;
    }

    & > ul > li > ul > li:first-of-type {
      border-top: 1px solid rgba(117, 90, 61, 0.5);
      padding: 0 20px 10px 20px;
    }

    & > ul > li > ul > li:not(:first-of-type) {
      padding: 10px 0 10px 10px;

      &:hover {
        color: var(--secondary-color3);
        text-decoration: underline;

        & .link_img {
          filter: brightness(0) invert(1);
        }
      }
    }

    & .side_menu {
      position: relative;
      bottom: -25px;
      color: #c09260;
      font-size: 18px;
      font-weight: bold;
      display: flex;
      margin-left: 30px;

      & div {
        cursor: pointer;
      }

      & div:not(:last-child)::after {
        content: "|";
        color: var(--primary-color2);
        opacity: 50%;
        font-size: 5px;
        font-weight: bold;
        margin: 0 8px;
        vertical-align: middle;
      }
    }
  }
`;
