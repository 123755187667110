import * as yup from "yup";

export default yup.object().shape({
  PartnerNewPassword: yup
    .string()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,15}[^\s]*$/,
      "알파벳, 숫자, 공백을 제외한 특수문자를 모두 포함한 8~15자를 입력해 주세요"
    ),
  PartnerNewPasswordCheck: yup
    .string()
    .oneOf(
      [yup.ref("PartnerNewPassword"), null],
      "비밀번호와 동일한 문자를 입력하세요."
    ),
  verifiCode: yup.string().required("문자로 수신한 인증코드를 입력해 주세요."),
});
