import { Global, css } from "@emotion/react";
import { useRecoilValue } from "recoil";

import { isDrawerOpenedAtom } from "./store/ui/atom";

/**
 * @file 전역 스타일 지정
 * @author TW.Lee
 */
const GlobalStyle = () => {
  const isDrawerOpened = useRecoilValue(isDrawerOpenedAtom);

  return (
    <Global
      styles={[
        css`
          * {
            margin: 0;
            padding: 0;
            border: 0;
            vertical-align: baseline;
            font-weight: 500;
            line-height: 1.5;
            text-decoration: none;
            box-sizing: border-box;
          }
          body {
            font-family: "Pretendard";
            -ms-overflow-style: none;
          }
          .scrollLock {
            position: fixed;
            top: 0;
            left: 0;
          }
          ::-webkit-scrollbar {
            display: none;
          }
          /* HTML5 display-role reset for older browsers */
          article,
          aside,
          details,
          figcaption,
          figure,
          footer,
          header,
          hgroup,
          menu,
          nav,
          section {
            display: block;
          }
          ol,
          ul {
            list-style: none;
          }
          blockquote,
          q {
            quotes: none;
          }
          blockquote:before,
          blockquote:after,
          q:before,
          q:after {
            content: "";
            content: none;
          }
          table {
            border-collapse: collapse;
            border-spacing: 0;
          }
          input {
            border: none;
            outline: none;
          }
          button {
            border: none;
            cursor: pointer;
            background: none;
          }
          /* -- reset */

          html,
          #root {
            overflow-x: hidden;
          }

          /* 요소 숨김 */
          .blind {
            overflow: hidden !important;
            position: absolute !important;
            width: 1px !important;
            height: 1px !important;
            clip: rect(0, 0, 0, 0) !important;
          }

          /* 텍스트 숨김 */
          .text-blind {
            font-size: 0 !important;
            color: transparent !important;
          }
        `,
        isDrawerOpened &&
          css`
            html,
            #root {
              overflow-y: hidden;
            }
          `,
      ]}
    />
  );
};

export default GlobalStyle;
