import React, { useEffect, useState, useRef } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import Button from "components/Button/Button";
import Row from "containers/LabelWithInput/LabelWithInput";
import Postcode from "components/Postcode/DaumPostcode";

// api
import authApis from "api/auth";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// hooks
import useAlert from "hooks/useAlert";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";
import { PREFIX_NUMBER_OPTIONS, CARD_COMPANY_OPTIONS } from "utils/constants";
import vaildationSchema from "utils/validation/partnermypageValidationSchema";
import vaildationSchemaEn from "utils/validation/partnermypageValidationSchemaEn";
import partnermypageVerifyValidationSchema from "utils/validation/partnermypageVerifyValidationSchema";
import partnermypageVerifyValidationSchemaEn from "utils/validation/partnermypageVerifyValidationSchemaEn";

// assets
import Banner01 from "assets/images/pc/img_banner01.png";
import BitClass02SVG from "assets/images/pc/bit_class02.svg";
import BitClass02EnSVG from "assets/images/pc/bit_class02_en.png";

const defaultValues = {
  PartnerEmail: "",
  PartnerPassword: "",
  PartnerNewPassword: "",
  PartnerNewPasswordCheck: "",
  PartnerName: "",
  PartnerTeam: "",
  PartnerLevel: "",
  userPhone1: "",
  userPhone2: "",
  verifiCode: "",
  companyName: "",
  OwnerPhone1: "",
  OwnerPhone2: "",
  Address1: "",
  Address2: "",
  Address3: "",
  companyFaxNumber01: "",
  companyFaxNumber02: "",
  companyFaxNumber03: "",
  businessNumber: "",
  OwnerName: "",
  TransferBank01: "",
  TransferBank02: "",
};

/**
 * @file 파트너 마이 페이지 - 회원 정보
 * @brief 회원 정보
 * @author TW.Lee
 */
const PartnerMypage = () => {
  const { t } = useTranslation();
  const { noti } = useAlert();
  const btnRef = useRef();
  const codeRef = useRef();
  const partnerBtnRef = useRef([]);
  const [companyData, setCompanyData] = useState();
  const [mypageType, setMypageType] = useState("manager");
  const [popup, setPopup] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [isSmsSand, setIsSmsSand] = useState(false);
  const [submitState, setSubmitState] = useState("schemako");
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const [enrollCompany, setEnrollCompany] = useState({
    address: "",
    zipcode: "",
  });

  const root = MypageStyled(t("lang"));
  const moroot = MobileMypageStyled(t("lang"));

  const targetStorage = localStorage.getItem("accessToken")
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem("accessToken");
  const userRole = targetStorage.getItem("role");

  const UI = {
    schemako: newPass ? partnermypageVerifyValidationSchema : vaildationSchema,
    schemaen: newPass
      ? partnermypageVerifyValidationSchemaEn
      : vaildationSchemaEn,
  };

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(UI[submitState]),
    mode: "onChange",
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (t("lang") === "EN") {
      setSubmitState("schemako");
    } else {
      setSubmitState("schemaen");
    }
  }, [t("lang")]);

  useEffect(() => {
    if (values.PartnerNewPassword !== "") {
      setNewPass(true);
    } else {
      setNewPass(false);
    }
  }, [values.PartnerNewPassword]);

  useEffect(() => {
    if (mypageType === "manager") {
      partnerBtnRef.current[0].className = "btn_action";
      partnerBtnRef.current[1].className = "partner_edit_btn";
    }
    if (mypageType === "company") {
      partnerBtnRef.current[0].className = "partner_edit_btn";
      partnerBtnRef.current[1].className = "btn_action";
    }
  }, [mypageType]);

  useEffect(() => {
    setValue("PartnerEmail", companyData?.signname);
    setValue("Address1", enrollCompany.zipcode);
    setValue("Address2", enrollCompany.address);
  }, [enrollCompany.zipcode, enrollCompany.address]);

  // 페이지 접속 시 마이페이지 내정보 호출 요청
  useEffect(() => {
    axios({ method: "get", url: "/auth/login" }).then((res) => {
      setCompanyData(res.data.result.raw);

      const phone1 = res.data.result?.raw.user_phone?.substr(0, 3);
      const phone2 = res.data.result?.raw.user_phone?.substr(3, 8);

      // 전화번호 앞자리 셋팅
      for (let i = 0; i < PREFIX_NUMBER_OPTIONS.length; i++) {
        if (PREFIX_NUMBER_OPTIONS[i].value === phone1) {
          setValue("userPhone1", PREFIX_NUMBER_OPTIONS[i]);
        }
      }
      setValue(
        "PartnerName",
        res.data.result.raw ? res.data.result.raw.user_name : ""
      );
      setValue(
        "PartnerTeam",
        res.data.result.raw ? res.data.result.raw.department : ""
      );
      setValue(
        "PartnerLevel",
        res.data.result.raw ? res.data.result.raw.position : ""
      );
      setValue("userPhone2", res.data.result.raw ? phone2 : "");

      const faxNumFirst = res.data.result.raw.company?.fax.substr(0, 2);
      const faxNumSecont = res.data.result.raw.company?.fax.substr(2, 3);
      const faxThird = res.data.result.raw.company?.fax.substr(5, 4);
      const faxNumSecont_1 = res.data.result.raw.company?.fax.substr(3, 4);
      const faxThird_1 = res.data.result.raw.company?.fax.substr(6, 4);

      const faxNumFirst2 = res.data.result.raw.company.fax.substr(0, 3);
      const faxNumSecont2 = res.data.result.raw.company?.fax.substr(3, 3);
      const faxThird2 = res.data.result.raw.company?.fax.substr(6, 4);
      const faxNumSecont2_1 = res.data.result.raw.company?.fax.substr(3, 4);
      const faxThird2_1 = res.data.result.raw.company?.fax.substr(7, 4);

      if (faxNumFirst === "02") {
        setValue("companyFaxNumber01", faxNumFirst);
        if (res.data.result.raw.company.fax.length === 9) {
          setValue("companyFaxNumber02", faxNumSecont);
          setValue("companyFaxNumber03", faxThird);
        }
        if (res.data.result.raw.company.fax.length === 10) {
          setValue("companyFaxNumber02", faxNumSecont_1);
          setValue("companyFaxNumber03", faxThird_1);
        }
      } else {
        setValue("companyFaxNumber01", faxNumFirst2);
        if (res.data.result.raw.company.fax.length === 10) {
          setValue("companyFaxNumber02", faxNumSecont2);
          setValue("companyFaxNumber03", faxThird2);
        }
        if (res.data.result.raw.company.fax.length === 11) {
          setValue("companyFaxNumber02", faxNumSecont2_1);
          setValue("companyFaxNumber03", faxThird2_1);
        }
      }

      setEnrollCompany({
        address: res.data.result.raw.company?.address,
        zipcode: res.data.result.raw.company?.zip_code,
      });
      setValue("Address1", enrollCompany.zipcode);
      setValue("Address2", enrollCompany.address);
      setValue(
        "Address3",
        res.data.result.raw ? res.data.result.raw.company?.detail_address : ""
      );
      setValue(
        "OwnerName",
        res.data.result.raw ? res.data.result.raw.company?.ceo : ""
      );
      setValue(
        "companyName",
        res.data.result.raw ? res.data.result.raw.company?.company_name : ""
      );
      setValue(
        "businessNumber",
        res.data.result.raw ? res.data.result.raw.company?.business_num : ""
      );

      // 대표전화번호 셋팅
      for (let i = 0; i < PREFIX_NUMBER_OPTIONS.length; i++) {
        if (
          PREFIX_NUMBER_OPTIONS[i].value ===
          res.data.result.raw.company?.company_phone.substr(0, 3)
        ) {
          setValue("OwnerPhone1", PREFIX_NUMBER_OPTIONS[i]);
        }
      }
      setValue(
        "OwnerPhone2",
        res.data.result.raw.company?.company_phone.substr(3, 8)
      );

      // 은행 셋팅
      for (let i = 0; i < CARD_COMPANY_OPTIONS.length; i++) {
        if (
          CARD_COMPANY_OPTIONS[i].value === res.data.result.raw.company?.bank
        ) {
          setValue("TransferBank01", CARD_COMPANY_OPTIONS[i]);
        }
      }
      setValue(
        "TransferBank02",
        res.data.result ? res.data.result.raw.company?.account_number : ""
      );
    });
  }, [accessToken]);

  // 기업정보 마이페이지 내정보 수정 요청
  const editCompanyMypage = () => {
    axios({
      method: "put",
      url: `/users/edit/company/${companyData.company.id}`,
      data: {
        company_name: values.companyName,
        company_phone:
          values.OwnerPhone1.value + values.OwnerPhone2
            ? values.OwnerPhone1.value + values.OwnerPhone2
            : undefined,
        zip_code: enrollCompany.zipcode,
        address: enrollCompany.address,
        detail_address: values.Address3,
        fax:
          values.companyFaxNumber01 +
          values.companyFaxNumber02 +
          values.companyFaxNumber03,
        business_num: values.businessNumber,
        ceo: values.OwnerName,
        bank: values.TransferBank01.value,
        account_number: values.TransferBank02,
        user_name: values.PartnerName,
        user_phone: values.userPhone1.value + values.userPhone2,
        email: values.PartnerEmail,
        is_withdraw: false,
      },
    }).then(async (res) => {
      if (res.status === 200) {
        if (t("lang") === "EN") {
          await noti("수정되었습니다.");
        } else {
          await noti(
            "Your information has been changed successfully.",
            "Confirm"
          );
        }
        window.location.reload();
      }
    });
  };

  // 협력사 마이페이지 내정보 수정 요청
  const editMypage = async () => {
    axios({
      method: "put",
      url: "/users/edit",
      data: {
        old_password: values.PartnerPassword,
        password: values.PartnerNewPassword
          ? values.PartnerNewPassword
          : undefined,
        user_name: values.PartnerName,
        department: values.PartnerTeam,
        position: values.PartnerLevel,
        user_phone: values.userPhone1.value + values.userPhone2,
      },
    }).then(async (res) => {
      if (res.status === 200) {
        editCompanyMypage();
      }
    });
  };

  // 문자전송 요청
  const smsSend = useMutation(
    () => {
      return authApis.smsSend({
        phoneNum: values.userPhone1.value + values.userPhone2,
      });
    },
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          if (t("lang") === "EN") {
            noti("인증코드가 전송되었습니다.");
          } else {
            noti("Verification code has been sent.", "Confirm");
          }
          setIsSmsSand(true);
        }
      },
      onError: async (err) => {},
    }
  );

  // 인증번호 검증 요청
  const verify = useMutation(
    () => {
      return authApis.smsVerify({
        phoneNum: values.userPhone1.value + values.userPhone2,
        verifyCodeNum: values.verifiCode,
      });
    },
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          if (t("lang") === "EN") {
            noti("인증되었습니다.");
          } else {
            noti("Certified.", "Confirm");
          }
          setIsVerify(true);
        }
      },
      onError: async (err) => {
        if (err.response.data.message === "not matched") {
          if (t("lang") === "EN") {
            noti("인증코드가 올바르지 않습니다. 인증번호를 재전송해주세요.");
          } else {
            noti(
              "Incorrect Verification Code.\nPlease try it again.",
              "Confirm"
            );
          }
          setIsVerify(false);
          setIsSmsSand(false);
          codeRef.current.focus();
        }
      },
    }
  );

  console.log(values.PartnerEmail);

  return (
    <Layout subFixed="true">
      <WhiteRoot>
        <SubLayout
          banner={Banner01}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.mypage : PAGE_LAYOUT_EN.mypage
          }
        >
          {isMobileWidth ? (
            <MypageMobileInner01 css={moroot}>
              <div className="mypage-info">
                <div className="mypage-user">
                  <span>{companyData ? companyData.user_name : ""}</span>
                  <br />
                  {t("userinfo")}
                </div>
                <div>
                  {t("lang") === "EN" ? (
                    <img src={BitClass02SVG} alt="" />
                  ) : (
                    <img src={BitClass02EnSVG} alt="" />
                  )}
                </div>
              </div>
            </MypageMobileInner01>
          ) : (
            <MypageInner01>
              <div className="mypage-info">
                <div className="mypage-user">
                  <span>{companyData ? companyData.user_name : ""}</span>
                  {t("userinfo")}
                </div>
                <div className="mypage-img">
                  {t("lang") === "EN" ? (
                    <img src={BitClass02SVG} alt="" />
                  ) : (
                    <img src={BitClass02EnSVG} alt="" />
                  )}
                </div>
              </div>
            </MypageInner01>
          )}
          <MypageInner02 css={root}>
            <div className={isMobileWidth ? "mo_partner_edit" : "partner_edit"}>
              <div
                ref={(el) => (partnerBtnRef.current[0] = el)}
                onClick={() => setMypageType("manager")}
              >
                {t("managerinfoedit")}
              </div>
              <div
                ref={(el) => (partnerBtnRef.current[1] = el)}
                onClick={() => setMypageType("company")}
              >
                {t("coinfoedit")}
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();

                handleSubmit(
                  () => {
                    if (isVerify) {
                      editMypage();
                    } else if (t("lang") === "EN") {
                      noti("본인인증을 완료해주세요.");
                    } else if (t("lang") === "KO") {
                      noti(
                        "Please complete your Verify your identity.",
                        "Confirm"
                      );
                    }
                  },
                  (err) => {
                    const keys = [
                      "PartnerEmail",
                      "PartnerName",
                      "PartnerTeam",
                      "PartnerLevel",
                      "userPhone1",
                      "userPhone2",
                      "verifiCode",
                      "companyName",
                      "OwnerPhone1",
                      "OwnerPhone2",
                      "Address1",
                      "Address2",
                      "Address3",
                      "companyFaxNumber01",
                      "companyFaxNumber02",
                      "companyFaxNumber03",
                      "businessNumber",
                      "OwnerName",
                      "TransferBank01",
                      "TransferBank02",
                    ];

                    const keys2 = [
                      "PartnerEmail",
                      "PartnerName",
                      "PartnerTeam",
                      "PartnerLevel",
                      "userPhone1",
                      "userPhone2",
                      "verifiCode",
                    ];

                    const keys3 = [
                      "companyName",
                      "OwnerPhone1",
                      "OwnerPhone2",
                      "Address1",
                      "Address2",
                      "Address3",
                      "companyFaxNumber01",
                      "companyFaxNumber02",
                      "companyFaxNumber03",
                      "businessNumber",
                      "OwnerName",
                      "TransferBank01",
                      "TransferBank02",
                    ];

                    for (const key of keys) {
                      if (err[key]) {
                        for (const key2 of keys2) {
                          if (key === key2) {
                            setMypageType("manager");
                          }
                        }
                        for (const key3 of keys3) {
                          if (key === key3) {
                            setMypageType("company");
                          }
                        }
                        break;
                      }
                    }
                  }
                )();
              }}
            >
              {userRole === "PARTNER" && mypageType === "manager" && (
                <div className={isMobileWidth ? "mo-form-inner" : "form-inner"}>
                  <Row label={t("emaillabel")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="PartnerEmail"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="text"
                            placeholder={t("enteridemail")}
                            readOnly
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("currentpassword")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="PartnerPassword"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="password"
                            placeholder={t("entercurrentpassword")}
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("newpassword")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="PartnerNewPassword"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="password"
                            placeholder={t("enternewpassword")}
                            fullWidth
                            errors={newPass ? errors : ""}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("newpasswordcheck")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="PartnerNewPasswordCheck"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="password"
                            placeholder={t("enternewpasswordcheck")}
                            fullWidth
                            errors={newPass ? errors : ""}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("namelabel")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="PartnerName"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="text"
                            placeholder={t("entername")}
                            fullWidth
                            errors={errors}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("department")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="PartnerTeam"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="text"
                            placeholder={t("enterdepartment")}
                            fullWidth
                            errors={errors}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("position")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="PartnerLevel"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="text"
                            placeholder={t("enterposition")}
                            fullWidth
                            errors={errors}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("phonelabel")}>
                    <div
                      className={
                        isMobileWidth
                          ? "mo_input-box phonenumber"
                          : "input-box phonenumber"
                      }
                    >
                      <Controller
                        control={control}
                        name="userPhone1"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder={t("select")}
                            variant="secondary"
                            w={isMobileWidth ? "110" : ""}
                            options={PREFIX_NUMBER_OPTIONS}
                          />
                        )}
                      />
                      <span></span>
                      <Controller
                        className="inp-item"
                        control={control}
                        name="userPhone2"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            placeholder={t("enternumber")}
                            fullWidth
                            maxLength="8"
                            errors={errors}
                          />
                        )}
                      />
                      {!isMobileWidth && (
                        <div>
                          {isSmsSand ? (
                            <button
                              type="button"
                              className="send-message"
                              ref={btnRef}
                              onClick={() => verify.mutate()}
                            >
                              {t("verifyself")}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="send-message"
                              ref={btnRef}
                              onClick={() => smsSend.mutate()}
                            >
                              {t("sendmessage")}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    {isMobileWidth && (
                      <div>
                        {isSmsSand ? (
                          <button
                            type="button"
                            className="mo-send-message"
                            ref={btnRef}
                            onClick={() => verify.mutate()}
                          >
                            {t("verifyself")}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="mo-send-message"
                            ref={btnRef}
                            onClick={() => smsSend.mutate()}
                          >
                            {t("sendmessage")}
                          </button>
                        )}
                      </div>
                    )}
                  </Row>
                  <Row label={t("idverifylabel")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        className="inp-item"
                        control={control}
                        name="verifiCode"
                        render={({ field }) => (
                          <Input
                            {...field}
                            ref={codeRef}
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            placeholder={t("enterverification")}
                            fullWidth
                            errors={errors}
                          />
                        )}
                      />
                    </div>
                  </Row>
                </div>
              )}
              {userRole === "PARTNER" && mypageType === "company" && (
                <div className={isMobileWidth ? "mo-form-inner" : "form-inner"}>
                  <Row label={t("Coname")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="companyName"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="text"
                            fullWidth
                            placeholder={t("enterconame")}
                            errors={errors}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("reprenum")}>
                    <div
                      className={
                        isMobileWidth
                          ? "mo_input-box phonenumber"
                          : "input-box phonenumber"
                      }
                    >
                      <Controller
                        control={control}
                        name="OwnerPhone1"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder={t("select")}
                            variant="secondary"
                            w={isMobileWidth ? "110" : ""}
                            options={PREFIX_NUMBER_OPTIONS}
                          />
                        )}
                      />
                      <span></span>
                      <Controller
                        className="inp-item"
                        control={control}
                        name="OwnerPhone2"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            placeholder={t("enternumber")}
                            fullWidth
                            maxLength="8"
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("addresslabel")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <div className="zipcode">
                        <Controller
                          control={control}
                          name="Address1"
                          render={({ field }) => (
                            <Input
                              {...field}
                              className="inp-item"
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              placeholder={t("enterzipcode")}
                              fullWidth
                              readOnly
                              value={enrollCompany.zipcode || ""}
                              errors={errors}
                            />
                          )}
                        />
                        {isMobileWidth ? (
                          <button
                            type="button"
                            className="mo-address-search"
                            onClick={() => {
                              setPopup(true);
                            }}
                          >
                            {t("searchaddrlabel")}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="address-search"
                            onClick={() => {
                              setPopup(true);
                            }}
                          >
                            {t("searchaddrlabel")}
                          </button>
                        )}
                        {popup && (
                          <Postcode
                            popup={popup}
                            setPopup={setPopup}
                            company={enrollCompany}
                            setcompany={setEnrollCompany}
                          />
                        )}
                      </div>
                      <div className="void-sec2"></div>
                      <Controller
                        control={control}
                        name="Address2"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="text"
                            placeholder={t("addresslabel")}
                            fullWidth
                            readOnly
                            value={enrollCompany.address || ""}
                            errors={errors}
                          />
                        )}
                      />
                      <div className="void-sec2"></div>
                      <Controller
                        control={control}
                        name="Address3"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="text"
                            placeholder={t("enterdetailaddr")}
                            fullWidth
                            errors={errors}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("fax")}>
                    <div
                      className={
                        isMobileWidth
                          ? "mo_input-box phonenumber"
                          : "input-box phonenumber"
                      }
                    >
                      <Controller
                        control={control}
                        name="companyFaxNumber01"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            fullWidth
                            errors={errors}
                          />
                        )}
                      />
                      <span></span>
                      <Controller
                        control={control}
                        name="companyFaxNumber02"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            fullWidth
                            errors={errors}
                          />
                        )}
                      />
                      <span></span>
                      <Controller
                        className="inp-item"
                        control={control}
                        name="companyFaxNumber03"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            fullWidth
                            errors={errors}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("businessnum")} isHightlight>
                    <div
                      className={
                        isMobileWidth
                          ? "mo_input-box business-number"
                          : "input-box business-number"
                      }
                    >
                      <Controller
                        control={control}
                        name="businessNumber"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            placeholder={t("enternumber")}
                            readOnly
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("reprename")}>
                    <div
                      className={isMobileWidth ? "mo_input-box" : "input-box"}
                    >
                      <Controller
                        control={control}
                        name="OwnerName"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="text"
                            fullWidth
                            placeholder={t("enterownername")}
                            errors={errors}
                          />
                        )}
                      />
                    </div>
                  </Row>
                  <Row label={t("coaccountnum")} isHightlight>
                    <div
                      className={
                        isMobileWidth
                          ? "mo_input-box bank-select"
                          : "input-box bank-select"
                      }
                    >
                      <Controller
                        control={control}
                        name="TransferBank01"
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder={t("enterbank")}
                            variant="secondary"
                            w={isMobileWidth ? "110" : ""}
                            options={CARD_COMPANY_OPTIONS}
                          />
                        )}
                      />
                      <span className="void-sec"></span>
                      <Controller
                        control={control}
                        name="TransferBank02"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            placeholder={t("enternumber")}
                            errors={errors}
                          />
                        )}
                      />
                    </div>
                  </Row>
                </div>
              )}
              <div className="submit-button">
                <Button type="submit" variant="primary" preset="1">
                  {t("modifying")}
                </Button>
              </div>
            </form>
          </MypageInner02>
          {/* <div className="submit-button">
            <button onClick={() => secessionOut()}>탈퇴하기</button>
          </div> */}
        </SubLayout>
      </WhiteRoot>
    </Layout>
  );
};

export default PartnerMypage;

const MypageStyled = (isLang) => {
  return css`
    & .send-message {
      font-size: ${isLang === "EN" ? "18px" : "15px"};
    }
  `;
};

const MobileMypageStyled = (lang) => {
  return css`
    & .mypage-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${lang === "EN" ? "20px" : "16px"};
      font-weight: bold;
      color: #707070;
      margin-bottom: 20px;

      & div {
        cursor: pointer;
      }

      & span {
        font-size: 35px;
        margin: 0 33px;
      }

      & .action {
        color: #c09260;
      }
    }
  `;
};

const WhiteRoot = styled.div`
  & > div > div > .float_wrap > div {
    padding: 0 410px;
  }
`;

const MypageInner01 = styled.div`
  margin-top: 60px;
  & .mypage-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: bold;
    color: #707070;
    margin-bottom: 30px;

    & div {
      cursor: pointer;
    }

    & span {
      font-size: 35px;
      margin: 0 33px;
    }

    & .action {
      color: #c09260;
    }
  }

  & .mypage-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f4ef;
    padding: 20px 30px;

    & div {
      display: flex;
      align-items: center;
    }
  }

  & .mypage-user {
    font-size: 20px;
    font-weight: normal;

    & span {
      font-size: 26px;
      font-weight: 600;
      color: #c09260;
      margin-right: 4px;
    }
  }
`;

const MypageInner02 = styled.div`
  margin-top: 20px;
  border-top: 1px solid #222;

  & .form-inner {
    margin: 30px 0 60px;
    border-bottom: 1px solid #ddd;
  }

  & .mo-form-inner {
    margin: 20px 0 30px;
    border-bottom: 1px solid #ddd;
  }

  & .partner_edit {
    padding-top: 40px;
    display: flex;
    text-align: center;
    margin-bottom: 30px;

    & div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 178px;
      height: 65px;
      font-size: 18px;
      font-weight: normal;
      border: solid 1px #c09260;
      cursor: pointer;
    }

    & .btn_action {
      background-color: #c09260;
      color: #fff;
      margin-left: 8px;
    }

    & .partner_edit_btn {
      background-color: #fff;
      color: #c09260;
      margin-left: 8px;
    }
  }

  & .mo_partner_edit {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;

    & div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 49%;
      height: 48px;
      font-size: 14px;
      font-weight: normal;
      border: solid 1px #c09260;
      cursor: pointer;
    }

    & .btn_action {
      background-color: #c09260;
      color: #fff;
    }

    & .partner_edit_btn {
      background-color: #fff;
      color: #c09260;
    }
  }

  & .submit-button {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
  }

  & .dim-input {
    background-color: #f9f9f9;
    color: #707070;
  }

  & .input-box {
    margin: 16px 0 30px;
  }

  & .mo_input-box {
    margin: 10px 0 20px;
  }

  & .phonenumber {
    display: flex;

    & span {
      margin: 0 4px;
    }
  }

  & .send-message {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    margin-left: 8px;
  }

  & .mo-send-message {
    width: 100%;
    height: 48px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 20px;
  }

  & .zipcode,
  & .business-number {
    display: flex;
  }

  & .void-sec {
    margin: 0 5px;
  }

  & .void-sec2 {
    height: 10px;
  }

  & .address-search,
  & .business-number-check {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
  }

  & .mo-address-search {
    width: 88px;
    height: 48px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 14px;
    margin-left: 8px;
  }

  & .mypage-txt {
    font-size: 16px;
    font-weight: normal;
    color: #707070;
    margin-top: 12px;
  }

  & .bank-select {
    display: flex;
  }
`;

const MypageMobileInner01 = styled.div`
  margin-top: 20px;

  & .mypage-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f4ef;
    padding: 20px;

    & .mypage-img {
      display: flex;
      align-items: center;
      width: 100px;

      & img {
        width: 100%;
      }
    }
  }

  & .mypage-user {
    font-size: 14px;
    font-weight: normal;
    line-height: 0.9;

    & span {
      font-size: 20px;
      font-weight: 600;
      color: #c09260;
    }
  }
`;
