import React, { useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css, ClassNames } from "@emotion/react";
import Modal from "react-modal";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// components
import Button from "components/Button/Button";

// hooks
import useAlert from "hooks/useAlert";

const modalStyleCore = `
  .overlay-base {  
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transition-property: opacity;
    transition-duration: 225ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms;
    outline: 0;
    z-index:1300;  
  }

  .overlay-after {
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
  }

  .overlay-before {
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
  }

  .content-base {  
    border: none;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 20px;
    outline: none;
    padding: 0px;  
    width: calc(100% - 32px);
    max-width: 390px;
    font-size: 18px;
    font-weight: normal;
    color: #222;
    max-height: calc(100% - 64px);
  }
`;

const mobileModalStyleCore = `
  .overlay-base {  
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transition-property: opacity;
    transition-duration: 225ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms;
    outline: 0;
    z-index:1300;  
  }

  .overlay-after {
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
  }

  .overlay-before {
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
  }

  .content-base {  
    border: none;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 20px;
    outline: none;
    padding: 0px;  
    width: calc(100% - 32px);
    max-width: 290px;
    font-size: 18px;
    font-weight: normal;
    color: #222;
    max-height: calc(100% - 64px);
  }
`;

const inner = css`
  padding: 100px 40px 40px;
  background-color: #fff;
  text-align: center;

  & .alertText1 {
    font-size: 16px;
  }
`;

const mobileInner = css`
  padding: 70px 20px 20px;
  background-color: #fff;
  text-align: center;

  & .alertText1 {
    font-size: 14px;
  }
`;

const innerbutton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
`;

const mobileInnerbutton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
`;

const confirmButton = css`
  width: 80%;
  height: 51px;
`;

const ButtonProceed = (props) => {
  return (
    <Button custom={[confirmButton]} {...props} variant="primary" preset="2">
      {props.children}
    </Button>
  );
};

/**
 * @file AlertModal
 * @brief 얼럿 모달
 */
const AlertModal = () => {
  const {
    message = "",
    renderMessage,
    isOpen = true,
    resolve,
    reject,
    ok = "확인",
  } = useAlert();

  const [newLinePrompt, setNewLinePrompt] = useState([]);
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  useEffect(() => {
    setNewLinePrompt(message.split("\n"));
  }, [isOpen]);

  if (isMobileWidth) {
    return (
      <ClassNames>
        {({ css: coreCss }) => (
          <Modal
            isOpen={isOpen}
            onRequestClose={reject}
            overlayClassName={{
              base: "overlay-base",
              afterOpen: "overlay-after",
              beforeClose: "overlay-before",
            }}
            className={{
              base: "content-base",
              afterOpen: "content-after",
              beforeClose: "content-before",
            }}
            portalClassName={coreCss`${mobileModalStyleCore}`}
          >
            <div css={mobileInner}>
              {renderMessage
                ? renderMessage()
                : newLinePrompt.map((x, i) => (
                    <p key={i} className={`alertText${i}`}>
                      {x}
                    </p>
                  ))}
            </div>
            <div css={mobileInnerbutton}>
              <ButtonProceed onClick={resolve}>{ok}</ButtonProceed>
            </div>
          </Modal>
        )}
      </ClassNames>
    );
  } else {
    return (
      <ClassNames>
        {({ css: coreCss }) => (
          <Modal
            isOpen={isOpen}
            onRequestClose={reject}
            overlayClassName={{
              base: "overlay-base",
              afterOpen: "overlay-after",
              beforeClose: "overlay-before",
            }}
            className={{
              base: "content-base",
              afterOpen: "content-after",
              beforeClose: "content-before",
            }}
            portalClassName={coreCss`${modalStyleCore}`}
          >
            <div css={inner}>
              {renderMessage
                ? renderMessage()
                : newLinePrompt.map((x, i) => (
                    <p key={i} className={`alertText${i}`}>
                      {x}
                    </p>
                  ))}
            </div>
            <div css={innerbutton}>
              <ButtonProceed onClick={resolve}>{ok}</ButtonProceed>
            </div>
          </Modal>
        )}
      </ClassNames>
    );
  }
};

export default AlertModal;
