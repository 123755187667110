/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ErrorMessage } from "@hookform/error-message";

import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

const warningMessage = (isMobileWidth) => {
  return css`
    color: var(--primary-color2);
    font-weight: 400;
    font-size: ${isMobileWidth ? "14px" : "16px"};
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
  `;
};

/**
 * @file Warning Message Components
 */
function WarningMessageComponent(props) {
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const { errors, name } = props;

  const root = warningMessage(isMobileWidth);
  return (
    <>
      {errors?.[name] && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={() => <p css={root}>{`${errors[name].message}`}</p>}
        />
      )}
    </>
  );
}

export default WarningMessageComponent;
