/* eslint-disable no-nested-ternary */
import React, { useRef, useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import Button from "components/Button/Button";

// hooks
import useAlert from "hooks/useAlert";

// api
import userApis from "api/user";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";
import { PREFIX_NUMBER_OPTIONS } from "utils/constants";
import vaildationSchema from "utils/validation/searchIdValidationSchema";
import vaildationSchemaEn from "utils/validation/searchIdValidationSchemaEn";

// assets
import Banner01 from "assets/images/pc/img_banner01.png";

const defaultValues = {
  userPhone1: "",
  userPhone2: "",
  verifiCode: "",
};

/**
 * @file 아이디 찾기 컴포넌트
 * @author TW.Lee
 */
const SearchId = () => {
  const { t } = useTranslation();
  const codeRef = useRef();
  const { noti } = useAlert();
  const navigate = useNavigate();
  const [isVerify, setIsVerify] = useState(false);
  const [isMessage, setIsMessage] = useState(true);
  const [isEmail, setIsEmail] = useState();
  const [submitState, setSubmitState] = useState("schemako");
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const UI = {
    schemako: vaildationSchema,
    schemaen: vaildationSchemaEn,
  };

  const root = NormalFormStyled(t("lang"));

  const {
    handleSubmit,
    setFocus,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(UI[submitState]),
    mode: "onSubmit",
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (t("lang") === "EN") {
      setSubmitState("schemako");
    } else {
      setSubmitState("schemaen");
    }
  }, [t("lang")]);

  // 아이디 찾기 문자전송 요청
  const findid = useMutation(
    () => {
      return userApis.findId({
        userPhone: values.userPhone1.value + values.userPhone2,
      });
    },
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          if (t("lang") === "EN") {
            noti("인증코드가 전송되었습니다.");
          } else {
            noti("Verification code has been sent.", "Confirm");
          }
        }
      },
      onError: async (err) => {
        if (err.response.data.message === "phone_number not found") {
          if (t("lang") === "EN") {
            noti("가입된 회원의 휴대폰 번호가 아닙니다.");
          } else {
            noti(
              "This is not the registered member's phone number..",
              "Confirm"
            );
          }
        }
      },
    }
  );

  // 인증번호 검증 요청
  const findidVerify = useMutation(
    () => {
      return userApis.findIdSmsVerify({
        verifyCodeNum: values.verifiCode,
      });
    },
    {
      onSuccess: (data) => {
        setIsVerify(true);
        setIsEmail(data.data.signname);
      },
      onError: async (err) => {
        if (err.response.data.message === "not matched") {
          if (t("lang") === "EN") {
            noti("인증코드가 올바르지 않습니다. 인증번호를 재전송해주세요.");
          } else {
            noti(
              "Incorrect Verification Code.\nPlease try it again.",
              "Confirm"
            );
          }
        }
      },
    }
  );

  if (isMobileWidth) {
    return (
      <Layout subFixed="true">
        <SubLayout
          banner={Banner01}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.login : PAGE_LAYOUT_EN.login
          }
        >
          <SearchInfoMobileRoot>
            <ul>
              <li
                className="switch-style-on"
                onClick={() => navigate("/login/searchId")}
              >
                {t("findid")}
              </li>
              <li
                className="switch-style-off"
                onClick={() => navigate("/login/searchPw")}
              >
                {t("findpw")}
              </li>
            </ul>
            <SearchIdRoot>
              {isVerify ? (
                isMessage ? (
                  <SearchIdMobileInner02>
                    <div className="id-search-success">
                      {t("lang") === "EN" ? "아이디는" : "Your ID is"}
                      &nbsp;<span>{isEmail}</span>&nbsp;
                      {t("lang") === "EN" && "입니다."}
                    </div>
                    <Button
                      type="button"
                      variant="primaryHover"
                      fullWidth
                      onClick={() =>
                        navigate("/login", {
                          replace: true,
                        })
                      }
                    >
                      {t("login")}
                    </Button>
                  </SearchIdMobileInner02>
                ) : (
                  <SearchIdMobileInner03>
                    <div className="id-search-fail">
                      {t("lang") === "EN"
                        ? "일치하는 회원 정보가 없습니다."
                        : "No matching information has been found. Please try it again."}
                    </div>
                    <div className="id-search-fail-btn">
                      <Button
                        type="button"
                        className="go-login"
                        variant="secondaryHover"
                        fullWidth
                        onClick={() => navigate("/login", { replace: true })}
                      >
                        {t("login")}
                      </Button>
                      <span className="void-sec1"></span>
                      <Button
                        type="button"
                        variant="primaryHover"
                        fullWidth
                        onClick={() => navigate("/signup", { replace: true })}
                      >
                        {t("sign")}
                      </Button>
                    </div>
                  </SearchIdMobileInner03>
                )
              ) : (
                <SearchIdMobileInner01>
                  <h4>
                    <span>
                      {t("lang") === "EN"
                        ? "본인인증"
                        : "Please Verify Your Identity."}
                    </span>
                    {t("lang") === "EN" && "이 필요합니다."}
                  </h4>
                  <div className="id-search-form">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();

                        handleSubmit(
                          () => {
                            findidVerify.mutate();
                          },
                          (err) => {
                            const keys = ["userPhone2", "verifiCode"];

                            for (const key of keys) {
                              if (err[key]) {
                                if (t("lang") === "EN") {
                                  noti(err[key].message, "확인");
                                } else {
                                  noti(err[key].message, "Confirm");
                                }
                                setFocus(key);
                                break;
                              }
                            }
                          }
                        )();
                      }}
                    >
                      <div className="input-box phonenumber">
                        <Controller
                          control={control}
                          name="userPhone1"
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder={
                                t("lang") === "EN" ? "선택" : "Select"
                              }
                              variant="secondary"
                              w={isMobileWidth ? "110" : ""}
                              options={PREFIX_NUMBER_OPTIONS}
                            />
                          )}
                        />
                        <span></span>
                        <Controller
                          className="inp-item"
                          control={control}
                          name="userPhone2"
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              placeholder={
                                t("lang") === "EN"
                                  ? "'-' 없이 숫자만 입력하세요."
                                  : "Please enter only numbers without '-'"
                              }
                              fullWidth
                              maxLength="8"
                            />
                          )}
                        />
                      </div>
                      <div>
                        <button
                          type="button"
                          className="send-message"
                          onClick={() => {
                            if (
                              values.userPhone1 === "" ||
                              values.userPhone2 === ""
                            ) {
                              if (t("lang") === "EN") {
                                noti("휴대전화번호를 입력해 주세요.");
                              } else {
                                noti(
                                  "Please enter your phone number.",
                                  "Confirm"
                                );
                              }
                            } else {
                              findid.mutate();
                            }
                          }}
                        >
                          {t("sendmessage")}
                        </button>
                      </div>
                      <div className="verifi-code">
                        <Controller
                          className="inp-item"
                          control={control}
                          name="verifiCode"
                          render={({ field }) => (
                            <Input
                              {...field}
                              ref={codeRef}
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              placeholder={
                                t("lang") === "EN"
                                  ? "인증코드를 입력하세요."
                                  : "Please enter verification code"
                              }
                              fullWidth
                              maxLength="6"
                              errors={errors}
                            />
                          )}
                        />

                        <div className="verifi-txt">
                          {t("lang") === "EN"
                            ? "문자로 수신한 인증코드를 입력하세요."
                            : "Please enter the verification code you received via text message."}
                        </div>
                      </div>
                      <div className="submit-button">
                        <Button type="submit" variant="primaryHover" fullWidth>
                          {t("findid")}
                        </Button>
                      </div>
                    </form>
                  </div>
                </SearchIdMobileInner01>
              )}
            </SearchIdRoot>
          </SearchInfoMobileRoot>
        </SubLayout>
      </Layout>
    );
  } else {
    return (
      <Layout subFixed="true">
        <SubLayout
          banner={Banner01}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.login : PAGE_LAYOUT_EN.login
          }
        >
          <SearchInfoRoot>
            <ul>
              <li
                className="switch-style-on"
                onClick={() => navigate("/login/searchId")}
              >
                {t("findid")}
              </li>
              <li
                className="switch-style-off"
                onClick={() => navigate("/login/searchPw")}
              >
                {t("findpw")}
              </li>
            </ul>
            <SearchIdRoot css={root}>
              {isVerify ? (
                isMessage ? (
                  <SearchIdInner02>
                    <div className="id-search-success">
                      {t("lang") === "EN" ? "아이디는" : "Your ID is"}
                      &nbsp;<span>&apos;{isEmail}&apos;</span>&nbsp;
                      {t("lang") === "EN" && "입니다."}
                    </div>
                    <Button
                      type="button"
                      variant="primaryHover"
                      fullWidth
                      onClick={() =>
                        navigate("/login", {
                          replace: true,
                        })
                      }
                    >
                      {t("login")}
                    </Button>
                  </SearchIdInner02>
                ) : (
                  <SearchIdInner03>
                    <div className="id-search-fail">
                      {t("lang") === "EN"
                        ? "일치하는 회원 정보가 없습니다."
                        : "No matching information has been found. Please try it again."}
                    </div>
                    <div className="id-search-fail-btn">
                      <Button
                        type="button"
                        className="go-login"
                        variant="secondaryHover"
                        fullWidth
                        onClick={() => navigate("/login", { replace: true })}
                      >
                        {t("login")}
                      </Button>
                      <span className="void-sec1"></span>
                      <Button
                        type="button"
                        variant="primaryHover"
                        fullWidth
                        onClick={() => navigate("/signup", { replace: true })}
                      >
                        {t("sign")}
                      </Button>
                    </div>
                  </SearchIdInner03>
                )
              ) : (
                <SearchIdInner01>
                  <h4>
                    <span>
                      {t("lang") === "EN"
                        ? "본인인증"
                        : "Please Verify Your Identity."}
                    </span>
                    {t("lang") === "EN" && "이 필요합니다."}
                  </h4>
                  <div className="id-search-form">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();

                        handleSubmit(
                          () => {
                            findidVerify.mutate();
                          },
                          (err) => {
                            const keys = ["userPhone2", "verifiCode"];

                            for (const key of keys) {
                              if (err[key]) {
                                if (t("lang") === "EN") {
                                  noti(err[key].message, "확인");
                                } else {
                                  noti(err[key].message, "Confirm");
                                }
                                break;
                              }
                            }
                          }
                        )();
                      }}
                    >
                      <div className="input-box phonenumber">
                        <Controller
                          control={control}
                          name="userPhone1"
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder={
                                t("lang") === "EN" ? "선택" : "Select"
                              }
                              variant="secondary"
                              w={120}
                              options={PREFIX_NUMBER_OPTIONS}
                            />
                          )}
                        />
                        <span></span>
                        <Controller
                          className="inp-item"
                          control={control}
                          name="userPhone2"
                          render={({ field }) => (
                            <Input
                              {...field}
                              className="en-placeholder"
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              placeholder={
                                t("lang") === "EN"
                                  ? "'-' 없이 숫자만 입력하세요."
                                  : "Please enter only numbers without '-'"
                              }
                              fullWidth
                              maxLength="8"
                            />
                          )}
                        />
                        <div>
                          <button
                            type="button"
                            className="send-message"
                            onClick={() => {
                              if (
                                values.userPhone1 === "" ||
                                values.userPhone2 === ""
                              ) {
                                if (t("lang") === "EN") {
                                  noti("휴대전화번호를 입력해 주세요.");
                                } else {
                                  noti(
                                    "Please enter your phone number.",
                                    "Confirm"
                                  );
                                }
                              } else {
                                findid.mutate();
                              }
                            }}
                          >
                            {t("sendmessage")}
                          </button>
                        </div>
                      </div>
                      <div className="verifi-code">
                        <Controller
                          className="inp-item"
                          control={control}
                          name="verifiCode"
                          render={({ field }) => (
                            <Input
                              {...field}
                              ref={codeRef}
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              placeholder={
                                t("lang") === "EN"
                                  ? "인증코드를 입력하세요."
                                  : "Please enter verification code"
                              }
                              fullWidth
                              maxLength="6"
                              errors={errors}
                            />
                          )}
                        />

                        <div className="verifi-txt">
                          {t("lang") === "EN"
                            ? "문자로 수신한 인증코드를 입력하세요."
                            : "Please enter the verification code you received via text message."}
                        </div>
                      </div>
                      <div className="submit-button">
                        <Button type="submit" variant="primaryHover" fullWidth>
                          {t("findid")}
                        </Button>
                      </div>
                    </form>
                  </div>
                </SearchIdInner01>
              )}
            </SearchIdRoot>
          </SearchInfoRoot>
        </SubLayout>
      </Layout>
    );
  }
};

export default SearchId;

const NormalFormStyled = (isLang) => {
  return css`
    .send-message {
      font-size: ${isLang === "EN" ? "18px" : "15px"};
    }
    .en-placeholder::placeholder {
      font-size: ${isLang === "EN" ? "16px" : "13px"};
    }
  `;
};

const SearchInfoRoot = styled.div`
  width: 500px;
  margin: 0 auto;
  & > ul {
    padding-top: 100px;
    display: flex;
    justify-content: space-between;

    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 250px;
      height: 64px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    }

    & .switch-style-on {
      color: #c09260;
      border-bottom: solid 1px #c09260;
    }
    & .switch-style-off {
      color: #999;
      border-bottom: solid 1px #ddd;
    }
  }
`;

const SearchIdRoot = styled.div`
  text-align: center;
  padding-bottom: 100px;
`;

const SearchIdInner01 = styled.div`
  & h4 {
    margin: 40px 0;
    font-size: 18px;
    font-weight: normal;
    & span {
      font-weight: bold;
    }
  }

  & .phonenumber {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & span {
      margin: 0 4px;
    }
  }

  & .send-message {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    margin-left: 8px;
  }

  & .verifi-code {
    text-align: left;
    font-size: 16px;
    color: #707070;
    margin-bottom: 40px;
  }
  & .verifi-txt {
    font-weight: normal;
    margin: 12px 0 40px;
  }
`;

const SearchIdInner02 = styled.div`
  & .id-search-success {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 232px;
    background-color: #f9f9f9;
    margin: 40px 0;

    & span {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;

const SearchIdInner03 = styled.div`
  & .id-search-fail {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 232px;
    background-color: #f9f9f9;
    margin: 40px 0;
  }

  & .id-search-fail-btn {
    display: flex;
    justify-content: space-between;

    & .void-sec1 {
      margin: 0 4px;
    }
  }
`;

const SearchInfoMobileRoot = styled.div`
  & > ul {
    position: relative;
    width: 100vw;
    left: -30px;
    display: flex;
    justify-content: space-between;

    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 51px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }

    & .switch-style-on {
      color: #c09260;
      border-bottom: solid 1px #c09260;
    }
    & .switch-style-off {
      color: #999;
      border-bottom: solid 1px #ddd;
    }
  }
`;

const SearchIdMobileInner01 = styled.div`
  & h4 {
    margin: 30px 0;
    font-size: 18px;
    font-weight: normal;
    & span {
      font-weight: bold;
    }
  }

  & .phonenumber {
    display: flex;
    align-items: center;

    & span {
      margin: 0 4px;
    }
  }

  & .send-message {
    width: 100%;
    height: 48px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 14px;
    margin: 10px 0;
  }

  & .verifi-code {
    text-align: left;
    font-size: 16px;
    color: #707070;
    margin-bottom: 30px;
  }
  & .verifi-txt {
    font-size: 13px;
    font-weight: normal;
    margin: 8px 0 30px;
  }
`;

const SearchIdMobileInner02 = styled.div`
  & .id-search-success {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 232px;
    background-color: #f9f9f9;
    margin: 40px 0;

    & span {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;

const SearchIdMobileInner03 = styled.div`
  & .id-search-fail {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 232px;
    background-color: #f9f9f9;
    margin: 40px 0;
  }

  & .id-search-fail-btn {
    display: flex;
    justify-content: space-between;

    & .void-sec1 {
      margin: 0 4px;
    }
  }
`;
