import * as yup from "yup";

export default yup.object().shape({
  companyEmail: yup
    .string()
    .required("")
    .matches(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      "이메일 주소를 형식에 맞게 입력해주세요."
    ),
  companyName: yup.string().required(""),
  companyBusiness: yup.object().nullable().required(""),
  companyManager: yup.string().required(""),
  companyPhone2: yup.string().required(""),
  companyPhone3: yup.string().required(""),
  companyMessage: yup.string().required(""),
});
