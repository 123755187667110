import axios from "axios";

// 로그인
const login = async ({ userEmail, userPassword }) => {
  try {
    const data = await axios.post("/auth/official/login", {
      username: userEmail,
      password: userPassword,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

// 일반,파트타이머 회원가입
const signUpUser = async ({
  NormalEmail,
  NormalPassword,
  NormalName,
  userPhone,
  zipcode,
  addressItem,
  detailAddress,
}) => {
  try {
    const data = await axios.post("/auth/register", {
      signname: NormalEmail,
      password: NormalPassword,
      user_name: NormalName,
      user_phone: userPhone,
      zip_code: zipcode,
      address: addressItem,
      detail_address: detailAddress,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

// 협력사 회원가입
const signUpPartner = async ({
  PartnerEmail,
  PartnerPassword,
  PartnerName,
  PartnerTeam,
  PartnerLevel,
  userPhone,
  companyName,
  OwnerPhone,
  zipcode,
  addressItem,
  detailAddress,
  faxNum,
  OwnerName,
  businessNum,
  bankNum,
  accountNumber,
}) => {
  try {
    const data = await axios.post("/auth/register/partner", {
      signname: PartnerEmail,
      password: PartnerPassword,
      user_name: PartnerName,
      department: PartnerTeam,
      position: PartnerLevel,
      user_phone: userPhone,
      company_name: companyName,
      company_phone: OwnerPhone,
      zip_code: zipcode,
      address: addressItem,
      detail_address: detailAddress,
      fax: faxNum,
      business_num: businessNum,
      ceo: OwnerName,
      bank: bankNum,
      account_number: accountNumber,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

// 인증문자 발송
const smsSend = async ({ phoneNum }) => {
  try {
    const data = await axios.post("/verifications/phone", {
      phone: phoneNum,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

// 인증번호 검증
const smsVerify = async ({ phoneNum, verifyCodeNum }) => {
  try {
    const data = await axios.post("/verifications/phone/verify", {
      phone: phoneNum,
      key: verifyCodeNum,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

export default {
  login,
  signUpUser,
  signUpPartner,
  smsSend,
  smsVerify,
};
