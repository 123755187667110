import { atom } from "recoil";

export const accessTokenAtom = atom({
  key: "accessToken",
  default: "",
});

export const refreshTokenAtom = atom({
  key: "refreshToken",
  default: "",
});

export const userIdAtom = atom({
  key: "userId",
  default: "",
});

export const userInfoAtom = atom({
  key: "userInfo",
  default: {},
});

export const userRoleAtom = atom({
  key: "userRole",
  default: "",
});

export const employeeRoleAtom = atom({
  key: "employeeRole",
  default: "",
});
