import * as yup from "yup";

/**
 * @file 로그인 유효성 검사 및 입력 체크
 * @author TW.Lee
 */
export default yup.object().shape({
  userId: yup.string().required("Please enter your Email Address."),
  userPw: yup.string().required("Please enter your Password."),
});
