/** @jsxImportSource @emotion/react */
import { forwardRef, useCallback, useRef, useEffect } from "react";
import { css } from "@emotion/react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// utils
import { ERROR_COLOR } from "utils/constants";

// assets
import BtnXSVG from "assets/images/pc/btn_x.svg";

const rootCss = (props, isMobileWidth) => {
  return css`
    display: ${isMobileWidth ? "block" : "flex"};
    width: ${props.fullWidth ? "100%" : "100%"};

    & .file-inner {
      width: 100%;
    }

    & #files {
      display: none;
    }

    & .file-delete {
      display: flex;
      align-items: center;
      position: absolute;
      margin-top: ${isMobileWidth ? "-34px" : "-42px"};
      right: ${isMobileWidth ? "45px" : "540px"};
      background: none;
      border: 0;
      font-size: 14px;
      color: #707070;
      font-weight: normal;

      & img {
        margin-left: 4px;
      }
    }
    & .add-file {
      width: ${isMobileWidth ? "100%" : "120px"};
      height: ${isMobileWidth ? "48px" : "65px"};
      border: 1px solid #444;
      background-color: #444;
      color: #fff;
      font-size: ${isMobileWidth ? "14px" : "18px"};
      margin-top: ${isMobileWidth ? "8px" : "0"};
      margin-left: ${isMobileWidth ? "0" : "8px"};

      & label {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  `;
};

const labelCss = (props, isMobileWidth) => {
  const error = props.errors?.[props.name];

  return css`
    & #file-submit {
      display: flex;
      align-items: center;
      width: 100%;
      height: ${isMobileWidth ? "48px" : "65px"};
      background: transparent;
      font-size: ${isMobileWidth ? "14px" : "16px"};
      color: #000;
      padding-left: 20px;
      border: solid 1px ${error ? ERROR_COLOR : "#ddd"};
      background: #f9f9f9;

      &::placeholder {
        color: #b2b2b2;
      }
    }
  `;
};

/**
 * @file File Input Components
 * @param fileRef 파일 첨부 대체 텍스트 input DOM
 * @param fileInput 실제 파일 input DOM
 * @param handleChange 파일 첨부 onChange 함수. Controller return.
 * @param handleRemoveFile 파일 삭제 함수
 */
const FileInput = forwardRef((props, ref) => {
  const { children, accept, ...rest } = props;
  const { t } = useTranslation();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const root = rootCss(props, isMobileWidth);
  const label = labelCss(props, isMobileWidth);

  const fileRef = useRef();
  const fileInput = useRef();

  useEffect(() => {
    if (rest.value === "") {
      fileInput.current.value = "";
      fileRef.current.value = "";
    }
    if (rest.value !== "") {
      fileRef.current.value = rest.value[0]?.name;
    }
  }, [rest.value]);

  const handleChange = useCallback((e) => {
    fileRef.current.value = e.target.files[0].name;
    rest.onChange(e.target.files);
  }, []);

  const handleRemoveFile = useCallback(() => {
    fileInput.current.value = "";
    fileRef.current.value = "";
    rest.onChange("");
  }, []);

  return (
    <div css={root}>
      <div className="file-inner">
        <input
          type="file"
          id="files"
          ref={fileInput}
          accept={accept}
          onChange={(e) => handleChange(e)}
        />
        <label css={label}>
          <input
            type="text"
            id="file-submit"
            ref={fileRef}
            placeholder={children}
            disabled
          />
        </label>
        <button
          type="button"
          className="file-delete"
          onClick={() => handleRemoveFile()}
        >
          {isMobileWidth ? "" : t("deletefile")}
          <img src={BtnXSVG} />
        </button>
      </div>
      <button type="button" className="add-file">
        <label htmlFor="files">{t("addfile")}</label>
      </button>
    </div>
  );
});

export default FileInput;
