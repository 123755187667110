import React, { useState, useRef, useEffect, useCallback } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// assets
import IconCheckOffSVG from "assets/images/pc/icon_check_off.svg";
import IconCheckOnSVG from "assets/images/pc/icon_check_on.svg";

/**
 * @file 인력등록 - 인력등록항목 팝업
 * @brief 인력등록 팝업 컴포넌트
 * @author TW.Lee
 * @param floatRef 팝업 DOM
 * @param bgRef 리스트 DOM
 * @param ScrollY Y축 스크롤 상태값
 */
const TransferListPopup = ({
  popupProfile,
  popupSupport,
  popupEdu,
  popupCareer,
  popupLicense,
  popupSelf,
}) => {
  const bgRef = useRef([]);
  const floatRef = useRef();
  const { t } = useTranslation();
  const [time, setTime] = useState();
  const [ScrollY, setScrollY] = useState(0);
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const root = TransferPopupStyled(t("lang"));

  const handleScroll = useCallback(() => {
    if (ScrollY > 459) {
      setScrollY(window.pageYOffset);
      floatRef.current.style.position = "fixed";
    } else {
      setScrollY(window.pageYOffset);
      floatRef.current.style.position = "absolute";
    }
  }, [ScrollY]);

  useEffect(() => {
    function scrollListener() {
      window.addEventListener("scroll", handleScroll);
    } //  window 에서 스크롤을 감시 시작
    scrollListener(); // window 에서 스크롤을 감시
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }; //  window 에서 스크롤을 감시를 종료
  });

  useEffect(() => {
    for (let i = 0; i < bgRef.current.length; i++) {
      bgRef.current[i].style.background = "#fff";
      bgRef.current[i].style.color = "#707070";

      if (ScrollY >= 0 && ScrollY <= 1890) {
        bgRef.current[0].style.background = "#f9f4ef";
        bgRef.current[0].style.color = "#c09260";
      } else if (ScrollY > 1890 && ScrollY <= 2722) {
        bgRef.current[1].style.background = "#f9f4ef";
        bgRef.current[1].style.color = "#c09260";
      } else if (ScrollY > 2722 && ScrollY <= 3792) {
        bgRef.current[2].style.background = "#f9f4ef";
        bgRef.current[2].style.color = "#c09260";
      } else if (ScrollY > 3792 && ScrollY <= 4870) {
        bgRef.current[3].style.background = "#f9f4ef";
        bgRef.current[3].style.color = "#c09260";
      } else if (ScrollY > 4870 && ScrollY <= 5396) {
        bgRef.current[4].style.background = "#f9f4ef";
        bgRef.current[4].style.color = "#c09260";
      } else if (ScrollY > 5396) {
        bgRef.current[5].style.background = "#f9f4ef";
        bgRef.current[5].style.color = "#c09260";
      }
    }
  }, [ScrollY]);

  return (
    <TransferPopupRoot css={root} ref={floatRef}>
      <div className="float-sec">
        <h4>{t("manpowerapplist")}</h4>
        <ul>
          <li ref={(el) => (bgRef.current[0] = el)}>
            <p>{t("profile")}</p>
            <p>
              <img
                src={popupProfile ? IconCheckOnSVG : IconCheckOffSVG}
                alt="인력등록항목 미체크 아이콘"
              />
            </p>
          </li>
          <li ref={(el) => (bgRef.current[1] = el)}>
            <p>{t("appfield")}</p>
            <p>
              <img
                src={popupSupport ? IconCheckOnSVG : IconCheckOffSVG}
                alt="인력등록항목 미체크 아이콘"
              />
            </p>
          </li>
          <li ref={(el) => (bgRef.current[2] = el)}>
            <p>{t("EduMilImp")}</p>
            <p>
              <img
                src={popupEdu ? IconCheckOnSVG : IconCheckOffSVG}
                alt="인력등록항목 미체크 아이콘"
              />
            </p>
          </li>
          <li ref={(el) => (bgRef.current[3] = el)}>
            <p>{t("career")}</p>
            <p>
              <img
                src={popupCareer ? IconCheckOnSVG : IconCheckOffSVG}
                alt="인력등록항목 미체크 아이콘"
              />
            </p>
          </li>
          <li ref={(el) => (bgRef.current[4] = el)}>
            <p>{t("certificate")}</p>
            <p>
              <img
                src={popupLicense ? IconCheckOnSVG : IconCheckOffSVG}
                alt="인력등록항목 미체크 아이콘"
              />
            </p>
          </li>
          <li ref={(el) => (bgRef.current[5] = el)}>
            <p>{t("yourself")}</p>
            <p>
              <img
                src={popupSelf ? IconCheckOnSVG : IconCheckOffSVG}
                alt="인력등록항목 미체크 아이콘"
              />
            </p>
          </li>
        </ul>
      </div>
    </TransferPopupRoot>
  );
};

export default TransferListPopup;

const TransferPopupStyled = (lang) => {
  return css`
    & h4 {
      font-size: ${lang === "EN" ? "20px" : "21px"};
      font-weight: bold;
      color: #222;
      border-bottom: 1px solid #b2b2b2;
      padding-bottom: 12px;
      margin-bottom: 20px;
    }
  `;
};

const TransferPopupRoot = styled.div`
  position: absolute;
  z-index: 700;
  top: 80px;
  right: 50%;
  transform: translateX(260%);

  & .temporary-sec {
    width: 250px;
    height: 112px;
    border-radius: 8px;
    padding: 20px 38px 20px 20px;
    box-shadow: 10px 10px 30px 0 rgba(168, 154, 139, 0.28);
    background-color: #f8f3ee;
    margin-bottom: 20px;

    & .temporary-hedding {
      font-weight: bold;
      color: #c09260;
      margin-bottom: 10px;
    }

    & p {
      font-size: 16px;
      font-weight: normal;
      color: #222;
      line-height: 1.5;
    }
  }

  & .float-sec {
    width: 250px;
    padding: 30px 20px;
    border-radius: 8px;
    box-shadow: 8px 8px 30px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }

  & li {
    width: 210px;
    height: 43px;
    padding: 12px 16px 12px 12px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #707070;
  }
`;
