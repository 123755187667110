import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import dayjs from "dayjs";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import Button from "components/Button/Button";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// assets
import Banner05 from "assets/images/pc/img_banner05.png";

import {
  DEPARTMENT_OPTIONS,
  CARD_COMPANY_OPTIONS,
  EDUCATION_OPTIONS,
  GRADUATE_OPTIONS,
  MILITARY_OPTIONS,
  ELIGIBILTY_OPTIONS,
  DISABLED_OPTIONS,
  EMPLOY_OPTIONS,
  PART1_OPTIONS,
  PART2_OPTIONS,
  PART3_OPTIONS,
  PART4_OPTIONS,
  PART5_OPTIONS,
  DEPARTMENT_OPTIONS_EN,
  CARD_COMPANY_OPTIONS_EN,
  EDUCATION_OPTIONS_EN,
  GRADUATE_OPTIONS_EN,
  MILITARY_OPTIONS_EN,
  ELIGIBILTY_OPTIONS_EN,
  DISABLED_OPTIONS_EN,
  EMPLOY_OPTIONS_EN,
  PART1_OPTIONS_EN,
  PART2_OPTIONS_EN,
  PART3_OPTIONS_EN,
  PART4_OPTIONS_EN,
  PART5_OPTIONS_EN,
} from "utils/constants";

import DetailPopup from "./components/DetailPopup";

/**
 * @file 커리어 - 인력등록 상세 페이지
 * @author TW.Lee
 */
const TransferDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [careerInfo, setCareerInfo] = useState();
  const [licenseInfo, setLicenseInfo] = useState();
  const [gender, setGender] = useState();
  const [bank, setBank] = useState();
  const [job1Depth1, setJob1Depth1] = useState();
  const [job1Depth2, setJob1Depth2] = useState();
  const [job2Depth1, setJob2Depth1] = useState();
  const [job2Depth2, setJob2Depth2] = useState();
  const [education, setEducation] = useState();
  const [graduation, setGraduation] = useState();
  const [military, setMilitary] = useState();
  const [eligibility, setEligibility] = useState();
  const [disabled, setDisabled] = useState();
  const [firstPartOption, setFirstPartOption] = useState([]);
  const [secondPartOption, setSecondPartOption] = useState([]);
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const root = TransferDetailStyled(t("lang"));
  const { REACT_APP_API } = process.env;

  useEffect(() => {
    axios({
      method: "get",
      url: `/resume/${location.state.resumeId}`,
    }).then((res) => {
      setTableData(res.data.result);
      setCareerInfo(JSON.parse(res.data.result.career_info));
      setLicenseInfo(JSON.parse(res.data.result.license_info));
    });
  }, [location.state.resumeId]);

  useEffect(() => {
    if (t("lang") === "EN") {
      // 성별 한글 세팅
      if (tableData?.gender === "남성" || tableData?.gender === "Male") {
        setGender("남성");
      } else {
        setGender("여성");
      }

      // 부서 한글 세팅
      for (let i = 0; i < DEPARTMENT_OPTIONS.length; i++) {
        if (DEPARTMENT_OPTIONS[i].value === tableData?.job1_depth1) {
          setJob1Depth1(DEPARTMENT_OPTIONS[i].label);
        }
        if (DEPARTMENT_OPTIONS[i].value === tableData?.job2_depth1) {
          setJob2Depth1(DEPARTMENT_OPTIONS[i].label);
        }
      }

      // 은행 한글 세팅
      for (let i = 0; i < CARD_COMPANY_OPTIONS.length; i++) {
        if (CARD_COMPANY_OPTIONS[i].value === tableData?.bank) {
          setBank(CARD_COMPANY_OPTIONS[i].label);
        }
      }

      // 최종학력 한글 세팅
      for (let i = 0; i < EDUCATION_OPTIONS.length; i++) {
        if (EDUCATION_OPTIONS[i].value === tableData?.education) {
          setEducation(EDUCATION_OPTIONS[i].label);
        }
      }

      // 최종학력 한글 세팅
      for (let i = 0; i < GRADUATE_OPTIONS.length; i++) {
        if (GRADUATE_OPTIONS[i].value === tableData?.graduation_category) {
          setGraduation(GRADUATE_OPTIONS[i].label);
        }
      }

      // 병역 한글 세팅
      for (let i = 0; i < MILITARY_OPTIONS.length; i++) {
        if (MILITARY_OPTIONS[i].value === tableData?.military_service) {
          setMilitary(MILITARY_OPTIONS[i].label);
        }
      }

      // 보훈 한글 세팅
      for (let i = 0; i < ELIGIBILTY_OPTIONS.length; i++) {
        if (ELIGIBILTY_OPTIONS[i].value === tableData?.veterans) {
          setEligibility(ELIGIBILTY_OPTIONS[i].label);
        }
      }

      // 장애 한글 세팅
      for (let i = 0; i < DISABLED_OPTIONS.length; i++) {
        if (DISABLED_OPTIONS[i].value === tableData?.disabled) {
          setDisabled(DISABLED_OPTIONS[i].label);
        }
      }
    } else {
      // 성별 영어 세팅
      if (tableData?.gender === "남성" || tableData?.gender === "Male") {
        setGender("Male");
      } else {
        setGender("Female");
      }

      // 은행 영어 세팅
      for (let i = 0; i < DEPARTMENT_OPTIONS_EN.length; i++) {
        if (DEPARTMENT_OPTIONS_EN[i].value === tableData?.job1_depth1) {
          setJob1Depth1(DEPARTMENT_OPTIONS_EN[i].label);
        }
        if (DEPARTMENT_OPTIONS_EN[i].value === tableData?.job2_depth1) {
          setJob2Depth1(DEPARTMENT_OPTIONS_EN[i].label);
        }
      }

      // 은행 영어 세팅
      for (let i = 0; i < CARD_COMPANY_OPTIONS_EN.length; i++) {
        if (CARD_COMPANY_OPTIONS_EN[i].value === tableData?.bank) {
          setBank(CARD_COMPANY_OPTIONS_EN[i].label);
        }
      }

      // 최종학력 영어 세팅
      for (let i = 0; i < EDUCATION_OPTIONS_EN.length; i++) {
        if (EDUCATION_OPTIONS_EN[i].value === tableData?.education) {
          setEducation(EDUCATION_OPTIONS_EN[i].label);
        }
      }

      // 최종학력 영어 세팅
      for (let i = 0; i < GRADUATE_OPTIONS_EN.length; i++) {
        if (GRADUATE_OPTIONS_EN[i].value === tableData?.graduation_category) {
          setGraduation(GRADUATE_OPTIONS_EN[i].label);
        }
      }

      // 병역 영어 세팅
      for (let i = 0; i < MILITARY_OPTIONS_EN.length; i++) {
        if (MILITARY_OPTIONS_EN[i].value === tableData?.military_service) {
          setMilitary(MILITARY_OPTIONS_EN[i].label);
        }
      }

      // 보훈 영어 세팅
      for (let i = 0; i < ELIGIBILTY_OPTIONS_EN.length; i++) {
        if (ELIGIBILTY_OPTIONS_EN[i].value === tableData?.veterans) {
          setEligibility(ELIGIBILTY_OPTIONS_EN[i].label);
        }
      }

      // 장애 영어 세팅
      for (let i = 0; i < DISABLED_OPTIONS_EN.length; i++) {
        if (DISABLED_OPTIONS_EN[i].value === tableData?.disabled) {
          setDisabled(DISABLED_OPTIONS_EN[i].label);
        }
      }
    }

    // 1차 파트 세팅
    for (let i = 0; i < firstPartOption.length; i++) {
      if (firstPartOption[i].value === tableData?.job1_depth2) {
        setJob1Depth2(firstPartOption[i].label);
      }
    }

    // 2차 파트 세팅
    for (let i = 0; i < secondPartOption.length; i++) {
      if (secondPartOption[i].value === tableData?.job2_depth2) {
        setJob2Depth2(secondPartOption[i].label);
      }
    }
  }, [t("lang"), tableData, firstPartOption, secondPartOption]);

  // 1차 희망 선택 시 파트 옵션
  const choiceFirstPartOption = useCallback(() => {
    if (t("lang") === "EN") {
      switch (tableData?.job1_depth1) {
        case "Room_part_Team":
          setFirstPartOption(PART1_OPTIONS);
          break;
        case "Housekeeping":
          setFirstPartOption(PART2_OPTIONS);
          break;
        case "Food_and_Beverage":
          setFirstPartOption(PART3_OPTIONS);
          break;
        case "Security":
          setFirstPartOption(PART4_OPTIONS);
          break;
        case "Facilities_Management":
          setFirstPartOption(PART5_OPTIONS);
          break;
        default:
          setFirstPartOption([]);
          break;
      }
    } else {
      switch (tableData?.job1_depth1) {
        case "Room_part_Team":
          setFirstPartOption(PART1_OPTIONS_EN);
          break;
        case "Housekeeping":
          setFirstPartOption(PART2_OPTIONS_EN);
          break;
        case "Food_and_Beverage":
          setFirstPartOption(PART3_OPTIONS_EN);
          break;
        case "Security":
          setFirstPartOption(PART4_OPTIONS_EN);
          break;
        case "Facilities_Management":
          setFirstPartOption(PART5_OPTIONS_EN);
          break;
        default:
          setFirstPartOption([]);
          break;
      }
    }
  }, [t("lang"), tableData?.job1_depth1]);

  // 2차 희망 선택 시 파트 옵션
  const choiceSecondPartOption = useCallback(() => {
    if (t("lang") === "EN") {
      switch (tableData?.job2_depth1) {
        case "Room_part_Team":
          setSecondPartOption(PART1_OPTIONS);
          break;
        case "Housekeeping":
          setSecondPartOption(PART2_OPTIONS);
          break;
        case "Food_and_Beverage":
          setSecondPartOption(PART3_OPTIONS);
          break;
        case "Security":
          setSecondPartOption(PART4_OPTIONS);
          break;
        case "Facilities_Management":
          setSecondPartOption(PART5_OPTIONS);
          break;
        default:
          setSecondPartOption([]);
          break;
      }
    } else {
      switch (tableData?.job2_depth1) {
        case "Room_part_Team":
          setSecondPartOption(PART1_OPTIONS_EN);
          break;
        case "Housekeeping":
          setSecondPartOption(PART2_OPTIONS_EN);
          break;
        case "Food_and_Beverage":
          setSecondPartOption(PART3_OPTIONS_EN);
          break;
        case "Security":
          setSecondPartOption(PART4_OPTIONS_EN);
          break;
        case "Facilities_Management":
          setSecondPartOption(PART5_OPTIONS_EN);
          break;
        default:
          setSecondPartOption([]);
          break;
      }
    }
  }, [t("lang"), tableData?.job2_depth1]);

  // 최대 업데이트 깊이 초과 에러 대응
  useEffect(() => {
    choiceFirstPartOption();
    choiceSecondPartOption();
  }, [choiceFirstPartOption, choiceSecondPartOption]);

  return (
    <Layout subFixed="true">
      <WhiteRoot css={root}>
        <SubLayout
          banner={Banner05}
          pageText={
            t("lang") === "EN"
              ? PAGE_LAYOUT.transferdetail
              : PAGE_LAYOUT_EN.transferdetail
          }
        >
          <DetailPopup
            time={dayjs(tableData?.created_at).format("YYYY-MM-DD HH:MM:ss")}
          />
          <div>
            <h3
              className={
                isMobileWidth
                  ? "mo-transfer-detail-text"
                  : "transfer-detail-text"
              }
            >
              &#183; {t("profile")} &#183;
            </h3>
            <ul className={isMobileWidth ? "mo-detail-list" : "detail-list"}>
              <li>
                <div>{t("name")}</div>
                <div>{tableData?.resume_name}</div>
              </li>
              <li>
                <div>{t("gender")}</div>
                <div>{gender}</div>
              </li>
              <li>
                <div>{t("BOD")}</div>
                <div>{tableData?.birth_day}</div>
              </li>
              <li>
                <div>{t("phonelabel")}</div>
                <div>{tableData?.phone}</div>
              </li>
              <li>
                <div>{t("emailaddr")}</div>
                <div>{tableData?.email}</div>
              </li>
              <li>
                <div>{t("idphoto")}</div>
                <div>
                  <img
                    src={
                      tableData?.profile_image_info
                        ? `${REACT_APP_API}/files/${tableData?.profile_image_info.id}`
                        : ""
                    }
                    alt="증명사진 이미지"
                  />
                </div>
              </li>
              <li>
                <div>{t("addresslabel")}</div>
                <div>
                  ({tableData?.zip_code}) {tableData?.address}{" "}
                  {tableData?.detail_address}
                </div>
              </li>
            </ul>
          </div>
          <div>
            <h3
              className={
                isMobileWidth
                  ? "mo-transfer-detail-text"
                  : "transfer-detail-text"
              }
            >
              &#183; {t("appfield")} &#183;
            </h3>
            <ul className={isMobileWidth ? "mo-detail-list" : "detail-list"}>
              <li>
                <div>{t("firstpre")}</div>
                <div>
                  <div>
                    <span>{t("department")}</span> {job1Depth1}
                  </div>
                  <div>
                    <span>{t("part")}</span> {job1Depth2}
                  </div>
                </div>
              </li>
              <li>
                <div>{t("Secondpre")}</div>
                <div>
                  <div>
                    <span>{t("department")}</span> {job2Depth1}
                  </div>
                  <div>
                    <span>{t("part")}</span> {job2Depth2}
                  </div>
                </div>
              </li>
              <li>
                <div>{t("accountnum")}</div>
                <div>
                  {bank} {tableData?.account_number}
                </div>
              </li>
            </ul>
          </div>
          <div>
            <h3
              className={
                isMobileWidth
                  ? "mo-transfer-detail-text"
                  : "transfer-detail-text"
              }
            >
              &#183; {t("EduMilImp2")} &#183;
            </h3>
            <ul className={isMobileWidth ? "mo-detail-list" : "detail-list"}>
              <li>
                <div>{t("edulevel")}</div>
                <div>
                  {education} {graduation}
                </div>
              </li>
              <li>
                <div>{t("schoolname")}</div>
                <div>{tableData?.school_name}</div>
              </li>
              <li>
                <div>{t("major")}</div>
                <div>{tableData?.major}</div>
              </li>
              <li>
                <div>{t("yearofgrad")}</div>
                <div>
                  {tableData?.graduation
                    ? tableData?.graduation +
                      (t("lang") === "EN" ? " 년도" : " Year")
                    : "-"}
                </div>
              </li>
              <li>
                <div>{t("militaryservice")}</div>
                <div>{military}</div>
              </li>
              <li>
                <div>{t("eligibility")}</div>
                <div>{eligibility}</div>
              </li>
              <li>
                <div>{t("impairment")}</div>
                <div>{disabled}</div>
              </li>
            </ul>
          </div>
          <div>
            <h3
              className={
                isMobileWidth
                  ? "mo-transfer-detail-text"
                  : "transfer-detail-text"
              }
            >
              &#183; {t("career2")} &#183;
            </h3>
            <ul className={isMobileWidth ? "mo-detail-list2" : "detail-list2"}>
              {careerInfo?.length > 0
                ? careerInfo.map((item, index) => {
                    const typeArr = [];
                    const EMPOLOY_STATE =
                      t("lang") === "EN" ? EMPLOY_OPTIONS : EMPLOY_OPTIONS_EN;
                    EMPOLOY_STATE.filter((type) => {
                      if (type?.value === item?.type?.value)
                        typeArr.push(type.label);
                    });

                    return (
                      <div className="detail-list-border" key={index}>
                        <li>
                          <div>{t("Coname")}</div>
                          <div>{item.career_name}</div>
                        </li>
                        <li>
                          <div>{t("emplotype")}</div>
                          <div>{typeArr[0]}</div>
                        </li>
                        <li>
                          <div>{t("emploperiod")}</div>
                          <div>
                            {item.start_date} ~ {item.end_date}
                          </div>
                        </li>
                        <li>
                          <div>{t("salary")}</div>
                          <div>
                            {t("lang") === "EN"
                              ? `${Number(item.salary).toLocaleString()} 원`
                              : `${Number(item.salary).toLocaleString()} Won`}
                          </div>
                        </li>
                        <li>
                          <div>{t("duty")}</div>
                          <div>{item.job}</div>
                        </li>
                        <li>
                          <div>{t("summarize")}</div>
                          <div>{item.job_summary}</div>
                        </li>
                      </div>
                    );
                  })
                : ""}
            </ul>
          </div>
          <div>
            <h3
              className={
                isMobileWidth
                  ? "mo-transfer-detail-text"
                  : "transfer-detail-text"
              }
            >
              &#183; {t("certificate")} &#183;
            </h3>
            <ul className={isMobileWidth ? "mo-detail-list" : "detail-list"}>
              {licenseInfo?.length > 0
                ? licenseInfo.map((item, index) => {
                    return (
                      <li key={index}>
                        <div>{item.pass_date}</div>
                        <div>
                          <div>
                            <span>{item.license_name}</span> {item.publisher}
                          </div>
                        </div>
                      </li>
                    );
                  })
                : ""}
            </ul>
          </div>
          <div>
            <h3
              className={
                isMobileWidth
                  ? "mo-transfer-detail-text"
                  : "transfer-detail-text"
              }
            >
              &#183; {t("yourself")} &#183;
            </h3>
            <div
              className={isMobileWidth ? "mo-slef-introduce" : "slef-introduce"}
            >
              {tableData.self_introducation}
            </div>
          </div>
          <div
            className={isMobileWidth ? "mo_transfer_submit" : "transfer_submit"}
          >
            <Button
              variant="secondary"
              preset="2"
              onClick={() => {
                navigate("/mypagelist");
              }}
            >
              {t("backtolist")}
            </Button>
          </div>
        </SubLayout>
      </WhiteRoot>
    </Layout>
  );
};

export default TransferDetail;

const TransferDetailStyled = (lang) => {
  return css`
    & .detail-list {
      border-top: 1px solid #222;
      margin-top: 30px;

      & .detail-list-border {
        border-top: 1px solid #000;
      }

      & li {
        display: flex;
        align-items: center;
        padding: 30px 0;
        border-bottom: 1px solid #ddd;
        font-size: 18px;

        & > div:nth-of-type(1) {
          width: 125px;
          font-weight: bold;
          color: #b2b2b2;
        }

        & > div:nth-of-type(2) {
          width: 500px;
        }

        & div {
          display: flex;

          & div:nth-of-type(2) {
            margin-left: ${lang === "EN" ? "20px" : "10px"};
          }
        }

        & span {
          color: #c09260;
          margin-right: 6px;
        }

        & img {
          width: 100px;
          height: 130px;
          background-color: #ddd;
        }
      }
    }

    & .mo-detail-list {
      border-top: 1px solid #222;
      margin-top: 12px;

      & .detail-list-border {
        border-top: 1px solid #000;
      }

      & li {
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #ddd;
        font-size: 14px;

        & > div:nth-of-type(1) {
          width: 170px;
          font-weight: bold;
          color: #b2b2b2;
        }

        & > div:nth-of-type(2) {
          ${lang !== "EN" && "display: flex"};
          ${lang !== "EN" && "flex-direction: column"};
          width: 500px;
        }

        & div {
          display: flex;

          & div:nth-of-type(2) {
            margin-left: ${lang === "EN" ? "20px" : "0"};
          }
        }

        & span {
          color: #c09260;
          margin-right: 6px;
        }

        & img {
          width: 100px;
          height: 130px;
          background-color: #ddd;
        }
      }
    }
  `;
};

const WhiteRoot = styled.div`
  & > div > div > .float_wrap > div {
    padding: 0 410px;
  }

  & .transfer-detail-text {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    margin-top: 80px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
  }

  & .mo-transfer-detail-text {
    clear: both;
    font-size: 18px;
    line-height: 38px;
    margin-top: 30px;
    color: var(--primary-color2);
    font-weight: bold;
    text-align: center;
  }

  & .transfer_submit {
    width: 408px;
    margin: 0 auto;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }

  & .mo_transfer_submit {
    margin: 0 auto;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;

    & .void-sec {
      margin: 0 6px;
    }
  }

  & .detail-list2 {
    margin-top: 30px;

    & .detail-list-border {
      border-top: 1px solid #000;
    }

    & li {
      display: flex;
      align-items: center;
      padding: 30px 0;
      border-bottom: 1px solid #ddd;
      font-size: 18px;

      & > div:nth-of-type(1) {
        width: 125px;
        font-weight: bold;
        color: #b2b2b2;
      }

      & > div:nth-of-type(2) {
        width: 500px;
      }

      & div {
        display: flex;

        & div:nth-of-type(2) {
          margin-left: 20px;
        }
      }

      & span {
        color: #c09260;
        margin-right: 6px;
      }

      & img {
        width: 100px;
        height: 130px;
        background-color: #ddd;
      }
    }
  }

  & .mo-detail-list2 {
    margin-top: 12px;

    & .detail-list-border {
      border-top: 1px solid #000;
    }

    & li {
      display: flex;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid #ddd;
      font-size: 14px;

      & > div:nth-of-type(1) {
        width: 170px;
        font-weight: bold;
        color: #b2b2b2;
      }

      & > div:nth-of-type(2) {
        width: 500px;
      }

      & div {
        display: flex;

        & div:nth-of-type(2) {
          margin-left: 20px;
        }
      }

      & span {
        color: #c09260;
        margin-right: 6px;
      }

      & img {
        width: 100px;
        height: 130px;
        background-color: #ddd;
      }
    }
  }

  & .slef-introduce {
    padding: 30px;
    background-color: #f9f9f9;
    margin: 30px 0 60px;
  }

  & .mo-slef-introduce {
    padding: 20px;
    background-color: #f9f9f9;
    margin: 12px 0 30px;
    font-size: 14px;
  }
`;
