import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isMobileWidthAtom } from "store/ui/atom";
import Button from "components/Button/Button";

const Error404 = () => {
  const navigate = useNavigate();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  if (isMobileWidth) {
    return (
      <ErrorMobileStyled>
        <h1>404</h1>
        <p className="error-message">
          죄송합니다.
          <br /> 현재 찾을 수 없는 페이지를 요청하셨습니다.
        </p>
        <p>
          존재하지 않는 주소를 입력하셨거나
          <br />
          요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.
          <br />
          궁금한 점이 있으시면 언제든 고객센터를 통해
          <br />
          문의해주시기 바랍니다.
          <br />
          감사합니다.
        </p>
        <Button
          type="button"
          variant="primaryHover"
          onClick={() => navigate("/home", { replace: true })}
        >
          메인으로
        </Button>
      </ErrorMobileStyled>
    );
  } else {
    return (
      <ErrorStyled>
        <h1>404</h1>
        <p className="error-message">
          죄송합니다. 현재 찾을 수 없는 페이지를 요청하셨습니다.
        </p>
        <p>
          존재하지 않는 주소를 입력하셨거나
          <br />
          요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.
          <br />
          궁금한 점이 있으시면 언제든 고객센터를 통해 문의해주시기 바랍니다.
          <br />
          감사합니다.
        </p>
        <Button
          type="button"
          variant="primaryHover"
          onClick={() => navigate("/home", { replace: true })}
        >
          메인으로
        </Button>
      </ErrorStyled>
    );
  }
};

export default Error404;

const ErrorStyled = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & h1 {
    font-size: 150px;
    color: #c09260;
  }

  & .error-message {
    font-size: 30px;
    color: #c09260;
  }

  & p {
    line-height: 30px;
    margin-bottom: 30px;
  }
`;

const ErrorMobileStyled = styled.div`
  height: 100vh;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & h1 {
    font-size: 100px;
    color: #c09260;
  }

  & .error-message {
    font-size: 20px;
    color: #c09260;
  }

  & p {
    font-size: 14px;
    margin-bottom: 30px;
  }
`;
