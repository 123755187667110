import React from "react";
import styled from "@emotion/styled";

// assets
import BgCircleFSVG from "assets/images/pc/bg_circle_f.svg";

const Circle02 = () => {
  return (
    <FloatRoot>
      <img
        src={BgCircleFSVG}
        className="bg_circle_f"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="300"
      />
    </FloatRoot>
  );
};

export default Circle02;

const FloatRoot = styled.div`
  & .bg_circle_f {
    position: absolute;
    margin: -210px 0 0 1000px;
  }
`;
