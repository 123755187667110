import * as yup from "yup";

export default yup.object().shape({
  TransferName: yup.string().required("이름을 입력하세요."),
  TransferGender: yup.string().required("성별을 선택하세요."),
  TransferBirth01: yup.string().required("생년월일을 입력하세요."),
  TransferBirth02: yup.string().required("생년월일을 입력하세요."),
  TransferBirth03: yup.string().required("이메일 주소를 입력하세요."),
  TransferPhone2: yup.string().required("휴대전화번호를 입력하세요."),
  TransferPhone3: yup.string().required("휴대전화번호를 입력하세요."),
  TransferEmail: yup.string().required("이메일 주소를 입력하세요."),
  Address1: yup.string().required("우편번호를 입력해 주세요."),
  Address2: yup.string().required("주소를 입력해 주세요."),
  Address3: yup.string().required("상세주소를 입력해 주세요."),
  TransferFirst01: yup
    .object()
    .nullable()
    .required("1차 희망 부서를 선택해 주세요."),
  TransferFirst02: yup
    .object()
    .nullable()
    .required("1차 희망 파트를 선택해 주세요."),
  TransferSecond01: yup
    .object()
    .nullable()
    .required("2차 희망 부서를 선택해 주세요."),
  TransferSecond02: yup
    .object()
    .nullable()
    .required("2차 희망 파트를 선택해 주세요."),
  TransferBank01: yup.object().nullable().required("은행을 선택해 주세요."),
  TransferBank02: yup.string().required("계좌번호를 입력해 주세요."),
  TransferEdu: yup.object().nullable().required("최종학력을 선택해 주세요."),
  TransferSchoolName: yup.string().required("학교명을 입력해 주세요."),
  TransferMajor: yup.string().required("전공을 입력해 주세요."),
  TransferGraduateGubun: yup
    .object()
    .nullable()
    .required("졸업구분을 선택해 주세요."),
  TransferArmy: yup.object().nullable().required("병역을 선택해 주세요."),
  TransferDisabled: yup
    .object()
    .nullable()
    .required("장애등급을 선택해 주세요."),
  TransferSelf: yup.string().required("자기소개를 입력해 주세요."),
  BankPolicyCheck: yup.boolean(),
});
