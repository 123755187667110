/* eslint-disable consistent-return */
import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// api
import busiApi from "api/business";

// components
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import Button from "components/Button/Button";
import FileInput from "components/FileInput/FileInput";
import Row from "containers/LabelWithInput/LabelWithInput";
import Postcode from "components/Postcode/DaumPostcode";

// hooks
import useAlert from "hooks/useAlert";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";
import {
  BUSINESS_OPTIONS,
  BUSINESS_OPTIONS_EN,
  PREFIX_NUMBER_OPTIONS,
} from "utils/constants";
import vaildationSchema from "utils/validation/businessValidationSchema";
import vaildationSchemaEn from "utils/validation/businessValidationSchemaEn";

// assets
import Banner04 from "assets/images/pc/img_banner04.png";

// 각 인풋 value 초기화 (name으로 구분)
const defaultValues = {
  companyName: "",
  companyBusiness: "",
  companyNumber: "",
  OwnerName: "",
  Address1: "",
  Address2: "",
  Address3: "",
  companySite: "",
  companyManager: "",
  companyPhone1: "",
  companyPhone2: "",
  companyPhone3: "",
  companyEmail: "",
  companyFile: "",
  companyMessage: "",
};

/**
 * @file 사업영역 페이지 Form
 * @brief 제휴문의 Form Validation
 * @author TW.Lee
 * @param files 첨부파일 상태 변수
 * @param isOpen 모달창 오픈 변수
 */
const Business = () => {
  const { noti } = useAlert();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const [submitState, setSubmitState] = useState("schemako");
  const [popup, setPopup] = useState(false);
  const [enrollCompany, setEnrollCompany] = useState({
    address: "",
    zipcode: "",
  });

  const UI = {
    schemako: vaildationSchema,
    schemaen: vaildationSchemaEn,
  };

  const {
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(UI[submitState]),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (t("lang") === "EN") {
      setSubmitState("schemako");
    } else {
      setSubmitState("schemaen");
    }
  }, [t("lang")]);

  useEffect(() => {
    if (values.companyFile[0]?.size > 1024 * 1024 * 5) {
      // 용량 초과시 경고후 해당 파일의 용량도 보여줌
      if (t("lang") === "EN") {
        noti("업로드 할 수 있는 파일 형식과 최대 용량을 확인해주세요.");
      } else {
        noti(
          "Please check the file types and maximum capacity that can be uploaded.",
          "Confirm"
        );
      }
      setValue("companyFile", "");
    }
  }, [values.companyFile]);

  const handleBusiSubmit = useMutation(
    (payload) => {
      return busiApi.busiInquiry(payload);
    },
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          if (t("lang") === "EN") {
            noti("관리자에게 전달되었습니다.");
          } else {
            noti(
              "Your inquiry has been delivered to our manager successfully.",
              "Confirm"
            );
          }
          navigate("/home", { replace: true });
        }
      },
      onError: async (err) => {
        throw err;
      },
    }
  );

  const fileFormData = new FormData();
  fileFormData.append("file", values.companyFile[0]);

  const addFile = async () => {
    const attachment = [];
    if (values.companyFile) {
      try {
        const res = await axios({
          method: "post",
          url: "/files",
          data: fileFormData,
        });
        if (res?.status === 200) {
          attachment.push(res.data.result.id);
        }
      } catch (err) {
        throw err;
      }
    }

    let payload = {
      company: values.companyName,
      type: values.companyBusiness.value,
      business_num: values.companyNumber,
      ceo: values.OwnerName,
      address: values.Address1 + values.Address2,
      detail_address: values.Address3,
      web_site: values.companySite,
      manager: values.companyManager,
      phone:
        values.companyPhone1.value +
        values.companyPhone2 +
        values.companyPhone3,
      email: values.companyEmail,
      message: values.companyMessage,
    };

    if (attachment) {
      payload = {
        ...payload,
        file: attachment,
      };
    }

    await handleBusiSubmit.mutate(payload);
  };

  // 사업자등록번호 검증
  const validationBusiness = useCallback(() => {
    const bNum = values.companyNumber;
    const regsplitNum = bNum
      .replace(/-/gi, "")
      .split("")
      .map((item) => {
        return parseInt(item, 10);
      });

    if (regsplitNum.length === 10) {
      const regkey = [1, 3, 7, 1, 3, 7, 1, 3, 5];
      let regNumSum = 0;

      for (let i = 0; i < regkey.length; i++) {
        regNumSum += regkey[i] * regsplitNum[i];
      }

      regNumSum += parseInt((regkey[8] * regsplitNum[8]) / 10, 10);
      const regCheck =
        Math.floor(regsplitNum[9]) === (10 - (regNumSum % 10)) % 10;

      return regCheck;
    }
  }, [values.companyNumber]);
  const isBusi = validationBusiness();

  return (
    <Layout subFixed="true">
      <WhiteRoot>
        <SubLayout
          banner={Banner04}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.business : PAGE_LAYOUT_EN.business
          }
        >
          <div className="business_inner">
            <BusinessInner01>
              <h3>&#183; {t("inquiryinfo")} &#183;</h3>
              <div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    handleSubmit(
                      (ele) => {
                        if (isBusi) {
                          addFile();
                        }
                        if (!isBusi) {
                          if (t("lang") === "EN") {
                            noti("유효하지 않은 사업자등록번호입니다.");
                          } else {
                            noti(
                              "Invalid business registration number.",
                              "Confirm"
                            );
                          }
                        }
                      },
                      (err) => {
                        const keys = [
                          "companyName",
                          "companyBusiness",
                          "companyNumber",
                          "companyManager",
                          "companyPhone1",
                          "companyPhone2",
                          "companyPhone3",
                          "companyEmail",
                          "companyMessage",
                        ];

                        for (const key of keys) {
                          if (err[key]) {
                            if (
                              key === "companyEmail" &&
                              err[key].type === "matches"
                            ) {
                              if (t("lang") === "EN") {
                                noti(err[key].message, "확인");
                              } else {
                                noti(err[key].message, "Confirm");
                              }
                              setFocus(key);
                              break;
                            } else {
                              if (t("lang") === "EN") {
                                noti("필수 입력 항목을 모두 작성해주세요.");
                              } else {
                                noti(
                                  "Please fill in all required fields.",
                                  "Confirm"
                                );
                              }
                              setFocus(key);
                              break;
                            }
                          }
                        }
                      }
                    )();
                  }}
                >
                  <div
                    className={isMobileWidth ? "mo_form-inner" : "form-inner"}
                  >
                    <Row label={t("Coname")} isHightlight>
                      <div
                        className={isMobileWidth ? "mo_input-box" : "input-box"}
                      >
                        <Controller
                          control={control}
                          name="companyName"
                          render={({ field }) => (
                            <Input
                              {...field}
                              className="inp-item"
                              type="text"
                              placeholder={t("enterconame")}
                              fullWidth
                              errors={errors}
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label={t("Busifield")} isHightlight>
                      <div
                        className={isMobileWidth ? "mo_input-box" : "input-box"}
                      >
                        <Controller
                          control={control}
                          name="companyBusiness"
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder={t("select")}
                              fullWidth
                              options={
                                t("lang") === "EN"
                                  ? BUSINESS_OPTIONS
                                  : BUSINESS_OPTIONS_EN
                              }
                              errors={errors}
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label={t("businessnum")}>
                      <div
                        className={isMobileWidth ? "mo_input-box" : "input-box"}
                      >
                        <Controller
                          control={control}
                          name="companyNumber"
                          render={({ field }) => (
                            <Input
                              {...field}
                              className="inp-item"
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              placeholder={t("enternumber")}
                              maxLength="10"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label={t("reprename")}>
                      <div
                        className={isMobileWidth ? "mo_input-box" : "input-box"}
                      >
                        <Controller
                          control={control}
                          name="OwnerName"
                          render={({ field }) => (
                            <Input
                              {...field}
                              className="inp-item"
                              type="text"
                              placeholder={t("enterownername")}
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label={t("addresslabel")}>
                      <div
                        className={isMobileWidth ? "mo_input-box" : "input-box"}
                      >
                        <div className="zipcode">
                          <Controller
                            control={control}
                            name="Address1"
                            render={({ field }) => (
                              <Input
                                {...field}
                                readOnly
                                className="inp-item"
                                type="number"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                placeholder={t("enterzipcode")}
                                fullWidth
                                value={enrollCompany.zipcode}
                              />
                            )}
                          />
                          {isMobileWidth ? (
                            <button
                              type="button"
                              className="mo-address-search"
                              onClick={() => {
                                setPopup(true);
                              }}
                            >
                              {t("searchaddrlabel")}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="address-search"
                              onClick={() => {
                                setPopup(true);
                              }}
                            >
                              {t("searchaddrlabel")}
                            </button>
                          )}
                          {popup && (
                            <Postcode
                              popup={popup}
                              setPopup={setPopup}
                              company={enrollCompany}
                              setcompany={setEnrollCompany}
                            />
                          )}
                        </div>
                        <div className="void-sec"></div>
                        <Controller
                          control={control}
                          name="Address2"
                          render={({ field }) => (
                            <Input
                              {...field}
                              readOnly
                              className="inp-item"
                              type="text"
                              placeholder={t("addresslabel")}
                              fullWidth
                              value={enrollCompany.address}
                            />
                          )}
                        />
                        <div className="void-sec"></div>
                        <Controller
                          control={control}
                          name="Address3"
                          render={({ field }) => (
                            <Input
                              {...field}
                              className="inp-item"
                              type="text"
                              placeholder={t("enterdetailaddr")}
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label={t("website")}>
                      <div
                        className={isMobileWidth ? "mo_input-box" : "input-box"}
                      >
                        <Controller
                          control={control}
                          name="companySite"
                          render={({ field }) => (
                            <Input
                              {...field}
                              className="inp-item"
                              type="text"
                              placeholder="https://"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label={t("managerlabel")} isHightlight>
                      <div
                        className={isMobileWidth ? "mo_input-box" : "input-box"}
                      >
                        <Controller
                          control={control}
                          name="companyManager"
                          render={({ field }) => (
                            <Input
                              {...field}
                              className="inp-item"
                              type="text"
                              placeholder={t("entermanagername")}
                              fullWidth
                              errors={errors}
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label={t("phonelabel")} isHightlight>
                      <div
                        className={
                          isMobileWidth
                            ? "mo_input-box phonenumber"
                            : "input-box phonenumber"
                        }
                      >
                        <Controller
                          control={control}
                          name="companyPhone1"
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder={t("select")}
                              variant="tertiary"
                              w={isMobileWidth ? "92" : ""}
                              options={PREFIX_NUMBER_OPTIONS}
                              errors={errors}
                            />
                          )}
                        />
                        <span>-</span>
                        <Controller
                          control={control}
                          name="companyPhone2"
                          render={({ field }) => (
                            <Input
                              {...field}
                              className="inp-item"
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              maxLength="4"
                              errors={errors}
                            />
                          )}
                        />
                        <span>-</span>
                        <Controller
                          control={control}
                          name="companyPhone3"
                          render={({ field }) => (
                            <Input
                              {...field}
                              className="inp-item"
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              maxLength="4"
                              errors={errors}
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label={t("emailaddr")} isHightlight>
                      <div
                        className={isMobileWidth ? "mo_input-box" : "input-box"}
                      >
                        <Controller
                          control={control}
                          name="companyEmail"
                          render={({ field }) => (
                            <Input
                              {...field}
                              className="inp-item"
                              type="text"
                              placeholder={t("enteremailaddr")}
                              fullWidth
                              errors={errors}
                            />
                          )}
                        />
                      </div>
                    </Row>
                    <Row label={t("attahfile")}>
                      <div
                        className={isMobileWidth ? "mo_input-box" : "input-box"}
                      >
                        <div className="attachment">
                          <Controller
                            control={control}
                            name="companyFile"
                            render={({ field }) => (
                              <FileInput
                                {...field}
                                className="inp-item"
                                handleRemoveFile
                              >
                                {t("enterfile")}
                              </FileInput>
                            )}
                          />
                        </div>
                      </div>
                    </Row>
                    <Row label={t("messagelabel")} isHightlight>
                      <div
                        className={isMobileWidth ? "mo_input-box" : "input-box"}
                      >
                        <Controller
                          control={control}
                          name="companyMessage"
                          render={({ field }) => (
                            <Input
                              {...field}
                              multiline
                              useMaxLengthIndicator
                              maxLength={1000}
                              className="inp-item"
                              type="text"
                              placeholder={t("entermessage")}
                              fullWidth
                              errors={errors}
                            />
                          )}
                        />
                      </div>
                    </Row>
                  </div>
                  <div className="submit-button">
                    <Button type="submit" variant="primary" preset="1">
                      {t("suggest")}
                    </Button>
                  </div>
                </form>
              </div>
            </BusinessInner01>
          </div>
        </SubLayout>
      </WhiteRoot>
    </Layout>
  );
};

export default Business;

const WhiteRoot = styled.div`
  & > div > div > .float_wrap > div {
    padding: 0 410px;
  }
`;

const BusinessInner01 = styled.div`
  margin-top: 60px;

  & .form-inner {
    margin: 30px 0 60px;
    padding-top: 40px;
    border-top: 1px solid #222;
    border-bottom: 1px solid #ddd;
  }

  & .mo_form-inner {
    margin: 12px 0 30px;
    padding-top: 20px;
    border-top: 1px solid #222;
    border-bottom: 1px solid #ddd;
  }

  & h3 {
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: #c09260;
    font-weight: bold;
    text-align: center;
  }

  & .box-text {
    font-size: 18px;
    font-weight: bold;
    color: #222;
  }

  & .input-box {
    margin: 16px 0 30px;
  }

  & .mo_input-box {
    margin: 10px 0 20px;
  }

  & .void-sec {
    height: 10px;
  }

  & .submit-button {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
  }

  & .address-search {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
  }

  & .mo-address-search {
    width: 88px;
    height: 48px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 14px;
    margin-left: 8px;
  }

  & .zipcode {
    display: flex;
  }

  & .attachment {
    display: flex;
    margin-bottom: 10px;
  }

  & .phonenumber {
    display: flex;
    align-items: center;

    & span {
      margin: 0 10px;
    }
  }
`;
