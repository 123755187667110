import * as yup from "yup";

export default yup.object().shape({
  PartnerName: yup.string().required("Please enter your Name."),
  PartnerTeam: yup.string().required("Please enter the Department name."),
  PartnerLevel: yup.string().required("Please enter the Position."),
  userPhone2: yup.string().required("Please enter your Phone Number."),
  verifiCode: yup
    .string()
    .required(
      "Please enter the verification code you received via Text message."
    ),
  Address1: yup.string().required("Please enter your Zip Code."),
  Address2: yup.string().required("Please enter your Address."),
  Address3: yup.string().required("Please enter your detailed address."),
  companyName: yup.string().required("Please enter the name of your company."),
  OwnerPhone2: yup.string().required("Please enter the Representative Number."),
  companyFaxNumber01: yup.string().required("Please enter the Fax Number."),
  companyFaxNumber02: yup.string().required(""),
  companyFaxNumber03: yup.string().required(""),
  businessNumber: yup
    .string()
    .required("Please enter the Business Registration Number."),
  OwnerName: yup.string().required("Please enter the Representative Name."),
  TransferBank02: yup
    .string()
    .required("Please enter the Company Account Number."),
});
