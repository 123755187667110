import React, { useEffect, useRef } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

const Line = () => {
  const canvasRef = useRef();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  useEffect(() => {
    const unit = 400;
    let canvas;
    let context;
    let height;
    let width;
    let xAxis;
    let yAxis;

    const paintSine = (t, zoom, delay) => {
      let x = t;
      let y = Math.sin(x) / zoom;
      context.moveTo(yAxis, unit * y + xAxis);
      for (let i = yAxis; i <= width + 10; i += 10) {
        x = t + (-yAxis + i) / unit / zoom;
        y = Math.sin(x - delay) / 15;
        context.lineTo(i, unit * y + xAxis);
      }
    };

    const paintWave = (color, alpha, zoom, delay) => {
      context.fillStyle = color;
      context.strokeStyle = color;
      context.globalAlpha = alpha;
      context.beginPath(); // start path
      // eslint-disable-next-line no-use-before-define
      paintSine(paint.t / 3.5, zoom, delay);
      context.stroke();
    };

    const paint = () => {
      context.clearRect(0, 0, width, height);
      if (isMobileWidth) {
        paintWave("rgb(219, 163, 102)", 0.15, 0.5, 0);
        paintWave("rgb(219, 163, 102)", 0.05, 1.5, 1250);
        paintWave("rgb(219, 163, 102)", 0.3, 1, 500);
      } else {
        paintWave("rgb(219, 163, 102)", 0.35, 1, 0);
        paintWave("rgb(219, 163, 102)", 0.25, 2.5, 1250);
        paintWave("rgb(219, 163, 102)", 0.6, 1.5, 500);
      }

      // Update the time and draw again
      paint.seconds += 0.014;
      paint.t = paint.seconds * Math.PI;
      setTimeout(paint, 35);
    };

    paint.seconds = 0;
    paint.t = 0;
    const init = () => {
      canvas = canvasRef.current;
      canvas.width = document.documentElement.clientWidth;
      canvas.height = document.documentElement.clientHeight;
      context = canvas.getContext("2d");
      height = canvas.height;
      width = canvas.width;
      xAxis = Math.floor(height / 2.5);
      yAxis = 0;

      paint();
    };

    init();
  }, []);

  const rootCss = () => {
    return css`
      width: 100vw;
      position: absolute;
      z-index: 500;
      left: -2px;
      margin-top: -5%;

      @media screen and (max-width: 700px) {
        margin-top: -35%;
      }
      @media screen and (min-width: 701px) and (max-width: 1300px) {
        margin-top: -20%;
      }
    `;
  };

  return (
    <canvas css={rootCss} width="100%" height="100%" ref={canvasRef}></canvas>
  );
};

export default Line;
