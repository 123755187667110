import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// hooks
import useAlert from "hooks/useAlert";

/**
 * @file AuthGuard Components
 */
const AuthGuard = ({ children }) => {
  const { noti } = useAlert();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const targetStorage = localStorage.getItem("accessToken")
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem("accessToken");

  useEffect(() => {
    if (!accessToken) {
      navigate("/login", { replace: true });
      if (t("lang") === "EN") {
        noti("로그인 후 이용해주세요.");
      } else {
        noti("Sorry! You must be logged in.", "Confirm");
      }
    }
  }, []);

  if (!accessToken) {
    return null;
  }
  return children;
};

export default AuthGuard;
