import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// assets
import BgCircleASVG from "assets/images/pc/bg_circle_a.svg";

const Innovation = () => {
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  if (isMobileWidth) {
    return (
      <FloatMobileRoot>
        <div>
          <img
            src={BgCircleASVG}
            className="bg_circle_a"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="300"
          />
          <img
            src={require("assets/images/pc/Innovation.png")}
            className="innovation"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="300"
          />
        </div>
      </FloatMobileRoot>
    );
  } else {
    return (
      <FloatRoot>
        <img
          src={BgCircleASVG}
          className="bg_circle_a"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        />
        <img
          src={require("assets/images/pc/Innovation.png")}
          className="innovation"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        />
      </FloatRoot>
    );
  }
};

export default Innovation;

const FloatRoot = styled.div`
  & .bg_circle_a {
    position: absolute;
    margin: -640px 0 0 1160px;
    z-index: 50;
  }
  & .innovation {
    position: absolute;
    margin: -220px 0 0 1400px;
  }
`;

const FloatMobileRoot = styled.div`
  position: absolute;
  top: 300px;
  right: 0;
  width: 190px;
  height: 440px;
  overflow: hidden;
  z-index: 10;

  & .bg_circle_a {
    width: 400px;
    position: absolute;
    z-index: 50;
  }
  & .innovation {
    width: 400px;
    position: absolute;
    margin: 190px 0 0 0;
  }
`;
