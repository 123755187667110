import React, { useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";
import { useNavigate, useLocation } from "react-router-dom";

// components
import Button from "components/Button/Button";

// utils
import {
  DEPARTMENT_OPTIONS,
  PART1_OPTIONS,
  PART2_OPTIONS,
  PART3_OPTIONS,
  PART4_OPTIONS,
  PART5_OPTIONS,
  DEPARTMENT_OPTIONS_EN,
  PART1_OPTIONS_EN,
  PART2_OPTIONS_EN,
  PART3_OPTIONS_EN,
  PART4_OPTIONS_EN,
  PART5_OPTIONS_EN,
} from "utils/constants";
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner05 from "assets/images/pc/img_banner05.png";
import IconCheckSVG from "assets/images/pc/icon_check.svg";

/**
 * @file 커리어 - 인력등록 완료 페이지
 * @author TW.Lee
 */
const TransferDone = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const [department1, setDepartment1] = useState();
  const [department2, setDepartment2] = useState();
  const [part1, setPart1] = useState();
  const [part2, setPart2] = useState();

  // eslint-disable-next-line no-use-before-define
  const root = TransferDoneStyled(t("lang"));

  useEffect(() => {
    switch (t("lang")) {
      case "EN":
        setDepartment1(
          DEPARTMENT_OPTIONS.filter(
            (v) => location.state?.job1Depth1 === v.value
          )
        );
        setDepartment2(
          DEPARTMENT_OPTIONS.filter(
            (v) => location.state?.job2Depth1 === v.value
          )
        );
        break;
      case "KO":
        setDepartment1(
          DEPARTMENT_OPTIONS_EN.filter(
            (v) => location.state?.job1Depth1 === v.value
          )
        );
        setDepartment2(
          DEPARTMENT_OPTIONS_EN.filter(
            (v) => location.state?.job2Depth1 === v.value
          )
        );
        break;
      default:
        break;
    }
  }, [t("lang"), DEPARTMENT_OPTIONS, DEPARTMENT_OPTIONS_EN]);

  useEffect(() => {
    const part1Option1 =
      t("lang") === "EN"
        ? PART1_OPTIONS.filter((v) => location.state?.job1Depth2 === v.value)
        : PART1_OPTIONS_EN.filter(
            (v) => location.state?.job1Depth2 === v.value
          );
    const part2Option1 =
      t("lang") === "EN"
        ? PART2_OPTIONS.filter((v) => location.state?.job1Depth2 === v.value)
        : PART2_OPTIONS_EN.filter(
            (v) => location.state?.job1Depth2 === v.value
          );
    const part3Option1 =
      t("lang") === "EN"
        ? PART3_OPTIONS.filter((v) => location.state?.job1Depth2 === v.value)
        : PART3_OPTIONS_EN.filter(
            (v) => location.state?.job1Depth2 === v.value
          );
    const part4Option1 =
      t("lang") === "EN"
        ? PART4_OPTIONS.filter((v) => location.state?.job1Depth2 === v.value)
        : PART4_OPTIONS_EN.filter(
            (v) => location.state?.job1Depth2 === v.value
          );
    const part5Option1 =
      t("lang") === "EN"
        ? PART5_OPTIONS.filter((v) => location.state?.job1Depth2 === v.value)
        : PART5_OPTIONS_EN.filter(
            (v) => location.state?.job1Depth2 === v.value
          );
    const part1Option2 =
      t("lang") === "EN"
        ? PART1_OPTIONS.filter((v) => location.state?.job2Depth2 === v.value)
        : PART1_OPTIONS_EN.filter(
            (v) => location.state?.job2Depth2 === v.value
          );
    const part2Option2 =
      t("lang") === "EN"
        ? PART2_OPTIONS.filter((v) => location.state?.job2Depth2 === v.value)
        : PART2_OPTIONS_EN.filter(
            (v) => location.state?.job2Depth2 === v.value
          );
    const part3Option2 =
      t("lang") === "EN"
        ? PART3_OPTIONS.filter((v) => location.state?.job2Depth2 === v.value)
        : PART3_OPTIONS_EN.filter(
            (v) => location.state?.job2Depth2 === v.value
          );
    const part4Option2 =
      t("lang") === "EN"
        ? PART4_OPTIONS.filter((v) => location.state?.job2Depth2 === v.value)
        : PART4_OPTIONS_EN.filter(
            (v) => location.state?.job2Depth2 === v.value
          );
    const part5Option2 =
      t("lang") === "EN"
        ? PART5_OPTIONS.filter((v) => location.state?.job2Depth2 === v.value)
        : PART5_OPTIONS_EN.filter(
            (v) => location.state?.job2Depth2 === v.value
          );

    if (part1Option1.length > 0) {
      setPart1(part1Option1);
    } else if (part2Option1.length > 0) {
      setPart1(part2Option1);
    } else if (part3Option1.length > 0) {
      setPart1(part3Option1);
    } else if (part4Option1.length > 0) {
      setPart1(part4Option1);
    } else if (part5Option1.length > 0) {
      setPart1(part5Option1);
    }
    if (part1Option2.length > 0) {
      setPart2(part1Option2);
    } else if (part2Option2.length > 0) {
      setPart2(part2Option2);
    } else if (part3Option2.length > 0) {
      setPart2(part3Option2);
    } else if (part4Option2.length > 0) {
      setPart2(part4Option2);
    } else if (part5Option2.length > 0) {
      setPart2(part5Option2);
    }
  }, [
    t("lang"),
    PART1_OPTIONS,
    PART2_OPTIONS,
    PART3_OPTIONS,
    PART4_OPTIONS,
    PART5_OPTIONS,
  ]);

  if (isMobileWidth) {
    return (
      <Layout subFixed="true">
        <WhiteRoot>
          <SubLayout
            banner={Banner05}
            pageText={
              t("lang") === "EN"
                ? PAGE_LAYOUT.transfer
                : PAGE_LAYOUT_EN.transfer
            }
          >
            <TransferDoneMobileInner>
              <div>
                <img src={IconCheckSVG} alt="체크 아이콘" />
              </div>
              <h3>{t("manpowerdone")}</h3>
              <div className="transfer-info">
                <div>
                  <p>{t("manpowerregist")}</p>{" "}
                  <span>{location.state ? location.state?.time : ""}</span>
                </div>
                <div>
                  <p>{t("manpowerapp")}</p>
                  <div className="transfer-list">
                    <div>
                      <span>{t("manpowerfirst")}</span>
                      {department1 ? department1[0]?.label : ""} -{" "}
                      {part1 ? part1[0]?.label : ""}
                    </div>
                    <div>
                      <span>{t("manpowersecond")}</span>
                      {department2 ? department2[0]?.label : ""} -{" "}
                      {part2 ? part2[0]?.label : ""}
                    </div>
                  </div>
                </div>
                <div>
                  <p>{t("manpowerid")}</p>{" "}
                  <span>{location.state ? location.state?.email : ""}</span>
                </div>
                <div>
                  <p>{t("manpowername")}</p>{" "}
                  <span>{location.state ? location.state?.name : ""}</span>
                </div>
                <div>
                  <p>{t("manpowerbank")}</p>{" "}
                  <span>
                    {location.state ? location.state?.bank : ""}{" "}
                    {location.state ? location.state?.account : ""}
                  </span>
                </div>
              </div>
              <div className="mypage-move">
                <Button
                  variant="primaryHover"
                  fullWidth
                  onClick={() => navigate("/mypage", { replace: true })}
                >
                  {t("mypage")}
                </Button>
              </div>
            </TransferDoneMobileInner>
          </SubLayout>
        </WhiteRoot>
      </Layout>
    );
  } else {
    return (
      <Layout subFixed="true">
        <WhiteRoot>
          <SubLayout
            banner={Banner05}
            pageText={
              t("lang") === "EN"
                ? PAGE_LAYOUT.transfer
                : PAGE_LAYOUT_EN.transfer
            }
          >
            <TransferDoneInner css={root}>
              <div>
                <img src={IconCheckSVG} alt="체크 아이콘" />
              </div>
              <h3>{t("manpowerdone")}</h3>
              <div className="transfer-info">
                <div>
                  <p>{t("manpowerregist")}</p>{" "}
                  <span>{location.state ? location.state?.time : ""}</span>
                </div>
                <div>
                  <p>{t("manpowerapp")}</p>
                  <div className="transfer-list">
                    <div>
                      <span>{t("manpowerfirst")}</span>
                      {department1 ? department1[0]?.label : ""} -{" "}
                      {part1 ? part1[0]?.label : ""}
                    </div>
                    <div>
                      <span>{t("manpowersecond")}</span>
                      {department2 ? department2[0]?.label : ""} -{" "}
                      {part2 ? part2[0]?.label : ""}
                    </div>
                  </div>
                </div>
                <div>
                  <p>{t("manpowerid")}</p>{" "}
                  <span>{location.state ? location.state?.email : ""}</span>
                </div>
                <div>
                  <p>{t("manpowername")}</p>{" "}
                  <span>{location.state ? location.state?.name : ""}</span>
                </div>
                <div>
                  <p>{t("manpowerbank")}</p>{" "}
                  <span>
                    {location.state ? location.state?.bank : ""}{" "}
                    {location.state ? location.state?.account : ""}
                  </span>
                </div>
              </div>
              <div className="mypage-move">
                <Button
                  variant="primaryHover"
                  fullWidth
                  onClick={() => navigate("/mypage", { replace: true })}
                >
                  {t("mypage")}
                </Button>
              </div>
            </TransferDoneInner>
          </SubLayout>
        </WhiteRoot>
      </Layout>
    );
  }
};

export default TransferDone;

const TransferDoneStyled = (lang) => {
  return css`
    & .transfer-info {
      width: 499px;
      padding: ${lang === "EN" ? "40px 110px" : "40px 50px"};
      border-radius: 8px;
      background-color: #f9f9f9;

      & > div {
        display: flex;

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }

      & p {
        width: ${lang === "EN" ? "100px" : "150px"};
        font-size: 16px;
        color: #666;
      }

      & span {
        font-size: 16px;
        width: 300px;
      }

      & .transfer-list {
        width: 300px;

        & > div {
          display: flex;
          margin-bottom: 8px;
          font-size: ${lang === "EN" ? "16px" : "14px"};
        }
        & span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: ${lang === "EN" ? "43px" : "50px"};
          height: 23px;
          margin-right: 6px;
          font-size: 13px;
          font-weight: normal;
          border-radius: 4px;
          background-color: #a89a8b;
        }
      }
    }
  `;
};

const WhiteRoot = styled.div`
  & > div > div > .float_wrap > div {
    padding: 0 410px;
  }
`;

const TransferDoneInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px 0;

  & h3 {
    font-size: 26px;
    font-weight: bold;
    margin: 24px 0 40px;
  }

  & .mypage-move {
    width: 500px;
    margin-top: 40px;
  }
`;

const TransferDoneMobileInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 0 60px;

  & img {
    width: 40px;
  }

  & h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 12px 0 20px;
  }

  & .transfer-info {
    padding: 30px 0 30px 30px;
    border-radius: 8px;
    background-color: #f9f9f9;

    & > div {
      display: flex;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }

    & p {
      width: 70px;
      font-size: 16px;
      color: #666;
    }

    & span {
      font-size: 16px;
      width: 240px;
    }

    & .transfer-list {
      width: 200px;

      & > div {
        display: flex;
        margin-bottom: 8px;
      }
      & span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 43px;
        height: 23px;
        margin-right: 6px;
        font-size: 13px;
        font-weight: normal;
        border-radius: 4px;
        background-color: #a89a8b;
      }
    }
  }

  & .mypage-move {
    width: 320px;
    margin-top: 20px;
  }
`;
