/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

const rootCss = (isMobileWidth) => {
  return css`
    font-size: ${isMobileWidth ? "14px" : "18px"};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #222;

    & span {
      color: #c09260;
      margin-left: 4px;
    }
  `;
};

/**
 * @file Label Components
 */
const Label = (props) => {
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const { children, isHightlight } = props;
  const root = rootCss(isMobileWidth);
  return (
    <label css={root}>
      {children}
      {isHightlight && <span>*</span>}
    </label>
  );
};

export default Label;
