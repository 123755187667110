// hooks
import useWindowScroll from "hooks/useWindowScroll";

import Header from "./Header";
import Footer from "./Footer";
import TopButton from "./TopButton";

/**
 * @file Header, Footer, Topbutton 공통 레이아웃
 */
const Layout = ({ children, subFixed }) => {
  const { overflowY, y } = useWindowScroll();

  return (
    <div>
      <Header subFixed={subFixed} />
      {children}
      {overflowY && y > 0 && <TopButton />}
      <Footer />
    </div>
  );
};

export default Layout;
