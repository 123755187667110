/**
 * @file 상수 관리 파일
 * @param ERROR_COLOR 에러 색상
 * @param LIST_OPTIONS 채용공고/행사공지 select 옵션
 * @param BUSINESS_OPTIONS 제휴문의 사업분야 옵션
 * @param PART_TIME_OPTIONS 근무가능시간 옵션
 * @param PREFIX_NUMBER_OPTIONS 핸드폰 앞자리 옵션
 * @param EDUCATION_OPTIONS 학력 옵션
 * @param GRADUATE_OPTIONS 재학상태 옵션
 * @param MILITARY_OPTIONS 병역 옵션
 * @param DISABLED_OPTIONS 장애 옵션
 * @param EMPLOY_OPTIONS 고용형태 옵션
 * @param DEPARTMENT_OPTIONS 1차 희망 옵션
 * @param PART1_OPTIONS 객실팀 옵션
 * @param PART2_OPTIONS 객실정비팀 옵션
 * @param PART3_OPTIONS 식음팀 옵션
 * @param PART4_OPTIONS 안전관리팀 옵션
 * @param PART5_OPTIONS 시설관리팀 옵션
 * @param CARD_COMPANY_OPTIONS 은행 옵션
 * @author TW.Lee
 */

export const ERROR_COLOR = "#ff4b4b";
export const LIST_OPTIONS = [
  { value: "created_at", label: "등록일순", code: "01" },
  { value: "end_date", label: "마감일순", code: "02" },
];

export const LIST_OPTIONS_EN = [
  { value: "createdAt", label: "Sort by registration dates", code: "01" },
  { value: "updatedAt", label: "Sort by deadlines", code: "02" },
];

export const BUSINESS_OPTIONS = [
  { value: "Laundry_service", label: "세탁물 공급업", code: "01" },
  { value: "Workforce_dispatch", label: "인력 공급업", code: "02" },
  { value: "Bedding_supplier", label: "침구류 공급업", code: "03" },
  { value: "Pottery_supplier", label: "도기류 공급업", code: "04" },
  { value: "Others", label: "기타", code: "05" },
  { value: "Lotte", label: "롯데호텔", code: "06" },
];

export const BUSINESS_OPTIONS_EN = [
  { value: "Laundry_service", label: "Laundry Service", code: "01" },
  { value: "Workforce_dispatch", label: "Workforce Dispatch", code: "02" },
  { value: "Bedding_supplier", label: "Bedding Supplier", code: "03" },
  { value: "Pottery_supplier", label: "Pottery Supplier", code: "04" },
  { value: "Others", label: "Others", code: "05" },
  { value: "Lotte", label: "Lotte Hotel", code: "06" },
];

export const PART_TIME_OPTIONS = [
  { value: "0", label: "0시" },
  { value: "1", label: "1시" },
  { value: "2", label: "2시" },
  { value: "3", label: "3시" },
  { value: "4", label: "4시" },
  { value: "5", label: "5시" },
  { value: "6", label: "6시" },
  { value: "7", label: "7시" },
  { value: "8", label: "8시" },
  { value: "9", label: "9시" },
  { value: "10", label: "10시" },
  { value: "11", label: "11시" },
  { value: "12", label: "12시" },
  { value: "13", label: "13시" },
  { value: "14", label: "14시" },
  { value: "15", label: "15시" },
  { value: "16", label: "16시" },
  { value: "17", label: "17시" },
  { value: "18", label: "18시" },
  { value: "19", label: "19시" },
  { value: "20", label: "20시" },
  { value: "21", label: "21시" },
  { value: "22", label: "22시" },
  { value: "23", label: "23시" },
];

export const PART_TIME_OPTIONS_EN = [
  { value: "0", label: "0 o'clock" },
  { value: "1", label: "1 o'clock" },
  { value: "2", label: "2 o'clock" },
  { value: "3", label: "3 o'clock" },
  { value: "4", label: "4 o'clock" },
  { value: "5", label: "5 o'clock" },
  { value: "6", label: "6 o'clock" },
  { value: "7", label: "7 o'clock" },
  { value: "8", label: "8 o'clock" },
  { value: "9", label: "9 o'clock" },
  { value: "10", label: "10 o'clock" },
  { value: "11", label: "11 o'clock" },
  { value: "12", label: "12 o'clock" },
  { value: "13", label: "13 o'clock" },
  { value: "14", label: "14 o'clock" },
  { value: "15", label: "15 o'clock" },
  { value: "16", label: "16 o'clock" },
  { value: "17", label: "17 o'clock" },
  { value: "18", label: "18 o'clock" },
  { value: "19", label: "19 o'clock" },
  { value: "20", label: "20 o'clock" },
  { value: "21", label: "21 o'clock" },
  { value: "22", label: "22 o'clock" },
  { value: "23", label: "23 o'clock" },
];

export const PREFIX_NUMBER_OPTIONS = [
  { value: "010", label: "010", code: "01" },
  { value: "011", label: "011", code: "02" },
  { value: "016", label: "016", code: "03" },
  { value: "017", label: "017", code: "04" },
  { value: "019", label: "019", code: "05" },
  { value: "02", label: "02", code: "06" },
  { value: "031", label: "031", code: "07" },
  { value: "032", label: "032", code: "08" },
  { value: "033", label: "033", code: "08" },
  { value: "041", label: "041", code: "10" },
  { value: "042", label: "042", code: "11" },
  { value: "043", label: "043", code: "12" },
  { value: "051", label: "051", code: "13" },
  { value: "052", label: "052", code: "14" },
  { value: "053", label: "053", code: "15" },
  { value: "054", label: "054", code: "16" },
  { value: "055", label: "055", code: "17" },
  { value: "061", label: "061", code: "18" },
  { value: "062", label: "062", code: "19" },
  { value: "063", label: "063", code: "20" },
  { value: "064", label: "064", code: "21" },
];

export const EDUCATION_OPTIONS = [
  { value: "Graduate_School", label: "대학원", code: "01" },
  { value: "University", label: "대학", code: "02" },
  { value: "College", label: "전문대학", code: "03" },
  { value: "High_School", label: "고등학교", code: "04" },
  { value: "Middle_School", label: "중학교", code: "05" },
];

export const EDUCATION_OPTIONS_EN = [
  { value: "Graduate_School", label: "Graduate School", code: "01" },
  { value: "University", label: "University", code: "02" },
  { value: "College", label: "College", code: "03" },
  { value: "High_School", label: "High School", code: "04" },
  { value: "Middle_School", label: "Middle school", code: "05" },
];

export const GRADUATE_OPTIONS = [
  { value: "Attending", label: "재학 중", code: "01" },
  { value: "Expected_to_Graduate", label: "졸업 예정", code: "02" },
  { value: "Graduated", label: "졸업", code: "03" },
];

export const GRADUATE_OPTIONS_EN = [
  { value: "Attending", label: "Attending", code: "01" },
  { value: "Expected_to_Graduate", label: "Expected to Graduate", code: "02" },
  { value: "Graduated", label: "Graduated", code: "03" },
];

export const MILITARY_OPTIONS = [
  { value: "None", label: "해당없음", code: "01" },
  { value: "Fulfilled", label: "만기전역", code: "02" },
  { value: "Impairment", label: "의병 · 의가사 제대", code: "03" },
  { value: "Unfulfilled", label: "미필", code: "04" },
  { value: "Exempted", label: "면제", code: "05" },
];

export const MILITARY_OPTIONS_EN = [
  { value: "None", label: "N/A", code: "01" },
  { value: "Fulfilled", label: "Honorable Discharge", code: "02" },
  { value: "Impairment", label: "Impairment", code: "03" },
  { value: "Unfulfilled", label: "Unfulfilled", code: "04" },
  { value: "Exempted", label: "Exempted", code: "05" },
];

export const ELIGIBILTY_OPTIONS = [
  { value: "Eligible", label: "해당", code: "01" },
  { value: "Not_Eligible", label: "미해당", code: "02" },
];

export const ELIGIBILTY_OPTIONS_EN = [
  { value: "Eligible", label: "Eligible", code: "01" },
  { value: "Not_Eligible", label: "Not Eligible", code: "02" },
];

export const DISABLED_OPTIONS = [
  { value: "None", label: "해당없음", code: "01" },
  { value: "Severe_Disease", label: "중증", code: "02" },
  { value: "Mild_Disease", label: "경증", code: "03" },
  { value: "Level1", label: "1급", code: "04" },
  { value: "Level2", label: "2급", code: "05" },
  { value: "Level3", label: "3급", code: "06" },
  { value: "Level4", label: "4급", code: "07" },
  { value: "Level5", label: "5급", code: "08" },
  { value: "Level6", label: "6급", code: "09" },
];

export const DISABLED_OPTIONS_EN = [
  { value: "None", label: "None", code: "01" },
  { value: "Severe_Disease", label: "Severe Disability", code: "02" },
  { value: "Mild_Disease", label: "Mild Disability", code: "03" },
  { value: "Level1", label: "Level 1", code: "04" },
  { value: "Level2", label: "Level 2", code: "05" },
  { value: "Level3", label: "Level 3", code: "06" },
  { value: "Level4", label: "Level 4", code: "07" },
  { value: "Level5", label: "Level 5", code: "08" },
  { value: "Level6", label: "Level 6", code: "09" },
];

export const EMPLOY_OPTIONS = [
  { value: "Full-time", label: "정규직", code: "01" },
  { value: "Temporary_Worker", label: "비정규직", code: "02" },
  { value: "Contract_Worker", label: "계약직", code: "03" },
  { value: "Freelancer", label: "프리랜서", code: "04" },
  { value: "Part_time_Job", label: "아르바이트", code: "05" },
];

export const EMPLOY_OPTIONS_EN = [
  { value: "Full-time", label: "Full-time", code: "01" },
  {
    value: "Temporary_Worker",
    label: "Temporary · Contract Worker",
    code: "02",
  },
  { value: "Contract_Worker", label: "Contract Worker", code: "03" },
  { value: "Freelancer", label: "Freelancer", code: "04" },
  { value: "Part_time_Job", label: "Part-timer", code: "05" },
];

export const DEPARTMENT_OPTIONS = [
  { value: "Room_part_Team", label: "객실팀", code: "01" },
  { value: "Housekeeping", label: "객실정비팀", code: "02" },
  { value: "Food_and_Beverage", label: "식음팀", code: "03" },
  { value: "Security", label: "안전관리팀", code: "04" },
  { value: "Facilities_Management", label: "시설관리팀", code: "05" },
];

export const DEPARTMENT_OPTIONS_EN = [
  { value: "Room_part_Team", label: "Room-part Team", code: "01" },
  { value: "Housekeeping", label: "Housekeeping Team", code: "02" },
  { value: "Food_and_Beverage", label: "Room Service Team", code: "03" },
  { value: "Security", label: "Safety Management Team", code: "04" },
  {
    value: "Facilities_Management",
    label: "Facility Management Team",
    code: "05",
  },
];

export const PART1_OPTIONS = [
  { value: "Concierge", label: "컨시어지", code: "01" },
  { value: "Front_Desk", label: "프론트", code: "02" },
  { value: "Reservation", label: "예약", code: "03" },
  { value: "Door_Attendant", label: "도어맨", code: "04" },
  { value: "Valet_Parking", label: "발렛파킹", code: "05" },
  { value: "Fitness", label: "휘트니스", code: "06" },
];

export const PART1_OPTIONS_EN = [
  { value: "Concierge", label: "Concierge", code: "01" },
  { value: "Front_Desk", label: "Front Desk", code: "02" },
  { value: "Reservation", label: "Reservation", code: "03" },
  { value: "Door_Attendant", label: "Door Attendant", code: "04" },
  { value: "Valet_Parking", label: "Valet Parking", code: "05" },
  { value: "Fitness", label: "Fitness", code: "06" },
];

export const PART2_OPTIONS = [
  { value: "Housekeeper", label: "객실정리", code: "01" },
  { value: "Laundry", label: "런드리", code: "02" },
  { value: "Linen", label: "린넨", code: "03" },
  { value: "Minibar", label: "미니바", code: "04" },
  { value: "Room_Maid", label: "룸메이드", code: "05" },
  { value: "Order_Taker", label: "오더테이커", code: "06" },
];

export const PART2_OPTIONS_EN = [
  { value: "Housekeeper", label: "Housekeeper", code: "01" },
  { value: "Laundry", label: "Laundry", code: "02" },
  { value: "Linen", label: "Linen", code: "03" },
  { value: "Minibar", label: "Minibar", code: "04" },
  { value: "Room_Maid", label: "Room Maid", code: "05" },
  { value: "Order_Taker", label: "Order Taker", code: "06" },
];

export const PART3_OPTIONS = [
  { value: "Restaurant", label: "영업장", code: "01" },
  { value: "Banquet", label: "연회", code: "02" },
];

export const PART3_OPTIONS_EN = [
  { value: "Restaurant", label: "Restaurant", code: "01" },
  { value: "Banquet", label: "Banquet", code: "02" },
];

export const PART4_OPTIONS = [
  { value: "Disaster_Prevention", label: "방재", code: "01" },
  { value: "Security", label: "경비", code: "02" },
];

export const PART4_OPTIONS_EN = [
  { value: "Disaster_Prevention", label: "Disaster Prevention", code: "01" },
  { value: "Security", label: "Security", code: "02" },
];

export const PART5_OPTIONS = [
  { value: "Construction", label: "건축", code: "01" },
  { value: "Electricity", label: "전기", code: "02" },
  { value: "Equipment", label: "설비", code: "03" },
];

export const PART5_OPTIONS_EN = [
  { value: "Construction", label: "Construction", code: "01" },
  { value: "Electricity", label: "Electricity", code: "02" },
  { value: "Equipment", label: "Equipment", code: "03" },
];

// 임시 은행 상수
export const CARD_COMPANY_OPTIONS = [
  { value: "BC", label: "비씨", code: "01" },
  { value: "KB", label: "KB국민", code: "02" },
  { value: "KEB_HANA", label: "하나(외환)", code: "03" },
  { value: "SAMSUNG", label: "삼성", code: "04" },
  { value: "SHINHAN", label: "신한", code: "06" },
  { value: "HYUNDAI", label: "현대", code: "07" },
  { value: "LOTTE", label: "롯데", code: "08" },
  { value: "HANMI", label: "한미", code: "09" },
  {
    value: "SHINSAEGAE_HANMI",
    label: "신세계한미",
    code: "10",
  },
  { value: "CITY", label: "씨티", code: "11" },
  { value: "NH", label: "NH채움", code: "12" },
  { value: "SUHYUP", label: "수협", code: "13" },
  { value: "SHINHYUP", label: "신협", code: "14" },
  { value: "WOORI", label: "우리", code: "15" },
  { value: "HANA", label: "하나", code: "16" },
  { value: "GWANGJU", label: "광주", code: "21" },
  { value: "JUNBUK", label: "전북", code: "22" },
  { value: "JEJU", label: "제주", code: "23" },
  { value: "SANEUN_CAPITAL", label: "산은캐피탈", code: "24" },
  { value: "FOREIGN_BISA", label: "해외비자", code: "25" },
  { value: "FOREIGN_MASTER", label: "해외마스터", code: "26" },
  { value: "FOREIGN_DINUS", label: "해외다이너스", code: "27" },
  { value: "FOREIGN_AMX", label: "해외AMX", code: "28" },
  { value: "FOREIGN_JCB", label: "해외JCB", code: "29" },
  { value: "SK_OK_CASH_BAG", label: "SK-OK CASH-BAG", code: "31" },
  { value: "POST_OFFICE", label: "우체국", code: "32" },
  { value: "SAVINGS_BANK", label: "저축은행", code: "33" },
  { value: "UNION_PAY", label: "은련", code: "34" },
  { value: "SAEMAUL_GEUMGO", label: "새마을금고", code: "35" },
  { value: "KDB", label: "KDB산업", code: "36" },
  { value: "KAKAO", label: "카카오뱅크", code: "37" },
  { value: "KBANK", label: "케이뱅크", code: "38" },
  { value: "PAYCO", label: "PAYCO 포인트", code: "39" },
  { value: "KAKAO_MONEY", label: "카카오머니", code: "40" },
  { value: "SSG", label: "SSG머니", code: "41" },
  { value: "NAVER", label: "네이버포인트", code: "42" },
];

export const CARD_COMPANY_OPTIONS_EN = [
  { value: "BC", label: "BC", code: "01" },
  { value: "KB", label: "KB", code: "02" },
  { value: "KEB_HANA", label: "KEB HANA", code: "03" },
  { value: "SAMSUNG", label: "SAMSUNG", code: "04" },
  { value: "SHINHAN", label: "SHINHAN", code: "06" },
  { value: "HYUNDAI", label: "HYUNDAI", code: "07" },
  { value: "LOTTE", label: "LOTTE", code: "08" },
  { value: "HANMI", label: "HANMI", code: "09" },
  {
    value: "SHINSAEGAE_HANMI",
    label: "SHINSAEGAE HANMI",
    code: "10",
  },
  { value: "CITY", label: "CITY", code: "11" },
  { value: "NH", label: "NH", code: "12" },
  { value: "SUHYUP", label: "SUHYUP", code: "13" },
  { value: "SHINHYUP", label: "SHINHYUP", code: "14" },
  { value: "WOORI", label: "WOORI", code: "15" },
  { value: "HANA", label: "HANA", code: "16" },
  { value: "GWANGJU", label: "GWANGJU", code: "21" },
  { value: "JUNBUK", label: "JUNBUK", code: "22" },
  { value: "JEJU", label: "JEJU", code: "23" },
  { value: "SANEUN_CAPITAL", label: "SANEUN CAPITAL", code: "24" },
  { value: "FOREIGN_BISA", label: "FOREIGN BISA", code: "25" },
  { value: "FOREIGN_MASTER", label: "FOREIGN MASTER", code: "26" },
  { value: "FOREIGN_DINUS", label: "FOREIGN DINUS", code: "27" },
  { value: "FOREIGN_AMX", label: "FOREIGN AMX", code: "28" },
  { value: "FOREIGN_JCB", label: "FOREIGN JCB", code: "29" },
  { value: "SK_OK_CASH_BAG", label: "SK OK CASH BAG", code: "31" },
  { value: "POST_OFFICE", label: "POST OFFICE", code: "32" },
  { value: "SAVINGS_BANK", label: "SAVINGS BANK", code: "33" },
  { value: "UNION_PAY", label: "UNION PAY", code: "34" },
  { value: "SAEMAUL_GEUMGO", label: "SAEMAUL GEUMGO", code: "35" },
  { value: "KDB", label: "KDB", code: "36" },
  { value: "KAKAO", label: "KAKAO", code: "37" },
  { value: "KBANK", label: "KBANK", code: "38" },
  { value: "PAYCO", label: "PAYCO", code: "39" },
  { value: "KAKAO_MONEY", label: "KAKAO MONEY", code: "40" },
  { value: "SSG", label: "SSG", code: "41" },
  { value: "NAVER", label: "NAVER", code: "42" },
];
