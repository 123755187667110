import * as yup from "yup";

export default yup.object().shape({
  companyEmail: yup
    .string()
    .required("")
    .matches(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      "Please enter your email address with a right format."
    ),
  companyName: yup.string().required(""),
  companyBusiness: yup.object().nullable().required(""),
  companyManager: yup.string().required(""),
  companyPhone2: yup.string().required(""),
  companyPhone3: yup.string().required(""),
  companyMessage: yup.string().required(""),
});
