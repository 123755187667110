import axios from "axios";

// 유저 정보 조회
const authInfo = async () => {
  try {
    const { data } = await axios.get("auth/login");

    return data;
  } catch (err) {
    throw err;
  }
};

// 아이디 찾기 인증문자 발송
const findId = async ({ userPhone }) => {
  try {
    const data = await axios.post("/verifications/email/find", {
      phone: userPhone,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

// 아이디 찾기 인증번호 검증
const findIdSmsVerify = async ({ verifyCodeNum }) => {
  try {
    const data = await axios.post("/verifications/email/verify", {
      key: verifyCodeNum,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

// 비밀번호 찾기
const findPw = async ({ userEmail }) => {
  try {
    const data = await axios.post("/auth/find-password", {
      email: userEmail,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

export default {
  authInfo,
  findId,
  findIdSmsVerify,
  findPw,
};
