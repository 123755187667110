import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// pages
import Main from "pages/main/Main";
import Greeting from "pages/introduce/greeting/Introduce-Greeting";
import IntroduceInfo from "pages/introduce/info/Introduce-Info";
import IntroduceNurida from "pages/introduce/nurida/Introduce-Nurida";
import ServiceInfo from "pages/service/Service";
import Business from "pages/business/Business";
import Hiring from "pages/career/hiring/Hiring";
import HiringDetail from "pages/career/hiring/HiringDetail";
import HiringEdit from "pages/career/hiring/HiringEdit";
import HiringRegist from "pages/career/hiring/HiringRegist";
import Promotion from "pages/career/promotion/Promotion";
import PromotionDetail from "pages/career/promotion/PromotionDetail";
import PromotionEdit from "pages/career/promotion/PromotionEdit";
import PromotionRegist from "pages/career/promotion/PromitionRegist";
import Transfer from "pages/career/transfer/Transfer";
import TransferDetail from "pages/career/transfer/TransferDetail";
import TransferEdit from "pages/career/transfer/TransferEdit";
import TransferDone from "pages/career/transfer/TransferDone";
import Mypage from "pages/mypage/Mypage";
import PartnerMypage from "pages/mypage/PartnerMypage";
import MypageList from "pages/mypage/MypageList";
import ContactInfo from "pages/contact/info/Contact-Info";
import ContactMap from "pages/contact/map/Contact-Map";
import TermsService from "pages/terms/service/Terms-Service";
import TermsPolicy from "pages/terms/policy/Terms-Policy";
import LoginMain from "pages/signup/login/Login";
import SearchId from "pages/signup/login/SearchId";
import SearchPw from "pages/signup/login/SearchPw";
import SignupMain from "pages/signup/signup/Signup";
import CreateAccount from "pages/signup/signup/CreateAccount";
import Joined from "pages/signup/signup/Joined";
import Error404 from "pages/Error404/Error404";

import AuthGuard from "components/Guard/AuthGuard";

const routesConfig = [
  {
    path: "/",
    element: () => <Navigate replace to="/home" />,
  },
  {
    path: "*",
    element: () => <Navigate replace to="/404" />,
  },
  {
    path: "/404",
    element: Error404,
  },
  {
    path: "/home",
    element: Main,
  },
  {
    path: "/greeting",
    element: Greeting,
  },
  {
    path: "/introduce",
    element: IntroduceInfo,
  },
  {
    path: "/nurida",
    element: IntroduceNurida,
  },
  {
    path: "/service",
    element: ServiceInfo,
  },
  {
    path: "/business",
    element: Business,
  },
  {
    path: "/hiring",
    element: Hiring,
    guard: AuthGuard,
  },
  {
    path: "/hiring/hiring-detail/:id",
    element: HiringDetail,
    guard: AuthGuard,
  },
  {
    path: "/hiring/hiring-edit/:id",
    element: HiringEdit,
    guard: AuthGuard,
  },
  {
    path: "/hiring/hiring-regist",
    element: HiringRegist,
    guard: AuthGuard,
  },
  {
    path: "/promotion",
    element: Promotion,
    guard: AuthGuard,
  },
  {
    path: "/promotion/promotion-detail/:id",
    element: PromotionDetail,
    guard: AuthGuard,
  },
  {
    path: "/promotion/promotion-edit/:id",
    element: PromotionEdit,
    guard: AuthGuard,
  },
  {
    path: "/promotion/promotion-regist",
    element: PromotionRegist,
    guard: AuthGuard,
  },
  {
    path: "/transfer",
    element: Transfer,
    guard: AuthGuard,
  },
  {
    path: "/transfer/transfer-detail/:id",
    element: TransferDetail,
    guard: AuthGuard,
  },
  {
    path: "/transfer/transfer-edit/:id",
    element: TransferEdit,
    guard: AuthGuard,
  },
  {
    path: "/transfer/transfer-done",
    element: TransferDone,
    guard: AuthGuard,
  },
  {
    path: "/mypage",
    element: Mypage,
    guard: AuthGuard,
  },
  {
    path: "/partnermypage",
    element: PartnerMypage,
    guard: AuthGuard,
  },
  {
    path: "/mypagelist",
    element: MypageList,
    guard: AuthGuard,
  },
  {
    path: "/contact_info",
    element: ContactInfo,
  },
  {
    path: "/contact_map",
    element: ContactMap,
  },
  {
    path: "/terms_service",
    element: TermsService,
  },
  {
    path: "/terms_policy",
    element: TermsPolicy,
  },
  {
    path: "/login",
    element: LoginMain,
  },
  {
    path: "/login/searchId",
    element: SearchId,
  },
  {
    path: "/login/searchPw",
    element: SearchPw,
  },
  {
    path: "/signup",
    element: SignupMain,
  },
  {
    path: "/signup/createAccount",
    element: CreateAccount,
  },
  {
    path: "/signup/joined",
    element: Joined,
  },
];

const renderRoutes = (routes) => (
  <Routes>
    {routes
      .filter((route) => !!route)
      .map((route, i) => {
        const Guard = route.guard || Fragment;
        const Element = route.element;
        const body = document.getElementsByTagName("body")[0];
        body.classList.remove("scrollLock");

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Element />
              </Guard>
            }
          />
        );
      })}
  </Routes>
);

const RoutesHandler = () => {
  return renderRoutes(routesConfig);
};

export default RoutesHandler;
