import { atom } from "recoil";

export const alertAtom = atom({
  key: "alertAtom",
  default: {
    message: "",
    isOpen: false,
    resolve: null,
    reject: null,
    ok: "",
  },
});
