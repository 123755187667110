import React, { useRef, useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Aos from "aos";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// assets
import BtnNextCircleSVG from "assets/images/pc/btn_next_circle.svg";

// components
import Line from "pages/main/components/Line.js";
import Innovation from "../components/Innovation";

const SloganSection = () => {
  const { t } = useTranslation();
  const scrollRef = useRef([]);
  const scrollHeadingRef = useRef([]);
  const scrollTextRef = useRef([]);
  const scrollDotRef = useRef([]);
  const [delay, setDelay] = useState(15000);
  const navigate = useNavigate();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const root = SloganStyled(t("lang"));

  let handleNext = () => {};

  useEffect(() => {
    const x = 3;
    let j = 0;
    let k = 0;
    let t = 0;

    const slide = () => {
      if (t === 4) t = 0;
      t++;

      if (x > 1) {
        if (j === x) {
          j = 0;
          k = x;
        } else if (t === 1) {
          k = j;
          j = t;
        } else if (t === 2) {
          k = j;
          j = t;
        } else if (t === 3) {
          k = j;
          j = t;
        } else {
          k = j;
          j++;
        }
      }

      scrollRef.current[k].style.display = "none";
      scrollHeadingRef.current[k].removeAttribute("class");
      scrollHeadingRef.current[k].removeAttribute("data-aos");
      scrollHeadingRef.current[k].removeAttribute("data-aos-duration");
      scrollHeadingRef.current[k].removeAttribute("data-aos-offset");
      scrollTextRef.current[k].removeAttribute("class");
      scrollTextRef.current[k].removeAttribute("data-aos");
      scrollTextRef.current[k].removeAttribute("data-aos-duration");
      scrollTextRef.current[k].removeAttribute("data-aos-offset");

      if (isMobileWidth) {
        scrollDotRef.current[k].style.backgroundColor = "#707070";
        scrollDotRef.current[j].style.backgroundColor = "#c09260";
      }

      scrollRef.current[j].style.display = "block";
      scrollHeadingRef.current[j].setAttribute("data-aos", "fade-left");
      scrollHeadingRef.current[j].setAttribute("data-aos-duration", "1500");
      scrollHeadingRef.current[j].setAttribute("data-aos-offset", "0");
      scrollTextRef.current[j].setAttribute("data-aos", "fade-left");
      scrollTextRef.current[j].setAttribute("data-aos-duration", "1500");
      scrollTextRef.current[j].setAttribute("data-aos-offset", "200");

      Aos.init();
    };

    const isInterval = setInterval(slide, delay);

    // NEXT 버튼 클릭 시
    handleNext = () => {
      setDelay(null);
      slide();
      setDelay(15000);
    };

    return () => clearInterval(isInterval);
  }, []);

  return (
    <SloganRoot css={root}>
      <Line />
      <div>
        <div className="slogan_wrap">
          <div className={isMobileWidth ? "mo_ul" : "ul"}>
            <div className="scrollList">
              <div
                ref={(el) => (scrollRef.current[0] = el)}
                className={
                  isMobileWidth ? "mo_main_sec_inner" : "main_sec_inner"
                }
              >
                <h2
                  ref={(el) => (scrollHeadingRef.current[0] = el)}
                  data-aos="fade-left"
                  data-aos-duration="1500"
                  data-aos-offset="0"
                >
                  {t("mainslogantext1")}
                  <br />
                  {t("mainslogantext2")}
                </h2>
                {t("lang") === "EN" ? (
                  <p
                    ref={(el) => (scrollTextRef.current[0] = el)}
                    data-aos="fade-left"
                    data-aos-duration="1500"
                  >
                    <span className="pc_word_break">
                      주식회사 누리글로벌서비스는&nbsp;
                      <br />
                      파트너 호텔이 고객에게 최상의 서비스를
                      <br />
                    </span>
                    <span className="pc_word_break">
                      제공할 수 있도록 적극 지원하는 기업입니다.
                      <br />
                    </span>
                    <span className="pc_word_break">
                      수준 높은 호텔 전문 인력 공급은 물론,&nbsp;
                      <br />
                      최첨단 정보기술을 접목한
                    </span>
                    <span className="pc_word_break">
                      업무용 디지털 플랫폼을&nbsp;
                      <br />
                      활용하여 파트너 호텔이 소비자 트렌드 분석과
                      <br />
                    </span>
                    <span className="pc_word_break">
                      원활한 재고관리를 할 수 있는 환경을 제공합니다.
                    </span>
                  </p>
                ) : (
                  <p
                    ref={(el) => (scrollTextRef.current[0] = el)}
                    data-aos="fade-left"
                    data-aos-duration="1500"
                  >
                    <span className="pc_word_break">
                      NURI Global Service is a company that actively supports{" "}
                      its partner hotels to grow and succeed. <br />
                      <br />
                      <span className="en_line"></span>
                    </span>
                    <span className="pc_word_break">
                      We help the partner hotels to keep their customer
                      satisfaction level on its highest{" "}
                    </span>
                    <span className="pc_word_break">
                      by providing well-trained and highly experienced
                      hoteliers.
                      <br />
                      <br />
                      <span className="en_line"></span>
                    </span>
                    <span className="pc_word_break">
                      Not only we are providing professional staffs that the
                      partners hotels need at a moment, but also{" "}
                    </span>
                    <span className="pc_word_break">
                      analyzing consumer trends and managing hotel assets
                      through a digitalized work platform.
                    </span>
                  </p>
                )}
              </div>
              <div
                ref={(el) => (scrollRef.current[1] = el)}
                className={
                  isMobileWidth ? "mo_main_sec_inner" : "main_sec_inner"
                }
              >
                <h2 ref={(el) => (scrollHeadingRef.current[1] = el)}>
                  {t("mainslogantext3")}
                  <br />
                  {t("mainslogantext4")}
                </h2>
                {t("lang") === "EN" ? (
                  <p ref={(el) => (scrollTextRef.current[1] = el)}>
                    <span className="pc_word_break">
                      국내 특급 호텔에서 <br />
                      장기간 근무한 호텔 전문 인원을
                    </span>
                    <span className="pc_word_break">
                      위주로 <br />
                      임직원을 구성합니다.
                      <br />
                    </span>
                    <span className="pc_word_break">
                      다년간 쌓아온 호텔 업무 노하우를 활용하여,
                      <br />
                    </span>
                    <span className="pc_word_break">
                      지속적인 파트너 호텔 서비스의 <br />
                      퀄리티 향상과
                    </span>
                    <span className="pc_word_break">
                      고객 만족도를 높이는데 <br />
                      사력을 다하고 있습니다.
                    </span>
                  </p>
                ) : (
                  <p ref={(el) => (scrollTextRef.current[1] = el)}>
                    <span className="pc_word_break">
                      NURI Global Service consists of staffs who have worked at
                      domestic 5-star hotels for a significant period. <br />
                      <br />
                      <span className="en_line"></span>
                    </span>
                    <span className="pc_word_break">
                      With the work experience and knowledge that they have
                      accumulated over the years,{" "}
                    </span>
                    <span className="pc_word_break">
                      our professional staffs will constantly find a way to
                      improve partner hotels’{" "}
                    </span>
                    <span className="pc_word_break">
                      service quality and increase customer satisfaction.
                    </span>
                  </p>
                )}
              </div>
              <div
                ref={(el) => (scrollRef.current[2] = el)}
                className={
                  isMobileWidth ? "mo_main_sec_inner" : "main_sec_inner"
                }
              >
                <h2 ref={(el) => (scrollHeadingRef.current[2] = el)}>
                  {t("mainslogantext5")}
                  <br />
                  {t("mainslogantext6")}
                </h2>
                {t("lang") === "EN" ? (
                  <p ref={(el) => (scrollTextRef.current[2] = el)}>
                    <span className="pc_word_break">
                      주식회사 누리글로벌서비스의 <br />
                      업무 범위는 런드리와
                    </span>
                    <span className="pc_word_break">
                      린넨 관리에만 <br />
                      국한되지 않습니다.
                      <br />
                    </span>
                    <span className="pc_word_break">
                      식음, 컨시어지, 프론트, 안전관리, 시설관리 등,
                      <br />
                    </span>
                    <span className="pc_word_break">
                      호텔 운영에 필수적인 전문 분야의 직원과의 <br />
                      긴밀한 네트워크가
                    </span>
                    <span className="pc_word_break">
                      구축되어 있으므로 <br />
                      원활한 상시 채용이 가능합니다.
                    </span>
                  </p>
                ) : (
                  <p ref={(el) => (scrollTextRef.current[2] = el)}>
                    <span className="pc_word_break">
                      NURI Global Service is not only limited to providing
                      laundry and linen management service,{" "}
                    </span>
                    <span className="pc_word_break">
                      but also other hotel services necessary for its business
                      partners.
                      <br />
                      <br />
                      <span className="en_line"></span>
                    </span>
                    <span className="pc_word_break">
                      In fact, we have close network with many experts in hotel
                      service industry including{" "}
                    </span>
                    <span className="pc_word_break">
                      Food & Beverage, Concierge, Front Desk, and Safety &
                      Facility Management Service.
                      <br />
                      <span className="en_line"></span>
                    </span>
                    <span className="pc_word_break">
                      We are always ready to provide variety of professional
                      hoteliers to our partners.
                    </span>
                  </p>
                )}
              </div>
              <div
                ref={(el) => (scrollRef.current[3] = el)}
                className={
                  isMobileWidth ? "mo_main_sec_inner" : "main_sec_inner"
                }
              >
                <h2 ref={(el) => (scrollHeadingRef.current[3] = el)}>
                  {t("mainslogantext7")}
                  <br />
                  {t("mainslogantext8")}
                </h2>
                {t("lang") === "EN" ? (
                  <p ref={(el) => (scrollTextRef.current[3] = el)}>
                    <span className="pc_word_break">
                      클라이언트에게 최상의 서비스를 <br />
                      제공하기 위해
                    </span>
                    <span className="pc_word_break">
                      임직원들이 일상적인 <br />
                      회사 생활을 하는데 있어 지장이 있거나
                      <br />
                    </span>
                    <span className="pc_word_break">
                      신체적 · 정신적으로 피해를 입을 수 있는 <br />
                      요소는 일체 근절하고 있습니다.
                      <br />
                    </span>
                    <span className="pc_word_break">
                      항상 합리적이고 공정한 업무 환경이
                      <br />
                    </span>
                    <span className="pc_word_break">
                      유지될 수 있도록 최선을 다 하겠습니다.
                    </span>
                  </p>
                ) : (
                  <p ref={(el) => (scrollTextRef.current[3] = el)}>
                    <span className="pc_word_break">
                      One of the core objectives of NURI Global Service is to
                      provide the best service to its clients.
                      <br />
                      <br />
                      <span className="en_line"></span>
                    </span>
                    <span className="pc_word_break">
                      To do so, it is our responsibility to keep our staffs in
                      their best condition. <br />
                      <span className="en_line"></span>
                    </span>
                    <span className="pc_word_break">
                      We eliminate factors that make workplace stressful,
                      demoralize employees, and encourage discrimination. <br />
                      <br />
                      <span className="en_line"></span>
                    </span>
                    <span className="pc_word_break">
                      We will always try our best to keep the business fair and
                      transparent for our staffs and partners.
                    </span>
                  </p>
                )}
              </div>
            </div>
            {isMobileWidth && (
              <div className="carusel_dots">
                <ul className="carusel_dots_list">
                  <li
                    ref={(el) => (scrollDotRef.current[0] = el)}
                    className="carusel_dot-1"
                  >
                    <span></span>
                  </li>
                  <li
                    ref={(el) => (scrollDotRef.current[1] = el)}
                    className="carusel_dot-2"
                  >
                    <span></span>
                  </li>
                  <li
                    ref={(el) => (scrollDotRef.current[2] = el)}
                    className="carusel_dot-3"
                  >
                    <span></span>
                  </li>
                  <li
                    ref={(el) => (scrollDotRef.current[3] = el)}
                    className="carusel_dot-4"
                  >
                    <span></span>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div
            className={isMobileWidth ? "mo_shortcut_btn" : "shortcut_btn"}
            onClick={() => navigate("/greeting")}
          >
            <div>
              <p>{t("componay")}</p>
              <img src={BtnNextCircleSVG} className="btn_next_circle" />
            </div>
          </div>
          {!isMobileWidth && (
            <span className="main_content_next" onClick={() => handleNext()}>
              NEXT
            </span>
          )}
        </div>
        <Innovation />
      </div>
    </SloganRoot>
  );
};

export default SloganSection;

const SloganStyled = (lang) => {
  return css`
    & .main_sec_inner {
      position: absolute;
      min-width: ${lang === "EN" ? "50vw" : "60vw"};
      margin: ${lang === "EN" ? "0 25vw 156px 25vw" : "0 20vw 156px 20vw"};
      display: none;

      @media screen and (max-width: 1900px) {
        min-width: 70vw;
        margin: 0 15vw 156px 15vw;
      }
    }

    & .main_sec_inner h2 {
      font-size: ${lang === "EN" ? "60px" : "50px"};
      line-height: 84px;
      font-family: "Arita-buri-SemiBold";
      color: var(--primary-color1);
      margin: 158px 0 40px 0;
    }

    & .mo_main_sec_inner h2 {
      font-size: ${lang === "EN" ? "28px" : "24px"};
      line-height: 1.43;
      font-family: "Arita-buri-SemiBold";
      color: var(--primary-color1);
      margin: ${lang === "EN" ? "80px 0 40px 0" : "40px 0 40px 0"};
    }

    & .mo_main_sec_inner > p {
      color: var(--Aaca298);
      text-align: left;
      font-size: 14px;
      margin-top: ${lang === "EN" ? "70px" : "60px"};
      position: relative;
      z-index: 100;

      .pc_word_break {
        display: inline;
        line-height: 1.86;
      }
      .en_line {
        display: inline;
      }
    }

    & .main_content_next {
      position: absolute;
      z-index: 999;
      right: ${lang === "EN" ? "25%" : "20%"};
      top: ${lang === "EN" ? "300px" : "350px"};
      transform: translateX(0%);
      opacity: 0.6;
      color: var(--bg-color1);
      cursor: pointer;

      @media screen and (max-width: 1900px) {
        right: 15%;
      }

      &:hover {
        opacity: 1;
      }
    }

    & .shortcut_btn {
      position: absolute;
      z-index: 999;
      left: ${lang === "EN" ? "25%" : "20%"};
      top: 340px;
      transform: translateX(0%);
      width: ${lang === "EN" ? "110px" : "200px"};

      @media screen and (max-width: 1900px) {
        left: 15%;
      }
      & > div {
        display: flex;
        align-items: center;

        &:hover {
          & p {
            opacity: 1;
          }
        }

        & p {
          cursor: pointer;
          opacity: 0.7;
          color: var(--bg-color1);
        }
      }
    }

    & .mo_shortcut_btn {
      position: absolute;
      font-size: 14px;
      font-weight: normal;
      z-index: 999;
      left: 30px;
      top: ${lang === "EN" ? "180px" : "155px"};
      transform: translateX(0%);
      width: ${lang === "EN" ? "110px" : "200px"};

      & > div {
        display: flex;
        align-items: center;

        & img {
          width: 26px;
        }

        &:hover {
          & p {
            opacity: 1;
          }
        }

        & p {
          cursor: pointer;
          opacity: 0.7;
          color: var(--bg-color1);
        }
      }
    }

    .carusel_dots {
      position: absolute;
      z-index: 400;
      top: ${lang === "EN" ? "430px" : "505px"};
      left: 30px;
    }
  `;
};

const SloganRoot = styled.div`
  padding-top: 92px;

  & .slogan_wrap {
    position: relative;
    z-index: 800;
    margin: 0 auto;
  }

  & .slogan_wrap > .ul {
    height: 762px;
  }

  & .main_sec_inner:first-of-type {
    display: block;
  }

  & .showing {
    display: block;
  }

  & .main_sec_inner > p {
    color: var(--Aaca298);
    text-align: right;
    font-size: 20px;
    margin-top: 80px;
    position: relative;
    z-index: 100;
  }

  & .main_sec_inner > p br {
    display: none;
  }
  .en_line {
    display: block;
    height: 20px;
  }

  & .active {
    display: block;
  }

  & .mo_ul {
    height: 530px;
  }

  & .mo_main_sec_inner {
    position: absolute;
    min-width: 50vw;
    margin: 0 30px 156px 30px;
    display: none;
  }

  & .mo_main_sec_inner h2 {
    font-size: 28px;
    line-height: 1.43;
    font-family: "Arita-buri-SemiBold";
    color: var(--primary-color1);
    margin: 80px 0 40px 0;
  }

  & .mo_main_sec_inner:first-of-type {
    display: block;
  }

  .carusel_dots_list {
    display: flex;
  }
  .carusel_dots_list li {
    width: 8px;
    height: 8px;
    margin: 0 8px 0 0;
    border-radius: 50%;
    background-color: #707070;
    cursor: pointer;
  }
  .carusel_dots_list .carusel_dot-1 {
    background-color: #c09260;
  }
`;
