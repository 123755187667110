import React, { useState, useEffect } from "react";
import axios from "axios";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import Select from "components/Select/Select";

// containers
import Pagination from "containers/Pagination/Pagination";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// utils
import { LIST_OPTIONS, LIST_OPTIONS_EN } from "utils/constants";
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner05 from "assets/images/pc/img_banner05.png";

/**
 * @file 커리어 - 행사공지 페이지
 * @brief 행사공지 Form Validation
 * @author TW.Lee
 */
const Promotion = () => {
  const { control } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [listOption, setListOption] = useState("created_at");
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const promotion03 = Promotion03Styled(t("lang"));

  const targetStorage = localStorage.getItem("accessToken")
    ? localStorage
    : sessionStorage;
  const userRole = targetStorage.getItem("role");
  const employeeRole = targetStorage.getItem("Erole");

  // 행사공지 리스트 요청
  useEffect(() => {
    axios({
      method: "get",
      url: "/posts",
      params: {
        type: "EVENT",
        orderCol: listOption,
        order_type: "DESC",
      },
    }).then((res) => {
      setTableData(res.data.result.data);
      setTotalCount(res.data.result.total_elements);
      setTotalPages(Math.ceil(res.data.result.total_elements / 10));
    });
  }, [page, limit, listOption]);

  const pageArr = [];
  const pagination = () => {
    for (let i = 0; i < tableData.length; i += 10) {
      pageArr.push(tableData.slice(i, i + 10));
    }
    return pageArr;
  };
  pagination();

  if (isMobileWidth) {
    return (
      <Layout subFixed="true">
        <SubLayout
          banner={Banner05}
          pageText={
            t("lang") === "EN"
              ? PAGE_LAYOUT.promotion
              : PAGE_LAYOUT_EN.promotion
          }
        >
          <Container>
            <PromotionMobileInner01></PromotionMobileInner01>
            <PromotionMobileInner02>
              <Controller
                control={control}
                name="OwnerName"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder={t("byregistration")}
                    variant="quaternary"
                    value={
                      t("lang") === "EN"
                        ? LIST_OPTIONS.find((item) => item.value === listOption)
                        : LIST_OPTIONS_EN.find(
                            (item) => item.value === listOption
                          )
                    }
                    onChange={(obj) => setListOption(obj.value)}
                    options={
                      t("lang") === "EN" ? LIST_OPTIONS : LIST_OPTIONS_EN
                    }
                    w={t("lang") === "EN" ? "75" : "160"}
                    bgColor="#f9f9f9"
                  />
                )}
              />
            </PromotionMobileInner02>
            <PromotionMobileInner03>
              {tableData?.length > 0 ? (
                pageArr[page - 1]?.map((item, index) => {
                  return (
                    <PromotionMobileContent01
                      key={index}
                      onClick={() =>
                        navigate(`/promotion/promotion-detail/${item.id}`, {
                          state: {
                            post: item,
                          },
                        })
                      }
                    >
                      <ul>
                        <ContentMobileTitle className="title">
                          {item.title}
                        </ContentMobileTitle>
                        <ContentMobilePeriod>
                          {t("period")} <span>{item.period}</span>
                        </ContentMobilePeriod>
                        <ContentMobileRegDtm>
                          {t("registrationdate")}{" "}
                          <span>{item.createdAt.substr(0, 10)}</span>
                        </ContentMobileRegDtm>
                      </ul>
                    </PromotionMobileContent01>
                  );
                })
              ) : (
                <PromotionContent02>{t("noevents")}</PromotionContent02>
              )}
              {userRole === "EMPLOYEE" && employeeRole === "ADMIN" && (
                <div className="new-post">
                  <div onClick={() => navigate("/promotion/promotion-regist")}>
                    {t("newapplication2")}
                  </div>
                </div>
              )}
            </PromotionMobileInner03>
            {totalPages > 1 ? (
              <Pagination
                page={page}
                limit={limit}
                count={totalPages}
                list={tableData.length}
                onChangePage={setPage}
              />
            ) : (
              ""
            )}
          </Container>
        </SubLayout>
      </Layout>
    );
  } else {
    return (
      <Layout subFixed="true">
        <SubLayout
          banner={Banner05}
          pageText={
            t("lang") === "EN"
              ? PAGE_LAYOUT.promotion
              : PAGE_LAYOUT_EN.promotion
          }
        >
          <Container>
            <PromotionInner01>
              <Controller
                control={control}
                name="OwnerName"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder={t("byregistration")}
                    variant="quaternary"
                    w={t("lang") === "EN" ? "" : "220"}
                    value={
                      t("lang") === "EN"
                        ? LIST_OPTIONS.find((item) => item.value === listOption)
                        : LIST_OPTIONS_EN.find(
                            (item) => item.value === listOption
                          )
                    }
                    onChange={(obj) => setListOption(obj.value)}
                    options={
                      t("lang") === "EN" ? LIST_OPTIONS : LIST_OPTIONS_EN
                    }
                  />
                )}
              />
            </PromotionInner01>
            <PromotionInner02>
              <ul>
                <GuideLineNo>NO.</GuideLineNo>
                <GuideLineTitle>{t("title")}</GuideLineTitle>
                <GuideLinePeriod>{t("period")}</GuideLinePeriod>
                <GuideLineRegDtm>{t("registrationdate")}</GuideLineRegDtm>
              </ul>
            </PromotionInner02>
            <PromotionInner03 css={promotion03}>
              {tableData?.length > 0 ? (
                pageArr[page - 1]?.map((item, index) => {
                  const start = item.start_date.substr(0, 10);
                  const end = item.end_date.substr(0, 10);
                  const period = `${start} ~ ${end}`;

                  return (
                    <PromotionContent01
                      key={index}
                      onClick={() =>
                        navigate(`/promotion/promotion-detail/${item.id}`, {
                          state: {
                            post: item,
                          },
                        })
                      }
                    >
                      <ul>
                        <ContentNo>
                          {(totalCount - (index + (page - 1) * 10))
                            .toString()
                            .padStart(2, "0")}
                        </ContentNo>
                        <ContentTitle className="title">
                          {item.title}
                        </ContentTitle>
                        <ContentPeriod>{period}</ContentPeriod>
                        <ContentRegDtm>
                          {item.created_at?.substr(0, 10)}
                        </ContentRegDtm>
                      </ul>
                    </PromotionContent01>
                  );
                })
              ) : (
                <PromotionContent02>{t("noevents")}</PromotionContent02>
              )}
              {userRole === "EMPLOYEE" && employeeRole === "ADMIN" && (
                <div className="new-post">
                  <div onClick={() => navigate("/promotion/promotion-regist")}>
                    {t("newapplication2")}
                  </div>
                </div>
              )}
            </PromotionInner03>
            {totalPages > 1 ? (
              <Pagination
                page={page}
                limit={limit}
                count={totalPages}
                list={tableData.length}
                onChangePage={setPage}
              />
            ) : (
              ""
            )}
          </Container>
        </SubLayout>
      </Layout>
    );
  }
};

export default Promotion;

const Promotion03Styled = (lang) => {
  return css`
    & .new-post {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & div {
        width: ${lang === "EN" ? "88px" : "151px"};
        margin-top: 12px;
        padding: 12px 16px;
        background-color: #444;
        color: #fff;
        cursor: pointer;
      }
    }
  `;
};

const Container = styled.div`
  padding-bottom: 100px;
`;

const PromotionInner01 = styled.div`
  display: flex;
  justify-content: end;
  align-items: flex-end;
  margin-top: 60px;
`;

const PromotionInner02 = styled.div`
  border-top: 1px solid #222;
  margin-top: 30px;
  padding-top: 12px;

  & ul {
    display: flex;
    background-color: #f9f9f9;
  }
  & ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-weight: bold;
  }
`;

const PromotionInner03 = styled.div``;

const PromotionContent01 = styled.div`
  border-bottom: 1px solid #ddd;
  cursor: pointer;

  &:hover {
    background-color: rgba(192, 146, 96, 0.1);

    & .title {
      text-decoration: underline;
      color: #c09260;
    }
  }

  & ul {
    display: flex;

    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 81px;
    }
  }
`;

const PromotionContent02 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 820px;
  font-size: 20px;
  border-bottom: 1px solid #ddd;
`;

const GuideLineNo = styled.li`
  width: 82px;
`;

const GuideLineTitle = styled.li`
  width: 643px;
`;

const GuideLinePeriod = styled.li`
  width: 276px;
`;

const GuideLineRegDtm = styled.li`
  width: 199px;
`;

const ContentNo = styled.li`
  width: 82px;
`;

const ContentTitle = styled.li`
  width: 643px;
`;

const ContentPeriod = styled.li`
  width: 276px;
`;

const ContentRegDtm = styled.li`
  width: 199px;
`;

const PromotionMobileInner01 = styled.div`
  margin-top: 40px;
`;

const PromotionMobileInner02 = styled.div`
  background-color: #f9f9f9;
  border-top: 1px solid #222;
  margin-top: 10px;
  padding: 12px 12px 12px;

  display: flex;
  justify-content: end;
`;

const PromotionMobileInner03 = styled.div`
  & .new-post {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & div {
      margin-top: 12px;
      padding: 10px 8px;
      font-size: 14px;
      background-color: #444;
      color: #fff;
      cursor: pointer;
    }
  }
`;

const PromotionMobileContent01 = styled.div`
  border-bottom: 1px solid #ddd;
  cursor: pointer;

  &:hover {
    background-color: rgba(192, 146, 96, 0.1);

    & .title {
      text-decoration: underline;
      color: #c09260;
    }
  }

  & ul {
    padding: 20px 10px;
  }
`;

const ContentMobileTitle = styled.li`
  font-size: 18px;
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ContentMobilePeriod = styled.li`
  font-size: 14px;
  font-weight: bold;
  & span {
    font-weight: normal;
    margin-left: 20px;
  }
`;

const ContentMobileRegDtm = styled.li`
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
  & span {
    font-weight: normal;
    margin-left: 8px;
  }
`;
