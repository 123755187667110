/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, forwardRef, useRef, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// components
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import WarningMessage from "components/WarningMessage/WarningMessage";

// utils
import { ERROR_COLOR } from "utils/constants";

const rootCss = (props) => {
  return css`
    position: relative;
    width: ${props.fullWidth ? "100%" : "680px"};
    background-color: #fff;
  `;
};

const inputCss = (props, isMobileWidth) => {
  const error =
    props.errors?.[props.name]?.type === "matches" ||
    props.errors?.[props.name]?.type === "oneOf";
  const warning = props.errors?.[props.name]?.type === "required";

  return css`
    width: 100%;
    height: ${isMobileWidth ? "48px" : "65px"};
    background: transparent;
    font-size: ${isMobileWidth ? "14px" : "16px"};
    color: #000;
    padding-left: 20px;
    border: solid 1px
      ${(warning && "#c09260") || (error && ERROR_COLOR) || "#ddd"};

    &::placeholder {
      color: #b2b2b2;
    }

    &:-internal-autofill-selected {
      transition: background-color 60000s;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  `;
};

const textareaCss = (props, isMobileWidth) => {
  const error =
    props.errors?.[props.name]?.type === "matches" ||
    props.errors?.[props.name]?.type === "oneOf";
  const warning = props.errors?.[props.name]?.type === "required";

  return css`
    width: 100%;
    height: ${props.doubleheight ? "400px" : "200px"};
    font-size: ${isMobileWidth ? "14px" : "16px"};
    background: transparent;
    color: #000;
    padding: 20px 0 0 20px;
    border: solid 1px
      ${(warning && "#c09260") || (error && ERROR_COLOR) || "#ddd"};

    &::placeholder {
      color: #b2b2b2;
    }
  `;
};

const maxLengthIndicator = css`
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 13px;
  color: #b2b2b2;
  line-height: 1;
`;

/**
 * @file Input Components
 */
const Input = forwardRef((props, ref) => {
  // forwardRef() Ref 전달하기
  const innerRef = useRef();
  const { t } = useTranslation();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const {
    type,
    multiline,
    useMaxLengthIndicator,
    fullWidth,
    errors,
    trim,
    ...rest
  } = props;

  const root = rootCss(props);
  const input = inputCss(props, isMobileWidth);
  const textarea = textareaCss(props, isMobileWidth);

  const [currentLength, setCurrentLength] = useState(0);

  useEffect(() => {
    if (innerRef?.current) {
      setCurrentLength(innerRef.current.value.length);
    }
  }, [innerRef?.current]);

  const handleChange = (e) => {
    if (e.target.maxLength > 0) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }

    props.onChange && props.onChange(e);
    setCurrentLength(e.target.value.length);
  };

  const handleBlur = (e) => {
    props.onBlur && props.onBlur(e);
    trim && trim(props.name, e.target.value.trim());
  };

  return (
    <>
      <div css={root} className="component-input">
        {multiline ? (
          <textarea
            ref={(r) => {
              if (ref) {
                ref.current = r;
              }
              innerRef.current = r;
            }}
            {...rest}
            css={[textarea]}
            onChange={handleChange}
          />
        ) : (
          <input
            {...rest}
            ref={ref}
            css={[input]}
            onBlur={handleBlur}
            onChange={handleChange}
            onWheel={(e) => e.target.blur()}
            type={type}
          />
        )}

        {useMaxLengthIndicator && props.maxLength ? (
          <p css={maxLengthIndicator}>
            {currentLength}/{props.maxLength}{" "}
            {t("lang") === "EN" ? "자" : "characters"}
          </p>
        ) : null}
        {(errors?.[props.name]?.type === "matches" ||
          errors?.[props.name]?.type === "oneOf") && (
          <ErrorMessage errors={errors} name={props.name} />
        )}
        {errors?.[props.name]?.type === "required" && (
          <WarningMessage errors={errors} name={props.name} />
        )}
      </div>
    </>
  );
});

export default Input;
