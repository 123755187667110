import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

import enPdf from "assets/download/2024_NURI-GS_Company_Profile.pdf";
import koPdf from "assets/download/2024_누리글로벌서비스_기업소개서.pdf";
import isomanPdf from "assets/download/ISO경영시스템_매뉴얼.pdf";

/**
 * @file Footer Layout
 */
const Footer = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  if (isMobileWidth) {
    return (
      <FooterMobileRoot>
        <div>
          <div>
            <div>
              <ul className="footer_mobile_menu">
                <li onClick={() => navigate("/terms_service")}>{t("terms")}</li>
                <li onClick={() => navigate("/terms_policy")}>
                  {t("privacy")}
                </li>
                <li>
                  <a href={i18n.language === "en" ? enPdf : koPdf} download>
                    {t("download")}
                  </a>
                </li>
                <li>
                  <a href={isomanPdf} download>
                    {t("isoman")}
                  </a>
                </li>
              </ul>
              <div className="footer_mobile_info">
                <div className="footer_addr">
                  <p>{t("logotext")}</p>
                  <p>{t("comzipcode")}</p>
                  <p>{t("comaddress")}</p>
                </div>
                <p>
                  Copyright 2022 NURI Global Service Co.,Ltd.
                  <br />
                  All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </FooterMobileRoot>
    );
  } else {
    return (
      <FooterRoot>
        <div>
          <div>
            <div>
              <FooterMenu>
                <li onClick={() => navigate("/terms_service")}>{t("terms")}</li>
                <li onClick={() => navigate("/terms_policy")}>
                  {t("privacy")}
                </li>
                <li>
                  <a href={i18n.language === "en" ? enPdf : koPdf} download>
                    {t("download")}
                  </a>
                </li>
                <li>
                  <a href={isomanPdf} download>
                    {t("isoman")}
                  </a>
                </li>
              </FooterMenu>
              <FooterInfo>
                <div className="footer_addr">
                  <p>{t("logotext")}</p>
                  <p>{t("comzipcode")}</p>
                  <p>{t("comaddress")}</p>
                </div>
                <p>
                  Copyright 2022 NURI Global Service Co.,Ltd. All Rights
                  Reserved.
                </p>
              </FooterInfo>
            </div>
          </div>
        </div>
      </FooterRoot>
    );
  }
};

export default Footer;

const FooterRoot = styled.footer`
  height: 200px;
  background-color: #000;

  & > div {
    position: relative;
    z-index: 700;

    & > div {
      position: absolute;
      left: 0;
      width: 100vw;

      & > div {
        max-width: 1300px;
        margin: 40px auto 0 auto;
        padding-left: 100px;
      }
    }
  }
`;

const FooterMenu = styled.ul`
  display: flex;

  & li {
    margin-right: 30px;
    color: #b49779;
    font-size: 20px;
    cursor: pointer;

    & a {
      color: #b49779;
      font-size: 20px;
    }
  }
`;

const FooterInfo = styled.div`
  & p {
    color: #888888;
    font-size: 14px;
    margin-right: 17px;
  }

  & p br {
    display: none;
  }

  & .footer_addr {
    display: flex;
    margin: 24px 0;
  }
`;

const FooterMobileRoot = styled.div`
  height: 230px;
  background-color: #000;

  & > div {
    position: relative;
    z-index: 700;

    & > div {
      position: absolute;
      left: 0;
      width: 100vw;

      & > div {
        margin: 30px auto 0 auto;
        padding-left: 20px;
      }
    }
  }

  & .footer_mobile_menu {
    display: flex;

    & li {
      margin-right: 30px;
      color: #b49779;
      font-size: 14px;
      cursor: pointer;

      & a {
        color: #b49779;
        font-size: 14px;
      }
    }
  }

  & .footer_mobile_info {
    & p {
      color: #888888;
      font-size: 12px;
    }

    & .footer_addr {
      margin: 20px 0;

      & p:nth-of-type(2) {
        margin: 8px 0;
      }
    }
  }
`;
