import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// assets
import BgCircleBSVG from "assets/images/pc/bg_circle_b.svg";

const Partnership = () => {
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  if (isMobileWidth) {
    return (
      <FloatMobileRoot>
        <img
          src={BgCircleBSVG}
          className="bg_circle_b"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        />
        <img
          src={require("assets/images/pc/Partnership.png")}
          className="partnership"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        />
      </FloatMobileRoot>
    );
  } else {
    return (
      <FloatRoot>
        <img
          src={BgCircleBSVG}
          className="bg_circle_b"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        />
        <img
          src={require("assets/images/pc/Partnership.png")}
          className="partnership"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        />
      </FloatRoot>
    );
  }
};

export default Partnership;

const FloatRoot = styled.div`
  position: absolute;
  margin: -440px 0 0 50px;
  width: 1100px;
  height: 440px;
  overflow: hidden;
  z-index: 50;

  & .bg_circle_b {
    position: relative;
    margin: 0 0 0 150px;
  }
  & .partnership {
    position: absolute;
    margin: 330px 0 0 -800px;
    z-index: 10;
  }
`;

const FloatMobileRoot = styled.div`
  position: absolute;
  margin: -240px 0 0 -100px;
  height: 240px;
  overflow: hidden;
  z-index: 10;

  & .bg_circle_b {
    width: 400px;
    position: relative;
    margin: 0 0 0 50px;
  }
  & .partnership {
    width: 400px;
    position: absolute;
    margin: 170px 0 0 -320px;
    z-index: 10;
  }
`;
