import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import Button from "components/Button/Button";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner01 from "assets/images/pc/img_banner01.png";
import IconCheckSVG from "assets/images/pc/icon_check.svg";

/**
 * @file 회원가입 완료 페이지
 * @brief 회원가입 완료
 * @author TW.Lee
 */
const Joined = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const root = JoinedStyled(t("lang"));

  if (isMobileWidth) {
    return (
      <Layout subFixed="true">
        <WhiteRoot>
          <SubLayout
            banner={Banner01}
            pageText={
              t("lang") === "EN" ? PAGE_LAYOUT.signup : PAGE_LAYOUT_EN.signup
            }
          >
            <JoinedMobileInner>
              <div>
                <img src={IconCheckSVG} alt="체크 아이콘" />
              </div>
              <h3>
                {t("lang") === "EN"
                  ? "회원가입이 완료되었습니다!"
                  : "Welcome Aboard!"}
              </h3>
              <div className="joined-info">
                <div>
                  <p>{t("lang") === "EN" ? "가입일시" : "Registered Date"}</p>{" "}
                  <span>{location ? location.state?.day : ""}</span>
                </div>
                <div>
                  <p>{t("lang") === "EN" ? "구분" : "Business Domain"}</p>{" "}
                  <span>{location ? location.state?.role : ""}</span>
                </div>
                <div>
                  <p>{t("lang") === "EN" ? "아이디" : "ID"}</p>{" "}
                  <span className="joined-id">
                    {location ? location.state?.email : ""}
                  </span>
                </div>
                <div>
                  <p>{t("lang") === "EN" ? "이름" : "Name"}</p>{" "}
                  <span>{location ? location.state?.name : ""}</span>
                </div>
              </div>
              <div className="login-move">
                <Button
                  variant="primaryHover"
                  fullWidth
                  onClick={() => navigate("/login", { replace: true })}
                >
                  {t("login")}
                </Button>
              </div>
            </JoinedMobileInner>
          </SubLayout>
        </WhiteRoot>
      </Layout>
    );
  } else {
    return (
      <Layout subFixed="true">
        <WhiteRoot>
          <SubLayout
            banner={Banner01}
            pageText={
              t("lang") === "EN" ? PAGE_LAYOUT.signup : PAGE_LAYOUT_EN.signup
            }
          >
            <JoinedInner css={root}>
              <div>
                <img src={IconCheckSVG} alt="체크 아이콘" />
              </div>
              <h3>
                {t("lang") === "EN"
                  ? "회원가입이 완료되었습니다!"
                  : "Welcome Aboard!"}
              </h3>
              <div className="joined-info">
                <div>
                  <p>{t("lang") === "EN" ? "가입일시" : "Registration Date"}</p>{" "}
                  <span>{location ? location.state?.day : ""}</span>
                </div>
                <div>
                  <p>{t("lang") === "EN" ? "구분" : "Business Domain"}</p>{" "}
                  <span>{location ? location.state?.role : ""}</span>
                </div>
                <div>
                  <p>{t("lang") === "EN" ? "아이디" : "ID"}</p>{" "}
                  <span className="joined-id">
                    {location ? location.state?.email : ""}
                  </span>
                </div>
                <div>
                  <p>{t("lang") === "EN" ? "이름" : "Name"}</p>{" "}
                  <span>{location ? location.state?.name : ""}</span>
                </div>
              </div>
              <div className="login-move">
                <Button
                  variant="primaryHover"
                  fullWidth
                  onClick={() => navigate("/login", { replace: true })}
                >
                  {t("login")}
                </Button>
              </div>
            </JoinedInner>
          </SubLayout>
        </WhiteRoot>
      </Layout>
    );
  }
};

export default Joined;

const JoinedStyled = (isLang) => {
  return css`
    & .joined-info {
      width: 499px;
      padding: ${isLang === "EN" ? "40px 110px" : "40px 70px"};
      border-radius: 8px;
      background-color: #f9f9f9;

      & > div {
        display: flex;

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }

      & p {
        width: ${isLang === "EN" ? "100px" : "170px"};
        font-size: 16px;
        text-align: right;
        margin-right: 10px;
        color: #666;
      }

      & span {
        font-size: 16px;
        width: 250px;
      }

      & .joined-id {
        font-weight: bold;
        color: #c09260;
      }
    }
  `;
};

const WhiteRoot = styled.div`
  & > div > div > .float_wrap > div {
    padding: 0 410px;
  }
`;

const JoinedInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px 0;

  & h3 {
    font-size: 26px;
    font-weight: bold;
    margin: 24px 0 40px;
  }

  & .login-move {
    width: 500px;
    margin-top: 40px;
  }
`;

const JoinedMobileInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 0 81px;

  & img {
    width: 42px;
  }

  & h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 12px 0 20px;
  }

  & .joined-info {
    padding: 30px;
    border-radius: 8px;
    background-color: #f9f9f9;

    & > div {
      display: flex;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }

    & p {
      width: 60px;
      font-size: 16px;
      color: #666;
    }

    & span {
      font-size: 16px;
      width: 200px;
    }

    & .joined-id {
      font-weight: bold;
      color: #c09260;
    }
  }

  & .login-move {
    width: 320px;
    margin-top: 20px;
  }
`;
