import axios from "axios";

// 제휴문의
const busiInquiry = async ({
  companyName,
  typeName,
  businessNum,
  ceoName,
  addressItem,
  detailAddressItem,
  webSite,
  managerName,
  phoneNum,
  emailId,
  files,
  messages,
}) => {
  try {
    const data = await axios.post("/business-inquiry", {
      company: companyName,
      type: typeName,
      business_num: businessNum,
      ceo: ceoName,
      address: addressItem,
      detail_address: detailAddressItem,
      web_site: webSite,
      manager: managerName,
      phone: phoneNum,
      email: emailId,
      file: files,
      message: messages,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

export default {
  busiInquiry,
};
