/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// assets
import IconDropSVG from "assets/images/pc/icon_drop.svg";

const rootCss = (props, isMobileWidth, isLang) => {
  return css`
    display: ${isMobileWidth ? "block" : "flex"};
    white-space: pre-wrap;

    .service_con_wrap_detail {
      width: ${isLang === "EN" ? "300px" : "400px"};
    }

    .service_con_wrap_image {
      height: ${isMobileWidth ? "90px" : "100px"};
    }
    .service_con_wrap_image img {
      width: ${isMobileWidth ? "80%" : "100%"};
    }
  `;
};

/**
 * @file Panel Components
 */
const Panel = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const { children, title, text1, text2, image, active, handleToggle, idx } =
    props;

  const root = rootCss(props, isMobileWidth, t("lang"));

  return (
    <div css={root}>
      <h3>{title}</h3>
      <div>
        <p className="service_con_wrap_text_1">
          {text1}
          <br />
          {text2}
        </p>
        <p
          className="service_con_wrap_detail"
          onClick={() => handleToggle(idx)}
        >
          {t("viewdetail")}
          <img
            src={IconDropSVG}
            style={
              active === idx
                ? { transform: "rotate(180deg)" }
                : { transform: "rotate(0deg)" }
            }
          />
        </p>
        <div
          className="service_con_wrap_text_2"
          style={active === idx ? { display: "block" } : { display: "none" }}
        >
          <div className="service_con_wrap_image">
            <img src={image} />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
});

export default Panel;
