import * as yup from "yup";

/**
 * @file 일반/파트타이머 유효성 검사 및 입력 체크
 * @author TW.Lee
 */
export default yup.object().shape({
  NormalEmail: yup
    .string()
    .required("이메일 주소를 입력하세요.")
    .matches(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      "이메일 주소가 올바르지 않습니다."
    ),
  NormalPassword: yup
    .string()
    .required("대소문자/숫자/특수문자를 조합, 8~15자를 입력하세요.")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,15}[^\s]*$/,
      "대소문자/숫자/특수문자를 조합한 8~15자를 입력하세요."
    ),
  NormalPasswordCheck: yup
    .string()
    .oneOf(
      [yup.ref("NormalPassword"), null],
      "비밀번호와 동일한 문자를 입력하세요."
    )
    .required("비밀번호와 동일한 문자를 입력하세요."),
  userPhone2: yup.string().required("본인인증을 완료해주세요."),
  NormalName: yup.string().required("성명을 입력해주세요."),
  verifiCode: yup.string().required("문자로 수신한 인증코드를 입력하세요."),
  Address1: yup.string().required("우편번호를 입력해 주세요."),
  Address2: yup.string().required("주소를 입력해 주세요."),
  Address3: yup.string().required("상세주소를 입력해 주세요."),
  PolicyCheck: yup
    .string()
    .required(
      "회원가입을 위해서는 서비스 이용약관 및 개인정보 처리 방침에 동의가 필요합니다."
    ),
});
