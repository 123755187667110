import React, { useState, useRef, useCallback, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

const IntroduceInfoPopup = () => {
  const floatRef = useRef();
  const bgRef = useRef([]);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [ScrollY, setScrollY] = useState(window.pageYOffset);
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const root = IntroduceInfoStyled(t("lang"));

  if (isMobileWidth) {
    // 모바일 화면
    const handleScroll = useCallback(() => {
      const moving = window.pageYOffset;
      setVisible(ScrollY > moving);
      setScrollY(moving);

      if (ScrollY > 550) {
        setScrollY(window.pageYOffset);
        floatRef.current.style.position = "fixed";
        floatRef.current.style.top = "0px";
        floatRef.current.style.left = "0px";
        floatRef.current.style.transform = "translateX(0%)";
        floatRef.current.style.marginTop = "0px";

        if (visible) {
          floatRef.current.style.top = "50px";
        } else {
          floatRef.current.style.top = "0px";
        }
      } else {
        setScrollY(window.pageYOffset);
        floatRef.current.style.position = "absolute";
        floatRef.current.style.top = "240px";
        floatRef.current.style.left = "50%";
        floatRef.current.style.transform = "translateX(-50%)";
      }
    }, [ScrollY]);

    useEffect(() => {
      function scrollListener() {
        window.addEventListener("scroll", handleScroll);
      } //  window 에서 스크롤을 감시 시작
      scrollListener(); // window 에서 스크롤을 감시
      return () => {
        window.removeEventListener("scroll", handleScroll);
      }; //  window 에서 스크롤을 감시를 종료
    });

    useEffect(() => {
      for (let i = 0; i < bgRef.current.length; i++) {
        bgRef.current[i].style.color = "#ddd";
        if (ScrollY >= 0 && ScrollY <= 1350) {
          bgRef.current[0].style.color = "#ffc17d";
        } else if (ScrollY > 1350 && ScrollY <= 2130) {
          bgRef.current[1].style.color = "#ffc17d";
        } else if (ScrollY > 2130 && ScrollY <= 2915) {
          bgRef.current[2].style.color = "#ffc17d";
        } else if (ScrollY > 2915) {
          bgRef.current[3].style.color = "#ffc17d";
        }
      }
    }, [ScrollY]);
  } else {
    // PC 화면
    const handleScroll = useCallback(() => {
      const moving = window.pageYOffset;
      setVisible(ScrollY > moving);
      setScrollY(moving);

      if (ScrollY > 1090) {
        setScrollY(window.pageYOffset);
        floatRef.current.style.position = "fixed";
        floatRef.current.style.top = "0px";
        floatRef.current.style.left = "0px";
        floatRef.current.style.transform = "translateX(0%)";
        floatRef.current.style.width = "100vw";
        floatRef.current.style.height = "80px";
        floatRef.current.style.marginTop = "0px";

        if (visible) {
          floatRef.current.style.top = "92px";
        } else {
          floatRef.current.style.top = "0px";
        }
      } else {
        setScrollY(window.pageYOffset);
        floatRef.current.style.position = "absolute";
        floatRef.current.style.top = t("lang") === "EN" ? "525px" : "515px";
        floatRef.current.style.left = "50%";
        floatRef.current.style.transform = "translateX(-50%)";
        floatRef.current.style.width = "1200px";
        floatRef.current.style.height = "110px";
        floatRef.current.style.marginTop = "120px";
      }
    }, [ScrollY]);

    useEffect(() => {
      function scrollListener() {
        window.addEventListener("scroll", handleScroll);
      } //  window 에서 스크롤을 감시 시작
      scrollListener(); // window 에서 스크롤을 감시
      return () => {
        window.removeEventListener("scroll", handleScroll);
      }; //  window 에서 스크롤을 감시를 종료
    });

    useEffect(() => {
      for (let i = 0; i < bgRef.current.length; i++) {
        bgRef.current[i].style.color = "#ddd";

        if (ScrollY >= 0 && ScrollY <= 2177) {
          bgRef.current[0].style.color = "#ffc17d";
        } else if (ScrollY > 2177 && ScrollY <= 3137) {
          bgRef.current[1].style.color = "#ffc17d";
        } else if (ScrollY > 3137 && ScrollY <= 4097) {
          bgRef.current[2].style.color = "#ffc17d";
        } else if (ScrollY > 4097) {
          bgRef.current[3].style.color = "#ffc17d";
        }
      }
    }, [ScrollY]);
  }

  if (isMobileWidth) {
    return (
      <InterduceMobileInner02 ref={floatRef}>
        {}
        <ul>
          <li
            ref={(el) => (bgRef.current[0] = el)}
            onClick={() =>
              window.scrollTo({
                top: t("lang") === "EN" ? 600 : 600,
                behavior: "smooth",
              })
            }
          >
            2020~2021
          </li>
          <li
            ref={(el) => (bgRef.current[1] = el)}
            onClick={() =>
              window.scrollTo({
                top: t("lang") === "EN" ? 1400 : 1600,
                behavior: "smooth",
              })
            }
          >
            2022~2023
          </li>
          <li
            ref={(el) => (bgRef.current[2] = el)}
            onClick={() =>
              window.scrollTo({
                top: t("lang") === "EN" ? 2180 : 2450,
                behavior: "smooth",
              })
            }
          >
            2024~2025
          </li>
          <li
            ref={(el) => (bgRef.current[3] = el)}
            onClick={() =>
              window.scrollTo({
                top: t("lang") === "EN" ? 2965 : 3450,
                behavior: "smooth",
              })
            }
          >
            2025~
          </li>
        </ul>
      </InterduceMobileInner02>
    );
  } else {
    return (
      <InterduceInner02 css={root} ref={floatRef}>
        <ul>
          <li
            ref={(el) => (bgRef.current[0] = el)}
            onClick={() => window.scrollTo({ top: 1228, behavior: "smooth" })}
          >
            2020~2021
          </li>
          <li
            ref={(el) => (bgRef.current[1] = el)}
            onClick={() => window.scrollTo({ top: 2178, behavior: "smooth" })}
          >
            2022~2023
          </li>
          <li
            ref={(el) => (bgRef.current[2] = el)}
            onClick={() => window.scrollTo({ top: 3138, behavior: "smooth" })}
          >
            2024~2025
          </li>
          <li
            ref={(el) => (bgRef.current[3] = el)}
            onClick={() => window.scrollTo({ top: 4098, behavior: "smooth" })}
          >
            2025~
          </li>
        </ul>
      </InterduceInner02>
    );
  }
};

export default IntroduceInfoPopup;

const IntroduceInfoStyled = (isLang) => {
  return css`
    position: absolute;
    z-index: 800;
    top: ${isLang === "EN" ? "525px" : "515px"};
    left: 50%;
    transform: translateX(-50%);
    width: 1200px;
    height: 110px;
    background-color: rgba(29, 29, 29, 0.7);
    margin-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
};

const InterduceInner02 = styled.div`
  & ul {
    display: flex;
    justify-content: center;
  }

  & ul li:not(:last-child)::after {
    content: "·";
    margin: 12px 30px;
    color: #ddd;
  }

  & ul li {
    font-size: 26px;
    font-weight: bold;
    color: #ddd;
    line-height: 38px;
    cursor: pointer;
  }
`;

const InterduceMobileInner02 = styled.div`
  position: absolute;
  z-index: 800;
  top: 240px;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 51px;
  background-color: rgba(29, 29, 29, 0.7);

  display: flex;
  justify-content: center;
  align-items: center;

  & ul {
    display: flex;
    justify-content: center;
  }

  & ul li:not(:last-child)::after {
    content: "·";
    margin: 12px 10px;
    color: #ddd;
  }

  & ul li {
    font-size: 14px;
    font-weight: bold;
    color: #ddd;
    line-height: 38px;
    cursor: pointer;
  }
`;
