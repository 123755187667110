import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

// store
import { saveAtom } from "store/save/atom";

/**
 * @file 임시저장 얼럿 커스텀 훅
 */
const useSave = () => {
  const [save, setSave] = useRecoilState(saveAtom);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const isSaved = (message, regist, ok) => {
    const promise = new Promise((resolve, reject) => {
      const isCustomMessage = typeof message === "function";

      setSave({
        message: isCustomMessage ? undefined : message,
        renderMessage: isCustomMessage ? message : undefined,
        isOpen: true,
        resolve,
        reject,
        regist,
        ok,
      });
      setNeedsCleanup(true);
    });

    const reset = () => {
      setSave({
        message: "",
        isOpen: false,
        resolve: null,
        reject: null,
        regist: "",
        ok: "",
      });
      setNeedsCleanup(false);
    };

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      }
    );
  };

  useEffect(
    () => () => {
      if (save.reject && needsCleanup) {
        save.reject();
      }
    },
    [save, needsCleanup]
  );

  return {
    ...save,
    isSaved,
  };
};

export default useSave;
