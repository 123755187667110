import * as yup from "yup";

export default yup.object().shape({
  mypageName: yup.string().required("Please enter your Name."),
  userPhone2: yup.string().required("Please enter your Mobile Number."),
  verifiCode: yup
    .string()
    .required(
      "Please enter the verification code you received via Text message."
    ),
  Address3: yup.string().required("Please enter your detailed address."),
});
