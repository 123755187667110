import React, { useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// hooks
import useDragScroll from "hooks/useDragScroll";

// assets
import BtnNextCircleSVG from "assets/images/pc/btn_next_circle.svg";

import CoreValue from "../components/CoreValue";

const ValuesSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const root = ValuesSectionStyled(t("lang"));

  useEffect(() => {
    useDragScroll(".main_sec3_list");
  }, []);

  return (
    <ValuesRoot>
      <div className={isMobileWidth ? "mo_values_section" : "values_section"}>
        <div>
          <p
            className={
              isMobileWidth ? "mo_main_section_text" : "main_section_text"
            }
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            OUR VALUES
          </p>
          <h3
            className={isMobileWidth ? "mo_main_section_h3" : "main_section_h3"}
            data-aos="fade-up"
            data-aos-duration="1600"
          >
            <span className="pc_word_break">
              {t("lang") === "EN" ? `주식회사 ` : ""}
              <br />
              {t("mainValuestext1")}
            </span>
            <span className="big_txt">{t("mainValuestext2")}</span>
          </h3>
          <p
            className={
              isMobileWidth
                ? "mo_main_section_sentence"
                : "main_section_sentence"
            }
            data-aos="fade-up"
            data-aos-duration="1650"
          >
            {t("mainValuestext3")}
            <br />
            {t("mainValuestext4")}
          </p>
          <div
            className={isMobileWidth ? "mo_more_btn" : "more_btn"}
            onClick={() => navigate("/greeting")}
          >
            <p>{t("viewdetail")}</p>
            <img src={BtnNextCircleSVG} className="btn_next_circle" />
          </div>
        </div>
        {isMobileWidth ? (
          <MobileValuesList className="main_sec3_list">
            <div>
              <ul>
                <li
                  className="main_list_box main_list_box1"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <div>
                    <p className="list_box_keyword">{t("needs")}</p>
                    <h4>
                      <span>N</span>eeds
                    </h4>
                    {t("lang") === "EN" ? (
                      <p className="list_box_text">
                        고객이 자신의 집처럼 편안하게
                        <br />
                        느낄 수 있도록 다방면에서
                        <br />
                        모든 서비스를 제공할 것입니다.
                      </p>
                    ) : (
                      <p className="list_box_text">
                        We will provide our best
                        <br />
                        service in a variety of
                        <br />
                        ways to make our customers
                        <br />
                        feel at home.
                      </p>
                    )}
                  </div>
                </li>
                <li
                  className="main_list_box main_list_box2"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                  data-aos-delay="150"
                >
                  <div>
                    <p className="list_box_keyword">{t("differentiation")}</p>
                    <h4>
                      <span>U</span>niqueness
                    </h4>
                    {t("lang") === "EN" ? (
                      <p className="list_box_text">
                        지속적인 개선과 차별화된
                        <br />
                        자사 서비스로 최상의 경쟁력을
                        <br />
                        갖추는데 앞장 서겠습니다.
                      </p>
                    ) : (
                      <p className="list_box_text">
                        We will differentiate our services
                        <br />
                        from those of other competitors
                        <br />
                        through continuous improvement.
                      </p>
                    )}
                  </div>
                </li>
                <li
                  className="main_list_box main_list_box3"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                  data-aos-delay="300"
                >
                  <div>
                    <p className="list_box_keyword">{t("reliability")}</p>
                    <h4>
                      <span>R</span>espect
                    </h4>
                    {t("lang") === "EN" ? (
                      <p className="list_box_text">
                        협력관계의 파트너에게 진실된
                        <br />
                        마음가짐으로 예우를 다하며,
                        <br />
                        네트워크를 구축할 것입니다.
                      </p>
                    ) : (
                      <p className="list_box_text">
                        We will be sincere and
                        <br />
                        courteous to our cooperative
                        <br />
                        partners, and throughout, we
                        <br />
                        will form strong bonds between
                        <br />
                        us and our partners.
                      </p>
                    )}
                  </div>
                </li>
                <li
                  className="main_list_box main_list_box4"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                  data-aos-delay="450"
                >
                  <div>
                    <p className="list_box_keyword">{t("innovation")}</p>
                    <h4>
                      <span>I</span>nnovation
                    </h4>
                    {t("lang") === "EN" ? (
                      <p className="list_box_text">
                        현 상황에 안주하지 않고
                        <br />
                        끊임없이 나아가 감히 도전하지
                        <br />
                        못한 분야를 개척할 것입니다.
                      </p>
                    ) : (
                      <p className="list_box_text">
                        We will continue to challenge
                        <br />
                        ourselves without being satisfied
                        <br />
                        with the current situation and
                        <br />
                        pioneer a field that no one has
                        <br />
                        dared to challenge.
                      </p>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </MobileValuesList>
        ) : (
          <ValuesList css={root} className="main_sec3_list">
            <div>
              <ul>
                <li
                  className="main_list_box main_list_box1"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <div>
                    <p className="list_box_keyword">{t("needs")}</p>
                    <h4>
                      <span>N</span>eeds
                    </h4>
                    {t("lang") === "EN" ? (
                      <p className="list_box_text">
                        고객이 자신의 집처럼 편안하게
                        <br />
                        느낄 수 있도록 다방면에서
                        <br />
                        모든 서비스를 제공할 것입니다.
                      </p>
                    ) : (
                      <p className="list_box_text">
                        We will provide our best
                        <br />
                        service in a variety of ways
                        <br />
                        to make our customers feel
                        <br />
                        at home.
                      </p>
                    )}
                  </div>
                </li>
                <li
                  className="main_list_box main_list_box2"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                  data-aos-delay="150"
                >
                  <div>
                    <p className="list_box_keyword">{t("differentiation")}</p>
                    <h4>
                      <span>U</span>niqueness
                    </h4>
                    {t("lang") === "EN" ? (
                      <p className="list_box_text">
                        지속적인 개선과 차별화된
                        <br />
                        자사 서비스로 최상의 경쟁력을
                        <br />
                        갖추는데 앞장 서겠습니다.
                      </p>
                    ) : (
                      <p className="list_box_text">
                        We will differentiate our
                        <br />
                        services from those of other
                        <br />
                        competitors through
                        <br />
                        continuous improvement.
                      </p>
                    )}
                  </div>
                </li>
                <li
                  className="main_list_box main_list_box3"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                  data-aos-delay="300"
                >
                  <div>
                    <p className="list_box_keyword">{t("reliability")}</p>
                    <h4>
                      <span>R</span>espect
                    </h4>
                    {t("lang") === "EN" ? (
                      <p className="list_box_text">
                        협력관계의 파트너에게 진실된
                        <br />
                        마음가짐으로 예우를 다하며,
                        <br />
                        네트워크를 구축할 것입니다.
                      </p>
                    ) : (
                      <p className="list_box_text">
                        We will be sincere and
                        <br />
                        courteous to our cooperative
                        <br />
                        partners, and throughout, we
                        <br />
                        will form strong bonds between
                        <br />
                        us and our partners.
                      </p>
                    )}
                  </div>
                </li>
                <li
                  className="main_list_box main_list_box4"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                  data-aos-delay="450"
                >
                  <div>
                    <p className="list_box_keyword">{t("innovation")}</p>
                    <h4>
                      <span>I</span>nnovation
                    </h4>
                    {t("lang") === "EN" ? (
                      <p className="list_box_text">
                        현 상황에 안주하지 않고
                        <br />
                        끊임없이 나아가 감히 도전하지
                        <br />
                        못한 분야를 개척할 것입니다.
                      </p>
                    ) : (
                      <p className="list_box_text">
                        We will continue to challenge
                        <br />
                        ourselves without being
                        <br />
                        satisfied with the current
                        <br />
                        situation and pioneer a field
                        <br />
                        that no one has dared to
                        <br />
                        challenge.
                      </p>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </ValuesList>
        )}
        <CoreValue />
      </div>
    </ValuesRoot>
  );
};

export default ValuesSection;

const ValuesSectionStyled = (isLang) => {
  return css`
    & h4 {
      font-size: 36px;
      font-weight: bold;
      line-height: 70px;
      letter-spacing: 0.36px;
      padding-left: ${isLang !== "EN" && "10px"};
      color: var(--Ae4caae);
      user-select: none;

      & span {
        font-weight: bold;
        color: var(--bg-color1);
        user-select: none;
      }
    }

    & .list_box_text {
      font-size: 18px;
      line-height: 30px;
      padding-left: ${isLang !== "EN" && "10px"};
      color: var(--bg-color1);
      user-select: none;
    }
  `;
};

const ValuesRoot = styled.div`
  & .values_section {
    width: 1120px;
    margin: 0 auto;
    padding-bottom: 90px;
  }

  & .mo_values_section {
    margin: 0 30px;
  }
`;

const ValuesList = styled.div`
  width: 100vw;
  margin-top: 60px;
  margin-right: 50px;
  position: relative;
  left: 30%;
  transform: translateX(-30%);
  z-index: 300;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    width: 110vw;
    display: flex;
    justify-content: center;

    @media (min-width: 2000px) {
      width: 125vw;
    }
  }

  & > div > ul {
    display: flex;
    justify-content: center;
    cursor: grab;
  }

  & .main_list_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 320px;
    margin: 0 12px 0 0;
    border-radius: 90px;
  }

  & .main_list_box1,
  & .main_list_box3 {
    opacity: 0.9;
    background-color: var(--A4e3d2a);
  }

  & .main_list_box2,
  & .main_list_box4 {
    border: solid 1.5px #604a32;
  }

  & .list_box_keyword {
    font-size: 16px;
    color: var(--bg-color1);
    user-select: none;
  }
`;

const MobileValuesList = styled.div`
  width: 100vw;
  margin-top: 30px;
  margin-right: 50px;
  position: relative;
  left: 25%;
  transform: translateX(-30%);
  z-index: 300;
  overflow-x: scroll;

  @media (min-width: 700px) {
    width: 120vw;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    width: 320vw;
    display: flex;
    justify-content: center;

    @media (min-width: 500px) {
      width: 250vw;
    }

    @media (min-width: 700px) {
      width: 180vw;
    }

    @media (min-width: 2000px) {
      width: 125vw;
    }
  }

  & > div > ul {
    display: flex;
    justify-content: center;
    cursor: grab;
  }

  & .main_list_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 270px;
    height: 270px;
    margin: 0 12px 0 0;
    border-radius: 70px;
  }

  & .main_list_box1,
  & .main_list_box3 {
    opacity: 0.9;
    background-color: var(--A4e3d2a);
  }

  & .main_list_box2,
  & .main_list_box4 {
    border: solid 1.5px #604a32;
  }

  & .list_box_keyword {
    font-size: 14px;
    color: var(--bg-color1);
    user-select: none;
  }

  & h4 {
    font-size: 28px;
    font-weight: bold;
    line-height: 70px;
    letter-spacing: 0.36px;
    color: var(--Ae4caae);
    user-select: none;

    & span {
      font-weight: bold;
      color: var(--bg-color1);
      user-select: none;
    }
  }

  & .list_box_text {
    font-size: 14px;
    line-height: 1.71;
    color: var(--bg-color1);
    user-select: none;
  }
`;
