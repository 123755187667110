import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

const DetailPopup = ({ time }) => {
  const floatRef = useRef();
  const bgRef = useRef([]);
  const { t } = useTranslation();
  const [ScrollY, setScrollY] = useState(0);
  const [isPopup, setIsPopup] = useState(true);
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const timeset1 = time?.substr(0, 10);
  const timeset2 = time?.substr(11, 8);

  const handleScroll = useCallback(() => {
    if (ScrollY > 459) {
      setScrollY(window.pageYOffset);
      floatRef.current.style.position = "fixed";
    } else {
      setScrollY(window.pageYOffset);
      floatRef.current.style.position = "absolute";
    }
  }, [ScrollY]);

  useEffect(() => {
    function scrollListener() {
      window.addEventListener("scroll", handleScroll);
    } //  window 에서 스크롤을 감시 시작
    scrollListener(); // window 에서 스크롤을 감시
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }; //  window 에서 스크롤을 감시를 종료
  });

  useEffect(() => {
    if (isPopup) {
      setTimeout(() => {
        setIsPopup(false);
      }, 4000);
    }
  }, [isPopup]);

  if (isMobileWidth) {
    return (
      <DetailPopupMobileRoot ref={floatRef}>
        {isPopup && (
          <div className="temporary-sec">
            <p>
              <span>{t("Submitdate")}</span>
              <br />
              {timeset1} {timeset2}
            </p>
          </div>
        )}
      </DetailPopupMobileRoot>
    );
  } else {
    return (
      <DetailPopupRoot ref={floatRef}>
        {isPopup && (
          <div className="temporary-sec">
            <p>
              <span>{t("Submitdate")}</span>
              <br />
              {timeset1} {timeset2}
            </p>
          </div>
        )}
      </DetailPopupRoot>
    );
  }
};

export default DetailPopup;

const DetailPopupRoot = styled.div`
  position: absolute;
  z-index: 700;
  top: 80px;
  right: 50%;
  transform: translateX(260%);

  & .temporary-sec {
    width: 250px;
    border-radius: 8px;
    padding: 20px 38px 20px 20px;
    box-shadow: 10px 10px 30px 0 rgba(168, 154, 139, 0.28);
    background-color: #f8f3ee;
    margin-bottom: 20px;

    & span {
      font-size: 18px;
      font-weight: bold;
      color: #c09260;
    }

    & p {
      font-size: 16px;
      font-weight: normal;
      color: #222;
      line-height: 1.5;
    }
  }
`;

const DetailPopupMobileRoot = styled.div`
  position: fixed;
  z-index: 900;
  left: 0;
  bottom: 70px;
  width: 100%;
  margin: 0 auto;

  & .temporary-sec {
    border-radius: 8px;
    padding: 20px;
    box-shadow: 10px 10px 30px 0 rgba(168, 154, 139, 0.5);
    background-color: #f8f3ee;
    margin: 0 10px 20px 10px;

    & .temporary-hedding {
      font-weight: bold;
      color: #c09260;
      margin-bottom: 10px;
      font-size: 16px;
    }

    & p {
      font-size: 14px;
      font-weight: normal;
      color: #222;
      line-height: 1.5;
    }
  }
`;
