import * as yup from "yup";

/**
 * @file 협력사 유효성 검사 및 입력 체크
 * @author TW.Lee
 */
export default yup.object().shape({
  PartnerEmail: yup
    .string()
    .required("Please enter your E-mail.")
    .matches(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      "Incorrect Email Address."
    ),
  PartnerPassword: yup
    .string()
    .required(
      "Please enter 8-15 characters with a combination of uppercase / lowercase / numbers / special characters."
    )
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,15}[^\s]*$/,
      "Please enter 8-15 characters with a combination of uppercase / lowercase / numbers / special characters."
    ),
  PartnerPasswordCheck: yup
    .string()
    .oneOf(
      [yup.ref("PartnerPassword"), null],
      "Please enter the same characters as your password."
    )
    .required("Please enter the same characters as your password."),
  PartnerName: yup.string().required("Please enter your Name."),
  userPhone2: yup.string().required("Complete your Verify your identity."),
  verifiCode: yup
    .string()
    .required(
      "Please enter the verification code you received via Text message."
    ),
  Address1: yup.string().required("Please enter your Zip Code."),
  Address2: yup.string().required("Please enter your Address."),
  Address3: yup.string().required("Please enter your detailed address."),
  companyName: yup.string().required("Please enter your Company Name."),
  companyFaxNumber01: yup.string().required("Please enter your Fax Number."),
  companyFaxNumber02: yup.string().required(""),
  companyFaxNumber03: yup.string().required(""),
  businessNumber: yup
    .string()
    .required("Please enter your Business Registration Number."),
  TransferBank02: yup
    .string()
    .required("Please enter your Company Account Number."),
  PolicyCheck: yup
    .string()
    .required(
      "To sign up for membership, you must agree to the Terms of Service and Privacy Policy."
    ),
});
