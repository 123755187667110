import * as yup from "yup";

export default yup.object().shape({
  PartnerName: yup.string().required("이름을 입력해 주세요."),
  PartnerTeam: yup.string().required("부서를 입력해 주세요."),
  PartnerLevel: yup.string().required("직급을 입력해 주세요."),
  userPhone2: yup.string().required("핸드폰 번호를 입력해 주세요."),
  verifiCode: yup.string().required("문자로 수신한 인증코드를 입력해 주세요."),
  Address1: yup.string().required("우편번호를 입력해 주세요."),
  Address2: yup.string().required("주소를 입력해 주세요."),
  Address3: yup.string().required("상세주소를 입력해 주세요."),
  companyName: yup.string().required("기업명을 입력해 주세요."),
  OwnerPhone2: yup.string().required("대표 전화번호를 입력해 주세요."),
  companyFaxNumber01: yup.string().required("팩스번호를 입력해 주세요."),
  companyFaxNumber02: yup.string().required(""),
  companyFaxNumber03: yup.string().required(""),
  businessNumber: yup.string().required("사업자등록번호를 입력해 주세요."),
  OwnerName: yup.string().required("대표자명을 입력해 주세요."),
  TransferBank02: yup.string().required("계좌번호를 입력해 주세요."),
});
