import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// assets
import Banner07 from "assets/images/pc/img_banner07.png";

/**
 * @file 약관안내 - 개인정보 처리방침 페이지
 * @author TW.Lee
 */
const TermsPolicy = () => {
  const { t } = useTranslation();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  return (
    <Layout subFixed="true">
      <SubLayout
        banner={Banner07}
        pageText={
          t("lang") === "EN" ? PAGE_LAYOUT.policy : PAGE_LAYOUT_EN.policy
        }
      >
        <div className="term_inner">
          <TermPolicyInner01>
            <div className="main_section_content">
              <div className="main_section_box">
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_title1"
                      : "main_section_title1"
                  }
                >
                  {t("lang") === "EN"
                    ? `<주식회사 누리글로벌서비스> ('www.nuri-gs.com' 및 'www.nurida.com', 이하 '누리')가 취급하는 모든 개인정보는
                  개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을
                  원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.`
                    : `All personal information handled by <Nuri Global Service Co., Ltd.> (“www.nuri-gs.com” and “www.nurida.com”; hereinafter referred to as the “Nuri”) shall be protected by personal information protection regulations under the Personal Information Protection Act.
                  The Nuri shall adopt privacy policy to protect its users' personal information & rights and resolve any difficulty that could arise when using the Nuri’s digital platforms and related service.
                  The following is the Nuri’s Privacy Policy:`}
                </p>
              </div>
            </div>
            <div className="main_section_content">
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? "1. 개인정보의 처리 목적"
                  : "1. The Purpose of the Privacy Policy"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3 privacy_text3"
                    : "main_section_content_text3 privacy_text3"
                }
              >
                {t("lang") === "EN"
                  ? `① <주식회사 누리글로벌서비스>는 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.`
                  : `① <Nuri Global Service Co., Ltd.> processes personal information for the following purposes and does not use it for any other intent:`}
              </p>
              {isMobileWidth ? (
                <p className="mo_main_section_content_text4 privacy_text4">
                  {t("lang") === "EN"
                    ? `- 고객의 가입 의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 급여 및 보상 지급, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급·배송, 마케팅 및 광고에의 활용`
                    : `- Identifying and confirming the users' intention to sign up, Maintaining and managing the users’ membership, Paying wages and compensation to the users, Paying for any material necessary for maintaining the Nuri’s web service, Supplying and delivering goods, Marketing and advertising`}
                </p>
              ) : (
                <p className="main_section_content_text4 privacy_text4">
                  {t("lang") === "EN"
                    ? `- 고객의 가입 의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 급여 및 보상 지급, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급·배송, 
                      마케팅 및 광고에의 활용`
                    : `- Identifying and confirming the users' intention to sign up, Maintaining and managing the users’ membership, Paying wages and compensation to the users, Paying for any material necessary for maintaining the Nuri’s web service, Supplying and delivering goods, Marketing and advertising`}
                </p>
              )}
            </div>
            <div className="main_section_content">
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? "2. 개인정보의 처리 및 보유 기간 작성"
                  : "2. Personal Information Processing & Retention Period"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3 privacy_text3"
                    : "main_section_content_text3 privacy_text3"
                }
              >
                {t("lang") === "EN"
                  ? `① <주식회사 누리글로벌서비스>는 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유·이용기간 또는 법령에 따른 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.`
                  : `① <Nuri Global Service Co., Ltd.> processes and preserves the user’s personal information within the retention period. The retention period is effective from the moment when the user agrees to provide his or her personal information to the Nuri, AND from the date the laws and regulations specify.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3 privacy_text3"
                    : "main_section_content_text3 privacy_text3"
                }
              >
                {t("lang") === "EN"
                  ? "② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다."
                  : "② The detail about the period of personal information processing and retention is described below:"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4 privacy_text4"
                    : "main_section_content_text4 privacy_text4"
                }
              >
                {t("lang") === "EN"
                  ? "- 고객 가입 및 관리 : 서비스 이용계약 만료 또는 탈퇴 후 2년까지, 다만 채권·채무관계 잔존시에는 해당 채권·채무관계 정산시까지"
                  : "- Membership Registration and Management: The Nuri will preserve the member’s personal information up to 2 years after his or her withdrawal from the Nuri’s websites and other service. If the Nuri and the user are involved in debt relationship, the Nuri will preserve the member’s personal information until the remaining payment is made, or bond relationship between the two parties is settled."}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4 privacy_text4"
                    : "main_section_content_text4 privacy_text4"
                }
              >
                {t("lang") === "EN"
                  ? "- 전자상거래에서의 계약·청약철회, 대금결제, 재화 등 공급기록 : 5년"
                  : "- Member Transaction Record: Up to 5 years"}
              </p>
            </div>
            <div className="main_section_content">
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? "3. 정보주체와 법정대리인의 권리·의무 및 그 행사방법"
                  : "3. Rights and Obligations of Information Provider and Legal Representatives · the Method of Exercising these Rights and Obligations"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3 privacy_text3"
                    : "main_section_content_text3 privacy_text3"
                }
              >
                {t("lang") === "EN"
                  ? `① 정보주체는 <주식회사 누리글로벌서비스>에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.`
                  : "① The information provider may request the followings against <NURI Global Service Co., Ltd.> to protect his or her personal information and privacy:"}
              </p>
              <div
                className={
                  isMobileWidth ? "mo_main_section_box" : "main_section_box"
                }
              >
                <span
                  className={
                    isMobileWidth
                      ? "mo_main_section_text2 mo_margin-sec"
                      : "main_section_text2 margin-sec"
                  }
                >
                  {t("lang") === "EN"
                    ? "1. 개인정보 열람 요구"
                    : "1. Request to access his or her personal information"}
                </span>
                <span
                  className={
                    isMobileWidth
                      ? "mo_main_section_text2 mo_margin-sec"
                      : "main_section_text2 margin-sec"
                  }
                >
                  {t("lang") === "EN"
                    ? "2. 오류 등이 있을 경우 정정 요구"
                    : "2. Request to correct his or her personal information"}
                </span>
                <span
                  className={
                    isMobileWidth
                      ? "mo_main_section_text2 mo_margin-sec"
                      : "main_section_text2 margin-sec"
                  }
                >
                  {t("lang") !== "EN" && <br />}
                  {t("lang") === "EN"
                    ? "3. 삭제 요구"
                    : `3. Request to delete his or her personal information`}
                </span>
                <span
                  className={
                    isMobileWidth
                      ? "mo_main_section_text2"
                      : "main_section_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? "4. 처리정지 요구"
                    : "4. Request to stop the processing of his or her personal information"}
                </span>
              </div>
            </div>
            <div className="main_section_content">
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? "4. 처리하는 개인정보 항목"
                  : "4. The Types of Personal Information Collected by NURI Global Service"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3 privacy_text3"
                    : "main_section_content_text3 privacy_text3"
                }
              >
                {t("lang") === "EN"
                  ? "① 개인정보 처리업무 : 홈페이지 회원가입 및 관리, 사업 제휴, 사업 협력, 인력 등록 및 급여 제공, 출퇴근 기록, 재화 또는 서비스 제공, 마케팅 및 광고에의 활용"
                  : "① Personal information is collected and preserved for website registration, membership management, business cooperation, manpower dispatch, salary disbursement, attendance checks, provision of marketing goods, and on & offline advertisement."}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3 privacy_text3"
                    : "main_section_content_text3 privacy_text3"
                }
              >
                {t("lang") === "EN"
                  ? `② 필수항목 : 개인 이메일 계정, 로그인 ID, 비밀번호, 본인 및 보호자의 전화번호,거주지 주소, 팩스번호, 사업자 등록정보, 증명사진, ∂은행 계좌번호, 경력사항, 병적사항, 장애여부, 병역사항, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제 기록, 실시간 위치 (GPS)`
                  : "② Required Personal Information: Personal e-mail address, NURI Global Service Website account information: ID and Password, Phone number and fax number, Home address, Business Registration Number, Identification Photo, Bank Account Number, Career details, Medical records, Disability records, Military service records, Service usage records, Access logs and cookies, IP address, Online transaction records, GPS records"}
              </p>
            </div>
            <div className="main_section_content">
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? "5. 개인정보의 파기"
                  : "5. Disposal of Personal Information"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3 privacy_text3"
                    : "main_section_content_text3 privacy_text3"
                }
              >
                {t("lang") === "EN" ? "① 파기절차" : "① Disposal Process:"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4 privacy_text4"
                    : "main_section_content_text4 privacy_text4"
                }
              >
                {t("lang") === "EN"
                  ? "-  이용자가 입력한 정보는 목적 달성 후, 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후, 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아닌 경우를 제외하고는 다른 목적으로 사용되지 않습니다."
                  : "-  The information provided by the user is transferred to a separate DB (separate documents if the provided information is in paper) and stored for a certain period in accordance with the company’s internal policies and other relevant laws & regulations. Then, the information is destroyed immediately sooner of after."}
              </p>
              {t("lang") !== "EN" && (
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4 privacy_text4"
                      : "main_section_content_text4 privacy_text4"
                  }
                >
                  - The personal information transferred to the DB shall not be
                  used for any other purpose unless it is requested by a
                  judicial institution.
                </p>
              )}
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3 privacy_text3"
                    : "main_section_content_text3 privacy_text3"
                }
              >
                {t("lang") === "EN" ? "② 파기기한" : "② Expiration Date:"}
              </p>
              {t("lang") !== "EN" && (
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4 privacy_text4"
                      : "main_section_content_text4 privacy_text4"
                  }
                >
                  - If the retention period has already passed, the user’s
                  personal information shall be disposed within 5 days from the
                  end of the retention period.
                </p>
              )}
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4 privacy_text4"
                    : "main_section_content_text4 privacy_text4"
                }
              >
                {t("lang") === "EN"
                  ? "-  이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다."
                  : "-  If the Nuri does not need the user’s personal information any longer or terminates its service and business, the user’s personal information shall be disposed within 5 days from the date which the Nuri considers his or her personal information is unnecessary."}
              </p>
            </div>
            <div className="main_section_content">
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? "6. 개인정보 자동 수집 장치의 설치·운영 및 그 거부에 관한 사항"
                  : "6. Automatic Collection of Personal Information · Device Installation and Management · Rejection"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3 privacy_text3"
                    : "main_section_content_text3 privacy_text3"
                }
              >
                {t("lang") === "EN"
                  ? `① <주식회사 누리글로벌서비스>는 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.`
                  : `① <NURI Global Service Co., Ltd.> uses the ‘cookies’, which save and load information on the server, to provide personalized web-service to the users.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4 privacy_text4"
                    : "main_section_content_text4 privacy_text4"
                }
              >
                {t("lang") === "EN"
                  ? "-  이용자가 입력한 정보는 목적 달성 후, 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후, 혹은 즉시 파기됩니다."
                  : "-  The information entered by the user is transferred to a separate DB after achieving the purpose (separate documents in the case of paper) and stored for a certain period in accordance with internal policies and other relevant laws or regulations, and then destroyed immediately."}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3 privacy_text3"
                    : "main_section_content_text3 privacy_text3"
                }
              >
                {t("lang") === "EN"
                  ? `② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 컴퓨터 내의 하드디스크에 저장되기도 합니다.`
                  : `② The ‘Cookies’ are small fragments of information that the server (http) sends to the user's browser. The Cookies are sometimes stored on the user's PC hard disk.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4 privacy_text4"
                    : "main_section_content_text4 privacy_text4"
                }
              >
                {t("lang") === "EN"
                  ? "-  쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다."
                  : "-  The purpose of using the Cookies: To provide optimized information to the users by identifying their web history, usage patterns, top searched terms, security access records, etc."}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4 privacy_text4"
                    : "main_section_content_text4 privacy_text4"
                }
              >
                {t("lang") === "EN"
                  ? `-  쿠키의 설치·운영 및 거부 : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.`
                  : `-  Installation, management, and rejection of the Cookies: The visitors and users can turn off the Cookies on by accessing Tools > Internet Options > Privacy at the top of their web browsers.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4 privacy_text4"
                    : "main_section_content_text4 privacy_text4"
                }
              >
                {t("lang") === "EN"
                  ? "-  쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다."
                  : "-  There may be difficulties receiving personalized service and related benefits if the user turns off the Cookies."}
              </p>
            </div>
            <div className="main_section_content">
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? "7. 개인정보 보호 책임자"
                  : "7. Information Security Manager"}
              </p>
              <div
                className={
                  isMobileWidth ? "mo_main_section_box" : "main_section_box"
                }
              >
                <span
                  className={
                    isMobileWidth
                      ? "mo_main_section_text2 mo_margin-sec"
                      : "main_section_text2 margin-sec"
                  }
                >
                  {t("lang") === "EN"
                    ? "- 이름 : 임성후"
                    : "- Name: Sung Hoo (Danny) Lim"}
                </span>
                <span
                  className={
                    isMobileWidth
                      ? "mo_main_section_text2 mo_margin-sec"
                      : "main_section_text2 margin-sec"
                  }
                >
                  {t("lang") === "EN"
                    ? "- 소속 : 주식회사 누리글로벌서비스 기획부"
                    : "- Company: NURI Global Service Planning Department"}
                </span>
                <span
                  className={
                    isMobileWidth
                      ? "mo_main_section_text2 mo_margin-sec"
                      : "main_section_text2 margin-sec"
                  }
                >
                  {t("lang") === "EN"
                    ? "- 전화 : 010-2331-4132"
                    : "- Mobile: +82-10-2331-4132"}
                </span>
                <span
                  className={
                    isMobileWidth
                      ? "mo_main_section_text2"
                      : "main_section_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? "- 이메일 : admin@nuri-gs.com"
                    : "- E-mail: admin@nuri-gs.com"}
                </span>
              </div>
            </div>
            <div className="main_section_content">
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? "8. 개인정보의 안전성 확보 조치"
                  : "8. Measures to Secure the Safety of Personal Information"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3 privacy_text3"
                    : "main_section_content_text3 privacy_text3"
                }
              >
                {t("lang") === "EN"
                  ? "① 개인정보의 암호화"
                  : "① Encryption of the user’s personal information"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4 privacy_text4"
                    : "main_section_content_text4 privacy_text4"
                }
              >
                {t("lang") === "EN"
                  ? "-  이용자의 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다."
                  : "-  The user's password is encrypted and safely preserved so that only the member can use it to log-in to the Nuri's websites."}
              </p>
              {t("lang") !== "EN" && (
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4 privacy_text4"
                      : "main_section_content_text4 privacy_text4"
                  }
                >
                  - The Nuri and its websites use other separate security
                  functions, such as encrypting data and locking files, to
                  protect confidential or private files.
                </p>
              )}
            </div>
            <div className="main_section_content">
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? "9. 개인정보처리방침의 변경"
                  : "9. Amending the Privacy Policy"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3 privacy_text3"
                    : "main_section_content_text3 privacy_text3"
                }
              >
                {t("lang") === "EN"
                  ? "① 이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다."
                  : "① The Privacy Policy will be effective on the date of its enforcement date. If an additional change, deletion, or correction is made to the Privacy Policy in accordance with domestic / international laws, regulations, and policies, the Nuri will notify the users 7 days before the change takes effect."}
              </p>
              <div
                className={
                  isMobileWidth ? "mo_main_section_box" : "main_section_box"
                }
              >
                <span
                  className={
                    isMobileWidth
                      ? "mo_main_section_text2 mo_margin-sec"
                      : "main_section_text2 margin-sec"
                  }
                >
                  {t("lang") === "EN"
                    ? "- 공고일자 : 2020년 11월 01일"
                    : "- Announcement Date: November 1, 2022"}
                </span>
                <span
                  className={
                    isMobileWidth
                      ? "mo_main_section_text2"
                      : "main_section_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? "- 시행일자 : 2023년 01월 01일"
                    : "- Enforcement Date: January 1, 2023"}
                </span>
              </div>
            </div>
          </TermPolicyInner01>
        </div>
      </SubLayout>
    </Layout>
  );
};

export default TermsPolicy;

const TermPolicyInner01 = styled.div`
  padding: 60px 0 100px 0;

  & .main_section_box {
    margin: 0 0 40px;
    padding: 30px;
    background-color: var(--bg-color2);
  }

  & .mo_main_section_box {
    margin: 0 0 50px;
    padding: 20px;
    background-color: var(--bg-color2);
  }

  & .main_section_title1 {
    font-family: "Pretendard";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
  }

  .mo_main_section_title1 {
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
  }

  & .main_section_content_text2 {
    font-family: "Pretendard";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
    margin-bottom: 16px;
    margin-top: 50px;
  }

  & .mo_main_section_content_text2 {
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
    margin-bottom: 16px;
    margin-top: 40px;
  }

  .main_section_content_text3 {
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
    margin-bottom: 12px;
  }

  .mo_main_section_content_text3 {
    font-family: "Pretendard";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
    margin-bottom: 12px;
  }

  .main_section_content_text4 {
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
    padding-left: 14px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .mo_main_section_content_text4 {
    font-family: "Pretendard";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
    padding-left: 14px;
    margin-left: 10px;
    margin-top: 12px;
    margin-bottom: 12px;
    text-indent: -10px;
  }

  & .main_section_content > .privacy_text3 {
    padding-left: 15px;
    text-indent: -15px;
  }

  & .main_section_content > .privacy_text4 {
    padding-left: 15px;
  }

  & .mo_main_section_text2 {
    font-size: 14px;
    display: block;
    font-weight: normal;
  }

  & .mo_margin-sec {
    margin-bottom: 12px;
  }

  & .margin-sec {
    margin-right: 30px;
  }
`;
