import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

import TopBtnSVG from "assets/images/pc/btn_next.svg";

/**
 * @file TopButton Layout
 */
const TopButton = () => {
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  if (isMobileWidth) {
    return (
      <TopButtonMobileStyled
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <div>
          <img src={TopBtnSVG} />
        </div>
      </TopButtonMobileStyled>
    );
  } else {
    return (
      <TopButtonStyled
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <div>
          <img src={TopBtnSVG} />
        </div>
      </TopButtonStyled>
    );
  }
};

export default TopButton;

const TopButtonStyled = styled.div`
  position: fixed;
  z-index: 950;
  right: 110px;
  bottom: 50px;

  & > div {
    width: 60px;
    height: 60px;
    padding: 26px 22px 26px 23px;
    opacity: 0.8;
    border-radius: 50%;
    background-color: #dba366;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TopButtonMobileStyled = styled.div`
  position: fixed;
  z-index: 950;
  right: 20px;
  bottom: 50px;

  & > div {
    width: 40px;
    height: 40px;
    padding: 17.5px 14.6px 17.2px 15.5px;
    opacity: 0.8;
    border-radius: 50%;
    background-color: #dba366;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 12px;
    }
  }
`;
