import { atom } from "recoil";

export const isMobileWidthAtom = atom({
  key: "isMobileWidth",
  default: false,
});

export const isDrawerOpenedAtom = atom({
  key: "isDrawerOpened",
  default: false,
});
