import React from "react";
import styled from "@emotion/styled";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner02 from "assets/images/pc/img_banner02.png";
import OwnerImgSVG from "assets/images/pc/img.png";

import "swiper/css";
import "swiper/css/pagination";

import Circle01 from "../components/Circle01";
import Circle02 from "../components/Circle02";
import Circle03 from "../components/Circle03";

// {t("lang") === "EN" ? () : ()}

/**
 * @file 회사소개 - 대표인사 페이지
 * @brief 대표 인사 및 회사 상세 소개
 * @author TW.Lee
 */
const Greeting = () => {
  const { t } = useTranslation();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  return (
    <Layout subFixed="true">
      <GreetingRoot>
        <SubLayout
          banner={Banner02}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.gretting : PAGE_LAYOUT_EN.gretting
          }
        >
          <div className="greeting_inner">
            {isMobileWidth ? (
              <GreetingMobileInner01>
                <div>
                  {t("lang") === "EN" ? (
                    <p data-aos="fade-up" data-aos-duration="1650">
                      안녕하십니까,
                      <br /> 주식회사 누리글로벌서비스
                      <br /> 대표 최재우입니다.
                    </p>
                  ) : (
                    <p data-aos="fade-up" data-aos-duration="1650">
                      <br /> It is pleasure to meet you.
                      <br /> My name is Jaewoo Choi,
                      <br /> the CEO of
                      <br /> NURI Global Service.
                    </p>
                  )}
                  <div
                    className="owner-img"
                    data-aos="fade-left"
                    data-aos-duration="1650"
                  >
                    <img src={OwnerImgSVG} />
                  </div>
                  {t("lang") === "EN" ? (
                    <p data-aos="fade-up" data-aos-duration="1650">
                      누리글로벌서비스의 홈페이지를 찾아주셔서 감사합니다.
                      <br /> 저희 누리글로벌서비스는 2020년 10월 설립되었으며,
                      <br /> 롯데호텔 본점·월드점과 호텔 서비스 전문인력 공급
                      계약을
                      <br /> 체결하였습니다.
                    </p>
                  ) : (
                    <p data-aos="fade-up" data-aos-duration="1650">
                      Thank you so much for visiting our official website.
                      <br /> NURI Global Service was founded in October 2020,
                      <br /> and as soon as we established the business, we
                      <br /> signed task delegation agreements with Hotel Lotte
                      Seoul and World.
                    </p>
                  )}
                  {t("lang") === "EN" ? (
                    <p data-aos="fade-up" data-aos-duration="1650">
                      자사의 주력 서비스는
                      <br />
                      <span>
                        객실정비, 객실관리, 식음관리, 안전관리,
                        <br /> 시설관리, 고객응대 서비스
                        <br />
                      </span>
                      등이 있으며 이를 통해 파트너 호텔의 성장과 고객 만족도
                      <br />
                      확보에 최선을 다 할 것입니다.
                    </p>
                  ) : (
                    <p data-aos="fade-up" data-aos-duration="1650">
                      Our main services include...
                      <br />
                      <span>
                        Room Maintenance, F&B, Security,
                        <br /> Facility Maintenance, and Customer Service.
                        <br />
                        <br />
                      </span>
                      We will do our best to ensure the growth of our
                      <br />
                      partner hotel and high customer satisfaction.
                    </p>
                  )}
                </div>
              </GreetingMobileInner01>
            ) : (
              <GreetingInner01>
                <div>
                  {t("lang") === "EN" ? (
                    <p data-aos="fade-up" data-aos-duration="1650">
                      안녕하십니까,
                      <br /> 주식회사 누리글로벌서비스
                      <br /> 대표 최재우입니다.
                    </p>
                  ) : (
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1650"
                      className="float-greeting"
                    >
                      <br /> It is pleasure to meet you.
                      <br /> My name is Jaewoo Choi,
                      <br /> the CEO of NURI Global Service.
                    </p>
                  )}
                  {t("lang") === "EN" ? (
                    <p data-aos="fade-up" data-aos-duration="1650">
                      누리글로벌서비스의 홈페이지를 찾아주셔서 감사합니다.
                      <br /> 저희 누리글로벌서비스는 2020년 10월 설립되었으며,
                      <br /> 롯데호텔 본점·월드점과 호텔 서비스 전문인력 공급
                      계약을
                      <br /> 체결하였습니다.
                    </p>
                  ) : (
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1650"
                      className="float-greeting-bottom"
                    >
                      Thank you so much for visiting our official website.
                      <br /> NURI Global Service was founded in October 2020,
                      <br /> and as soon as we established the business, we
                      signed task delegation agreements with Hotel Lotte Seoul
                      and World.
                      <br />
                    </p>
                  )}
                  {t("lang") === "EN" ? (
                    <p data-aos="fade-up" data-aos-duration="1650">
                      자사의 주력 서비스는
                      <br />
                      <span>
                        객실정비, 객실관리, 식음관리, 안전관리,
                        <br /> 시설관리, 고객응대 서비스
                        <br />
                      </span>
                      등이 있으며 이를 통해 파트너 호텔의 성장과 고객 만족도
                      <br />
                      확보에 최선을 다 할 것입니다.
                    </p>
                  ) : (
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1650"
                      className="float-greeting"
                    >
                      Our main services include...
                      <br />
                      <span>
                        Room Maintenance, F&B, Security,
                        <br /> Facility Maintenance, and Customer Service.
                        <br />
                        <br />
                      </span>
                      We will do our best to ensure the growth of our
                      <br />
                      partner hotel and high customer satisfaction.
                    </p>
                  )}
                </div>
                <div
                  className="owner-img"
                  data-aos="fade-left"
                  data-aos-duration="1650"
                >
                  <img src={OwnerImgSVG} />
                </div>
              </GreetingInner01>
            )}
            <Circle01 />
            {isMobileWidth ? (
              <GreetingMobileInner02>
                {t("lang") === "EN" ? (
                  <div data-aos="fade-up" data-aos-duration="1650">
                    누리글로벌서비스의
                    <br />
                    전문 인력은
                    <br />
                    국내 유수 호텔에서
                    <br />
                    장기간 근무한 경험이 있는
                    <br />
                    우수한 인재로 구성되어
                    <br />
                    있습니다.
                  </div>
                ) : (
                  <div data-aos="fade-up" data-aos-duration="1650">
                    The staffs of NURI Global
                    <br /> Service are &#39;highly&#39;
                    <br /> experienced. consists of talented and
                    <br /> experienced Over time,
                    <br /> our employees built their
                    <br /> valued careers and skills
                    <br /> by working at major domestic
                    <br /> hotels for more than a decade.
                  </div>
                )}
                <div className="greeting_inner_interaction">
                  <MobileCircleGray data-aos="zoom-in" data-aos-duration="500">
                    <div className="circle"></div>
                  </MobileCircleGray>
                  <MobileCircleBorderYellow
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                  >
                    <div className="circle"></div>
                  </MobileCircleBorderYellow>
                  <MobileCircleBorderWhite
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                  >
                    <div className="circle"></div>
                  </MobileCircleBorderWhite>
                  <MobileCircleYellow
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    <div className="circle"></div>
                  </MobileCircleYellow>
                </div>
                <div className="greeting_inner_2_area">
                  {t("lang") === "EN" ? (
                    <div>
                      <p data-aos="fade-up" data-aos-duration="1500">
                        호텔 고객에게 편의를 제공하는 업종인 만큼,
                        <br /> 자사는 인력선발에 있어 인성 &#183; 커뮤니케이션
                        능력 등에
                        <br /> 엄격한 기준을 적용하고 있습니다.
                      </p>
                      <p data-aos="fade-up" data-aos-duration="1500">
                        또한, 호텔 서비스의 미래 산업화를 위해
                        <br /> AI기술 도입화를 추진 중입니다.
                        <br /> 지금까지 대부분의 호텔 서비스업은 과거의 업무
                        형태에서
                        <br /> 벗어나지 못하고 비효율적인 작업을 이어왔습니다.
                      </p>
                      <p
                        className="greeting_inner_2_area_span"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                      >
                        <span>
                          자사는 인공지능 기반의 통합 호텔 서비스 업무
                          <br />
                          플랫폼인 NURIDA
                        </span>
                        <span>(NURI Data Application)</span>을 활용하여
                        <br /> 업무의 효율성 증강과 서비스 발전에 기여할
                        것입니다.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p data-aos="fade-up" data-aos-duration="1500">
                        Also, we apply high standard when hiring
                        <br /> new employees; our company only hires staffs with
                        <br /> strong sociability, fluent foreign language
                        skills,
                        <br /> and cooperative attitude.
                        <br />
                        <br />
                        I am very sure that these skills will significantly
                        <br />
                        improve our partner hotel&#39;s service quality and
                        <br /> increase its brand name value.
                      </p>
                      <p data-aos="fade-up" data-aos-duration="1500">
                        In addition, we are pursuing for the futurization of
                        <br /> hotel service industry by implementing AI and
                        <br /> information technology on our past work system.
                        <br />
                        <br /> In fact, majority of domestic hotel service
                        companies
                        <br /> have remained in their outdated form - saving
                        their
                        <br /> works in countless Excel files and creating
                        <br /> handwritten documents.
                      </p>
                      <p
                        className="greeting_inner_2_area_span"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                      >
                        NURI Global Service decided to be progressive;
                        <br />
                        <span className="color-big">
                          We began to develop a new digitalized hotel <br />
                          service work platform called NURIDA
                        </span>
                        <br />
                        <span className="color-small">
                          (NURI Data Application).
                          <br />
                          <br />
                        </span>
                        NURIDA will help our hotel staffs to work with increased
                        <br />
                        accuracy, but with less time and effort. It will also
                        analyze
                        <br />
                        vast collection of data automatically and assist
                        <br />
                        the hotel administrators to plan for effective future
                        <br />
                        business strategies.
                      </p>
                    </div>
                  )}
                </div>
              </GreetingMobileInner02>
            ) : (
              <GreetingInner02>
                {t("lang") === "EN" ? (
                  <div data-aos="fade-up" data-aos-duration="1650">
                    누리글로벌서비스의
                    <br /> 전문 인력은 국내 유수 호텔에서 장기간 근무한 경험이
                    있는
                    <br /> 우수한 인재로 구성되어 있습니다.
                  </div>
                ) : (
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1650"
                    className="float-greeting-bottom2"
                  >
                    The staffs of NURI Global Service are &#39;highly&#39;
                    experienced.
                    <br /> Over time, our employees built their valued careers
                    and skills
                    <br /> by working at major domestic hotels for more than a
                    decade.
                  </div>
                )}
                <div className="greeting_inner_interaction">
                  <CircleGray data-aos="zoom-in" data-aos-duration="500">
                    <div className="circle"></div>
                  </CircleGray>
                  <CircleBorderYellow
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                  >
                    <div className="circle"></div>
                  </CircleBorderYellow>
                  <CircleBorderWhite
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                  >
                    <div className="circle"></div>
                  </CircleBorderWhite>
                  <CircleYellow data-aos="zoom-in" data-aos-duration="500">
                    <div className="circle"></div>
                  </CircleYellow>
                </div>
                <div className="greeting_inner_2_area">
                  {t("lang") === "EN" ? (
                    <div>
                      <p data-aos="fade-up" data-aos-duration="1500">
                        호텔 고객에게 편의를 제공하는 업종인 만큼,
                        <br /> 자사는 인력선발에 있어 인성 &#183; 커뮤니케이션
                        능력 등에
                        <br /> 엄격한 기준을 적용하고 있습니다.
                      </p>
                      <p data-aos="fade-up" data-aos-duration="1500">
                        또한, 호텔 서비스의 미래 산업화를 위해 AI기술 도입화를
                        추진 중입니다.
                        <br /> 지금까지 대부분의 호텔 서비스업은 과거의 업무
                        형태에서 벗어나지 못하고
                        <br /> 비효율적인 작업을 이어왔습니다.
                      </p>
                      <p
                        className="greeting_inner_2_area_span"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                      >
                        <span className="color-big">
                          자사는 인공지능 기반의 통합 호텔 서비스 업무 플랫폼인
                          <br /> NURIDA
                        </span>
                        <span className="color-small">
                          (NURI Data Application)
                        </span>
                        을 활용하여
                        <br /> 업무의 효율성 증강과 서비스 발전에 기여할
                        것입니다.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p data-aos="fade-up" data-aos-duration="1500">
                        Also, we apply high standard when hiring new employees;
                        <br /> our company only hires staffs with strong
                        sociability,
                        <br /> fluent foreign language skills, and cooperative
                        attitude.
                        <br />
                        <br />
                        I am very sure that these skills will significantly
                        improve our
                        <br />
                        partner hotel&#39;s service quality and increase its
                        brand name value.
                      </p>
                      <p data-aos="fade-up" data-aos-duration="1500">
                        In addition, we are pursuing for the futurization of
                        hotel service
                        <br /> industry by implementing AI and information
                        technology on our
                        <br /> past work system.
                        <br />
                        <br />
                        In fact, majority of domestic hotel service companies
                        <br /> have remained in their outdated form - saving
                        their works
                        <br /> in countless Excel files and creating handwritten
                        documents.
                      </p>
                      <p
                        className="greeting_inner_2_area_span"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                      >
                        NURI Global Service decided to be progressive.
                        <br />
                        <span className="color-big">
                          We began to develop a new digitalized hotel service
                          <br />
                          work platform called NURIDA
                        </span>
                        <span className="color-small">
                          (NURI Data Application).
                          <br />
                          <br />
                        </span>
                        NURIDA will help our hotel staffs to work with increased
                        <br />
                        accuracy, but with less time and effort. It will also
                        analyze vast collection
                        <br />
                        of data automatically and assist the hotel
                        administrators to plan for
                        <br /> effective future business strategies.
                      </p>
                    </div>
                  )}
                </div>
              </GreetingInner02>
            )}
            <Circle02 />
            {isMobileWidth ? (
              <GreetingMobileInner03>
                {t("lang") === "EN" ? (
                  <p data-aos="fade-up" data-aos-duration="1500">
                    창업 초기,
                    <br /> &#39;<span>N.U.R.I</span>&#39; 라는 알파벳 초성을
                    활용하여
                    <br /> 기업가치관을 설정하였습니다.
                    <br /> 자사의 모든 임직원 일동은 이를 실현하기
                    <br /> 위해 최선을 다하고 있습니다.
                  </p>
                ) : (
                  <p data-aos="fade-up" data-aos-duration="1500">
                    When I found NURI Global Service,
                    <br /> my staffs and I wanted to set core
                    <br /> business values with initial letters of
                    <br /> &#39;<span>N.U.R.I</span>&#39;
                    <br /> Here are the four core business values:
                  </p>
                )}
                <Swiper
                  pagination={true}
                  modules={[Pagination]}
                  className="mySwiper"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <SwiperSlide className="ceo_list_box ceo_list_box1">
                    <div>
                      <p className="ceo_list_box_keyword">{t("needs")}</p>
                      <h4>
                        <span>N</span>eeds
                      </h4>
                      {t("lang") === "EN" ? (
                        <p className="ceo_list_box_text">
                          저희는 고객의 니즈를 중요시 합니다.
                          <br /> 고객이 자신의 집처럼 편안함을
                          <br /> 느낄 수 있도록 다방면에서 모든 서비스를
                          <br /> 제공할 것입니다.
                        </p>
                      ) : (
                        <p className="ceo_list_box_text">
                          We value the &#39;needs&#39; of our customers.
                          <br /> We will provide the most satisfying hotel
                          <br /> services to our guests so that they can
                          <br /> experience &#39;home away from home&#39;.
                        </p>
                      )}
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="ceo_list_box ceo_list_box2">
                    <div>
                      <p className="ceo_list_box_keyword">
                        {t("differentiation")}
                      </p>
                      <h4>
                        <span>U</span>niqueness
                      </h4>
                      {t("lang") === "EN" ? (
                        <p className="ceo_list_box_text">
                          저희는 독창성을 중시합니다.
                          <br /> 지속적으로 자사의 호텔 서비스 플랫폼을
                          <br /> 개선하여 저희 서비스와 타 경쟁업체의
                          <br /> 서비스를 차별화 할 수 있도록 만들 것입니다.
                        </p>
                      ) : (
                        <p className="ceo_list_box_text">
                          We value &#39;uniqueness&#39;.
                          <br /> We will differentiate us and our competitors
                          <br /> through continuous improvement of our
                          <br /> hotel service quality and digitalized work
                          <br /> platforms.
                        </p>
                      )}
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="ceo_list_box ceo_list_box3">
                    <div>
                      <p className="ceo_list_box_keyword">{t("reliability")}</p>
                      <h4>
                        <span>R</span>espect
                      </h4>
                      {t("lang") === "EN" ? (
                        <p className="ceo_list_box_text">
                          저희는 상호간의 존중을 중시합니다.
                          <br /> 협력관계에 있는 파트너에게 진실된 마음
                          <br /> 가짐으로 예우를 다 할 것이며, 이를 통해
                          <br /> 끈끈한 네트워크를 구축할 것입니다.
                        </p>
                      ) : (
                        <p className="ceo_list_box_text">
                          We value mutual &#39;respect&#39;.
                          <br /> We will build a strong network with our
                          <br /> business partners by showing them
                          <br /> true respect and honesty.
                        </p>
                      )}
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="ceo_list_box ceo_list_box4">
                    <div>
                      <p className="ceo_list_box_keyword">{t("innovation")}</p>
                      <h4>
                        <span>I</span>nnovation
                      </h4>
                      {t("lang") === "EN" ? (
                        <p className="ceo_list_box_text">
                          저희는 기술 혁신성을 중시합니다.
                          <br /> 저희는 현 상황에 안주하지 않고 끊임없이
                          <br /> 도전하여, 그 누구도 감히 도전하지 못한
                          <br /> 분야를 개척할 것입니다.
                        </p>
                      ) : (
                        <p className="ceo_list_box_text">
                          We value technological &#39;innovation&#39;.
                          <br />
                          We will relentlessly challenge anew
                          <br />
                          where no one has dared to go.
                        </p>
                      )}
                    </div>
                  </SwiperSlide>
                </Swiper>
                {t("lang") === "EN" ? (
                  <p
                    data-aos-anchor=".greeting_inner_cards"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    고객 여러분을 위해 초심을 잃지 않고,
                    <br />
                    항상 도전하며 최선을 다하는
                    <br /> 누리글로벌서비스가 되겠습니다. 감사합니다.
                  </p>
                ) : (
                  <p data-aos="fade-up" data-aos-duration="1500">
                    We will not rest on our laurels and
                    <br /> always try our best to serve our clients
                    <br /> with sincerity.
                    <br />
                    Thank you.
                  </p>
                )}
                {t("lang") === "EN" ? (
                  <p
                    data-aos-anchor=".greeting_inner_cards"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-delay="200"
                  >
                    대표<span>최재우</span>
                  </p>
                ) : (
                  <p
                    data-aos-anchor=".greeting_inner_cards"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-delay="200"
                  >
                    CEO<span>Choi, Jaewoo</span>
                  </p>
                )}
              </GreetingMobileInner03>
            ) : (
              <GreetingInner03>
                {t("lang") === "EN" ? (
                  <p data-aos="fade-up" data-aos-duration="1500">
                    창업 초기, &#39;<span>N.U.R.I</span>&#39; 라는 알파벳 초성을
                    활용하여 기업가치관을 설정하였습니다.
                    <br /> 자사의 모든 임직원 일동은 이를 실현하기 위해 최선을
                    다하고 있습니다.
                  </p>
                ) : (
                  <p data-aos="fade-up" data-aos-duration="1500">
                    When I found NURI Global Service,
                    <br />
                    my staffs and I wanted to set core business values with
                    initial letters of &#39;<span>N.U.R.I</span>&#39;
                    <br /> Here are the four core business values:
                  </p>
                )}
                <div className="greeting_inner_cards">
                  <ul>
                    <li
                      className="ceo_list_box1"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <div>
                        <p className="ceo_list_box_keyword">{t("needs")}</p>
                        <h4>
                          <span>N</span>eeds
                        </h4>
                        {t("lang") === "EN" ? (
                          <p className="ceo_list_box_text">
                            저희는 고객의 니즈를 중요시 합니다.
                            <br />
                            고객이 자신의 집처럼 편안함을 느낄 수 있도록
                            다방면에서
                            <br />
                            모든 서비스를 제공할 것입니다.
                          </p>
                        ) : (
                          <p className="ceo_list_box_text">
                            We value the &#39;needs&#39; of our customers.
                            <br />
                            We will provide the most satisfying hotel services
                            to our guests
                            <br />
                            so that they can experience &#39;home away from
                            home&#39;.
                          </p>
                        )}
                      </div>
                    </li>
                    <li
                      className="ceo_list_box2"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <div>
                        <p className="ceo_list_box_keyword">
                          {t("differentiation")}
                        </p>
                        <h4>
                          <span>U</span>niqueness
                        </h4>
                        {t("lang") === "EN" ? (
                          <p className="ceo_list_box_text">
                            저희는 독창성을 중시합니다.
                            <br />
                            지속적으로 자사의 호텔 서비스 플랫폼을 개선하여 저희
                            서비스와
                            <br />타 경쟁업체의 서비스를 차별화 할 수 있도록
                            만들 것입니다.
                          </p>
                        ) : (
                          <p className="ceo_list_box_text">
                            We value &#39;uniqueness&#39;.
                            <br />
                            We will differentiate us and our competitors through
                            continuous
                            <br />
                            improvement of our hotel service quality and
                            digitalized work
                            <br /> platforms.
                          </p>
                        )}
                      </div>
                    </li>
                    <li
                      className="ceo_list_box3"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <div>
                        <p className="ceo_list_box_keyword">
                          {t("reliability")}
                        </p>
                        <h4>
                          <span>R</span>espect
                        </h4>
                        {t("lang") === "EN" ? (
                          <p className="ceo_list_box_text">
                            저희는 상호간의 존중을 중시합니다.
                            <br />
                            협력관계에 있는 파트너에게 진실된 마음가짐으로
                            예우를
                            <br />다 할 것이며, 이를 통해 끈끈한 네트워크를
                            구축할 것입니다.
                          </p>
                        ) : (
                          <p className="ceo_list_box_text">
                            We value mutual &#39;respect&#39;.
                            <br />
                            We will build a strong network with our business
                            <br />
                            partners by showing them true respect and honesty.
                          </p>
                        )}
                      </div>
                    </li>
                    <li
                      className="ceo_list_box4"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <div>
                        <p className="ceo_list_box_keyword">
                          {t("innovation")}
                        </p>
                        <h4>
                          <span>I</span>nnovation
                        </h4>
                        {t("lang") === "EN" ? (
                          <p className="ceo_list_box_text">
                            저희는 기술 혁신성을 중시합니다.
                            <br />
                            저희는 현 상황에 안주하지 않고 끊임없이 도전하여,
                            <br />그 누구도 감히 도전하지 못한 분야를 개척할
                            것입니다.
                          </p>
                        ) : (
                          <p className="ceo_list_box_text">
                            We value technological &#39;innovation&#39;.
                            <br />
                            We will relentlessly challenge anew where no one has
                            dared to go.
                            <br />
                          </p>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
                {t("lang") === "EN" ? (
                  <p data-aos="fade-up" data-aos-duration="1500">
                    고객 여러분을 위해 초심을 잃지 않고,
                    <br />
                    항상 도전하며 최선을 다하는 누리글로벌서비스가 되겠습니다.
                    감사합니다.
                  </p>
                ) : (
                  <p data-aos="fade-up" data-aos-duration="1500">
                    We will not rest on our laurels and always try our best to
                    serve our clients with sincerity.
                    <br />
                    Thank you.
                  </p>
                )}
                {t("lang") === "EN" ? (
                  <p data-aos="fade-up" data-aos-duration="1500">
                    대표<span>최재우</span>
                  </p>
                ) : (
                  <p data-aos="fade-up" data-aos-duration="1500">
                    CEO<span>Choi, Jaewoo</span>
                  </p>
                )}
              </GreetingInner03>
            )}
            <Circle03 />
          </div>
        </SubLayout>
      </GreetingRoot>
    </Layout>
  );
};

export default Greeting;

const GreetingRoot = styled.div`
  background-image: linear-gradient(to bottom, #111, #060606);

  & .greeting_inner {
    color: var(--secondary-color3);
  }
`;

const GreetingInner01 = styled.div`
  display: flex;
  .float-greeting {
    position: absolute;
    width: 600px;
  }
  .float-greeting-bottom {
    padding-top: 371px;
  }
  & .owner-img {
    margin: 120px 0 0 90px;
  }
  & p:nth-of-type(1) {
    font-family: Arita-buri-SemiBold;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.61;
    color: var(--primary-color1);
    margin-top: 140px;
  }

  & p:nth-of-type(2) {
    font-size: 18px;
    line-height: 1.89;
    font-weight: normal;
    margin: 60px 0 40px 0;
  }

  & p:nth-of-type(3) {
    font-size: 18px;
    line-height: 1.89;
    font-weight: normal;
  }

  & p:nth-of-type(3) span {
    font-size: 22px;
    line-height: 1.73;
    font-weight: bold;
    color: var(--Affdab2);
  }
`;

const GreetingInner02 = styled.div`
  & > div:nth-of-type(1) {
    font-family: Arita-buri-SemiBold;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.61;
    color: var(--primary-color1);
    margin-top: 220px;
  }

  .float-greeting-bottom2 {
    padding-top: 150px;
  }

  & .greeting_inner_interaction {
    position: absolute;
    margin-top: 100px;
    width: 270px;
    height: 270px;
    display: inline-block;

    & div {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  & .greeting_inner_2_area {
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
  }

  & .greeting_inner_2_area p {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.89;
  }

  & .greeting_inner_2_area p:nth-of-type(2) {
    margin: 40px 0;
  }

  & .color-big {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.73;
    color: var(--Affdab2);
  }

  & .color-small {
    font-size: 14px;
    line-height: 2.43;
    letter-spacing: 0.14px;
    color: var(--Affdab2);
  }

  .en-bold {
    font-size: 16px;
    line-height: 2.43;
    letter-spacing: 0.14px;
    color: var(--Affdab2);
  }
`;

const GreetingInner03 = styled.div`
  margin-top: 220px;

  & > p:nth-of-type(1) {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.69;

    & span {
      color: var(--button-color1);
    }
  }

  & > p:nth-of-type(2) {
    position: relative;
    z-index: 100;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.8;
    margin-top: 140px;
  }

  & > p:nth-of-type(3) {
    position: relative;
    z-index: 100;
    font-size: 18px;
    color: var(--secondary-color1);
    line-height: 2;
    margin-top: 50px;

    & span {
      font-family: Arita-buri-SemiBold;
      font-size: 28px;
      font-weight: 600;
      color: var(--secondary-color1);
      margin-left: 16px;
    }
  }

  & .greeting_inner_cards {
    margin-top: 60px;

    & ul {
      display: flex;
      flex-wrap: wrap;
    }

    & li {
      display: flex;
      align-items: center;
      flex: 1 1 40%;
      width: 590px;
      height: 313px;
      padding-left: 50px;
      border-radius: 90px;
    }

    & .ceo_list_box1 {
      margin: 0 20px 20px 0;
    }

    & .ceo_list_box4 {
      margin-left: 20px;
    }

    & .ceo_list_box1,
    & .ceo_list_box4 {
      opacity: 0.9;
      background-color: var(--A4e3d2a);
    }

    & .ceo_list_box2,
    & .ceo_list_box3 {
      border: solid 1.5px var(--A604a32);
    }

    & h4 {
      font-size: 40px;
      font-weight: bold;
      line-height: 70px;
      letter-spacing: 0.36px;
      color: var(--Ae4caae);
      user-select: none;

      & span {
        color: var(--bg-color1);
        user-select: none;
      }
    }
  }
`;

const CircleGray = styled.div`
  .circle {
    border-radius: 80%;
    width: 100%;
    height: 100%;
    max-width: 50px;
    max-height: 50px;
    position: relative;

    &:before {
      border-radius: 80%;
      content: "";
      display: block;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transform: scale(0.875);
      transform-origin: center center;
    }

    &:after {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 80%;
      content: "";
      display: block;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      transform: scale(3);
      transform-origin: center center;
    }
  }
`;

const CircleYellow = styled.div`
  top: 110px;
  left: 110px;
  .circle {
    border-radius: 80%;
    width: 100%;
    height: 100%;
    max-width: 50px;
    max-height: 50px;
    position: relative;

    &:before {
      border-radius: 80%;
      content: "";
      display: block;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transform: scale(0.875);
      transform-origin: center center;
    }

    &:after {
      background-color: rgba(219, 163, 102, 0.8);
      border-radius: 80%;
      content: "";
      display: block;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      transform: scale(3);
      transform-origin: center center;
    }
  }
`;

const CircleBorderWhite = styled.div`
  top: 110px;
  .circle {
    border-radius: 80%;
    width: 100%;
    height: 100%;
    max-width: 50px;
    max-height: 50px;
    position: relative;

    &:before {
      border-radius: 80%;
      content: "";
      display: block;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transform: scale(0.875);
      transform-origin: center center;
    }

    &:after {
      border: 1.5px solid white;
      border-radius: 80%;
      content: "";
      display: block;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      transform: scale(3);
      transform-origin: center center;
    }
  }
`;

const CircleBorderYellow = styled.div`
  left: 110px;

  .circle {
    border-radius: 80%;
    width: 100%;
    height: 100%;
    max-width: 50px;
    max-height: 50px;
    position: relative;

    &:before {
      border-radius: 80%;
      content: "";
      display: block;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transform: scale(0.875);
      transform-origin: center center;
    }

    &:after {
      border: 1.5px solid #dba366;
      border-radius: 80%;
      content: "";
      display: block;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      transform: scale(3);
      transform-origin: center center;
    }
  }
`;

const GreetingMobileInner01 = styled.div`
  & .owner-img {
    width: 100%;
    margin-top: 30px;
    & img {
      width: 100%;
    }
  }
  & p:nth-of-type(1) {
    font-family: Arita-buri-SemiBold;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.61;
    color: var(--primary-color1);
    margin-top: 40px;
  }

  & p:nth-of-type(2) {
    font-size: 14px;
    line-height: 1.86;
    font-weight: normal;
    margin: 40px 0 26px 0;
  }

  & p:nth-of-type(3) {
    font-size: 14px;
    line-height: 1.86;
    font-weight: normal;
  }

  & p:nth-of-type(3) span {
    font-size: 16px;
    line-height: 1.75;
    font-weight: bold;
    color: var(--Affdab2);
  }
`;
const GreetingMobileInner02 = styled.div`
  & > div:nth-of-type(1) {
    font-family: Arita-buri-SemiBold;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.58;
    color: var(--primary-color1);
    margin-top: 100px;
  }

  & .greeting_inner_interaction {
    position: relative;
    margin-top: 50px;
    margin-left: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
    display: inline-block;

    & div {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  & .greeting_inner_2_area {
    display: flex;
    margin-top: 60px;
  }

  & .greeting_inner_2_area p {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.86;
    margin-top: 40px;
  }

  & .greeting_inner_2_area p:nth-of-type(2) {
    margin: 26px 0;
  }

  & .color-big {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.75;
    color: var(--Affdab2);
  }

  & .color-small {
    font-size: 12px;
    font-weight: normal;
    line-height: 2.43;
    letter-spacing: 0.14px;
    color: var(--Affdab2);
  }

  .en-bold {
    line-height: 2.43;
    letter-spacing: 0.14px;
    color: var(--Affdab2);
  }
`;
const GreetingMobileInner03 = styled.div`
  margin-top: 100px;

  & > p:nth-of-type(1) {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.69;

    & span {
      color: var(--button-color1);
    }
  }

  & > p:nth-of-type(2) {
    position: relative;
    z-index: 100;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.8;
    margin-top: 100px;
  }

  & > p:nth-of-type(3) {
    position: relative;
    z-index: 100;
    font-size: 14px;
    color: var(--secondary-color1);
    line-height: 2;
    margin-top: 20px;

    & span {
      font-family: Arita-buri-SemiBold;
      font-size: 22px;
      font-weight: 600;
      color: var(--secondary-color1);
      margin-left: 8px;
    }
  }

  .mySwiper {
    width: 100%;
    height: 300px;
    margin-top: 30px;
  }

  .swiper-pagination-bullet {
    background-color: #999999;
  }

  .swiper-pagination-bullet-active {
    background-color: #c09260 !important;
  }

  & .ceo_list_box {
    display: flex;
    align-items: center;
    width: 330px;
    height: 263px;
    padding-left: 50px;
    border-radius: 70px;
  }

  & .ceo_list_box1,
  & .ceo_list_box3 {
    opacity: 0.9;
    background-color: var(--A4e3d2a);
  }

  & .ceo_list_box2,
  & .ceo_list_box4 {
    border: solid 1.5px var(--A604a32);
  }

  .ceo_list_box p {
    font-size: 14px;
    line-height: 1.71;
  }

  & h4 {
    font-size: 28px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 0.36px;
    color: var(--Ae4caae);
    user-select: none;

    & span {
      font-weight: bold;
      color: var(--bg-color1);
      user-select: none;
    }
  }
`;

const MobileCircleGray = styled.div`
  .circle {
    border-radius: 80%;
    width: 60%;
    height: 60%;
    max-width: 50px;
    max-height: 50px;
    position: relative;

    &:before {
      border-radius: 80%;
      content: "";
      display: block;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transform: scale(0.875);
      transform-origin: center center;
    }

    &:after {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 80%;
      content: "";
      display: block;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 60%;
      height: 60%;
      transform: scale(3);
      transform-origin: center center;
    }
  }
`;

const MobileCircleYellow = styled.div`
  top: 70px;
  left: 70px;
  .circle {
    border-radius: 80%;
    width: 60%;
    height: 60%;
    max-width: 50px;
    max-height: 50px;
    position: relative;

    &:before {
      border-radius: 80%;
      content: "";
      display: block;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transform: scale(0.875);
      transform-origin: center center;
    }

    &:after {
      background-color: rgba(219, 163, 102, 0.8);
      border-radius: 80%;
      content: "";
      display: block;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 60%;
      height: 60%;
      transform: scale(3);
      transform-origin: center center;
    }
  }
`;

const MobileCircleBorderWhite = styled.div`
  top: 70px;
  .circle {
    border-radius: 80%;
    width: 55%;
    height: 55%;
    max-width: 50px;
    max-height: 50px;
    position: relative;

    &:before {
      border-radius: 80%;
      content: "";
      display: block;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transform: scale(0.875);
      transform-origin: center center;
    }

    &:after {
      border: 1.5px solid white;
      border-radius: 80%;
      content: "";
      display: block;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 55%;
      height: 55%;
      transform: scale(3);
      transform-origin: center center;
    }
  }
`;

const MobileCircleBorderYellow = styled.div`
  left: 70px;

  .circle {
    border-radius: 80%;
    width: 55%;
    height: 55%;
    max-width: 50px;
    max-height: 50px;
    position: relative;

    &:before {
      border-radius: 80%;
      content: "";
      display: block;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transform: scale(0.875);
      transform-origin: center center;
    }

    &:after {
      border: 1.5px solid #dba366;
      border-radius: 80%;
      content: "";
      display: block;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 55%;
      height: 55%;
      transform: scale(3);
      transform-origin: center center;
    }
  }
`;
