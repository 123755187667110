import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

// assets
import IconCheckOffSVG from "assets/images/pc/icon_check_off.svg";
import IconCheckOnSVG from "assets/images/pc/icon_check_on.svg";

/**
 * @file 인력등록 - 인력등록항목 팝업
 * @brief 인력등록 팝업 컴포넌트
 * @author TW.Lee
 * @param floatRef 팝업 DOM
 * @param bgRef 리스트 DOM
 * @param ScrollY Y축 스크롤 상태값
 */
const TransferListMobilePopup = ({
  popupProfile,
  popupSupport,
  popupEdu,
  popupCareer,
  popupLicense,
  popupSelf,
}) => {
  const bgRef = useRef([]);
  const floatRef = useRef();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [ScrollY, setScrollY] = useState(0);
  const [time, setTime] = useState();

  const handleScroll = useCallback(() => {
    const moving = window.pageYOffset;
    setVisible(ScrollY > moving);

    if (ScrollY > 459) {
      setScrollY(window.pageYOffset);
      floatRef.current.style.position = "fixed";
      floatRef.current.style.top = "0";

      if (visible) {
        floatRef.current.style.top = "50px";
      } else {
        floatRef.current.style.top = "0px";
      }
    } else {
      setScrollY(window.pageYOffset);
      floatRef.current.style.position = "absolute";
      floatRef.current.style.top = "150px";
    }
  }, [ScrollY]);

  useEffect(() => {
    function scrollListener() {
      window.addEventListener("scroll", handleScroll);
    } //  window 에서 스크롤을 감시 시작
    scrollListener(); // window 에서 스크롤을 감시
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }; //  window 에서 스크롤을 감시를 종료
  });

  useEffect(() => {
    for (let i = 0; i < bgRef.current.length; i++) {
      bgRef.current[i].style.color = "#707070";

      if (ScrollY >= 0 && ScrollY <= 1480) {
        bgRef.current[0].style.color = "#c09260";
      } else if (ScrollY > 1480 && ScrollY <= 2200) {
        bgRef.current[1].style.color = "#c09260";
      } else if (ScrollY > 2200 && ScrollY <= 3000) {
        bgRef.current[2].style.color = "#c09260";
      } else if (ScrollY > 3000 && ScrollY <= 4000) {
        bgRef.current[3].style.color = "#c09260";
      } else if (ScrollY > 4000 && ScrollY <= 4500) {
        bgRef.current[4].style.color = "#c09260";
      } else if (ScrollY > 4500) {
        bgRef.current[5].style.color = "#c09260";
      }
    }
  }, [ScrollY]);

  return (
    <>
      <TransferPopupRoot ref={floatRef}>
        <div className="float-sec">
          <ul>
            <li ref={(el) => (bgRef.current[0] = el)}>
              <p>{t("profile")}</p>
              <p>
                <img
                  src={popupProfile ? IconCheckOnSVG : IconCheckOffSVG}
                  alt="인력등록항목 미체크 아이콘"
                />
              </p>
            </li>
            <li ref={(el) => (bgRef.current[1] = el)}>
              <p>{t("appfield")}</p>
              <p>
                <img
                  src={popupSupport ? IconCheckOnSVG : IconCheckOffSVG}
                  alt="인력등록항목 미체크 아이콘"
                />
              </p>
            </li>
            <li ref={(el) => (bgRef.current[2] = el)}>
              <p>{t("EduMilImp")}</p>
              <p>
                <img
                  src={popupEdu ? IconCheckOnSVG : IconCheckOffSVG}
                  alt="인력등록항목 미체크 아이콘"
                />
              </p>
            </li>
            <li ref={(el) => (bgRef.current[3] = el)}>
              <p>{t("career")}</p>
              <p>
                <img
                  src={popupCareer ? IconCheckOnSVG : IconCheckOffSVG}
                  alt="인력등록항목 미체크 아이콘"
                />
              </p>
            </li>
            <li ref={(el) => (bgRef.current[4] = el)}>
              <p>{t("certificate")}</p>
              <p>
                <img
                  src={popupLicense ? IconCheckOnSVG : IconCheckOffSVG}
                  alt="인력등록항목 미체크 아이콘"
                />
              </p>
            </li>
            <li ref={(el) => (bgRef.current[5] = el)}>
              <p>{t("yourself")}</p>
              <p>
                <img
                  src={popupSelf ? IconCheckOnSVG : IconCheckOffSVG}
                  alt="인력등록항목 미체크 아이콘"
                />
              </p>
            </li>
          </ul>
        </div>
      </TransferPopupRoot>
    </>
  );
};

export default TransferListMobilePopup;

const TransferPopupRoot = styled.div`
  position: absolute;
  z-index: 700;
  top: 150px;
  left: 0;

  & .float-sec {
    width: 100%;
    overflow-x: scroll;
    padding: 16px 20px;
    background-color: #f9f9f9;
  }

  & h4 {
    font-size: 20px;
    font-weight: bold;
    color: #222;
    border-bottom: 1px solid #b2b2b2;
    padding-bottom: 12px;
    margin-bottom: 20px;
  }

  & ul {
    width: 180%;
    display: flex;
  }

  & li {
    padding: 0 10px 0 10px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #707070;

    & p {
      margin-right: 6px;
    }
  }
`;
