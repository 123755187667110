/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { forwardRef } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// store
import { isMobileWidthAtom } from "store/ui/atom";

const rootCss = (isMobileWidth) => {
  return css`
    width: 100%;

    & .policy-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: ${isMobileWidth ? "10px" : "16px"};

      & p {
        font-size: ${isMobileWidth ? "14px" : "18px"};
        font-weight: bold;
        color: #222;
        margin: 0;
      }

      & a {
        border: 0;
        background: none;
        text-decoration: underline;
        color: #707070;
        font-size: ${isMobileWidth ? "13px" : "14px"};
        font-weight: normal;
        cursor: pointer;
      }
    }
  `;
};

const textareaCss = (isMobileWidth) => {
  return css`
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px 20px 20px 20px;

    & div {
      height: 86px;
      overflow-y: scroll;
      color: #707070;
      font-size: ${isMobileWidth ? "13px" : "16px"};
      font-weight: normal;
      line-height: 1.38;

      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #a89a8b;
        border: 2px solid transparent;
        border-radius: 10px;
        background-clip: padding-box;
      }
      &::-webkit-scrollbar-track {
        margin: -10px 0;
      }
    }
  `;
};

/**
 * @file Policybox Components
 */
const Policybox = forwardRef((props, ref) => {
  const { children, label, type, fullWidth, errors } = props;
  //const navigate = useNavigate();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const root = rootCss(isMobileWidth);
  const textarea = textareaCss(isMobileWidth);
  const { t } = useTranslation();

  return (
    <div css={root}>
      <div className="policy-text">
        <p>{label}</p>
        {type === "service" ? (
          <Link to="/terms_service" target="_blank">
            {t("view")}
          </Link>
        ) : (
          <Link to="/terms_policy" target="_blank">
            {t("view")}
          </Link>
        )}
      </div>
      <div css={textarea}>
        <div>{children}</div>
      </div>
    </div>
  );
});

export default Policybox;
