import { atom } from "recoil";

export const saveAtom = atom({
  key: "saveAtom",
  default: {
    message: "",
    isOpen: false,
    resolve: null,
    reject: null,
    regist: "",
    ok: "",
  },
});
