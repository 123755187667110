import React from "react";
import styled from "@emotion/styled";

/**
 * @file GradientLayout Layout (메인 페이지 백그라운드 디자인 스타일)
 */
const GradientLayout = ({ children }) => {
  return (
    <GradientRoot>
      <div className="gradient_blue1">
        <div className="gradient_yellow2">
          <div className="gradient_blue2">
            <div className="gradient_yellow3">{children}</div>
          </div>
        </div>
      </div>
    </GradientRoot>
  );
};

export default GradientLayout;

const GradientRoot = styled.div`
  background: radial-gradient(
    circle at right top,
    rgba(177, 136, 86, 0.3),
    rgba(128, 102, 70, 0) 20%
  );

  & .gradient_blue1 {
    background: radial-gradient(
      circle at 35% 32%,
      rgba(38, 46, 86, 0.3),
      rgba(38, 46, 86, 0) 31%
    );
  }

  & .gradient_blue2 {
    background: radial-gradient(
      circle at 45% 85%,
      rgba(38, 46, 86, 0.3),
      rgba(38, 46, 86, 0) 31%
    );
  }

  & .gradient_yellow2 {
    background: radial-gradient(
      circle at 100% 60%,
      rgba(177, 136, 86, 0.3),
      rgba(128, 102, 70, 0) 30%
    );
  }

  & .gradient_yellow3 {
    background: radial-gradient(
      circle at -10% 90%,
      rgba(177, 136, 86, 0.3),
      rgba(128, 102, 70, 0) 44%
    );
  }
`;
