import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// containers
import Pagination from "containers/Pagination/Pagination";

// hooks
import useSave from "hooks/useSave";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner01 from "assets/images/pc/img_banner01.png";

/**
 * @file 마이 페이지 - 인력등록 현황
 * @brief 인력등록 현황
 * @author TW.Lee
 */
const MypageList = () => {
  const { t } = useTranslation();
  const { isSaved } = useSave();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]); // 게시판 리스트 데이터
  const [totalPages, setTotalPages] = useState(0); // 게시판 총 페이지 수
  const [page, setPage] = useState(1); // 게시판 페이지 번호
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const root = MobileMypage03Styled(t("lang"));
  const moroot = MobileMypageStyled(t("lang"));

  useEffect(() => {
    axios({
      method: "get",
      url: "/resume",
    }).then((res) => {
      if (res.data.result.data.length > 0) {
        setTableData(res.data.result.data);
        setTotalCount(res.data.result.total_elements);
        setTotalPages(Math.ceil(res.data.result.total_elements / 10));
      } else {
        setTableData(0);
      }
    });
  }, [page, limit]);

  const pageArr = [];
  const pagination = () => {
    for (let i = 0; i < tableData.length; i += 10) {
      pageArr.push(tableData.slice(i, i + 10));
    }
    return pageArr;
  };
  pagination();

  if (isMobileWidth) {
    return (
      <Layout subFixed="true">
        <SubLayout
          banner={Banner01}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.mypage : PAGE_LAYOUT_EN.mypage
          }
        >
          <Container>
            <MypageMobileInner01 css={moroot}>
              <div className="mypage-title">
                <div onClick={() => navigate("/mypage")}>
                  {t("information")}
                </div>
                <span>&#183;</span>
                <div className="action">{t("manpowerstatus")}</div>
              </div>
            </MypageMobileInner01>
            <MypageListMobileInner02></MypageListMobileInner02>
            <MypageListInner03>
              {tableData?.length > 0 ? (
                pageArr[page - 1]?.map((item, index) => {
                  return (
                    <MypageListMobileContent01 key={index}>
                      <ul>
                        {item.state === "APPROVED" && (
                          <ContentMobileStatusApprove>
                            {t("approval")}
                          </ContentMobileStatusApprove>
                        )}
                        {item.state === "APPROVE_REJECT" && (
                          <ContentMobileStatusRefuse>
                            {t("reject")}
                          </ContentMobileStatusRefuse>
                        )}
                        {item.state === "APPROVE_WAITING" && (
                          <ContentMobileStatus>
                            {t("waitingapproval")}
                          </ContentMobileStatus>
                        )}
                        {item.state === "TEMPORARY_SAVE" && (
                          <ContentMobileStatus>
                            {t("temporarysave")}
                          </ContentMobileStatus>
                        )}
                        <ContentMobilePeriod>
                          <div>
                            {t("lang") === "EN" ? (
                              <div className="content_title">
                                {item.job_title_id
                                  ? item.job_title
                                  : "누리글로벌서비스 파트타이머 채용"}
                              </div>
                            ) : (
                              <div className="content_title">
                                {item.job_title_id
                                  ? item.job_title
                                  : "NURI Global Service Part-timers Recruitment"}
                              </div>
                            )}
                            <ContentMobileRegDtm>
                              {t("registrationdate")}{" "}
                              <span>
                                {dayjs(item.created_at).format("YYYY-MM-DD")}
                              </span>
                            </ContentMobileRegDtm>
                            <div className="mypage_btn">
                              {item.job_title_id && (
                                <div
                                  className="showpost"
                                  onClick={() =>
                                    navigate(
                                      `/hiring/hiring-detail/${item.job_title_id}`,
                                      {
                                        state: {
                                          postId: item.job_title_id,
                                        },
                                      }
                                    )
                                  }
                                >
                                  {t("viewrecruitment")}
                                </div>
                              )}
                              {item.state !== "TEMPORARY_SAVE" ? (
                                <div
                                  className="showdetail"
                                  onClick={() =>
                                    navigate(
                                      `/transfer/transfer-detail/${item.id}`,
                                      {
                                        state: {
                                          resumeId: item.id,
                                        },
                                      }
                                    )
                                  }
                                >
                                  {t("viewdetail2")}
                                </div>
                              ) : (
                                <div
                                  className="reloaddetail"
                                  onClick={() => {
                                    const isLoad = isSaved(
                                      t("lang") === "EN"
                                        ? "임시 저장된 내용을 불러오시겠습니까?"
                                        : "Would you like to load your temporarily saved part-timer application?"
                                    );
                                    const getData = () => {
                                      isLoad.then((result) => {
                                        if (result === true) {
                                          navigate(
                                            `/transfer/transfer-edit/${item.id}`,
                                            {
                                              state: {
                                                resumeId: item.id,
                                              },
                                            }
                                          );
                                        }
                                      });
                                    };
                                    getData();
                                  }}
                                >
                                  {t("load")}
                                </div>
                              )}
                            </div>
                          </div>
                        </ContentMobilePeriod>
                      </ul>
                    </MypageListMobileContent01>
                  );
                })
              ) : (
                <MypageListContent02>{t("noregistration")}</MypageListContent02>
              )}
            </MypageListInner03>
            {totalPages > 1 ? (
              <Pagination
                page={page}
                limit={limit}
                count={totalPages}
                list={tableData.length}
                onChangePage={setPage}
              />
            ) : (
              ""
            )}
          </Container>
        </SubLayout>
      </Layout>
    );
  } else {
    return (
      <Layout subFixed="true">
        <SubLayout
          banner={Banner01}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.mypage : PAGE_LAYOUT_EN.mypage
          }
        >
          <Container>
            <MypageInner01>
              <div className="mypage-title">
                <div onClick={() => navigate("/mypage")}>
                  {t("information")}
                </div>
                <span>&#183;</span>
                <div className="action">{t("manpowerstatus")}</div>
              </div>
            </MypageInner01>
            <MypageListInner02>
              <ul>
                <GuideLineNo>NO.</GuideLineNo>
                <GuideLineStatus>{t("status")}</GuideLineStatus>
                <GuideLinePeriod>{t("recruitment")}</GuideLinePeriod>
                <GuideLineRegDtm>{t("registrationdate")}</GuideLineRegDtm>
              </ul>
            </MypageListInner02>
            <MypageListInner03 css={root}>
              {tableData?.length > 0 ? (
                pageArr[page - 1]?.map((item, index) => {
                  return (
                    <MypageListContent01 key={index}>
                      <ul>
                        <ContentNo>
                          {(totalCount - (index + (page - 1) * 10))
                            .toString()
                            .padStart(2, "0")}
                        </ContentNo>
                        {item.state === "APPROVED" && (
                          <ContentStatusApprove>
                            {t("approval")}
                          </ContentStatusApprove>
                        )}
                        {item.state === "APPROVE_REJECT" && (
                          <ContentStatusRefuse>
                            {t("reject")}
                          </ContentStatusRefuse>
                        )}
                        {item.state === "APPROVE_WAITING" && (
                          <ContentStatus>{t("waitingapproval")}</ContentStatus>
                        )}
                        {item.state === "TEMPORARY_SAVE" && (
                          <ContentStatus>{t("temporarysave")}</ContentStatus>
                        )}
                        <ContentPeriod className="period_style">
                          <div>
                            {t("lang") === "EN" ? (
                              <div>
                                {item.job_title_id
                                  ? item.job_title
                                  : "누리글로벌서비스 파트타이머 채용"}
                              </div>
                            ) : (
                              <div>
                                {item.job_title_id
                                  ? item.job_title
                                  : "NURI Global Service Part-timers Recruitment"}
                              </div>
                            )}
                            <div>
                              {item.job_title_id && (
                                <div
                                  className="showpost"
                                  onClick={() =>
                                    navigate(
                                      `/hiring/hiring-detail/${item.job_title_id}`,
                                      {
                                        state: {
                                          postId: item.job_title_id,
                                        },
                                      }
                                    )
                                  }
                                >
                                  {t("viewrecruitment")}
                                </div>
                              )}
                              {item.state !== "TEMPORARY_SAVE" ? (
                                <div
                                  className="showdetail"
                                  onClick={() =>
                                    navigate(
                                      `/transfer/transfer-detail/${item.id}`,
                                      {
                                        state: {
                                          resumeId: item.id,
                                        },
                                      }
                                    )
                                  }
                                >
                                  {t("viewdetail2")}
                                </div>
                              ) : (
                                <div
                                  className="reloaddetail"
                                  onClick={() => {
                                    const isLoad = isSaved(
                                      t("lang") === "EN"
                                        ? "임시 저장된 내용을 불러오시겠습니까?"
                                        : "Would you like to load your temporarily saved part-timer application?",
                                      "No",
                                      "Yes"
                                    );
                                    const getData = () => {
                                      isLoad.then((result) => {
                                        if (result === true) {
                                          navigate(
                                            `/transfer/transfer-edit/${item.id}`,
                                            {
                                              state: {
                                                resumeId: item.id,
                                              },
                                            }
                                          );
                                        }
                                      });
                                    };
                                    getData();
                                  }}
                                >
                                  {t("load")}
                                </div>
                              )}
                            </div>
                          </div>
                        </ContentPeriod>
                        <ContentRegDtm>
                          {dayjs(item.created_at).format("YYYY-MM-DD")}
                        </ContentRegDtm>
                      </ul>
                    </MypageListContent01>
                  );
                })
              ) : (
                <MypageListContent02>{t("noregistration")}</MypageListContent02>
              )}
            </MypageListInner03>
            {totalPages > 1 ? (
              <Pagination
                page={page}
                limit={limit}
                count={totalPages}
                totalCount={totalCount}
                list={tableData.length}
                onChangePage={setPage}
              />
            ) : (
              ""
            )}
          </Container>
        </SubLayout>
      </Layout>
    );
  }
};

export default MypageList;

const MobileMypage03Styled = (lang) => {
  return css`
    & .showpost {
      width: 96px;
      text-align: center;
      font-size: ${lang === "EN" ? "16px" : "14px"};
      line-height: 15px;
      height: 43px;
      border-radius: 8px;
      color: #c09260;
      border: solid 1px #c09260;
      cursor: pointer;
    }
  `;
};

const MobileMypageStyled = (lang) => {
  return css`
    & .mypage-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${lang === "EN" ? "20px" : "16px"};
      font-weight: bold;
      color: #707070;

      & div {
        cursor: pointer;
      }

      & span {
        font-size: 35px;
        margin: 0 12px;
        color: #b2b2b2;
      }

      & .action {
        color: #c09260;
      }
    }
  `;
};

const Container = styled.div`
  padding-bottom: 100px;
`;

const MypageInner01 = styled.div`
  & .mypage-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: bold;
    color: #707070;
    margin: 60px 0 30px;

    & div {
      cursor: pointer;
    }

    & span {
      font-size: 35px;
      margin: 0 33px;
    }

    & .action {
      color: #c09260;
    }
  }

  & .mypage-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f4ef;
    padding: 20px 30px;

    & div {
      display: flex;
      align-items: center;
    }
  }

  & .mypage-user {
    font-size: 20px;
    font-weight: normal;

    & span {
      font-size: 26px;
      font-weight: 600;
      color: #c09260;
      margin-right: 4px;
    }
  }
`;

const MypageListInner02 = styled.div`
  margin-top: 20px;
  border-top: 1px solid #222;
  padding-top: 12px;

  & ul {
    display: flex;
    background-color: #f9f9f9;
  }
  & ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-weight: bold;
  }
`;

const MypageListInner03 = styled.div``;

const MypageListContent01 = styled.div`
  border-bottom: 1px solid #ddd;
  /* cursor: pointer; */

  &:hover {
    background-color: rgba(192, 146, 96, 0.1);
  }

  & ul {
    display: flex;

    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 81px;
    }
  }
`;

const MypageListContent02 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 820px;
  font-size: 20px;
  border-bottom: 1px solid #ddd;
`;

const GuideLineNo = styled.li`
  width: 82px;
`;

const GuideLineStatus = styled.li`
  width: 162px;
`;

const GuideLinePeriod = styled.li`
  width: 757px;
`;

const GuideLineRegDtm = styled.li`
  width: 199px;
`;

const ContentNo = styled.li`
  width: 82px;
`;

const ContentStatusApprove = styled.li`
  width: 162px;
  color: #c09260;
  font-weight: bold;
`;
const ContentStatusRefuse = styled.li`
  width: 162px;
  color: #b2b2b2;
  font-weight: bold;
`;
const ContentStatus = styled.li`
  width: 162px;
  color: #000;
  font-weight: bold;
`;

const ContentPeriod = styled.li`
  width: 757px;
  padding-left: 20px;

  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: normal;

    & div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .showdetail {
    width: 96px;
    height: 43px;
    border-radius: 8px;
    color: #fff;
    background-color: #c09260;
    margin: 0 22px 0 4px;
    cursor: pointer;
  }

  & .reloaddetail {
    width: 96px;
    height: 43px;
    border-radius: 8px;
    color: #fff;
    background-color: #a89a8b;
    margin: 0 22px 0 4px;
    cursor: pointer;
  }
`;

const ContentRegDtm = styled.li`
  width: 199px;
`;

const MypageMobileInner01 = styled.div`
  margin-top: 20px;

  & .mypage-info {
    display: flex;
    justify-content: space-between;
    background-color: #f9f4ef;
    padding: 20px;

    & .mypage-img {
      display: flex;
      align-items: center;
      width: 100px;

      & img {
        width: 100%;
      }
    }
  }

  & .mypage-user {
    font-size: 14px;
    font-weight: normal;
    line-height: 0.9;
    & span {
      font-size: 20px;
      font-weight: 600;
      color: #c09260;
    }
  }
`;

const MypageListMobileInner02 = styled.div`
  margin-top: 8px;
  border-top: 1px solid #222;
`;

const MypageListMobileContent01 = styled.div`
  border-bottom: 1px solid #ddd;
  margin-top: 20px;
`;

const ContentMobileStatusApprove = styled.li`
  color: #c09260;
  font-weight: bold;
  font-size: 14px;
`;
const ContentMobileStatusRefuse = styled.li`
  color: #b2b2b2;
  font-weight: bold;
  font-size: 14px;
`;
const ContentMobileStatus = styled.li`
  color: #000;
  font-weight: bold;
  font-size: 14px;
`;

const ContentMobilePeriod = styled.li`
  & > div {
    width: 100%;
    font-size: 14px;
    font-weight: normal;

    & .mypage_btn {
      display: flex;
      justify-content: flex-end;
    }

    & .mypage_btn div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .content_title {
    justify-content: start;
    font-size: 18px;
    margin-bottom: 8px;
  }

  & .showpost {
    width: 50%;
    height: 43px;
    border-radius: 8px;
    color: #c09260;
    border: solid 1px #c09260;
    margin: 12px 4px 20px 0;
    cursor: pointer;
  }
  & .showdetail {
    width: 50%;
    height: 43px;
    border-radius: 8px;
    color: #fff;
    background-color: #c09260;
    cursor: pointer;
    margin: 12px 0 20px;
  }

  & .reloaddetail {
    width: 50%;
    height: 43px;
    border-radius: 8px;
    color: #fff;
    background-color: #a89a8b;
    cursor: pointer;
    margin: 12px 0 20px;
  }
`;

const ContentMobileRegDtm = styled.div`
  width: 200px;
  font-weight: bold;
  font-size: 14px;

  & .span {
    font-weight: normal;
  }
`;
