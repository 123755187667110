import * as yup from "yup";

/**
 * @file 아이디 찾기 유효성 검사 및 입력 체크
 * @author TW.Lee
 */
export default yup.object().shape({
  userPhone2: yup.string().required("Please enter your Mobile Number."),
  verifiCode: yup.string().required("Please enter your Verify your identity."),
});
