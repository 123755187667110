/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef } from "react";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

const rootCss = (props, isMobileWidth) => {
  const paddingTop = props.p ? `${props.p}px` : "88px";
  return css`
    position: relative;

    & span {
      position: absolute;
      padding-top: ${isMobileWidth ? paddingTop : "0"};
      top: ${isMobileWidth ? "-50%" : "-80%"};
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: ${isMobileWidth ? "14px" : "16px"};
      font-weight: normal;
      color: #000;
    }
  `;
};

const inputCss = (props, isMobileWidth) => {
  const width = props.w ? `${props.w}px` : "88px";
  const height = props.h ? `${props.h}px` : "65px";

  return css`
    width: ${width};
    height: ${isMobileWidth ? height : "65px"};
    border: solid 1px #ddd;
    border-radius: 0;
    -webkit-border-radius: 0;
    appearance: none;
    cursor: pointer;

    &:checked {
      border: solid 1px #c09260;
      & + span {
        color: #c09260;
      }
    }
  `;
};

/**
 * @file Radio Components
 */
const Radio = forwardRef((props, ref) => {
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const { isToggle, children, ...rest } = props;

  const root = rootCss(props, isMobileWidth);
  const input = inputCss(props, isMobileWidth);

  return (
    <>
      {isToggle ? (
        <label css={root} className="component-radio">
          <input ref={ref} css={input} type="checkbox" {...rest} />
          <span>{children}</span>
        </label>
      ) : (
        <label css={root} className="component-radio">
          <input ref={ref} css={input} type="radio" {...rest} />
          <span>{children}</span>
        </label>
      )}
    </>
  );
});

export default Radio;
