import { useEffect, useState } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";
import { ToastContainer, cssTransition } from "react-toastify";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import Modal from "react-modal";

// dayjs
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday"; // 일, 월, 화, 수 ... 등의 요일을 locale에 맞게 표현
import timezone from "dayjs/plugin/timezone"; // 나라별 시간을 보정하기 위한 플러그인
import isBetween from "dayjs/plugin/isBetween"; // 날짜가 지정한 시간 단위에서 특정 날짜들 사이에 있는지 구하기
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"; // 날짜가 지정한 시간 단위에서 특정 날짜보다 이후 이거나 같은지 구하기
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"; // 날짜가 지정한 시간 단위에서 특정 날짜보다 이전 이거나 같은지 구하기

// containers
import AlertModal from "containers/modals/AlertModal/AlertModal";
import SaveModal from "containers/modals/SaveModal/SaveModal";
import SecessionOutModal from "containers/modals/SecessionOutModal/SecessionOutModal";

// api
import userApis from "api/user";
import mypageApis from "api/mypage";

// store
import useAuthActions from "store/auth/action";
import {
  accessTokenAtom,
  userInfoAtom,
  userRoleAtom,
  employeeRoleAtom,
} from "store/auth/atom";
import { isMobileWidthAtom } from "store/ui/atom";

// hooks
import useWindowSize from "hooks/useWindowSize";

// assets
import "assets/styles/font.css";
import "assets/styles/variables.css";

import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";
import "animate.css/animate.min.css";
import "react-datepicker/dist/react-datepicker.css";
import RoutesHandler from "./Routes";
import GlobalStyle from "./GlobalStyle";

Modal.setAppElement("#root");

// 타임존 설정
dayjs.extend(weekday);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.tz.setDefault("Asia/Seoul");

let currentPath = "";

const App = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { width } = useWindowSize();
  const authActions = useAuthActions();
  const accessToken = useRecoilValue(accessTokenAtom);
  const setUserInfo = useSetRecoilState(userInfoAtom);
  const setUserRole = useSetRecoilState(userRoleAtom);
  const setEmployeeRoleAtom = useSetRecoilState(employeeRoleAtom);
  const setIsMobileWidth = useSetRecoilState(isMobileWidthAtom);
  const [loginAutoChecked, setLoginAutoChecked] = useState(false);

  const targetStorage = localStorage.getItem("accessToken")
    ? localStorage
    : sessionStorage;

  useEffect(() => {
    if (t("lang") === "EN") {
      document.title = "주식회사 누리글로벌서비스";
    } else {
      document.title = "NURI Global Service";
    }
  }, [t("lang")]);

  // 현재 링크 클릭 시 새로고침 되게 설정
  useEffect(() => {
    if (currentPath === location.pathname) window.location.reload();

    currentPath = location.pathname;
  }, [location]);

  useEffect(() => {
    // 화면사이즈 변동 감지
    if (width < 1299) {
      setIsMobileWidth(true);
    } else {
      setIsMobileWidth(false);
    }
  }, [width]);

  useEffect(() => {
    // AOS 초기화
    Aos.init();
  });

  useEffect(() => {
    // 자동 로그인 체크
    const loginAutoCheck = async () => {
      await authActions.loginAuto();

      setLoginAutoChecked(true);
    };

    loginAutoCheck();
  }, []);

  useEffect(() => {
    // 로그인 성공 시 본인정보 불러오기
    if (accessToken) {
      userApis.authInfo().then((res) => {
        targetStorage.setItem("role", res.result.raw.user_role);
        setUserInfo(res.result.raw.user_name);
        setUserRole(res.result.raw.user_role);
        if (res.result.raw.user_employee_info) {
          targetStorage.setItem(
            "Erole",
            res.result.raw.user_employee_info.user_role
          );
          setEmployeeRoleAtom(res.result.raw.user_employee_info.user_role);
        }
      });
    }
  }, [accessToken]);

  if (!loginAutoChecked) {
    return null;
  }

  return (
    <>
      <GlobalStyle />
      <RoutesHandler />
      <AlertModal />
      <SecessionOutModal />
      <ToastContainer
        enableMultiContainer
        position="bottom-center"
        hideProgressBar
        closeButton={false}
        draggable={false}
        transition={cssTransition({
          enter: "animate__animated animate__fadeIn",
          exit: "animate__animated animate__fadeOut",
        })}
        autoClose={2000}
      />
    </>
  );
};

export default App;
