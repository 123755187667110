import * as yup from "yup";

export default yup.object().shape({
  PromotionTitle: yup.string().required("제목을 입력해주세요."),
  PromotionRegDtm: yup.string().required("시작일을 선택해주세요."),
  PromotionDeadline: yup.string().required("마감일을 선택해주세요."),
  PromotionSite: yup
    .string()
    .required("웹사이트 주소를 입력해주세요.")
    .matches(
      /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi,
      "웹사이트 주소를 입력해주세요."
    ),
  PromotionContent: yup.string().required("내용을 입력해주세요."),
});
