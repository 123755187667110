import React from "react";
import styled from "@emotion/styled";

// assets
import BgCircleASVG from "assets/images/pc/bg_circle_a.svg";

const Circle03 = () => {
  return (
    <FloatRoot>
      <img
        src={BgCircleASVG}
        className="bg_circle_a"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="300"
      />
    </FloatRoot>
  );
};

export default Circle03;

const FloatRoot = styled.div`
  position: absolute;
  margin: -300px 0 0 -300px;
  height: 500px;
  overflow: hidden;
  z-index: 50;
`;
