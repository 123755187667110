import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner02 from "assets/images/pc/img_banner02.png";
import History01 from "assets/images/pc/img_history01.png";
import History02 from "assets/images/pc/img_history02.png";
import History03 from "assets/images/pc/img_history03.png";
import History04 from "assets/images/pc/img_history04.png";

import IntroduceInfoPopup from "./components/IntroduceInfoPopup";

/**
 * @file 회사소개 - 기업정보 페이지
 * @brief 회사 연혁
 * @author TW.Lee
 */
const IntroduceInfo = () => {
  const { t } = useTranslation();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  return (
    <Layout subFixed="true">
      <GreetingRoot>
        <SubLayout
          banner={Banner02}
          pageText={
            t("lang") === "EN"
              ? PAGE_LAYOUT.companyinfo
              : PAGE_LAYOUT_EN.companyinfo
          }
        >
          <div className="interduce_inner">
            {isMobileWidth ? (
              <InterduceMobilenner01>
                <h2>
                  Endless Challenge For
                  <br /> Your Tomorrow
                </h2>
              </InterduceMobilenner01>
            ) : (
              <InterduceInner01>
                <h2>
                  Endless Challenge For
                  <br /> Your Tomorrow
                </h2>
              </InterduceInner01>
            )}
            <IntroduceInfoPopup />
            {isMobileWidth ? (
              <InterduceMobileInner03>
                <ul>
                  <li
                    className="ceo_con_info_img1"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <div>
                      <p>{t("birth")}</p>
                      <h4>2020~2021</h4>
                    </div>
                    {t("lang") === "EN" ? (
                      <ul className="ceo_con_info_img_text">
                        <li>주식회사 누리글로벌서비스 설립</li>
                        <li>호텔 서비스 전문인력 파견 기업 등록</li>
                        <li>기능 직종별 체제 도입 및 전문 인력의 배치관리</li>
                        <li>롯데호텔 서울·월드 협력사 선정</li>
                        <li>임금구조의 표준화</li>
                        <li>조직 체계구성 일원화 및 지휘 통제력 확대</li>
                      </ul>
                    ) : (
                      <ul className="ceo_con_info_img_text">
                        <li>Establishment of NURI Global Service Co., Ltd</li>
                        <li>
                          Registered as a workforce dispatch agency
                          <br /> which provides professionals in domestic
                          <br /> hotel service industry
                        </li>
                        <li>
                          Placement of specialists with right talents
                          <br /> in right positions by utilizing advanced
                          <br /> labor management system
                        </li>
                        <li>
                          Selected as a business partner of
                          <br /> Lotte Hotel Seoul and World
                        </li>
                        <li>
                          Standardized employees&#39; wage structure
                          <br /> for fair work environment
                        </li>
                        <li>
                          Consolidated the company&#39;s command
                          <br /> & control by unifying organizational
                          <br /> structure and system
                        </li>
                      </ul>
                    )}
                  </li>
                  <li className="ceo_con_info_img2">
                    <div>
                      <p>{t("leaps")}</p>
                      <h4>2022~2023</h4>
                    </div>
                    {t("lang") === "EN" ? (
                      <ul className="ceo_con_info_img_text">
                        <li>
                          빅데이터 기반의 업무용 모바일 어플 및<br /> 웹사이트
                          개발 및 운영
                        </li>
                        <li>
                          RFID 태그를 활용한 린넨 및 런드리
                          <br /> 관리 실시
                        </li>
                        <li>각 부서별 업무 매뉴얼 제작</li>
                        <li>각 직종별 업무평가 실시 및 인사고과 반영</li>
                      </ul>
                    ) : (
                      <ul className="ceo_con_info_img_text">
                        <li>
                          Developing and operating NURI Data
                          <br /> Application (NURIDA), a digitalized
                          <br /> hotel work platform (web & mobile)
                          <br /> based on &#39;Big-Data&#39; technology
                        </li>
                        <li>
                          Efficiently managing hotel linen and
                          <br /> laundry service via RFID system
                        </li>
                        <li>Producing work manuals for each department</li>
                        <li>
                          Implementing performance and assessment
                          <br /> evaluation for each team and employee
                        </li>
                      </ul>
                    )}
                  </li>
                  <li className="ceo_con_info_img3">
                    <div>
                      <p>{t("stable")}</p>
                      <h4>2024~2025</h4>
                    </div>
                    {t("lang") === "EN" ? (
                      <ul className="ceo_con_info_img_text">
                        <li>
                          디지털화 된 업무 플랫폼을 활용한 파트너사의
                          <br /> 서비스 품질 개선
                        </li>
                        <li>상/하반기 신입사원 OJT 실시</li>
                        <li>
                          파트너 호텔의 니즈 파악을 위한 자체적 국내·외
                          <br /> 시장조사 시행
                        </li>
                        <li>
                          빅데이터를 활용하여 파트너 호텔에게
                          <br /> 미래지향적 전략 제언
                        </li>
                        <li>안정적인 기업운영을 위한 투자유치</li>
                      </ul>
                    ) : (
                      <ul className="ceo_con_info_img_text">
                        <li>
                          Improving our partner hotel&#39;s service
                          <br /> quality through utilizing a digitalized
                          <br /> work platform
                        </li>
                        <li>
                          Holding OJTs (On-the-Job Training)
                          <br /> every first and second half of the
                          <br /> year for new employees
                        </li>
                        <li>
                          Conducting independent domestic &
                          <br /> oversea market research for identifying
                          <br /> the needs of our partner hotels
                        </li>
                        <li>
                          Suggesting future-oriented business
                          <br /> strategies to partner hotels through
                          <br /> our &#39;Big-Data&#39; collection
                        </li>
                        <li>
                          Attracting investment for stable
                          <br /> business operation
                        </li>
                      </ul>
                    )}
                  </li>
                  <li className="ceo_con_info_img4">
                    <div>
                      <p>{t("expansion")}</p>
                      <h4>2025~</h4>
                    </div>
                    {t("lang") === "EN" ? (
                      <ul className="ceo_con_info_img_text">
                        <li>
                          고도화 된 인공지능 기술과
                          <br /> 기존 디지털 업무 플랫폼 융합하여 파트너사의
                          <br /> 서비스 퀄리티 극대화
                        </li>
                        <li>
                          유수의 국내·해외 기업과 파트너쉽 체결
                          <br /> 및 해외 시장 진출
                        </li>
                        <li>
                          호텔 업계 외, 타 분야의 사업 타당성 검토
                          <br /> 및 진출
                        </li>
                        <li>전문인력 대거 확보 및 운영</li>
                      </ul>
                    ) : (
                      <ul className="ceo_con_info_img_text">
                        <li>
                          Maximizing partner hotel&#39;s service quality
                          <br /> by combining advanced artificial intelligence
                          <br /> technology with existing digitalized business
                          <br /> platforms
                        </li>
                        <li>
                          Signing business partnership contracts
                          <br /> with influential domestic and foreign
                          <br /> companies
                        </li>
                        <li>
                          Proceeding multiple feasibility studies on
                          <br /> other industries and make smart investment
                          <br /> on potential business
                        </li>
                        <li>
                          Securing and managing sufficient experts
                          <br /> in a necessary field of business
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </InterduceMobileInner03>
            ) : (
              <InterduceInner03>
                <ul>
                  <li
                    className="ceo_con_info_img1"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <div>
                      <p>{t("birth")}</p>
                      <h4>2020~2021</h4>
                    </div>
                    {t("lang") === "EN" ? (
                      <ul className="ceo_con_info_img_text">
                        <li>주식회사 누리글로벌서비스 설립</li>
                        <li>호텔 서비스 전문인력 파견 기업 등록</li>
                        <li>기능 직종별 체제 도입 및 전문 인력의 배치관리</li>
                        <li>롯데호텔 서울·월드 협력사 선정</li>
                        <li>임금구조의 표준화</li>
                        <li>조직 체계구성 일원화 및 지휘 통제력 확대</li>
                      </ul>
                    ) : (
                      <ul className="ceo_con_info_img_text">
                        <li>Establishment of NURI Global Service Co., Ltd</li>
                        <li>
                          Registered as a workforce dispatch agency which
                          <br />
                          provides professionals in domestic hotel service
                          industry
                        </li>
                        <li>
                          Placement of specialists with right talents in right
                          positions by
                          <br />
                          utilizing advanced labor management system
                        </li>
                        <li>
                          Selected as a business partner of Lotte Hotel Seoul
                          and World
                        </li>
                        <li>
                          Standardized employees&#39; wage structure for fair
                          work environment
                        </li>
                        <li>
                          Consolidated the company&#39;s command & control by
                          unifying
                          <br />
                          organizational structure and system
                        </li>
                      </ul>
                    )}
                  </li>
                  <li className="ceo_con_info_img2">
                    <div>
                      <p>{t("leaps")}</p>
                      <h4>2022~2023</h4>
                    </div>
                    {t("lang") === "EN" ? (
                      <ul className="ceo_con_info_img_text">
                        <li>
                          빅데이터 기반의 업무용 모바일 어플 및 웹사이트 개발 및
                          운영
                        </li>
                        <li>RFID 태그를 활용한 린넨 및 런드리 관리 실시</li>
                        <li>각 부서별 업무 매뉴얼 제작</li>
                        <li>각 직종별 업무평가 실시 및 인사고과 반영</li>
                      </ul>
                    ) : (
                      <ul className="ceo_con_info_img_text">
                        <li>
                          Developing and operating NURI Data Application
                          (NURIDA),
                          <br />a digitalized hotel work platform (web & mobile)
                          based on
                          <br />
                          &#39;Big-Data&#39; technology
                        </li>
                        <li>
                          Efficiently managing hotel linen and laundry service
                          via RFID system
                        </li>
                        <li>Producing work manuals for each department</li>
                        <li>
                          Implementing performance and assessment evaluation for
                          each
                          <br />
                          team and employee
                        </li>
                      </ul>
                    )}
                  </li>
                  <li className="ceo_con_info_img3">
                    <div>
                      <p>{t("stable")}</p>
                      <h4>2024~2025</h4>
                    </div>
                    {t("lang") === "EN" ? (
                      <ul className="ceo_con_info_img_text">
                        <li>
                          디지털화 된 업무 플랫폼을 활용한 파트너사의 서비스
                          품질 개선
                        </li>
                        <li>상/하반기 신입사원 OJT 실시</li>
                        <li>
                          파트너 호텔의 니즈 파악을 위한 자체적 국내·외 시장조사
                          시행
                        </li>
                        <li>
                          빅데이터를 활용하여 파트너 호텔에게 미래지향적 전략
                          제언
                        </li>
                        <li>안정적인 기업운영을 위한 투자유치</li>
                      </ul>
                    ) : (
                      <ul className="ceo_con_info_img_text">
                        <li>
                          Improving our partner hotel&#39;s service quality
                          through
                          <br />
                          utilizing a digitalized work platform
                        </li>
                        <li>
                          Holding OJTs (On-the-Job Training) every first and
                          second
                          <br />
                          half of the year for new employees
                        </li>
                        <li>
                          Conducting independent domestic & oversea market
                          research
                          <br />
                          for identifying the needs of our partner hotels
                        </li>
                        <li>
                          Suggesting future-oriented business strategies to
                          partner
                          <br />
                          hotels through our &#39;Big-Data&#39; collection
                        </li>
                        <li>
                          Attracting investment for stable business operation
                        </li>
                      </ul>
                    )}
                  </li>
                  <li className="ceo_con_info_img4">
                    <div>
                      <p>{t("expansion")}</p>
                      <h4>2025~</h4>
                    </div>
                    {t("lang") === "EN" ? (
                      <ul className="ceo_con_info_img_text">
                        <li>
                          고도화 된 인공지능 기술과 기존 디지털 업무 플랫폼
                          <br />
                          융합하여 파트너사의 서비스 퀄리티 극대화
                        </li>
                        <li>
                          유수의 국내·해외 기업과 파트너쉽 체결 및 해외 시장
                          진출
                        </li>
                        <li>
                          호텔 업계 외, 타 분야의 사업 타당성 검토 및 진출
                        </li>
                        <li>전문인력 대거 확보 및 운영</li>
                      </ul>
                    ) : (
                      <ul className="ceo_con_info_img_text">
                        <li>
                          Maximizing partner hotel&#39;s service quality by
                          combining advanced
                          <br /> artificial intelligence technology with
                          existing digitalized business platforms
                        </li>
                        <li>
                          Signing business partnership contracts with
                          influential domestic
                          <br />
                          and foreign companies
                        </li>
                        <li>
                          Proceeding multiple feasibility studies on other
                          industries and
                          <br />
                          make smart investment on potential business
                        </li>
                        <li>
                          Securing and managing sufficient experts in a
                          necessary
                          <br />
                          field of business
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </InterduceInner03>
            )}
          </div>
        </SubLayout>
      </GreetingRoot>
    </Layout>
  );
};

export default IntroduceInfo;

const GreetingRoot = styled.div`
  background-image: linear-gradient(to bottom, #111, #060606);
  padding-bottom: 100px;
`;

const InterduceInner01 = styled.div`
  margin-top: 120px;
  margin-bottom: 350px;

  & h2 {
    font-size: 40px;
    font-weight: bold;
    color: var(--Affdab2);
  }
`;

const InterduceMobilenner01 = styled.div`
  margin-top: 40px;
  margin-bottom: 72px;

  & h2 {
    font-size: 20px;
    line-height: 1.6;
    font-weight: bold;
    color: var(--Affdab2);
  }
`;

const InterduceInner03 = styled.div`
  margin-bottom: -250px;
  position: relative;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);

  & > ul > li {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  & .ceo_con_info_img1 {
    background: url(${History01}) no-repeat center/cover;
  }

  & .ceo_con_info_img2 {
    background: url(${History02}) no-repeat center/cover;
  }

  & .ceo_con_info_img3 {
    background: url(${History03}) no-repeat center/cover;
  }

  & .ceo_con_info_img4 {
    background: url(${History04}) no-repeat center/cover;
  }

  & p {
    font-size: 36px;
    font-family: Arita-buri-SemiBold;
    color: #ffc17d;
    padding-top: 390px;
  }

  & h4 {
    font-size: 60px;
    line-height: 71px;
    font-weight: bold;
    color: #fff;
    padding-bottom: 445px;
  }

  & .ceo_con_info_img_text {
    font-size: 22px;
    line-height: 38px;
    color: #fff;
  }

  & .ceo_con_info_img_text li {
    list-style: disc;
  }

  & .ceo_con_info_img_text li:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const InterduceMobileInner03 = styled.div`
  margin-bottom: -250px;
  position: relative;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);

  & > ul > li {
    width: 100vw;
    padding-left: 30px;
  }

  & .ceo_con_info_img1 {
    background: url(${History01}) no-repeat center/cover;
  }

  & .ceo_con_info_img2 {
    background: url(${History02}) no-repeat center/cover;
  }

  & .ceo_con_info_img3 {
    background: url(${History03}) no-repeat center/cover;
  }

  & .ceo_con_info_img4 {
    background: url(${History04}) no-repeat center/cover;
  }

  & p {
    font-size: 20px;
    font-family: Arita-buri-SemiBold;
    color: #ffc17d;
    padding-top: 236px;
  }

  & h4 {
    font-size: 32px;
    font-weight: bold;
    color: #fff;
    padding-bottom: 30px;
  }

  & .ceo_con_info_img_text {
    font-size: 16px;
    line-height: 1.63;
    color: #fff;
    padding-left: 20px;
    padding-bottom: 236px;
  }

  & .ceo_con_info_img_text li {
    list-style: disc;
  }

  & .ceo_con_info_img_text li:not(:last-child) {
    margin-bottom: 12px;
  }
`;
