import React from "react";
import styled from "@emotion/styled";
import DaumPostcode from "react-daum-postcode";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

/**
 * @file Postcode Components
 */
const Postcode = (props) => {
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);

  const complete = (data) => {
    props.setPopup(false);
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    props.setcompany({
      ...props.company,
      address: fullAddress,
      zipcode: data.zonecode,
    });
  };
  const postCodeStyle = {
    width: isMobileWidth ? "350px" : "500px",
    height: isMobileWidth ? "400px" : "450px",
  };

  return (
    <DaumPopup onClick={() => props.setPopup(false)}>
      <div>
        <DaumPostcode
          className="postmodal"
          style={postCodeStyle}
          autoClose
          onComplete={complete}
        />
      </div>
    </DaumPopup>
  );
};

export default Postcode;

const DaumPopup = styled.div`
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  & div {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -35%);
  }
`;
