import React, { useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css, ClassNames } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

// components
import Button from "components/Button/Button";

// hooks
import useSecessionOut from "hooks/useSecessionOut";

const modalStyleCore = `
  .overlay-base {  
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transition-property: opacity;
    transition-duration: 225ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms;
    outline: 0;
    z-index:1300;  
  }

  .overlay-after {
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
  }

  .overlay-before {
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
  }

  .content-base {  
    border: none;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 20px;
    outline: none;
    padding: 0px;  
    width: calc(100% - 32px);
    max-width: 390px;
    font-size: 18px;
    font-weight: normal;
    color: #222;
    max-height: calc(100% - 64px);
  }
`;

const inner = css`
  padding: 100px 40px 40px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const innerbutton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
`;

const confirmButton = css`
  width: 80%;
  height: 51px;
`;

const btnCss = css`
  width: 80%;
  display: flex;
  justify-content: space-between;
  & button {
    margin: 0 5px;
  }
`;

const ButtonProceed = (props) => {
  const navigate = useNavigate();

  return (
    <div css={btnCss}>
      <Button
        custom={[confirmButton]}
        {...props}
        variant="secondary"
        preset="1"
        onClick={() => props.reject()}
      >
        {props.regist}
      </Button>
      <Button
        custom={[confirmButton]}
        {...props}
        variant="primary"
        preset="2"
        onClick={() => props.resolve()}
      >
        {props.ok}
      </Button>
    </div>
  );
};

/**
 * @file SaveModal
 * @brief 임시저장 모달
 */
const SecessionOutModal = () => {
  const {
    message = "",
    renderMessage,
    isOpen = true,
    resolve,
    reject,
    regist = "아니오",
    ok = "예",
  } = useSecessionOut();

  const [newLinePrompt, setNewLinePrompt] = useState([]);

  useEffect(() => {
    setNewLinePrompt(message.split("\n"));
  }, [isOpen]);

  return (
    <ClassNames>
      {({ css: coreCss }) => (
        <Modal
          isOpen={isOpen}
          onRequestClose={reject}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after",
            beforeClose: "content-before",
          }}
          portalClassName={coreCss`${modalStyleCore}`}
        >
          <div css={inner}>
            {renderMessage
              ? renderMessage()
              : newLinePrompt.map((x, i) => <p key={i}>{x}</p>)}
          </div>
          <div css={innerbutton}>
            <ButtonProceed
              resolve={resolve}
              reject={reject}
              ok={ok}
              regist={regist}
            />
          </div>
        </Modal>
      )}
    </ClassNames>
  );
};

export default SecessionOutModal;
