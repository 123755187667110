/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef } from "react";
import { useRecoilValue } from "recoil";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// assets
import BtnCheckOffSVG from "assets/images/pc/btn_check_off@3x.png";
import BtnCheckOnSVG from "assets/images/pc/btn_check_on@3x.png";

// utils
import { ERROR_COLOR } from "utils/constants";

const rootCss = (props, isMobileWidth) => {
  const errors = props.errors?.[props.name]?.type === "required";
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${isMobileWidth ? "14px" : "16px"};
    color: ${errors ? ERROR_COLOR : "#000"};
    cursor: pointer;
    font-weight: ${props.fontWegiht ? "bold" : "nomal"};
  `;
};

const inputCss = (props, isMobileWidth) => {
  return css`
    appearance: none;
    margin-right: ${isMobileWidth ? "6px" : "8px"};
    width: ${isMobileWidth ? "16px" : "20px"};
    height: ${isMobileWidth ? "16px" : "20px"};
    background: url(${BtnCheckOffSVG}) no-repeat center/100%;
    cursor: pointer;

    &:checked {
      background: url(${BtnCheckOnSVG}) no-repeat center/100%;
    }
  `;
};

/**
 * @file Checkbox Components
 */
const Checkbox = forwardRef((props, ref) => {
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const {
    checked, // [boolean] 현재 체크 상태
    onChange, // [function] 이벤트 핸들러
    children, // [string] 라벨
    errors,
    fontWegiht,
    ...rest
  } = props;

  const root = rootCss(props, isMobileWidth);
  const input = inputCss(props, isMobileWidth);

  return (
    <>
      <label css={root} className="component-checkbox">
        <input
          ref={ref}
          css={input}
          type="checkbox"
          onChange={(e) => onChange(e)}
          checked={checked}
          {...rest}
        />
        {children}
      </label>
    </>
  );
});

export default Checkbox;
