import React from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// assets
import Banner01 from "assets/images/pc/img_banner01.png";

import NormalForm from "./components/NormalForm";
import PartnerForm from "./components/PartnerForm";

const CreateAccount = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const pageText = {
    mainTextEn: "Sign Up",
    mainTextKo: t("sign"),
    subText: location.state.id === 1 ? t("regular") : t("partners"),
    bgBlack: false,
  };

  return (
    <Layout subFixed="true">
      <WhiteRoot>
        <SubLayout banner={Banner01} pageText={pageText}>
          {location.state.id === 1 ? (
            <div>
              <AccountInner01>
                <h3
                  className={
                    isMobileWidth ? "mo-normal-header" : "normal-header"
                  }
                >
                  &#183; {t("basicinfo")} &#183;
                </h3>
                <div
                  className={
                    isMobileWidth ? "mo_account_inner" : "account_inner"
                  }
                >
                  <NormalForm />
                </div>
              </AccountInner01>
            </div>
          ) : (
            <div>
              <AccountInner01>
                <h3
                  className={
                    isMobileWidth ? "mo-normal-header" : "normal-header"
                  }
                >
                  &#183; {t("contactinfo")} &#183;
                </h3>
                <div
                  className={
                    isMobileWidth ? "mo_account_inner" : "account_inner"
                  }
                >
                  <PartnerForm />
                </div>
              </AccountInner01>
            </div>
          )}
        </SubLayout>
      </WhiteRoot>
    </Layout>
  );
};

export default CreateAccount;

const WhiteRoot = styled.div`
  & > div > div > .float_wrap > div {
    padding: 0 410px;
  }
`;

const AccountInner01 = styled.div`
  & h3 {
    margin-top: 60px;
    clear: both;
    font-size: 26px;
    line-height: 38px;
    color: #c09260;
    font-weight: bold;
    text-align: center;
  }

  & .mo-normal-header {
    margin-top: 40px;
    clear: both;
    font-size: 18px;
    color: #c09260;
    font-weight: bold;
    text-align: center;
  }

  & .account_inner {
    margin: 30px 0 60px;
    padding-top: 40px;
    border-top: 1px solid #222;
  }

  & .mo_account_inner {
    margin: 12px 0 40px;
    padding-top: 20px;
    border-top: 1px solid #222;
  }

  & .input-box {
    margin: 16px 0 30px;
  }

  & .mo_input-box {
    margin: 10px 0 20px;
  }

  & .phonenumber {
    display: flex;

    & span {
      margin: 0 4px;
    }
  }

  & .send-message {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
  }

  & .mo-send-message {
    width: 100%;
    height: 48px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 20px;
  }

  & .zipcode,
  & .business-number {
    display: flex;
  }

  & .void-sec {
    margin: 0 5px;
  }

  & .void-sec2 {
    height: 10px;
  }

  & .address-search,
  & .business-number-check {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
  }

  & .mo-address-search {
    width: 88px;
    height: 48px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 14px;
    margin-left: 8px;
  }

  & .policy-agree {
    border-bottom: 1px solid #ddd;
    padding-bottom: 40px;
  }

  & .mo-policy-agree {
    border-bottom: 1px solid #ddd;
    padding-bottom: 40px;
  }

  & .void-sec3 {
    margin: 15px 0;
  }

  & .compony-info h3,
  & .policy-agree h3 {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #222;
    padding: 80px 0 30px;
    margin: 40px 0 40px;
  }

  & .mo-compony-info h3,
  & .mo-policy-agree h3 {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #222;
    padding: 40px 0 12px;
    margin: 20px 0;
    font-size: 18px;
  }

  & .submit-button {
    display: flex;
    justify-content: center;
    padding: 60px 0 100px;
  }
  & .mo-submit-button {
    display: flex;
    justify-content: center;
    padding: 30px 0 60px;
  }
  & .bank-select {
    display: flex;
  }
`;
