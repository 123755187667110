import React, { useEffect, useState, useRef } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// components
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import Button from "components/Button/Button";
import Row from "containers/LabelWithInput/LabelWithInput";
import Postcode from "components/Postcode/DaumPostcode";

// api
import authApis from "api/auth";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// hooks
import useAlert from "hooks/useAlert";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";
import { PREFIX_NUMBER_OPTIONS } from "utils/constants";
import vaildationSchema from "utils/validation/mypageValidationSchema";
import vaildationSchemaEn from "utils/validation/mypageValidationSchemaEn";
import mypageVerifyValidationSchema from "utils/validation/mypageVerifyValidationSchema";
import mypageVerifyValidationSchemaEn from "utils/validation/mypageVerifyValidationSchemaEn";

// assets
import Banner01 from "assets/images/pc/img_banner01.png";
import BitClass01SVG from "assets/images/pc/bit_class01.png";
import BitClass01EnSVG from "assets/images/pc/bit_class01_en.png";

const defaultValues = {
  mypageEmail: "",
  mypagePassword: "",
  mypageNewPassword: "",
  mypageNewPasswordCheck: "",
  mypageName: "",
  userPhone1: "",
  userPhone2: "",
  verifiCode: "",
  Address1: "",
  Address2: "",
  Address3: "",
};

/**
 * @file 마이 페이지 - 회원 정보
 * @brief 회원 정보
 * @author TW.Lee
 */
const Mypage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { noti } = useAlert();
  const btnRef = useRef();
  const codeRef = useRef();
  const [basicData, setBasicData] = useState();
  const [popup, setPopup] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [isSmsSand, setIsSmsSand] = useState(false);
  const [submitState, setSubmitState] = useState("schemako");
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const [enrollCompany, setEnrollCompany] = useState({
    address: "",
    zipcode: "",
  });

  const root = MypageStyled(t("lang"));
  const moroot = MobileMypageStyled(t("lang"));

  const targetStorage = localStorage.getItem("accessToken")
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem("accessToken");
  const userRole = targetStorage.getItem("role");

  const UI = {
    schemako: newPass ? mypageVerifyValidationSchema : vaildationSchema,
    schemaen: newPass ? mypageVerifyValidationSchemaEn : vaildationSchemaEn,
  };

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(UI[submitState]),
    mode: "onChange",
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (t("lang") === "EN") {
      setSubmitState("schemako");
    } else {
      setSubmitState("schemaen");
    }
  }, [t("lang")]);

  useEffect(() => {
    if (values.mypageNewPassword !== "") {
      setNewPass(true);
    } else {
      setNewPass(false);
    }
  }, [values.mypageNewPassword]);

  // 페이지 접속 시 마이페이지 내정보 호출 요청
  useEffect(() => {
    axios({ method: "get", url: "/auth/login" }).then((res) => {
      setBasicData(res.data.result.raw);

      if (res.data.result) {
        const phone1 = res.data.result.raw.user_phone.substr(0, 3);
        const phone2 = res.data.result.raw.user_phone.substr(3, 8);
        setEnrollCompany({
          address: res.data.result.raw.address,
          zipcode: res.data.result.raw.zip_code,
        });
        // 전화번호 앞자리 셋팅
        for (let i = 0; i < PREFIX_NUMBER_OPTIONS.length; i++) {
          if (PREFIX_NUMBER_OPTIONS[i].value === phone1) {
            setValue("userPhone1", PREFIX_NUMBER_OPTIONS[i]);
          }
        }
        setValue(
          "mypageName",
          res.data.result ? res.data.result.raw.user_name : ""
        );
        setValue("userPhone2", res.data.result.raw ? phone2 : "");
        setValue("Address1", enrollCompany.zipcode);
        setValue("Address2", enrollCompany.address);
        setValue("Address3", res.data.result.raw.detail_address);
      }
    });
  }, [accessToken]);

  // 마이페이지 내정보 수정 요청
  const editMypage = async () => {
    await axios({
      method: "put",
      url: "/users/edit",
      data: {
        old_password: values.mypagePassword,
        password: values.mypageNewPassword
          ? values.mypageNewPassword
          : undefined,
        user_name: values.mypageName,
        zip_code: enrollCompany.zipcode,
        address: enrollCompany.address,
        detail_address: values.Address3,
        user_phone: values.userPhone1.value + values.userPhone2,
      },
    }).then(async (res) => {
      if (res.status === 200) {
        if (t("lang") === "EN") {
          await noti("수정되었습니다.");
        } else {
          await noti(
            "Your information has been changed successfully.",
            "Confirm"
          );
        }
        window.location.reload();
      }
    });
  };

  // 문자전송 요청
  const smsSend = useMutation(
    () => {
      return authApis.smsSend({
        phoneNum: values.userPhone1.value + values.userPhone2,
      });
    },
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          if (t("lang") === "EN") {
            noti("인증코드가 전송되었습니다.");
          } else {
            noti("Verification code has been sent.", "Confirm");
          }
          setIsSmsSand(true);
        }
      },
      onError: async (err) => {},
    }
  );

  // 인증번호 검증 요청
  const verify = useMutation(
    () => {
      return authApis.smsVerify({
        phoneNum: values.userPhone1.value + values.userPhone2,
        verifyCodeNum: values.verifiCode,
      });
    },
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          if (t("lang") === "EN") {
            noti("인증되었습니다.");
          } else {
            noti("Certified.");
          }
          setIsVerify(true);
        }
      },
      onError: async (err) => {
        if (err.response.data.message === "not matched") {
          if (t("lang") === "EN") {
            noti("인증코드가 올바르지 않습니다. 인증번호를 재전송해주세요.");
          } else {
            noti(
              "Incorrect Verification Code.\nPlease try it again.",
              "Confirm"
            );
          }
          setIsVerify(false);
          setIsSmsSand(false);
          codeRef.current.focus();
        }
      },
    }
  );

  return (
    <Layout subFixed="true">
      <WhiteRoot>
        <SubLayout
          banner={Banner01}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.mypage : PAGE_LAYOUT_EN.mypage
          }
        >
          {isMobileWidth ? (
            <MypageMobileInner01 css={moroot}>
              <div className="mypage-title">
                <div className="action" onClick={() => navigate("/mypage")}>
                  {t("information")}
                </div>
                {(userRole === "PARTTIMER" ||
                  userRole === "PARTNER_PARTTIMER") && <span>&#183;</span>}

                {(userRole === "PARTTIMER" ||
                  userRole === "PARTNER_PARTTIMER") && (
                  <div onClick={() => navigate("/mypagelist")}>
                    {t("manpowerstatus")}
                  </div>
                )}
              </div>
              <div className="mypage-info">
                <div className="mypage-user">
                  <span>{basicData ? basicData.user_name : ""}</span>
                  <br />
                  {t("userinfo")}
                </div>
                {(userRole === "PARTTIMER" ||
                  userRole === "PARTNER_PARTTIMER") && (
                  <div className="mypage-img">
                    {t("lang") === "EN" ? (
                      <img src={BitClass01SVG} alt="" />
                    ) : (
                      <img src={BitClass01EnSVG} alt="" />
                    )}
                  </div>
                )}
              </div>
            </MypageMobileInner01>
          ) : (
            <MypageInner01>
              <div className="mypage-title">
                <div className="action" onClick={() => navigate("/mypage")}>
                  {t("information")}
                </div>
                {(userRole === "PARTTIMER" ||
                  userRole === "PARTNER_PARTTIMER") && <span>&#183;</span>}
                {(userRole === "PARTTIMER" ||
                  userRole === "PARTNER_PARTTIMER") && (
                  <div onClick={() => navigate("/mypagelist")}>
                    {t("manpowerstatus")}
                  </div>
                )}
              </div>
              <div className="mypage-info">
                <div className="mypage-user">
                  <span>{basicData ? basicData.user_name : ""}</span>
                  {t("userinfo")}
                </div>
                {(userRole === "PARTTIMER" ||
                  userRole === "PARTNER_PARTTIMER") && (
                  <div>
                    {t("lang") === "EN" ? (
                      <img src={BitClass01SVG} alt="" />
                    ) : (
                      <img src={BitClass01EnSVG} alt="" />
                    )}
                  </div>
                )}
              </div>
            </MypageInner01>
          )}
          <MypageInner02 css={root}>
            <form
              onSubmit={(e) => {
                e.preventDefault();

                handleSubmit(
                  () => {
                    if (isVerify) {
                      editMypage();
                    } else if (t("lang") === "EN") {
                      noti("본인인증을 완료해주세요.");
                    } else if (t("lang") === "KO") {
                      noti(
                        "Please complete your Verify your identity.",
                        "Confirm"
                      );
                    }
                  },
                  (err) => {}
                )();
              }}
            >
              <div className={isMobileWidth ? "mo-form-inner" : "form-inner"}>
                <Row label={t("emaillabel")}>
                  <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
                    <Controller
                      control={control}
                      name="mypageEmail"
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="inp-item dim-input"
                          type="text"
                          placeholder={t("enteridemail")}
                          readOnly
                          fullWidth
                          value={basicData ? basicData.signname : ""}
                        />
                      )}
                    />
                  </div>
                </Row>
                <Row label={t("currentpassword")} isHightlight>
                  <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
                    <Controller
                      control={control}
                      name="mypagePassword"
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="inp-item"
                          type="password"
                          placeholder={t("entercurrentpassword")}
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </Row>
                <Row label={t("newpassword")}>
                  <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
                    <Controller
                      control={control}
                      name="mypageNewPassword"
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="inp-item"
                          type="password"
                          placeholder={t("enternewpassword")}
                          fullWidth
                          errors={newPass ? errors : ""}
                        />
                      )}
                    />
                  </div>
                </Row>
                <Row label={t("newpasswordcheck")}>
                  <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
                    <Controller
                      control={control}
                      name="mypageNewPasswordCheck"
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="inp-item"
                          type="password"
                          placeholder={t("enternewpasswordcheck")}
                          fullWidth
                          errors={newPass ? errors : ""}
                        />
                      )}
                    />
                  </div>
                </Row>
                <Row label={t("namelabel")}>
                  <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
                    <Controller
                      control={control}
                      name="mypageName"
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="inp-item"
                          type="text"
                          placeholder={t("entername")}
                          fullWidth
                          errors={errors}
                        />
                      )}
                    />
                  </div>
                </Row>
                <Row label={t("phonelabel")}>
                  <div
                    className={
                      isMobileWidth
                        ? "mo_input-box phonenumber"
                        : "input-box phonenumber"
                    }
                  >
                    <Controller
                      control={control}
                      name="userPhone1"
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder={t("select")}
                          variant="secondary"
                          w={isMobileWidth ? "110" : ""}
                          options={PREFIX_NUMBER_OPTIONS}
                        />
                      )}
                    />
                    <span></span>
                    <Controller
                      className="inp-item"
                      control={control}
                      name="userPhone2"
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          placeholder={t("enternumber")}
                          fullWidth
                          errors={errors}
                        />
                      )}
                    />
                    {!isMobileWidth && (
                      <div>
                        {isSmsSand ? (
                          <button
                            type="button"
                            className="send-message"
                            ref={btnRef}
                            onClick={() => verify.mutate()}
                          >
                            {t("verifyself")}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="send-message"
                            ref={btnRef}
                            onClick={() => smsSend.mutate()}
                          >
                            {t("sendmessage")}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                  {isMobileWidth && (
                    <div>
                      {isSmsSand ? (
                        <button
                          type="button"
                          className="mo-send-message"
                          ref={btnRef}
                          onClick={() => verify.mutate()}
                        >
                          {t("verifyself")}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="mo-send-message"
                          ref={btnRef}
                          onClick={() => smsSend.mutate()}
                        >
                          {t("sendmessage")}
                        </button>
                      )}
                    </div>
                  )}
                </Row>
                <Row label={t("idverifylabel")}>
                  <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
                    <Controller
                      className="inp-item"
                      control={control}
                      name="verifiCode"
                      render={({ field }) => (
                        <Input
                          {...field}
                          ref={codeRef}
                          type="number"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          placeholder={t("enterverification")}
                          fullWidth
                          errors={errors}
                        />
                      )}
                    />
                  </div>
                </Row>
                <Row label={t("addresslabel")}>
                  <div className={isMobileWidth ? "mo_input-box" : "input-box"}>
                    <div className="zipcode">
                      <Controller
                        control={control}
                        name="Address1"
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="inp-item"
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            placeholder={t("enterzipcode")}
                            fullWidth
                            readOnly
                            value={enrollCompany.zipcode || ""}
                          />
                        )}
                      />
                      {isMobileWidth ? (
                        <button
                          type="button"
                          className="mo-address-search"
                          onClick={() => {
                            setPopup(true);
                          }}
                        >
                          {t("searchaddrlabel")}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="address-search"
                          onClick={() => {
                            setPopup(true);
                          }}
                        >
                          {t("searchaddrlabel")}
                        </button>
                      )}
                      {popup && (
                        <Postcode
                          popup={popup}
                          setPopup={setPopup}
                          company={enrollCompany}
                          setcompany={setEnrollCompany}
                        />
                      )}
                    </div>
                    <div className="void-sec2"></div>
                    <Controller
                      control={control}
                      name="Address2"
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="inp-item"
                          type="text"
                          placeholder={t("addresslabel")}
                          fullWidth
                          readOnly
                          value={enrollCompany.address || ""}
                        />
                      )}
                    />
                    <div className="void-sec2"></div>
                    <Controller
                      control={control}
                      name="Address3"
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="inp-item"
                          type="text"
                          placeholder={t("enterdetailaddr")}
                          fullWidth
                          errors={errors}
                        />
                      )}
                    />
                  </div>
                </Row>
              </div>
              <div
                className={isMobileWidth ? "mo-submit-button" : "submit-button"}
              >
                <Button type="submit" variant="primary" preset="1">
                  {t("modifying")}
                </Button>
              </div>
            </form>
          </MypageInner02>
          {/* <div className="submit-button">
            <button onClick={() => secessionOut()}>탈퇴하기</button>
          </div> */}
        </SubLayout>
      </WhiteRoot>
    </Layout>
  );
};

export default Mypage;

const MypageStyled = (isLang) => {
  return css`
    & .send-message {
      font-size: ${isLang === "EN" ? "18px" : "15px"};
    }
  `;
};

const MobileMypageStyled = (lang) => {
  return css`
    & .mypage-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${lang === "EN" ? "20px" : "16px"};
      font-weight: bold;
      color: #707070;
      margin-bottom: 20px;

      & div {
        cursor: pointer;
      }

      & span {
        font-size: 35px;
        margin: 0 12px;
        color: #b2b2b2;
      }

      & .action {
        color: #c09260;
      }
    }
  `;
};

const WhiteRoot = styled.div`
  & > div > div > .float_wrap > div {
    padding: 0 410px;
  }
`;

const MypageInner01 = styled.div`
  margin-top: 60px;
  & .mypage-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: bold;
    color: #707070;
    margin-bottom: 30px;

    & div {
      cursor: pointer;
    }

    & span {
      font-size: 35px;
      margin: 0 33px;
      color: #b2b2b2;
    }

    & .action {
      color: #c09260;
    }
  }

  & .mypage-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f4ef;
    padding: 20px 30px;

    & div {
      display: flex;
      align-items: center;
    }
  }

  & .mypage-user {
    font-size: 20px;
    font-weight: normal;

    & span {
      font-size: 26px;
      font-weight: 600;
      color: #c09260;
      margin-right: 4px;
    }
  }
`;

const MypageInner02 = styled.div`
  margin-top: 20px;

  & .form-inner {
    margin: 30px 0 60px;
    padding-top: 40px;
    border-top: 1px solid #222;
    border-bottom: 1px solid #ddd;
  }

  & .mo-form-inner {
    margin: 20px 0 0;
    padding-bottom: 10px;
    padding-top: 20px;
    border-top: 1px solid #222;
    border-bottom: 1px solid #ddd;
  }

  & .partner_edit {
    display: flex;
    margin-bottom: 30px;

    & div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 178px;
      height: 65px;
      font-size: 18px;
      font-weight: normal;
      border: solid 1px #c09260;
      cursor: pointer;
    }

    & .manager_btn {
      background-color: #c09260;
      color: #fff;
    }
    & .company_btn {
      background-color: #fff;
      color: #c09260;
      margin-left: 8px;
    }
  }

  & .submit-button {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
  }

  & .mo-submit-button {
    display: flex;
    justify-content: center;
    padding: 30px 0 60px;
  }

  & .dim-input {
    background-color: #f9f9f9;
    color: #707070;
  }

  & .input-box {
    margin: 16px 0 30px;
  }

  & .mo_input-box {
    margin: 10px 0 20px;
  }

  & .phonenumber {
    display: flex;

    & span {
      margin: 0 4px;
    }
  }

  & .send-message {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    margin-left: 8px;
  }

  & .mo-send-message {
    width: 100%;
    height: 48px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 20px;
  }

  & .zipcode,
  & .business-number {
    display: flex;
  }

  & .void-sec {
    margin: 0 5px;
  }

  & .void-sec2 {
    height: 10px;
  }

  & .address-search,
  & .business-number-check {
    width: 120px;
    height: 65px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
  }

  & .mo-address-search {
    width: 88px;
    height: 48px;
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    font-size: 14px;
    margin-left: 8px;
  }

  & .mypage-txt {
    font-size: 16px;
    font-weight: normal;
    color: #707070;
    margin-top: 12px;
  }
`;

const MypageMobileInner01 = styled.div`
  margin-top: 20px;

  & .mypage-info {
    display: flex;
    justify-content: space-between;
    background-color: #f9f4ef;
    padding: 20px;

    & .mypage-img {
      display: flex;
      align-items: center;
      width: 100px;

      & img {
        width: 100%;
      }
    }
  }

  & .mypage-user {
    font-size: 14px;
    font-weight: normal;
    line-height: 0.9;
    & span {
      font-size: 20px;
      font-weight: 600;
      color: #c09260;
    }
  }
`;
