import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// utils
import { PAGE_LAYOUT, PAGE_LAYOUT_EN } from "utils/pageLayoutConstants";

// assets
import Banner02 from "assets/images/pc/img_banner02.png";
import OwnerImgSVG from "assets/images/pc/img.png";

import "swiper/css";
import "swiper/css/pagination";

// {t("lang") === "EN" ? () : ()}

/**
 * @file 회사소개 - 대표인사 페이지
 * @brief 대표 인사 및 회사 상세 소개
 * @author TW.Lee
 */
const Nurida = () => {
  const { t } = useTranslation();

  return (
    <Layout subFixed="true">
      <GreetingRoot>
        <SubLayout
          banner={Banner02}
          pageText={
            t("lang") === "EN" ? PAGE_LAYOUT.nurida : PAGE_LAYOUT_EN.nurida
          }
        >
          <div className="greeting_inner">
            <GreetingInner01>
              <div>
                <p data-aos="fade-up" data-aos-duration="1650">
                  누리다
                </p>
                <p data-aos="fade-up" data-aos-duration="1650">
                  NURIDA (NURI Data Application)
                </p>
                <p data-aos="fade-up" data-aos-duration="1650">
                  <strong>NURIDA (NURI Data Application)</strong>는
                  PC&middot;모바일&middot;키오스크 등의 장치를 통해 원격으로
                  업무를 처리하고, 업무 데이터를 보관하여
                  <br />
                  인공지능 재학습을 통해 자동 업무 지시, 고객 패턴 및 근무자
                  업무 패턴 예측 등이 가능한{" "}
                  <strong>최첨단 디지털 업무 처리 플랫폼</strong>입니다.
                  <br />
                  당사는 <strong>NURIDA</strong>를 통해 파트너사의
                  호텔&middot;주거&middot;상업&middot;공공시설 등을 효율적으로
                  위탁운영 및 관리하고 있습니다.
                  <br />
                  <br />
                  <strong>NURIDA</strong>는{" "}
                  <strong>롯데호텔 서울&middot;월드</strong>의 세탁 서비스, 린넨
                  관리 등의 하우스키핑 위탁업무 처리에 최초 사용되었습니다.
                  <br />그 결과, 기존 수기로 진행되던 호텔 서비스 업무가
                  디지털화되어 <strong>
                    휴먼에러(Human error) 감소
                  </strong>와 <strong>서비스 소요 시간 단축</strong>
                  <br />
                  효과를 거뒀으며,{" "}
                  <u>
                    궁극적으로는 투숙객의 만족도를 높여 파트너 호텔의 브랜드
                    가치를 향상에 기여하였습니다
                  </u>
                  .
                  <br />
                  <br />
                  현재는 ´<strong>누리글로벌솔루션´</strong>이라는 임시명칭을
                  사용 중이며, <u>최종 버전 출시 후</u>, <strong>NURIDA</strong>
                  라는 정식 명칭을 사용할 예정입니다.
                  <br />
                  <br />
                  <strong>
                    많은 성원과 기대 부탁드리며, NURIDA를 통해 아웃소싱 효율과
                    브랜드 가치를 향상시켜 보십시오.
                  </strong>
                </p>
              </div>
            </GreetingInner01>
            <YoutubeWrapper>
              <div>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/mZVLDCKPUNU?si=8k16GM3Wy8CMRXDp"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </YoutubeWrapper>
          </div>
        </SubLayout>
      </GreetingRoot>
    </Layout>
  );
};

export default Nurida;

const GreetingRoot = styled.div`
  background-image: linear-gradient(to bottom, #111, #060606);

  & .greeting_inner {
    color: var(--secondary-color3);
  }
`;

const GreetingInner01 = styled.div`
  display: flex;
  .float-greeting {
    position: absolute;
    width: 600px;
  }
  .float-greeting-bottom {
    padding-top: 371px;
  }

  & p:nth-of-type(1) {
    font-family: Arita-buri-SemiBold;
    font-size: 48px;
    font-weight: 600;
    line-height: 1.61;
    color: var(--primary-color1);
    margin-top: 140px;
    line-height: 1.2;
  }
  & p:nth-of-type(2) {
    font-family: Arita-buri-SemiBold;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.61;
    color: var(--primary-color1);
  }

  & p:nth-of-type(3) {
    font-size: 18px;
    line-height: 1.89;
    font-weight: normal;
    margin: 40px 0;
  }

  & strong {
    font-weight: bold;
    color: var(--Affdab2);
  }

  & u {
    text-decoration: underline;
  }

  & strong:last-of-type {
    color: var(--primary-color1);
  }
`;

const YoutubeWrapper = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    aspect-ratio: 16 / 9;
    width: 100%;
    max-width: 1024px;
  }
`;
