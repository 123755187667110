import React, { useState } from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

// store
import { isMobileWidthAtom } from "store/ui/atom";

// layout
import Layout from "layout/Layout";
import SubLayout from "layout/SubLayout";

// assets
import Banner07 from "assets/images/pc/img_banner07.png";

/**
 * @file 약관안내 - 서비스 이용약관 페이지
 * @author TW.Lee
 * @param pageText 서브페이지 공통 layout props
 */
const TermsService = () => {
  const { t } = useTranslation();
  const [change, setChange] = useState(false);
  const [textChange, setTextChange] = useState("서비스 이용약관");
  const [textChangeEn, setTextChangeEn] = useState(
    "Terms and Conditions for Services"
  );
  const isMobileWidth = useRecoilValue(isMobileWidthAtom);
  const pageText = {
    mainTextEn: "Terms of Service",
    mainTextKo: "약관안내",
    subText: textChange,
    bgBlack: false,
  };
  const pageTextEn = {
    mainTextEn: "Terms of Service",
    mainTextKo: "Terms of Service",
    subText: textChangeEn,
    bgBlack: false,
  };

  return (
    <Layout subFixed="true">
      <SubLayout
        banner={Banner07}
        pageText={t("lang") === "EN" ? pageText : pageTextEn}
      >
        <TermServiceInner className="term_inner">
          <div className={isMobileWidth ? "mo_service_tab" : "service_tab"}>
            <ul>
              <li
                className={change ? "switch-style-off" : "switch-style-on"}
                onClick={() => {
                  setTextChange("서비스 이용약관");
                  setTextChangeEn("Terms and Conditions for Services");
                  if (textChange !== "서비스 이용약관") {
                    setChange((prev) => !prev);
                  }
                }}
              >
                {t("termsservice")}
              </li>
              <li>
                <span>&#183;</span>
              </li>
              <li
                className={change ? "switch-style-on" : "switch-style-off"}
                onClick={() => {
                  setTextChange("회원가입 약관");
                  setTextChangeEn("Terms and Conditions of Registration");
                  if (textChange !== "회원가입 약관") {
                    setChange((prev) => !prev);
                  }
                }}
              >
                {t("termssignup")}
              </li>
            </ul>
          </div>
          {(textChange === "서비스 이용약관" ||
            textChangeEn === "서비스 이용약관") && (
            <div>
              <div className="main_section_content">
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text1"
                      : "main_section_content_text1"
                  }
                >
                  {t("lang") === "EN"
                    ? "제1장 총칙"
                    : "Chapter 1: General Provisions"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? "제1조 (목적)"
                    : "Article #1 (Purpose of the Service)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `이 약관은 주식회사 누리글로벌서비스 (이하
                      '누리')가 운영하는 누리글로벌서비스 웹사이트(이하
                      '웹')에서 제공하는 용역 및 인터넷 관련 서비스(이하
                      '서비스')를 이용함에 있어 이용자와
                      '누리'의 권리 &middot; 의무 및 책임사항을 규정함을
                      목적으로 합니다.`
                    : `These Terms of Service govern both NURI Global Service Co.,
                      Ltd. (the “Nuri”) and users’ rights, responsibility, and
                      obligation of using any website (the “Web”), mobile, or
                      tablet application, or other online platforms (the
                      “Service”) provided by the Nuri.`}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? "제2조 (정의)"
                    : "Article #2 (Definition)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? "1. 본 약관에서 사용하는 용어의 정의는 다음과 같습니다."
                    : "1. The following is definition of words and phrases used in the Terms of Service"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `① 이용계약 : 서비스 이용과 관련하여 '누리'와 이용자 간에 체결하는 계약`
                    : "① The Contract of Usage (The Usage Contract): An agreement between the Nuri and users when using the Service"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `② 가입 : '웹'이 제공하는 신청서 양식에 해당 정보를 기입하고, 본 약관에 동의하여 서비스 이용계약을 완료하는 행위`
                    : "② Registration: An action of users completing the registration application form provided by the Web and agreeing its Terms and Service"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `③ 이용자 : '누리'에서 제공하는 '웹'의 모든 형태의 서비스를 이용하는 회원 및 비회원`
                    : "③ Visitors: Both members and non-members who use any forms of service provided by the Web and Nuri"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `④ 회원 : '웹'에 회원가입에 필요한 개인 정보를 제공하여 회원 등록을 한 자로서, '웹'의 정보 및 서비스를 이용할 수 있는 자`
                    : "④ Members (Users): Any user who completed registration process by providing his or her personal information to the Web, thus can use the Service provided by the Nuri"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑤ 이용자 번호(ID) : 이용 고객의 식별과 이용자의 원활한 회원가입을 위한 본인의 이메일 주소, 또는 관리자 및 이용자가 정한 문자와 숫자의 조합`
                    : "⑤ User (Member) ID: Any e-mail address or a combination of digits and alphabets created by the Web administers that can be used to identify users and simplify the registration process."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑥ 비밀번호(PW) : 이용자가 등록 회원과 동일인인지 신원을 확인하고 통신상의 자신의 개인정보보호를 위하여 이용자 자신이 정한 문자와 숫자의 조합`
                    : "⑥ User (Member) Password: A combination of letters and numbers formed by the users to verify whether the user is the same person as the registered member. It could also be used to protect the users’ personal information provided to the Web during the registration process."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑦ 게시물 : '누리'가 회원에게 원활한 서비스 제공을 위해 '웹'에 게시하는 글, 이미지, 동영상 등 각종 파일과 링크 등, 또는, 회원이 '웹'에 게시하는 글, 이미지, 동영상 등 각종 파일과 링크 등`
                    : "⑦ Posts: Various files and links, such as articles, images, videos, etc. posted by the Nuri on the Web for flexible provision of services to its members"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑧ 탈퇴(해지) : 서비스 또는 회원이 이용계약을 종료하는 행위`
                    : "⑧ Termination: An act of terminating the Service or a member's Contract of Usage"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑨ 파트너 : '누리'와 업무적으로 계약을 맺거나 업무 협력관계에 있는 모든 사업체, 단체, 또는 개인`
                    : "⑨ Partners: Any business, organization, or individual who signed any form of contract with the Nuri"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. 본 약관에서 정의하지 않은 용어는 개별서비스에 대한 별도약관 및 이용 규정에서 정의합니다.`
                    : "2. Any term or phrase that are not specified in the Article #2 are separately defined in other articles."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제3조 (약관의 명시와 개정)`
                    : "Article #3 (Clarification and Amendment of the Terms and Service)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. '누리'는 이 약관의 내용과 주소지, 관리자의 성명, 개인정보보호 담당자의 성명, 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가 알 수 있도록 '웹'의 초기 서비스 화면(전면)에 게시합니다.`
                    : "1. The Nuri will post the copy of these Terms and Service on the Web. The Nuri will also post the administrator’s contact information, such as address, name, telephone number, fax number, e-mail address, etc., on the main screen of the Web."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. '누리'는 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.`
                    : "2. The Nuri may amend the Terms and Service to the extent that it does not violate the relevant laws, such as the Act on the Regulation of Terms and Service, the Framework Act on Electronic Transactions, the Electronic Signature Act, the Promotion of Information and Communication Network Use, etc."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `3. '누리'가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 '누리'의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.`
                    : "3. If the Nuri revises the Terms and Service, it shall be notified on the main screen of the Web with the revised Terms and Service. The notification will be posted for 7 days from the day of notification to the day before the actual renewal date."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `4. 이 약관에 동의하는 것은 정기적으로 '웹'을 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 '누리'에서 책임지지 않습니다.`
                    : "4. Acceptance of these Terms and Service means that the users agree to visit the Web on a regular basis to confirm any changes made to the Terms and Service. The Nuri is not responsible for any damage caused by not recognizing any change made in the Terms and Service."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `5. '누리'는 회원이 변경된 약관에 동의하지 않을 경우, 회원 탈퇴(해지)를 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이후에도 거부의사를 표시하지 아니 하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.`
                    : "5. If a member disagrees with the renewed Terms and Service, the Nuri may request withdrawal of his or her membership (cancellation of an account), and if the member continues to use the Service without expressing a rejection after 7 days from the renewal date, the Nuri will consider that the member has agreed to its Terms and Service."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `6. 본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 기타 관련 법령의 규정에 의합니다.`
                    : "6. Any other detail that are not specified in the Terms and Service shall be governed by the Framework Act on Telecommunications, the Telecommunications Business Act, the Information and Communication Network Promotion Act, the Information Protection Act, and other relevant laws and regulations."}
                </p>
              </div>
              <div className="main_section_content">
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text1"
                      : "main_section_content_text1"
                  }
                >
                  {t("lang") === "EN"
                    ? `제2장 이용계약의 성립 및 해지`
                    : "Chapter 2: Establishment and Termination of the Usage Contract"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제4조(이용 계약의 성립)`
                    : "Article #4 (Establishment of the Usage Contract)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. 이용계약은 이용자가 본 이용약관 내용에 대한 동의와 이용신청에 대하여 '누리'의 '웹' 이용 승낙으로 성립합니다.`
                    : "1. The Usage Contract is established when the user agrees to the Terms and Service, AND the Nuri gives the user permission to use the Web."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. 본 이용약관에 대한 동의는 이용 신청 당시 해당 '웹'의 '동의함' 버튼을 누름으로써 의사 표시를 합니다.`
                    : "2. The user agrees to the Terms and Service by pressing the ‘Agree’ button on the Web during the online registration process."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제5조(회원가입 및 탈퇴)`
                    : "Article #5 (Online Registration and Account Deletion)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. 회원가입은 신청자(이용자)가 온라인으로 '누리'에서 제공하는 소정의 가입신청 양식에서 요구하는 사항을 기록하여 가입을 완료하는 것으로 성립됩니다.`
                    : "1. Online registration in the Web is completed when the user fills out the entire registration form provided by the Nuri."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. '누리'는 다음 각 호에 해당하는 회원에 대하여는 가입을 취소할 수 있습니다.`
                    : "2. The Nuri may cancel the membership for the following members:"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `① 다른 사람의 명의를 사용하여 신청하였을 때`
                    : "① When the user submits an application with another person's name"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `② 회원가입 신청서의 내용을 허위로 기재하였거나 신청하였을 때`
                    : "② When the user submits an application with false information"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `③ 사회의 안녕 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때`
                    : "③ When the user submits an application with the purpose of hindering the well-being and order of society."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `④ 다른 사람의 '웹' 이용을 방해하거나 정보를 도용하는 등의 행위를 하였을 때`
                    : "④ When the user interrupts another person’s use of the Web or steals someone’s personal information"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑤ '웹'을 이용하여 법령과 본 약관이 금지하는 행위를 하는 경우`
                    : "⑤ When the user uses the Web to engage in action that is prohibited by domestic & international laws and the Nuri’s Terms and Service"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑥ 기타 '누리'가 정한 회원가입 요건이 미비 되었을 때`
                    : "⑥ When the user fails to provide other necessary requirements for the membership registration."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑦ 어떠한 경우라도 '누리', '파트너', 그리고 타 이용자에게 사회적 및 금전적 손해를 야기할 수 있다고 판단되는 이용자일 경우`
                    : "⑦ When the user is deemed to cause social and financial damage to the Nuri, the Partners, and other users in any case."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑧ 기존에 '누리', '파트너', 그리고 타 이용자에게 사회적 및 금전적 피해를 끼친 이력이 있는 이용자일 경우`
                    : "⑧ When the user has a record of causing social and financial damage to the Nuri, the Partners, and other users in any case."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `3. '웹'은 다음 각 항에 해당하는 경우 그 사유가 해소될 때까지 이용계약 성립을 유보할 수 있습니다.`
                    : "3. The Web may withhold or postpone the member’s Usage Contract until the following issues are resolved:"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `① 서비스 관련 제반 용량이 부족한 경우`
                    : "① When the Web has insufficient server capacity for all Services"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `② 기술상 장애 사유가 있는 경우`
                    : "② When the Web is experiencing technical issues"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `③ '누리', '파트너', 그리고 타 이용자에게 사회적 및 금전적 손해를 야기할 수 있다고 판단되는 이용자일 경우`
                    : "③ When the user is causing social and financial damage to the Nuri, the Partners, and other users"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `4. '누리'는 '웹'을 자체 개발하거나 다른 기관과의 협의 등을 통하여 일체의 서비스를 제공하며, 변경 시 변경될 서비스의 내용을 이용자에게 공지하고 제공할 수 있습니다.`
                    : "4. The Nuri can develop the Web with self-development or through partnership with other organizations. Any changes made to the Web and Service will be notified to all users."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `5. 회원은 등록사항에 변경이 있는 경우, 즉시 회원정보 수정 등 기타 방법으로 '누리'에 대하여 그 변경사항을 알려야 합니다.`
                    : "5. The user shall immediately notify the Nuri of any change made to his or her membership information."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `6. 회원은 가입 이후 '웹'에서 제공하는 서비스를 제공받을 의사가 없는 등의 사유가 있을 경우에는 언제든지 회원탈퇴(해지)를 할 수 있습니다. 다만, 이용자가 회원가입 시 제공하였던 모든 내용은 '웹'에서 탈퇴 일로부터 2년간 보유하며, 2년이 지난 후 즉시 삭제됩니다.`
                    : "6. The user may cancel his or her membership at any time if he or she has no further intention of receiving any form of service provided by the Web. However, all the contents including personal information provided by the user in the Web will be held for two years since the date of his or her membership cancellation on the Web. Such data will be deleted immediately after two years from the user’s membership cancellation date."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제6조(이용자번호 부여 및 변경 등)`
                    : "Article #6 (Assignment and Modification of User Identification)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. '누리'는 이용고객에 대하여 약관에 정하는 바에 따라 이용자 ID를 부여하거나, 이용자 본인이 설정한 이메일 계정을 ID로 설정합니다.`
                    : "1. The Nuri assigns the User Identification (ID) to the users according to the Terms and Service. The users also can set up their personal or business email account as User ID."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. 이용자번호(ID)는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는 해당 ID를 해지하고 재가입해야 합니다.`
                    : "2. The general rule is that the User ID cannot be changed at any instance. However, if the user must change his or her ID due to unavoidable reasons, he or she may cancel the current membership and rejoin the Web through the registration process."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `3. 이용자번호(ID)는 다음 각 호에 해당하는 경우에는 이용자의 요청으로 변경할 수 있습니다.`
                    : "3. The User ID may be changed with the request of the user in any of the following cases:"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `① 이용자번호(ID)가 이용자의 전화번호 또는 주민등록번호 등으로 등록되어 사생활 침해가 우려되는 경우`
                    : "① If the User ID contains the user's phone number or Resident Registration Number so that there is a risk of leaking his or her personal information to the public"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `② 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우`
                    : "② If the User ID disturbs others or goes against the customs of good manners"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `③ 기타 합리적인 사유가 있는 경우`
                    : "③ If there are other reasonable (acceptable) reasons for the change"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `4. 서비스 이용자번호(ID) 및 비밀번호의 관리 및 책임은 이용자에게 있습니다. 이를 소홀히 관리하여 발생하는 서비스 이용 상의 손해 또는 제3자에 의한 부정이용 등에 대한 책임은 이용자에게 있으며 '누리'는 그에 대한 책임을 일절 지지 않습니다.`
                    : "4. The user is fully responsible for his or her User ID and password. The user is also fully responsible for any damage caused by his or her negligence or 3rd party’s illegal activities such as hacking. The Nuri does not have to compensate the users for the issues stated above."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제7조(회원정보 사용에 대한 동의)`
                    : "Article #7 (Consent on Using the Members’ Personal Information)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. '누리'의 '웹' 회원정보는 '개인정보보호법' 및 “개인정보처리방침” 의해 보호됩니다.`
                    : "1. Any personal information provided by the Web users during the registration process is protected by the Personal Information Protection Act and the Personal Information Processing Policy."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. 회원의 개인정보는 오직 본인만이 열람/수정/삭제할 수 있으며, 이는 전적으로 회원의 ID와 비밀번호에 의해 관리되고 있습니다. 따라서 타인에게 본인의 ID와 비밀번호를 알려주어서는 아니되며, 작업 종료 시에는 반드시 로그아웃하고, 웹 브라우저의 창을 닫아야 합니다. (이는 타인과 컴퓨터를 공유하는 인터넷 카페나 도서관 같은 공공장소에서 컴퓨터를 사용하는 경우에 본인의 정보보호를 위하여 필요한 사항입니다)`
                    : "2. Only the user him/herself can view, modify, or delete his or her personal information by logging in to the Web with User ID and password. Thus, the members should not share their User ID and password with others and must log-out before they close web browsers. (This is particularly important when using computers in public places, such as an Internet cafe or libraries, where computers could be used with other people.)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `3. 회원이 '누리'에 본 약관에 따라 이용신청을 하는 것은 '웹'에서 본 약관에 따라 신청서에 기재된 회원정보를 수집, 이용하는 것에 동의하는 것으로 간주됩니다.`
                    : "3. The user’s completion of the Web registration process will be considered as acceptance for the Nuri to collect and use the user information."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제8조(이용자의 정보 보안)`
                    : "Article #8 (Protection of the User’s Information)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. 회원은 '웹'의 서비스 가입 절차를 완료하는 순간부터 입력한 정보의 비밀을 유지할 책임이 있으며, ID와 비밀번호를 사용하여 발생하는 모든 결과에 대한 책임은 본인에게 있습니다.`
                    : "1. The users are fully responsible for keeping confidentiality of the information they provided to the Web. The users are also fully responsible for any consequences related with their User IDs and passwords."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. ID와 비밀번호에 관한 모든 관리의 책임은 본인에게 있으며, 본인의 ID나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시 '누리'에 신고하여야 합니다. 신고를 하지 않음으로 인해 발생하는 모든 책임은 회원 본인에게 있습니다.`
                    : "2. The users are fully responsible of their User IDs and passwords. If the users find out that their User IDs or passwords have been used illegally by 3rd parties, they must report such issue to the Nuri’s administrators immediately. Even though the users’ accounts get compromised by 3rd parties illegally, the users will be fully responsible if they do not report the issue to the Nuri’s administrators immediately."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `3. 이용자는 '웹'의 서비스의 사용 종료 시마다 정확히 로그아웃(Log-out)해야 하며, 로그아웃 하지 아니하여 제3자가 회원 정보를 도용하는 등의 결과로 인해 발생하는 손해 및 손실에 대하여 '누리'는 책임을 부담하지 아니합니다.`
                    : "3. The users must completely log-out from the Web after they are done with using the Service. The Nuri shall not be liable for any form of damage caused by not logging out from computers and mobile phones."}
                </p>
              </div>
              <div className="main_section_content">
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text1"
                      : "main_section_content_text1"
                  }
                >
                  {t("lang") === "EN"
                    ? `제3장 서비스의 이용`
                    : "Chapter 3: The Use of Services"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제9조(서비스 이용시간)`
                    : "Article #9 (Service Hours)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. 서비스 이용시간은 '누리'의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.`
                    : "1. The regular Web service hours shall be 24 hours a day, 7 days a week (all year round), unless the Nuri experiences any form of issues that interrupt its business operations."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. 제1항의 이용시간 중 정기점검 등의 필요로 인하여 '누리'가 정한 날 또는 시간은 예외로 합니다.`
                    : "2. Exceptions shall be made to the date or time determined by the Nuri due to the need for regular inspections, etc. during the hours of use referred to in Article #9 (1)."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제10조(서비스의 중지 및 중지에 대한 공지)`
                    : "Article #10 (Suspension of Services and an Announcement)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. 이용자는 '웹'의 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 국가의 비상사태, 정전, '웹'의 관리 범위 외의 서비스 설비 장애 및 기타 불가항력에 의하여 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 '누리'는 관련 책임을 부담하지 아니합니다.`
                    : "1. The Web shall not be liable for any loss of data, such as messages transmitted through the Service, due to national emergencies, power outages, failure of service facilities, or natural disasters."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. '누리'가 정상적인 서비스 제공의 어려움으로 인하여 일시적으로 '웹'의 서비스를 중지하여야 할 경우에는 서비스 중지 전에 중지사유 및 일시를 공지한 후 서비스를 중지할 수 있으며, 회원이 공지내용을 인지하지 못한 데 대하여 '누리'는 책임을 부담하지 아니합니다. 또한 위 서비스 중지에 의하여 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 대하여도 '누리'는 책임을 부담하지 아니합니다.`
                    : "2. If the Nuri temporarily discontinues to provide the Service due to difficulties in operating and maintaining servers, the Nuri may suspend the Service after notifying the reason and date of suspension to the users. The Nuri is also not responsible for any disadvantages that the users could receive from not confirming the notice. In addition, the Nuri shall not be liable for any loss of communication data, such as messages stored in the server or transmitted through the Web, due to the suspension of the Service and Web."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `3. '누리'의 사정으로 '웹'의 서비스를 영구적으로 중단하여야 할 경우에는 제2항에 의거합니다.`
                    : "3. If the Service must be permanently suspended due to the Nuri’s private circumstances, it shall also be governed by the Article #10 (2)."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `4. '누리'는 사전 공지 후 모든 서비스와 정보를 일시적, 또는 영구적으로 수정, 변경 및 중단할 수 있으며, 이에 대하여 회원 또는 제3자에게 어떠한 책임도 부담하지 아니합니다.`
                    : "4. The Nuri may temporarily or permanently modify, delete, and suspend the Service (including the related information) with prior notifications to the users; the users and other third parties shall not bear any responsibilities from the Nuri modification, removal, and suspension of the Service."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `5. '누리'는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고 없이 일시적으로 '웹'의 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 '누리'가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.`
                    : "5. The Nuri may temporarily suspend the Service without any notification WITH unavoidable reasons such as urgent system inspection, expansion, and replacement. The Nuri also may completely suspend the Service WITH any reasons that the Nuri considered to be adequate or necessary for the Nuri’s business operations."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `6. '누리'는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 '웹'의 서비스 제공이 불가능할 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만 이 경우 그 사유 및 기간 등을 이용자에게 사전 또는 사후에 공지합니다.`
                    : "6. The Nuri may restrict or suspend all or part of the Service if the Nuri is further incapable of providing any form of service due to national emergencies, power outages, facility failures, or server saturation. However, in this case, the reason and expected period of suspension will be notified to the users in advance or afterward."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `7. '누리'가 통제할 수 없는 사유로 인한 '웹'의 서비스 중단의 경우(시스템 관리자의 고의 과실 없는 디스크 장애, 시스템 다운 등)에 사전통지가 불가능하며 타인(PC통신회사, 기간통신사업자 등)의 고의 과실로 인한 시스템 중단 등의 경우에는 통지하지 않습니다.`
                    : "7. In case when the Web and Service operations are interrupted due to reasons beyond control of the Nuri’s administrators, posting prior notifications will not be possible. ‘Reasons beyond control’ can be an accidental disk failure, server outage, and other possible incidents that could obstruct the Service in any way. The Nuri is also not responsible for posting notifications about system disruption caused by other users’ or third parties’ (telecommunication service providers, internet service providers, etc.) intentional negligence."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `8. '누리'는 '웹'의 서비스를 특정범위로 분할하여 각 범위별로 이용가능 시간을 별도로 지정할 수 있습니다. 다만 이 경우 그 내용을 공지합니다.`
                    : "8. The Nuri can separate the Service into specific parts and specify available service hours for each of them. However, in this case, the related notifications will be announced to all visitors."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `9. '누리'는 회원이 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지하거나 회원의 동의 없이 이용계약을 해지할 수 있습니다. 이 경우 '누리'는 위 이용자의 '웹' 접속을 금지할 수 있습니다.`
                    : "9. The Nuri may arbitrarily restrict and suspend the use of the Service or terminate the Usage Contract without consent from the member IF the member violates the Nuri’s Terms and Service. In this case, the Nuri may prohibit the member from accessing the Service and Web."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제11조(정보 제공 및 홍보물 게재)`
                    : "Article #11 (Provision of Information and Posting Advertisement)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. '누리'는 서비스를 운영함에 있어서 각종 정보를 '웹'에 게재하는 방법, 전자우편, 서신우편, 휴대폰 메시지 발송 등으로 회원에게 제공할 수 있습니다.`
                    : "1. The Nuri may provide various information related with the Service and business to members through web-posting, e-mail, letters, or mobile text messages."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. '누리'는 서비스에 적절하다고 판단되거나, 상업성 및 공익성이 있는 홍보물을 게재할 수 있습니다.`
                    : "2. The Nuri may publish marketing materials or advertisements that are appropriate for the promotion of the Service or that are beneficial to public interests."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제12조 ('웹' 게시물의 관리 및 운영)`
                    : "Article #12 (Web-contents Management)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. '누리'가 게시한 게시물의 내용에 대한 권리는 '누리'에게 있으며, 회원이 게시한 게시물의 내용에 대한 권리는 회원에게 있습니다.`
                    : "1. All rights of the Web-contents posted by the Nuri belongs to NURI Global Service Co., Ltd. All rights of the Web-contents posted by the user belongs to the user him/herself."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. '누리'는 게시된 내용을 사전 통지 없이 편집, 이동할 수 있는 권리를 보유하며, 다음의 경우 사전 통지 없이 삭제할 수 있습니다.`
                    : "2. The Nuri reserves the rights to edit and relocate published any Web-content without prior notice. The Nuri also may delete any Web-contents without prior notice in following cases:"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `① 본 서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우`
                    : "① If the Web-contents are deemed to be violating the Nuri’s Terms and Service, or is deemed to be commercial, illegal, obscene, or vulgar"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `② 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우`
                    : "② If the Web-contents have a purpose of defaming other visitors, members, or any other third party"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `③ 공공질서 및 미풍양속에 위반되는 내용인 경우`
                    : "③ If the Web-contents violate the public order and well-being of society"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `④ 범죄적 행위에 결부된다고 인정되는 내용일 경우`
                    : "④ If the Web-contents are found to be related with crime or illegal activities"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑤ 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우`
                    : "⑤ If the Web-contents infringe copyright of other users and third parties"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑥ 기타 관계 법령에 위배되는 경우`
                    : "⑥ If the Web-contents violate domestic and international laws"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `3. 회원의 게시물이 타인의 저작권을 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 회원 본인이 부담하여야 합니다.`
                    : "3. The person who infringed other members’ posts and copyrights in the Web shall be responsible for the related legal claims, compensation, etc."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제13조(서비스 이용제한)`
                    : "Article #13 (Restriction on Use of the Service)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. 회원이 제공하는 정보의 내용이 허위인 것으로 판명되거나, 허위가 있다고 의심할 만한 합리적인 사유가 발생할 경우 '누리'는 회원의 본 서비스 사용을 일부 또는 전부 중지할 수 있으며, 이로 인해 발생하는 불이익에 대해 책임을 부담하지 아니합니다.`
                    : "1. If the information provided by the member is found to be false or the administrator reasonably suspects that the information is false, the Nuri may partially or fully suspend the member’s access to the Web and Service."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. '누리'는 회원이 본 약관 제15조(회원의 의무) 등 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지할 수 있습니다. 이 경우 '누리'은 회원의 접속을 금지할 수 있으며, 법적 소송으로 대응할 수 있습니다.`
                    : "2. The Nuri may arbitrarily restrict and suspend the use of the Service if the member violates the Terms and Service, such as Article #15 of this agreement (The Member's Obligations and Responsibilities). In this case, the Nuri may prohibit members from accessing the Web and may respond him/her with legal action."}
                </p>
              </div>
              <div className="main_section_content">
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text1"
                      : "main_section_content_text1"
                  }
                >
                  {t("lang") === "EN"
                    ? `제4장 의무 및 책임`
                    : "Chapter 4: Obligations and Responsibilities"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제14조('누리'의 의무)`
                    : "Article #14 (The Nuri’s Obligations and Responsibilities)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. '누리'는 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 지속적이고 안정적으로 서비스를 제공하기 위해 노력할 의무가 있습니다.`
                    : "1. The Nuri is obligated to continuously check and maintain the Web server for its stability. The Nuri is also obligated to prohibited posts that goes against domestic and international laws and regulations."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. '누리'는 회원의 개인정보를 본인의 승낙 없이 타인에게 누설, 배포하지 않습니다. 다만, 개인정보보호법 제18조에 의해 제공해야할 경우 그러하지 아니합니다.`
                    : "2. The Nuri does not manipulate or distribute the member's personal information to others without his or her consent, UNLESS it needs to be provided to 3rd parties according to the Article 18 of the Personal Information Protection Act."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `3. '누리'는 이용자가 안전하게 '웹'의 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위한 보안시스템을 갖추어야 합니다.`
                    : "3. The Nuri shall operate a security system to protect users' personal information (including personal credit information) so that the users can safely use the Service."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제15조(회원의 의무)`
                    : "Article #15 (The Members’ Obligations and Responsibilities)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. 회원가입 시에 요구되는 정보는 정확하게 기입하여야 합니다. 또한 이미 제공된 회원에 대한 정보가 정확한 정보가 되도록 유지, 갱신하여야 하며, 회원은 자신의 ID 및 비밀번호를 제3자가 이용하게 해서는 안 됩니다.`
                    : "1. The member must accurately fill out information required during the Web registration process. In addition, the member shall continuously maintain his or her information provided to the Web up to date for accuracy. The member also should not allow any third party to use his or her Web IDs and passwords."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. 회원의 부정확한 개인정보 입력으로 인하여 받는 법적, 금전적 불이익은 '누리'에서 책임지지 않습니다.`
                    : "2. The Nuri is not responsible for any legal claims and financial damage caused by the member inputting incorrect personal information during the Web registration process."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `3. 회원은 '누리'의 사전 승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.`
                    : "3. The members shall not engage in any profit-making activities using the Service without the prior consent of the Nuri."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `4. 회원은 '웹'의 서비스를 이용하여 얻은 정보를 '누리'의 사전승낙 없이 복사, 복제, 변경, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.`
                    : "4. The members shall not copy, duplicate, alter, translate, publish, broadcast, or otherwise use the information obtained through the Service and Web without the prior consent of the Nuri. Any materials earned from activities stated above must not be shared to others."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `5. 회원은 '웹'의 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안 됩니다.`
                    : "5. The members shall not engage in any of the following acts when using the Web and Service:"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `① 다른 회원의 비밀번호와 ID를 도용하여 부정 사용하는 행위`
                    : "① Infringing other members' accounts and using them illegally"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `② 저속, 음란, 모욕적, 위협적이거나 타인의 개인정보를 침해할 수 있는 내용을 전송, 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위`
                    : "② Sending, e-mailing, or otherwise transmitting information that is vulgar, obscene, insulting, threatening, or may infringe on another person's personal information"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `③ 서비스를 통하여 전송된 내용의 출처를 위장하는 행위`
                    : "③ Disguising the source of the Web-contents through the Service"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `④ 법률, 계약에 의해 이용할 수 없는 내용을 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위`
                    : "④ Posting, publishing, e-mailing, or otherwise transmitting unlawful information"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑤ 타인의 특허, 상표, 영업비밀, 저작권, 기타 지적재산권을 침해하는 내용을 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위`
                    : "⑤ Posting, publishing, e-mailing, or otherwise transmitting information that could infringe another person's patents, trademarks, trade confidential, copyrights, or other intellectual property rights"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑥ '누리'의 승인을 받지 아니한 광고, 판촉물, 스팸메일, 행운의 편지, 피라미드 조직 기타, 다른 형태의 권유를 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위`
                    : "⑥ Posting, publishing, e-mailing, or otherwise transmitting advertisements, promotional materials, spam mails, or other forms of solicitation, without the approval of the Nuri’s administrators"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑦ 다른 사용자의 개인정보를 수집 또는 저장하는 행위`
                    : "⑦ Collecting or keeping other users' personal information"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑧ 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위`
                    : "⑧ Participating in criminal activities or criminal-related activities"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑨ 선량한 풍속, 기타 사회질서를 해하는 행위`
                    : "⑨ Participating in any form of activity that harms goodness and order of society"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑩ 타인의 명예를 훼손하거나 모욕하는 행위`
                    : "⑩ Defaming or insulting others"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑪ 타인의 지적재산권 등의 권리를 침해하는 행위`
                    : "⑪ Violating another person's intellectual property rights"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑫ 해킹행위 또는 컴퓨터바이러스의 유포행위`
                    : "⑫ Hacking or spreading viruses"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑬ 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위`
                    : "⑬ Sending advertisements or other information against the will of others"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑭ 서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위`
                    : "⑭ Participating in any act that hinders or may hinder the Service management"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text4"
                      : "main_section_content_text4"
                  }
                >
                  {t("lang") === "EN"
                    ? `⑭ 타 전기통신사업법 제53조 제1항과 전기통신사업법 시행령 16조(불온통신)에 위배되는 행위`
                    : "⑭ Violating the Article 53 (1) of the Telecommunications Business Act and Article 16 of the Enforcement Decree of the Telecommunications Business Act."}
                </p>
                <p></p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제16조('누리'의 소유권)`
                    : "Article #16 (The Nuri’s Ownership and Copyrights)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. '누리'가 '웹'에서 제공하는 용역, 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스 명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타 권리는 '누리'에게 소유권이 있습니다`
                    : "1. All the intellectual property and other rights related to the Service, including software, images, marks, logos, designs, information, and trademarks, provided by the Nuri on the Web shall be owned by the Nuri."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. 모든 이용자는 '누리'가 명시적으로 승인한 경우를 제외하고는 전항의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재라이센스, 담보권 설정 행위, 상업적 이용 행위를 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.`
                    : "2. Unless authorized by the Nuri, no user shall modify, lend, sell, distribute, manufacture, transfer, re-license, establish, or exploit the Nuri’s property rights stated in the preceding paragraphs. The users also do not have rights to allow any third party to modify, lend, sell, distribute, manufacture, transfer, re-license, establish, or exploit the Nuri’s property rights stated in the preceding paragraphs."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제17조(양도금지)`
                    : "Article #17 (Prohibition of Transfer)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. 회원이 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.`
                    : "1. Members cannot transfer or offer the right to use the Service to others and cannot use the Service and Web as collateral."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제18조(손해배상)`
                    : "Article #18 (Compensation)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. '누리'는 무료로 제공되는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 '누리'가 고의로 행한 범죄행위를 제외하고는 이에 대하여 책임을 부담하지 아니합니다.`
                    : "1. The Nuri shall not be liable for any damage the member received from the Service with free of charge unless the Service was provided with intention of participating or assisting criminal activities."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. '누리'는 이용자가 어떠한 경우라도 '웹'의 정상적인 운영을 방해하거나 피해를 끼칠 시, 이용자에게 이에 대한 법적책임과 손해배상을 물을 수 있습니다.`
                    : "2. The Nuri may take legal action and request compensation against the user who damages the Web and Service in any direction."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제19조(면책조항)`
                    : "Article #19 (Exemption Clause)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. '누리'는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.`
                    : "1. The Nuri is exempted from its responsibility for compensation if the Service operation is inevitably impossible due to natural disasters, wars, or other forms of irresistible force."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. '누리'는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.`
                    : "2. The Nuri is exempted from its responsibility for compensation if the mobile carrier or internet service supplier fail to provide its service to the Nuri."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `3. '누리'는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.`
                    : "3. The Nuri is exempted from its responsibility for compensation if any form of damage occurs in the Web and Service by unavoidable reasons, such as repair, replacements, inspections, and construction of service facilities."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `4. '누리'는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.`
                    : "4. The Nuri is exempted from its responsibility for compensation if any form of damage occurs to the user due to his or her PC / mobile error. The Nuri is not liable for any loss and damage caused from the user providing wrong personal information to the Web during his or her registration process."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `5. '누리'는 서비스에 표출된 어떠한 의견이나 정보에 대해 확신이나 대표할 의무가 없으며 회원이나 제3자에 의해 표출된 의견을 승인하거나 반대하거나 수정하지 않습니다. '누리'는 어떠한 경우라도 회원의 서비스에 담긴 정보에 의존해 얻은 이득이나 입은 손해에 대해 책임이 없습니다.`
                    : "5. The Nuri has no obligation to confirm or represent any comment or information expressed in the Service by the users or third parties. The Nuri also does not have to approve, oppose, or modify any comment expressed by the members or third parties. The Nuri shall not be liable for any damage occurred to the users by collecting their personal information during the Web registration process."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `6. '누리'는 회원 간 또는 회원과 제3자 간에 서비스를 매개로 하여 물품거래 혹은 금전적 거래 등과 관련하여 어떠한 책임도 부담하지 아니하고, 회원이 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.`
                    : "6. The Nuri shall not bear any responsibility for transactions made between members or between members and third parties through the Service. The Nuri also shall not bear any responsibility for the any form of profit that the members expected through using the Web and Service."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `7. '누리'는 이용자가 서비스를 이용하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않으며, 회원이 본 서비스에 게재한 정보, 자료, 사실의 신뢰도 등 내용에 관하여는 책임을 지지 않습니다.`
                    : "7. The Nuri shall not be responsible for the user’s loss caused by him or herself obtaining wrong information through the Service and Web and shall not be responsible for the reliability of information posted on the Service and Web by the other members or third parties."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `8. '누리'는 서비스 이용과 관련하여 이용자에게 발생한 손해 중 이용자의 고의, 과실에 의한 손해에 대하여 책임을 부담하지 아니합니다.`
                    : "8. The Nuri shall not be liable for damages occurred to the users during the Service usage if the damages were intentionally or negligibly incurred by the members."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `9. '누리'는 '웹'이 제공한 서비스가 아닌 가입자 또는 기타 유관기관이 제공하는 서비스의 내용상의 정확성, 완전성 및 질에 대하여 보장하지 않습니다. 따라서 '누리'는 이용자가 위의 내용을 이용함으로 인하여 입게 된 모든 종류의 손실이나 손해에 대하여 책임을 부담하지 아니합니다. 또한 '누리'는 이용자가 서비스를 이용하며 타 이용자로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.`
                    : "9. Other than those provided by the Web and its administrators, the Nuri does not guarantee the reliability, completeness, and quality of any content posted on the Service and Web by the users or third parties. Therefore, the Nuri shall not be liable for any kind of loss or damage caused by the member's use of contents posted by other users and third parties. In addition, The Nuri is not responsible for compensating users for mental damage that they received from other users while using the Service and Web."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN"
                    ? `제20조(재판권 및 준거법)`
                    : "Article #20 (Jurisdiction and Compliance)"}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. 이 약관에 명시되지 않은 사항은 전기통신사업법 등 대한민국의 관계법령과 상관습에 따릅니다.`
                    : "1. Matters not specified in these Terms and Service are subject to the relevant laws and regulations of the Republic of Korea, such as the Telecommunications Business Act."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `2. 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 대한민국 서울중앙지방법원을 관할 법원으로 합니다.`
                    : "2. The Seoul Central District Court has jurisdiction over any legal matters arising from using the Web and Service."}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text2"
                      : "main_section_content_text2"
                  }
                >
                  {t("lang") === "EN" ? `<부칙>` : `<Supplementary Provision>`}
                </p>
                <p
                  className={
                    isMobileWidth
                      ? "mo_main_section_content_text3"
                      : "main_section_content_text3"
                  }
                >
                  {t("lang") === "EN"
                    ? `1. 본 약관은 2022년 11월 01일부터 적용됩니다.`
                    : "1. These Terms and Service will be effective from November 01, 2022."}
                </p>
              </div>
            </div>
          )}
          {(textChange === "회원가입 약관" ||
            textChangeEn === "회원가입 약관") && (
            <div>
              <div className="main_section_content">
                <div className="main_section_box">
                  <p className="main_section_title1 main_section_title2">
                    {t("lang") === "EN"
                      ? `주식회사 누리글로벌서비스 홈페이지 회원에 가입하시면 더욱 더 다양한 서비스를 이용하실 수 있습니다. 우리 사이트는 정보통신망 이용촉진에 관한 법률 등 관련 법령에 따라 아래와 같이 이용자의 동의를 받은 후 회원가입을 받고 있습니다.`
                      : `The purpose of these Terms and Conditions is to prescribe the rights, obligations, and responsibilities of the users and NURI Global Service Co., Ltd. (hereinafter referred to as the “Nuri") when using services provided by its websites (hereinafter referred to as "Web") and Internet-related services (hereinafter referred to as the "Service").`}
                  </p>
                </div>
              </div>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN" ? `제1조 (목적)` : "Article #1 (Purpose)"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3"
                    : "main_section_content_text3"
                }
              >
                {t("lang") === "EN"
                  ? `이 약관은 주식회사 누리글로벌서비스 (이하 “누리”)가 운영하는 누리글로벌서비스 웹사이트(이하 “웹”)에서 제공하는 용역 및 인터넷 관련 서비스(이하 “서비스”)를 이용함에 있어 이용자와 “누리”의 권리 · 의무 및 책임사항을 규정함을 목적으로 합니다.`
                  : `The purpose of these Terms and Conditions is to prescribe the rights, obligations, and responsibilities of the users and NURI Global Service Co., Ltd. (hereinafter referred to as the “Nuri") when using services provided by its websites (hereinafter referred to as "Web") and Internet-related services (hereinafter referred to as the "Service").`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제2조 (정의)`
                  : "Article #2 (Definition)"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3"
                    : "main_section_content_text3"
                }
              >
                {t("lang") === "EN"
                  ? `1. 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.`
                  : "1. The phrases used in these Terms and Conditions are defined as following:"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 이용계약: 서비스 이용과 관련하여 “누리”와 이용자 간에 체결하는 계약`
                  : "• The Contract of Usage (The Usage Contract): An agreement between the Nuri and the users when using the Web and Service provided by NURI Global Service Co., Ltd."}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 가입: “웹”이 제공하는 신청서 양식에 해당 정보를 기입하고, 본 약관에 동의하여 서비스 이용계약을 완료하는 행위`
                  : "• Registration: The process of the users agreeing with the Nuri’s Terms & Conditions and entering the information in an online registration form provided by the Web."}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 이용자: “누리”에서 제공하는 “웹”의 모든 형태의 서비스를 이용하는 회원 및 비회원`
                  : "• Visitors: Members and non-members who use all forms of the web services provided by the Nuri"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원: “웹”에 회원가입에 필요한 개인 정보를 제공하여 회원 등록을 한 자로서, “웹”의 정보 및 서비스를 이용할 수 있는 자`
                  : "• Members (Users): The person who has registered to the Web as a member by providing his or her personal information necessary for a complete registration process. The members can use information and services provided the Web and Nuri."}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 이용자 번호(ID): 이용 고객의 식별과 이용자의 원활한 회원가입을 위한 본인의 이메일 주소, 또는 관리자 및 이용자가 정한 문자와 숫자의 조합`
                  : "• User (Member) ID:  An e-mail address for identifying the members of the Web. The User ID could be a combination of Alphabets and numbers the assigned by the Web administrators or chosen by the member him or herself."}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 비밀번호(PW): 이용자가 등록 회원과 동일인인지 신원을 확인하고 통신상의 자신의 개인정보보호를 위하여 이용자 자신이 정한 문자와 숫자의 조합`
                  : "• User (Member) Password: A combination of letters and numbers that is necessary for the members to identify their Web membership and protect their personal information. The combination can be determined by the user him/herself."}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 게시물: “누리”가 회원에게 원활한 서비스 제공을 위해 “웹”에 게시하는 글, 이미지, 동영상 등 각종 파일과 링크 등, 또는, 회원이 “웹”에 게시하는 글, 이미지, 동영상 등 각종 파일과 링크 등`
                  : "• Posts: Various files and links, such as articles, images, videos, etc. posted by the Nuri on the Web for flexible provision of services to its members. It could also be various files and links, such as articles, images, videos, etc. posted on the Web by the users."}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 탈퇴(해지): 서비스 또는 회원이 이용계약을 종료하는 행위`
                  : "• Termination: An act of terminating the Service or a member's Contract of Usage"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 파트너: “누리”와 업무적으로 계약을 맺거나 업무 협력관계에 있는 모든 사업체, 단체, 또는 개인`
                  : "• Partners: Any business, organization, or individual who signed any form of contract with the Nuri"}
              </p>
              {t("lang") === "EN"
                ? `2. 본 약관에서 정의하지 않은 용어는 개별서비스에 대한 별도약관 및 이용 규정에서 정의합니다.`
                : "2. Any term or phrase that are not specified in the Article #2 are separately defined in other articles."}
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제3조 (약관의 명시와 개정)`
                  : "Article #3 (Clarification and Amendment of the Terms and Conditions)"}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 이 약관의 내용과 주소지, 관리자의 성명, 개인정보보호 담당자의 성명, 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가 알 수 있도록 “웹”의 초기 서비스 화면(전면)에 게시합니다.`
                  : "• The Nuri will post the copy of these Terms and Conditions on the Web. The Nuri will also post the administrator’s contact information, such as address, name, telephone number, fax number, e-mail address, etc., on the main screen of the Web."}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.`
                  : "• The Nuri may amend the Terms and Conditions to the extent that it does not violate the relevant laws, such as the Act on the Regulation of Terms and Service, the Framework Act on Electronic Transactions, the Electronic Signature Act, the Promotion of Information and Communication Network Use, etc."}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 “누리”의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.`
                  : `• If the Nuri revises the Terms and Conditions, it shall be notified on the main screen of the Web with the revised Terms and Service. The notification will be posted for 7 days from the day of notification to the day before the actual renewal date.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 이 약관에 동의하는 것은 정기적으로 “웹”을 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 “누리”에서 책임지지 않습니다.`
                  : `• Acceptance of these Terms and Conditions means that the users agree to visit the Web on a regular basis to confirm any changes made to the Terms and Conditions. The Nuri is not responsible for any damage caused by not recognizing any change made in the Terms and Conditions`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 회원이 변경된 약관에 동의하지 않을 경우, 회원 탈퇴(해지)를 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이후에도 거부의사를 표시하지 아니 하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.`
                  : `• If a member disagrees with the renewed Terms and Conditions, the Nuri may request withdrawal of his or her membership (cancellation of an account), and if the member continues to use the Service without expressing a rejection after 7 days from the renewal date, the Nuri will consider that the member has agreed to its Terms and Conditions.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 기타 관련 법령의 규정에 의합니다.`
                  : `• Any other detail that are not specified in the Terms and Conditions shall be governed by the Framework Act on Telecommunications, the Telecommunications Business Act, the Information and Communication Network Promotion Act, the Information Protection Act, and other relevant laws and regulations.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제4조(이용 계약의 성립)`
                  : `Article #4 (Establishment of the Usage Contract)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3"
                    : "main_section_content_text3"
                }
              >
                {t("lang") === "EN"
                  ? `• 이용계약은 이용자가 본 이용약관 내용에 대한 동의와 이용신청에 대하여 “누리”의 “웹” 이용 승낙으로 성립합니다.`
                  : `• The Usage Contract is established when the user agrees to the Terms and Conditions, AND the Nuri gives the user permission to use the Web.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text3"
                    : "main_section_content_text3"
                }
              >
                {t("lang") === "EN"
                  ? `• 본 이용약관에 대한 동의는 이용 신청 당시 해당 “웹”의 '동의함' 버튼을 누름으로써 의사 표시를 합니다.`
                  : `• The user agrees to the Terms and Conditions by pressing the ‘Agree’ button on the Web during the online registration process.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제5조(회원가입 및 탈퇴)`
                  : `Article #5 (Online Registration and Account Deletion)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원가입은 신청자(이용자)가 온라인으로 “누리”에서 제공하는 소정의 가입신청 양식에서 요구하는 사항을 기록하여 가입을 완료하는 것으로 성립됩니다.`
                  : `• Online registration in the Web is completed when the user fills out the entire registration form provided by the Nuri.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 다음 각 호에 해당하는 회원에 대하여는 가입을 취소할 수 있습니다.`
                  : `• The Nuri may cancel the membership for the following members:`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 다른 사람의 명의를 사용하여 신청하였을 때`
                  : `• When the user registers the Web with another person's name`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원가입 신청서의 내용을 허위로 기재하였거나 신청하였을 때`
                  : `• When the user registers the Web with false information`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 사회의 안녕 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때`
                  : `• When the user registers the Web with the purpose of hindering the well-being and order of society.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 다른 사람의 “웹” 이용을 방해하거나 정보를 도용하는 등의 행위를 하였을 때`
                  : `• When the user interrupts another person’s use of the Web or steals someone’s personal information`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “웹”을 이용하여 법령과 본 약관이 금지하는 행위를 하는 경우`
                  : `• When the user uses the Web to engage in action that is prohibited by domestic & international laws and the Nuri’s Terms and Service`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 기타 “누리”가 정한 회원가입 요건이 미비 되었을 때`
                  : `• When the user fails to provide other necessary requirements for the membership registration. `}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 어떠한 경우라도 “누리”, “파트너”, 그리고 타 이용자에게 사회적 및 금전적 손해를 야기할 수 있다고 판단되는 이용자일 경우`
                  : `• When the user is deemed to cause social and financial damage to the Nuri, the Partners, and other users in any case.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 기존에 “누리”, “파트너”, 그리고 타 이용자에게 사회적 및 금전적 피해를 끼친 이력이 있는 이용자일 경우`
                  : `• When the user has a record of causing social and financial damage to the Nuri, the Partners, and other users in any case.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “웹”은 다음 각 항에 해당하는 경우 그 사유가 해소될 때까지 이용계약 성립을 유보할 수 있습니다.`
                  : `• The Web may withhold or postpone the member’s Usage Contract until the following issues are resolved:`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 서비스 관련 제반 용량이 부족한 경우`
                  : `• When the Web has insufficient server capacity for all Services`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 기술상 장애 사유가 있는 경우`
                  : `• When the Web is experiencing technical issues`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”, “파트너”, 그리고 타 이용자에게 사회적 및 금전적 손해를 야기할 수 있다고 판단되는 이용자일 경우`
                  : `• When the user is causing social and financial damage to the Nuri, the Partners, and other users`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 “웹”을 자체 개발하거나 다른 기관과의 협의 등을 통하여 일체의 서비스를 제공하며, 변경 시 변경될 서비스의 내용을 이용자에게 공지하고 제공할 수 있습니다.`
                  : `• The Nuri can develop the Web with self-development or through partnership with other organizations. Any changes made to the Web and Service will be notified to all users.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원은 등록사항에 변경이 있는 경우, 즉시 회원정보 수정 등 기타 방법으로 “누리”에 대하여 그 변경사항을 알려야 합니다.`
                  : `• The user shall immediately notify the Nuri of any change made to his or her membership information.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원은 가입 이후 “웹”에서 제공하는 서비스를 제공받을 의사가 없는 등의 사유가 있을 경우에는 언제든지 회원탈퇴(해지)를 할 수 있습니다. 다만, 이용자가 회원가입 시 제공하였던 모든 내용은 ”웹”에서 탈퇴 일로부터 2년간 보유하며, 2년이 지난 후 즉시 삭제됩니다.`
                  : `• The user may cancel his or her membership at any time if he or she has no further intention of receiving any form of service provided by the Web. However, all the contents including personal information provided by the user in the Web will be held for two years since the date of his or her membership cancellation on the Web. Such data will be deleted immediately after two years from the user’s membership cancellation date.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제6조(이용자번호 부여 및 변경 등)`
                  : `Article #6 (Assignment and Modification of User Identification)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 이용고객에 대하여 약관에 정하는 바에 따라 이용자 ID를 부여하거나, 이용자 본인이 설정한 이메일 계정을 ID로 설정합니다.`
                  : `• The Nuri assigns the User Identification (ID) to the users according to the Terms and Conditions. The users also can set up their personal or business email account as User ID.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 이용자번호(ID)는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는 해당 ID를 해지하고 재가입해야 합니다.`
                  : `• The general rule is that the User ID cannot be changed at any instance. However, if the user must change his or her ID due to unavoidable reasons, he or she may cancel the current membership and rejoin the Web through the registration process.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 이용자번호(ID)는 다음 각 호에 해당하는 경우에는 이용자의 요청으로 변경할 수 있습니다.`
                  : `• The User ID may be changed with the request of the user in any of the following cases:`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 이용자번호(ID)가 이용자의 전화번호 또는 주민등록번호 등으로 등록되어 사생활 침해가 우려되는 경우`
                  : `• If the User ID contains the user's phone number or Resident Registration Number so that there is a risk of leaking his or her personal information to the public`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우`
                  : `• If the User ID disturbs others or goes against the customs of good manners`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 기타 합리적인 사유가 있는 경우`
                  : `• If there are other reasonable (acceptable) reasons for the change`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 서비스 이용자번호(ID) 및 비밀번호의 관리 및 책임은 이용자에게 있습니다. 이를 소홀히 관리하여 발생하는 서비스 이용 상의 손해 또는 제3자에 의한 부정이용 등에 대한 책임은 이용자에게 있으며 “누리”는 그에 대한 책임을 일절 지지 않습니다.`
                  : `• The user is fully responsible for his or her User ID and password. The user is also fully responsible for any damage caused by his or her negligence or 3rd party’s illegal activities such as hacking. The Nuri does not have to compensate the users for the issues stated above.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제7조(회원정보 사용에 대한 동의)`
                  : `Article #7 (Consent on Using the Members’ Personal Information)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”의 “웹” 회원정보는 “개인정보보호법” 및 “개인정보처리방침” 의해 보호됩니다.`
                  : `• Any personal information provided by the Web users during the registration process is protected by the Personal Information Protection Act and the Personal Information Processing Policy.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원의 개인정보는 오직 본인만이 열람/수정/삭제할 수 있으며, 이는 전적으로 회원의 ID와 비밀번호에 의해 관리되고 있습니다. 따라서 타인에게 본인의 ID와 비밀번호를 알려주어서는 아니되며, 작업 종료 시에는 반드시 로그아웃하고, 웹 브라우저의 창을 닫아야 합니다. (이는 타인과 컴퓨터를 공유하는 인터넷 카페나 도서관 같은 공공장소에서 컴퓨터를 사용하는 경우에 본인의 정보보호를 위하여 필요한 사항입니다)`
                  : `• Only the user him/herself can view, modify, or delete his or her personal information by logging in to the Web with User ID and password. Thus, the members should not share their User ID and password with others and must log-out before they close web browsers. (This is particularly important when using`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원이 “누리”에 본 약관에 따라 이용신청을 하는 것은 “웹”에서 본 약관에 따라 신청서에 기재된 회원정보를 수집, 이용하는 것에 동의하는 것으로 간주됩니다.`
                  : `• The user’s completion of the Web registration process will be considered as acceptance for the Nuri to collect and use the user information.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제8조(이용자의 정보 보안)`
                  : `Article #8 (Protection of the User’s Information)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원은 “웹”의 서비스 가입 절차를 완료하는 순간부터 입력한 정보의 비밀을 유지할 책임이 있으며, ID와 비밀번호를 사용하여 발생하는 모든 결과에 대한 책임은 본인에게 있습니다.`
                  : `• The users are fully responsible for keeping confidentiality of the information they provided to the Web. The users are also fully responsible for any consequences related with their User IDs and passwords.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• ID와 비밀번호에 관한 모든 관리의 책임은 본인에게 있으며, 본인의 ID나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시 “누리”에 신고하여야 합니다. 신고를 하지 않음으로 인해 발생하는 모든 책임은 회원 본인에게 있습니다.`
                  : `• The users are fully responsible of their User IDs and passwords. If the users find out that their User IDs or passwords have been used illegally by 3rd parties, they must report such issue to the Nuri’s administrators immediately. Even though the users’ accounts get compromised by 3rd parties illegally, the users will be fully responsible if they do not report the issue to the Nuri’s administrators immediately.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 이용자는 “웹”의 서비스의 사용 종료 시마다 정확히 로그아웃(Log-out)해야 하며, 로그아웃 하지 아니하여 제3자가 회원 정보를 도용하는 등의 결과로 인해 발생하는 손해 및 손실에 대하여 “누리”는 책임을 부담하지 아니합니다.`
                  : `• The users must completely log-out from the Web after they are done with using the Service. The Nuri shall not be liable for any form of damage caused by not logging out from computers and mobile phones.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제9조(서비스 이용시간)`
                  : `Article #9 (Service Hours)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 서비스 이용시간은 “누리”의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.`
                  : `• The regular Web service hours shall be 24 hours a day, 7 days a week (all year round), unless the Nuri experiences any form of issues that interrupt its business operations.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 제1항의 이용시간 중 정기점검 등의 필요로 인하여 “누리”가 정한 날 또는 시간은 예외로 합니다.`
                  : `• Exceptions shall be made to the date or time determined by the Nuri due to the need for regular inspections, etc. during the hours of use referred to in Article #9 (1).`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제10조(서비스의 중지 및 중지에 대한 공지)`
                  : `Article #10 (Suspension of Services and an Announcement)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 이용자는 “웹”의 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 국가의 비상사태, 정전, “웹”의 관리 범위 외의 서비스 설비 장애 및 기타 불가항력에 의하여 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 “누리”는 관련 책임을 부담하지 아니합니다.`
                  : `• The Nuri shall not be liable for any loss of data, such as messages transmitted through the Web and Service, due to national emergencies, power outages, failure of service facilities, or natural disasters.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”가 정상적인 서비스 제공의 어려움으로 인하여 일시적으로 “웹”의 서비스를 중지하여야 할 경우에는 서비스 중지 전에 중지사유 및 일시를 공지한 후 서비스를 중지할 수 있으며, 회원이 공지내용을 인지하지 못한 데 대하여 “누리”는 책임을 부담하지 아니합니다. 또한 위 서비스 중지에 의하여 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 대하여도 “누리”는 책임을 부담하지 아니합니다.`
                  : `• If the Nuri temporarily discontinues to provide the Web and Service due to difficulties in operating and maintaining servers, the Nuri may suspend the Web and Service after notifying the reason and date of suspension to the users. The Nuri is also not responsible for any disadvantages that the users could receive from not confirming the notice. In addition, the Nuri shall not be liable for any loss of communication data, such as messages stored in the server or transmitted through the Web, due to the suspension of the Service and Web.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”의 사정으로 “웹”의 서비스를 영구적으로 중단하여야 할 경우에는 제2항에 의거합니다.`
                  : `• If the Web and Service must be permanently suspended due to the Nuri’s private circumstances, it shall also be governed by the Article #10 (2).`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 사전 공지 후 모든 서비스와 정보를 일시적, 또는 영구적으로 수정, 변경 및 중단할 수 있으며, 이에 대하여 회원 또는 제3자에게 어떠한 책임도 부담하지 아니합니다.`
                  : `• The Nuri may temporarily or permanently modify, delete, and suspend the Web and Service (including the related information) with prior notifications to the users; the users and other third parties shall not bear any responsibilities from the Nuri modification, removal, and suspension of the Web and Service.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고 없이 일시적으로 “웹”의 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 “누리”가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.`
                  : `• The Nuri may temporarily suspend the Web and Service without any notification WITH unavoidable reasons such as urgent system inspection, expansion, and replacement. The Nuri also may completely suspend the Web and Service WITH any reasons that the Nuri considered to be adequate or necessary for the Nuri’s business operations.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 “웹”의 서비스 제공이 불가능할 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만 이 경우 그 사유 및 기간 등을 이용자에게 사전 또는 사후에 공지합니다.`
                  : `• The Nuri may restrict or suspend all or part of the Web and Service if the Nuri is further incapable of providing any form of service due to national emergencies, power outages, facility failures, or server saturation. However, in this case, the reason and expected period of suspension will be notified to the users in advance or afterward.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”가 통제할 수 없는 사유로 인한 “웹”의 서비스 중단의 경우(시스템 관리자의 고의 과실 없는 디스크 장애, 시스템 다운 등)에 사전통지가 불가능하며 타인(PC통신회사, 기간통신사업자 등)의 고의 과실로 인한 시스템 중단 등의 경우에는 통지하지 않습니다.`
                  : `• In case when the Web and Service operations are interrupted due to reasons beyond control of the Nuri’s administrators, posting prior notifications will not be possible. ‘Reasons beyond control’ can be an accidental disk failure, server outage, and other possible incidents that could obstruct the Web and Service in any way. The Nuri is also not responsible for posting notifications about system disruption caused by other users’ or third parties’ (telecommunication service providers, internet service providers, etc.) intentional negligence.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 “웹”의 서비스를 특정범위로 분할하여 각 범위별로 이용가능 시간을 별도로 지정할 수 있습니다. 다만 이 경우 그 내용을 공지합니다.`
                  : `• The Nuri can separate the Web and Service into specific parts and specify available service hours for each of them. However, in this case, the related notifications will be announced to all visitors.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 회원이 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지하거나 회원의 동의 없이 이용계약을 해지할 수 있습니다. 이 경우 “누리”는 위 이용자의 “웹” 접속을 금지할 수 있습니다.`
                  : `• The Nuri may arbitrarily restrict and suspend the use of the Web and Service or terminate the Usage Contract without consent from the member IF the member violates the Nuri’s Terms and Service. In this case, the Nuri may prohibit the member from accessing the Web and Service.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제11조(정보 제공 및 홍보물 게재)`
                  : `Article #11 (Provision of Information and Posting Advertisement)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 서비스를 운영함에 있어서 각종 정보를 “웹”에 게재하는 방법, 전자우편, 서신우편, 휴대폰 메시지 발송 등으로 회원에게 제공할 수 있습니다.`
                  : `• The Nuri may provide various information related with the Service and business to members through Web-posting, e-mail, letters, or mobile text messages.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 서비스에 적절하다고 판단되거나, 상업성 및 공익성이 있는 홍보물을 게재할 수 있습니다.`
                  : `• The Nuri may publish marketing materials or advertisements that are appropriate for the promotion of the Web and Service or that are beneficial to public interests.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제12조 (“웹” 게시물의 관리 및 운영)`
                  : `Article #12 (Web-contents Management)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”가 게시한 게시물의 내용에 대한 권리는 “누리”에게 있으며, 회원이 게시한 게시물의 내용에 대한 권리는 회원에게 있습니다.`
                  : `• All rights of the Web-contents posted by the Nuri belongs to NURI Global Service Co., Ltd. All rights of the Web-contents posted by the user belongs to the user him/herself.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 게시된 내용을 사전 통지 없이 편집, 이동할 수 있는 권리를 보유하며, 다음의 경우 사전 통지 없이 삭제할 수 있습니다.`
                  : `• The Nuri reserves the rights to edit and relocate published any Web-content without prior notice. The Nuri also may delete any Web-contents without prior notice in following cases:`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 본 서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우`
                  : `• If the Web-contents are deemed to be violating the Nuri’s Terms and Service, or is deemed to be commercial, illegal, obscene, or vulgar`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우`
                  : `• If the Web-contents have a purpose of defaming other visitors, members, or any other third party`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 공공질서 및 미풍양속에 위반되는 내용인 경우`
                  : `• If the Web-contents violate the public order and well-being of society`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 범죄적 행위에 결부된다고 인정되는 내용일 경우`
                  : `• If the Web-contents are found to be related with crime or illegal activities`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우`
                  : `• If the Web-contents infringe copyright of other users and third parties`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 기타 관계 법령에 위배되는 경우`
                  : `• If the Web-contents violate domestic and international laws`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원의 게시물이 타인의 저작권을 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 회원 본인이 부담하여야 합니다.`
                  : `• The person who infringed other members’ posts and copyrights in the Web shall be responsible for the related legal claims, compensation, etc.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제13조(서비스 이용제한)`
                  : `Article #13 (Restriction on Use of the Service)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원이 제공하는 정보의 내용이 허위인 것으로 판명되거나, 허위가 있다고 의심할 만한 합리적인 사유가 발생할 경우 “누리”는 회원의 본 서비스 사용을 일부 또는 전부 중지할 수 있으며, 이로 인해 발생하는 불이익에 대해 책임을 부담하지 아니합니다.`
                  : `• If the information provided by the member is found to be false or the administrator reasonably suspects that the information is false, the Nuri may partially or fully suspend the member’s access to the Web and Service.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 회원이 본 약관 제15조(회원의 의무) 등 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지할 수 있습니다. 이 경우 “누리”은 회원의 접속을 금지할 수 있으며, 법적 소송으로 대응할 수 있습니다.`
                  : `• The Nuri may arbitrarily restrict and suspend the use of the Service if the member violates the Terms and Service, such as Article #15 of this agreement (The Member's Obligations and Responsibilities). In this case, the Nuri may prohibit members from accessing the Web and may respond him/her with legal action.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제14조(“누리”의 의무)`
                  : `Article #14 (The Nuri’s Obligations and Responsibilities)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 지속적이고 안정적으로 서비스를 제공하기 위해 노력할 의무가 있습니다.`
                  : `• The Nuri is obligated to continuously check and maintain the Web server for its stability. The Nuri is also obligated to prohibited posts that goes against domestic and international laws and regulations.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 회원의 개인정보를 본인의 승낙 없이 타인에게 누설, 배포하지 않습니다. 다만, 개인정보보호법 제18조에 의해 제공해야할 경우 그러하지 아니합니다.`
                  : `• The Nuri does not manipulate or distribute the member's personal information to others without his or her consent, UNLESS it needs to be provided to 3rd parties according to the Article 18 of the Personal Information Protection Act.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 이용자가 안전하게 “웹”의 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위한 보안시스템을 갖추어야 합니다.`
                  : `• The Nuri shall operate a security system to protect users' personal information (including personal credit information) so that the users can safely use the Service.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 이용자의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지 않습니다.`
                  : `• The Nuri is not responsible for any Web service disorder solely caused by the user him or herself.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제15조(회원의 의무)`
                  : `Article #15 (The Members’ Obligations and Responsibilities)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원가입 시에 요구되는 정보는 정확하게 기입하여야 합니다. 또한 이미 제공된 회원에 대한 정보가 정확한 정보가 되도록 유지, 갱신하여야 하며, 회원은 자신의 ID 및 비밀번호를 제3자가 이용하게 해서는 안 됩니다.`
                  : `• The member must accurately fill out information required during the Web registration process. In addition, the member shall continuously maintain his or her information provided to the Web up to date for accuracy. The member also should not allow any third party to use his or her Web IDs and passwords.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원의 부정확한 개인정보 입력으로 인하여 받는 법적, 금전적 불이익은 “누리”에서 책임지지 않습니다.`
                  : `• The Nuri is not responsible for any legal claims and financial damage caused by the member inputting incorrect personal information during the Web registration process.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원은 “누리”의 사전 승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.`
                  : `• The members shall not copy, duplicate, alter, translate, publish, broadcast, or otherwise use the information obtained through the Service and Web without the prior consent of the Nuri. Any materials earned from activities stated above must not be shared to others.
                  `}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원은 “웹”의 서비스를 이용하여 얻은 정보를 “누리”의 사전승낙 없이 복사, 복제, 변경, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.`
                  : `• The members shall not engage in any profit-making activities using the Service without the prior consent of the Nuri.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원은 “웹”의 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안 됩니다.`
                  : `• The members shall not engage in any of the following acts when using the Web and Service:`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 다른 회원의 비밀번호와 ID를 도용하여 부정 사용하는 행위`
                  : `• Infringing other members' accounts and using them illegally`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 저속, 음란, 모욕적, 위협적이거나 타인의 개인정보를 침해할 수 있는 내용을 전송, 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위`
                  : `• Sending, e-mailing, or otherwise transmitting information that is vulgar, obscene, insulting, threatening, or may infringe on another person's personal information.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 서비스를 통하여 전송된 내용의 출처를 위장하는 행위`
                  : `• Disguising the source of the Web-contents through the Service`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 법률, 계약에 의해 이용할 수 없는 내용을 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위`
                  : `• Posting, publishing, e-mailing, or otherwise transmitting unlawful information`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 타인의 특허, 상표, 영업비밀, 저작권, 기타 지적재산권을 침해하는 내용을 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위`
                  : `• Posting, publishing, e-mailing, or otherwise transmitting information that could infringe another person's patents, trademarks, trade confidential, copyrights, or other intellectual property rights`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”의 승인을 받지 아니한 광고, 판촉물, 스팸메일, 행운의 편지, 피라미드 조직 기타, 다른 형태의 권유를 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위`
                  : `• Posting, publishing, e-mailing, or otherwise transmitting advertisements, promotional materials, spam mails, or other forms of solicitation, without the approval of the Nuri’s administrators Collecting or keeping other users' personal information`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 다른 사용자의 개인정보를 수집 또는 저장하는 행위`
                  : `• Participating in criminal activities or criminal-related activities`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위`
                  : `• Participating in any form of activity that harms goodness and order of society`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 선량한 풍속, 기타 사회질서를 해하는 행위 • 타인의 명예를 훼손하거나 모욕하는 행위`
                  : `• Defaming or insulting others`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 타인의 지적재산권 등의 권리를 침해하는 행위`
                  : `• Violating another person's intellectual property rights`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 해킹행위 또는 컴퓨터바이러스의 유포행위`
                  : `• Hacking or spreading viruses`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위`
                  : `• Sending advertisements or other information against the will of others`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위`
                  : `• Participating in any act that hinders or may hinder the Service management`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 타 전기통신사업법 제53조 제1항과 전기통신사업법 시행령 16조(불온통신)에 위배되는 행위`
                  : `• Violating the Article 53 (1) of the Telecommunications Business Act and Article 16 of the Enforcement Decree of the Telecommunications Business Act.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제16조(“누리”의 소유권)`
                  : `Article #16 (The Nuri’s Ownership and Copyrights)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”가 “웹”에서 제공하는 용역, 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스 명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타 권리는 “누리”에게 소유권이 있습니다`
                  : `• All the intellectual property and other rights related to the Service, including software, images, marks, logos, designs, information, and trademarks, provided by the Nuri on the Web shall be owned by the Nuri.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 모든 이용자는 “누리”가 명시적으로 승인한 경우를 제외하고는 전항의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재라이센스, 담보권 설정 행위, 상업적 이용 행위를 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.`
                  : `• Unless authorized by the Nuri, no user shall modify, lend, sell, distribute, manufacture, transfer, re-license, establish, or exploit the Nuri’s property rights stated in the preceding paragraphs. The users also do not have rights to allow any third party to modify, lend, sell, distribute, manufacture, transfer, re-license, establish, or exploit the Nuri’s property rights stated in the preceding paragraphs.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제17조(양도금지)`
                  : `Article #17 (Prohibition of Transfer)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 회원이 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.`
                  : `• Members cannot transfer or offer the right to use the Service to others and cannot use the Service and Web as collateral.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제18조(손해배상)`
                  : `Article #18 (Compensation)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 무료로 제공되는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 “누리”가 고의로 행한 범죄행위를 제외하고는 이에 대하여 책임을 부담하지 아니합니다.`
                  : `• The Nuri shall not be liable for any damage the member received from the Service with free of charge unless the Service was provided with intention of participating or assisting criminal activities.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 이용자가 어떠한 경우라도 “웹”의 정상적인 운영을 방해하거나 피해를 끼칠 시, 이용자에게 이에 대한 법적책임과 손해배상을 물을 수 있습니다.`
                  : `• The Nuri may take legal action and request compensation against the user who damages the Web and Service in any direction.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제19조(면책조항)`
                  : `Article #19 (Exemption Clause)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• "누리"는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.`
                  : `• The Nuri is exempted from its responsibility for compensation if the Service operation is inevitably impossible due to natural disasters, wars, or other forms of irresistible force.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.`
                  : `• The Nuri is exempted from its responsibility for compensation if the mobile carrier or internet service supplier fail to provide its service to the Nuri.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.`
                  : `• The Nuri is exempted from its responsibility for compensation if any form of damage occurs in the Web and Service by unavoidable reasons, such as repair, replacements, inspections, and construction of service facilities.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.`
                  : `• The Nuri is exempted from its responsibility for compensation if any form of damage occurs to the user due to his or her PC / mobile error. The Nuri is not liable for any loss and damage caused from the user providing wrong personal information to the Web during his or her registration process.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 서비스에 표출된 어떠한 의견이나 정보에 대해 확신이나 대표할 의무가 없으며 회원이나 제3자에 의해 표출된 의견을 승인하거나 반대하거나 수정하지 않습니다. “누리”는 어떠한 경우라도 회원의 서비스에 담긴 정보에 의존해 얻은 이득이나 입은 손해에 대해 책임이 없습니다.`
                  : `• The Nuri has no obligation to confirm or represent any comment or information expressed in the Service by the users or third parties. The Nuri also does not have to approve, oppose, or modify any comment expressed by the members or third parties. The Nuri shall not be liable for any damage occurred to the users by collecting their personal information during the Web registration process.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 회원 간 또는 회원과 제3자 간에 서비스를 매개로 하여 물품거래 혹은 금전적 거래 등과 관련하여 어떠한 책임도 부담하지 아니하고, 회원이 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.`
                  : `• The Nuri shall not bear any responsibility for transactions made between members or between members and third parties through the Service. The Nuri also shall not bear any responsibility for the any form of profit that the members expected through using the Web and Service.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 이용자가 서비스를 이용하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않으며, 회원이 본 서비스에 게재한 정보, 자료, 사실의 신뢰도 등 내용에 관하여는 책임을 지지 않습니다.`
                  : `• The Nuri shall not be responsible for the user’s loss caused by him or herself obtaining wrong information through the Service and Web and shall not be responsible for the reliability of information posted on the Service and Web by the other members or third parties.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 서비스 이용과 관련하여 이용자에게 발생한 손해 중 이용자의 고의, 과실에 의한 손해에 대하여 책임을 부담하지 아니합니다.`
                  : `• The Nuri shall not be liable for damages occurred to the users during the Service usage if the damages were intentionally or negligibly incurred by the members.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• “누리”는 “웹”이 제공한 서비스가 아닌 가입자 또는 기타 유관기관이 제공하는 서비스의 내용상의 정확성, 완전성 및 질에 대하여 보장하지 않습니다. 따라서 “누리”는 이용자가 위의 내용을 이용함으로 인하여 입게 된 모든 종류의 손실이나 손해에 대하여 책임을 부담하지 아니합니다. 또한 “누리”는 이용자가 서비스를 이용하며 타 이용자로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.`
                  : `• Other than those provided by the Web and its administrators, the Nuri does not guarantee the reliability, completeness, and quality of any content posted on the Service and Web by the users or third parties. Therefore, the Nuri shall not be liable for any kind of loss or damage caused by the member's use of contents posted by other users and third parties. In addition, The Nuri is not responsible for compensating users for mental damage that they received from other users while using the Service and Web.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `제20조(재판권 및 준거법)`
                  : `Article #20 (Jurisdiction and Compliance)`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 이 약관에 명시되지 않은 사항은 전기통신사업법 등 대한민국의 관계법령과 상관습에 따릅니다.`
                  : `• Matters not specified in these Terms and Service are subject to the relevant laws and regulations of the Republic of Korea, such as the Telecommunications Business Act.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `• 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 대한민국 서울중앙지방법원을 관할 법원으로 합니다.`
                  : `• The Seoul Central District Court has jurisdiction over any legal matters arising from using the Web and Service.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN" ? `<부칙>` : `<Supplementary Provision>`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text4"
                    : "main_section_content_text4"
                }
              >
                {t("lang") === "EN"
                  ? `1. 본 약관은 2022년 11월 01일부터 적용됩니다.`
                  : `1. These Terms and Service will be effective from November 01, 2022.`}
              </p>
              <p
                className={
                  isMobileWidth
                    ? "mo_main_section_content_text2"
                    : "main_section_content_text2"
                }
              >
                {t("lang") === "EN"
                  ? `위 약관을 모두 숙지하였으며 회원가입에 동의합니다.`
                  : `I have understood all the Terms and Conditions above and agree to complete the Web membership registration process.`}
              </p>
            </div>
          )}
        </TermServiceInner>
      </SubLayout>
    </Layout>
  );
};

export default TermsService;

const TermServiceInner = styled.div`
  padding-bottom: 100px;

  & .service_tab {
    margin: 0 auto;
    & ul {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64px;
      text-align: center;
      font-size: 26px;
      font-weight: bold;
      cursor: pointer;
    }

    & .switch-style-on {
      font-weight: bold;
      color: #c09260;
    }
    & .switch-style-off {
      color: #707070;
    }
    & span {
      font-size: 35px;
      margin: 0 12px;
      color: #b2b2b2;
    }
  }

  & .mo_service_tab {
    margin: 0 auto;
    & ul {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    }

    & .switch-style-on {
      font-weight: bold;
      color: #c09260;
    }
    & .switch-style-off {
      color: #707070;
    }
    & span {
      font-size: 35px;
      margin: 0 12px;
      color: #b2b2b2;
    }
  }

  & .main_section_box {
    margin: 60px 0 50px;
    padding: 30px 30px 30px 30px;
    background-color: var(--bg-color2);
  }

  & .main_section_title1 {
    font-family: "Pretendard";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
  }

  & .main_section_content_text1 {
    font-family: "Arita-buri-SemiBold";
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary-color2);
    margin-top: 80px;
    margin-bottom: 40px;
  }

  & .mo_main_section_content_text1 {
    font-family: "Arita-buri-SemiBold";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary-color2);
    margin-top: 40px;
    margin-bottom: 20px;
  }

  & .main_section_content_text2 {
    font-family: "Pretendard";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
    margin-bottom: 16px;
    margin-top: 50px;
  }

  & .mo_main_section_content_text2 {
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
    margin-bottom: 16px;
    margin-top: 20px;
  }

  & .main_section_content_text3 {
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
    margin-bottom: 12px;
  }

  & .mo_main_section_content_text3 {
    font-family: "Pretendard";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
    margin-bottom: 12px;
  }

  & .main_section_content_text4 {
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
    padding-left: 14px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  & .mo_main_section_content_text4 {
    font-family: "Pretendard";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-color2);
    padding-left: 14px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;
