import * as yup from "yup";

export default yup.object().shape({
  PromotionTitle: yup.string().required("Please enter the title."),
  PromotionRegDtm: yup.string().required("Please select Start Date."),
  PromotionDeadline: yup.string().required("Please select End Date."),
  PromotionSite: yup
    .string()
    .required("Please enter your website address.")
    .matches(
      /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi,
      "Please enter your website address."
    ),
  PromotionContent: yup.string().required("Please enter the content."),
});
